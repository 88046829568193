import { Inject, Injectable, InjectionToken } from '@angular/core';
import en_us from '../i18n/ag-grid/en_us';
import { IAgGridLocale } from '../i18n/ag-grid/locale.interface';
import pt_br from '../i18n/ag-grid/pt_br';
import { BehaviorSubject, Observable, scheduled } from 'rxjs';

export const AG_GRID_LOCALE = new InjectionToken<IAgGridLocale>(
  'AG_GRID_LOCALE'
);

export type LocaleTypes = 'pt_br' | 'en_us';

@Injectable({
  providedIn: 'root',
})
export class PsLocalizationService {
  private localizations: { [key: string]: IAgGridLocale } = {
    pt_br,
    en_us,
  };
  private currentLocale: BehaviorSubject<IAgGridLocale> = new BehaviorSubject(
    this.localizations.pt_br
  );

  constructor(@Inject(AG_GRID_LOCALE) private agGridLocale: IAgGridLocale) {}

  public setLocale(locale: LocaleTypes) {
    const findLocale = this.localizations[locale];

    if (!findLocale) {
      throw new Error(
        `[${PsLocalizationService.name}]: Locale (${locale}) não encontrado`
      );
    }

    this.currentLocale.next(findLocale);
  }

  public getLocale(): Observable<IAgGridLocale> {
    return this.currentLocale;
  }
}
