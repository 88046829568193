import { NgModule } from '@angular/core';
import { FullLayoutComponent } from './full-layout/full-layout.component';
import { CommonLayoutComponent } from './common-layout/common-layout.component';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { PsComponentsModule } from '../components';
import { NzBreadCrumbModule, NzIconModule } from '../antd';
import { NzLayoutModule } from 'ng-zorro-antd/layout';

@NgModule({
  declarations: [FullLayoutComponent, CommonLayoutComponent],
  imports: [
    CommonModule,
    RouterModule,
    PsComponentsModule,
    NzBreadCrumbModule,
    NzLayoutModule,
    NzIconModule,
  ],
  exports: [FullLayoutComponent, CommonLayoutComponent],
})
export class PsLayoutModule {}
