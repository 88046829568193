import { NgModule, ModuleWithProviders } from '@angular/core';
import { PsGridComponent } from './ps-grid.component';
import { CommonModule } from '@angular/common';
import {
  NzTableModule,
  NzCardModule,
  NzButtonModule,
  NzFormModule,
  NzSwitchModule,
  NzDividerModule,
  NzToolTipModule,
  NzRadioModule,
  NzTagModule,
  NzSpinModule,
  NzAlertModule,
} from '../../antd';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { PsDynamicFormModule } from '../ps-dynamic-form';
import { GridConfig, GRID_CONFIG } from './models/grid.config';
import { PsDirectiveModule } from '../../directives/ps-directives.module';
import { AgGridModule } from 'ag-grid-angular';
import 'ag-grid-enterprise';
import { CustomLoadingOverlay } from './ag-grid/components/custom-loading-overlay.component';
import { GridFormlyCellComponent } from './types/grid-formly-cell.component';

@NgModule({
  declarations: [
    PsGridComponent,
    GridFormlyCellComponent,
    CustomLoadingOverlay,
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    PsDynamicFormModule,
    PsDirectiveModule,
    NzTableModule,
    NzCardModule,
    NzButtonModule,
    NzFormModule,
    NzTagModule,
    NzSwitchModule,
    NzDividerModule,
    NzToolTipModule,
    NzAlertModule,
    NzRadioModule,
    NzSpinModule,
    AgGridModule.withComponents([GridFormlyCellComponent]),
  ],
  exports: [PsGridComponent],
})
export class PsGridModule {
  static forRoot(config: GridConfig): ModuleWithProviders<PsGridModule> {
    return {
      ngModule: PsGridModule,
      providers: [{ provide: GRID_CONFIG, useValue: config }],
    };
  }
}
