import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  Output,
} from '@angular/core';
import { FormGroup } from '@angular/forms';
import { FormlyFieldConfig, FormlyFormOptions } from '@ngx-formly/core';
import * as _ from 'lodash';
import { NzMessageService } from '../../antd';
import { BehaviorSubject } from 'rxjs';
import { getField } from '../ps-dynamic-form/utils/utils';

export class Constants {
  static UNIQUE = 'unique';
  static REPEATED = 'repeated';

  static LIMITADO = 'limitado';
}

@Component({
  // tslint:disable-next-line: component-selector
  selector: 'ps-filter',
  templateUrl: './ps-filter.component.html',
  styleUrls: ['./ps-filter.scss'],
  // changeDetection: ChangeDetectionStrategy.OnPush
})
export class PsFilterComponent {
  @Input() fields: FormlyFieldConfig[];
  @Input() fieldsAvancado: FormlyFieldConfig[];

  // tslint:disable-next-line: no-input-rename
  @Input('filterKey') filterKey = 'filter';

  // tslint:disable-next-line: no-output-rename
  @Output('filterAppliedChanged')
  filterAppliedChange: EventEmitter<any> = new EventEmitter<any>();

  // tslint:disable-next-line: no-output-rename
  @Output('filterSubmitted')
  filterSubmitted: EventEmitter<any> = new EventEmitter<any>();

  model = {};
  modelAvancado = {};
  operador;
  filtersApplied: BehaviorSubject<any[]> = new BehaviorSubject<any>([]);
  filtersAppliedObs = this.filtersApplied.asObservable();

  currentFieldApplied: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  currentFieldAppliedObs = this.currentFieldApplied.asObservable();

  form: FormGroup = new FormGroup({});
  formAvancado: FormGroup = new FormGroup({});
  options: FormlyFormOptions = {};

  constructor(
    private messageService: NzMessageService,
    private cdr: ChangeDetectorRef
  ) {}
  submit() {}

  handleClose(filterRemoved) {
    const filters = this.filtersApplied
      .getValue()
      // tslint:disable-next-line: no-shadowed-variable
      .filter((filter) => !_.isEqual(filter, filterRemoved));
    this.filtersApplied.next(filters);
    this.toggleFilterOption(filterRemoved, false);
  }

  applyFilter() {
    this.filterSubmitted.emit(
      this.filtersApplied
        .getValue()
        .map(({ field, filterName, filterValue }) => {
          return {
            filterValue,
            filterName,
            field,
          };
        })
    );
  }

  addFilter() {
    const currentFilter = this.form.get('filter').value;
    const field: FormlyFieldConfig = getField('valor', this.fields);
    const { templateOptions } = getField('filter', this.fields);
    const optionLabel: any = (templateOptions.options as []).filter(
      (opt: { label: string; value: string; disabled: boolean }) =>
        opt.value === this.form.get('filter').value
    );

    const currentValue = this.form.get('valor').value;

    const filterApplied = {
      filterName: currentFilter,
      filterLabel: optionLabel[0].label,
      filterValue: currentValue,
      field,
      valueFormatted: this.formatterValueField(field, currentValue, false),
    };

    if (
      this.filterIsDuplicated(
        field,
        this.filtersApplied.getValue(),
        filterApplied
      )
    ) {
      return;
    }

    // this.filtersApplied.next([
    //   ...this.filtersApplied.getValue(),
    //   filterApplied,

    // ]);
    // this.currentFieldApplied.next(filterApplied);

    this.options.resetModel();
    this.toggleFilterOption(filterApplied, true);
    this.filterAppliedChange.emit(filterApplied);
  }

  addFilterToRow() {
    const currentFilter = this.formAvancado.get('filterAvancado').value;
    const field: FormlyFieldConfig = getField(
      'valorAvancado',
      this.fieldsAvancado
    );
    const { templateOptions } = getField('filterAvancado', this.fieldsAvancado);
    const optionLabel: any = (templateOptions.options as []).filter(
      (opt: { label: string; value: string; disabled: boolean }) =>
        opt.value === this.formAvancado.get('filterAvancado').value
    );

    const currentValue = this.formAvancado.get('valorAvancado').value;

    const filterApplied = {
      filterName: currentFilter,
      filterLabel: optionLabel[0]?.label || '',
      filterValue: currentValue,
      field,
      valueFormatted: this.formatterValueField(field, currentValue, true),
      operador: this.operador,
    };

    if (this.formAvancado.valid) {
      if (
        this.filterIsDuplicated(
          field,
          this.filtersApplied.getValue(),
          filterApplied
        )
      ) {
        return;
      }

      this.filtersApplied.next([
        ...this.filtersApplied.getValue(),
        filterApplied,
      ]);
    } else if (this.operador !== undefined) {
      this.filtersApplied.next([
        ...this.filtersApplied.getValue(),
        filterApplied,
      ]);
    } else {
      this.messageService.error('Por favor selecione os filtros desejados');
    }

    this.currentFieldApplied.next(filterApplied);
    this.formAvancado.reset();
    this.operador = '';
  }

  setOperator = (operador: string) => (this.operador = operador);

  private formatterValueField(
    field: FormlyFieldConfig,
    valueFiltered: any,
    avancado: boolean
  ) {
    let value = null;
    switch (field.type) {
      case 'select':
        value = (field.templateOptions.options as Array<any>).filter((opt) => {
          if (opt.value === valueFiltered) {
            return opt;
          }
        });
        break;
      case 'input':
        value = avancado
          ? this.formAvancado.get(field.key as string).value
          : this.form.get(field.key as string).value;
        break;
      case 'checkbox':
        if (avancado) {
          value = this.formAvancado.get(field.key as string).value
            ? 'Sim'
            : 'Não';
        } else {
          value = this.form.get(field.key as string).value ? 'Sim' : 'Não';
        }
        break;
    }

    return Array.isArray(value) && value.length ? value[0] : value;
  }

  private filterIsDuplicated(
    field: FormlyFieldConfig,
    filtersApplied,
    currentFilterApplied
  ): boolean {
    if (field.templateOptions.filterType !== Constants.UNIQUE) {
      // tslint:disable-next-line: no-shadowed-variable
      const hasDuplicated = filtersApplied.filter((filter) => {
        return _.isEqual(filter, currentFilterApplied);
      });

      if (hasDuplicated.length) {
        this.messageService.error(
          `[Filtro]: <b>${field?.templateOptions?.label}</b> já aplicado!`,
          { nzAnimate: true, nzDuration: 2000 }
        );
        return true;
      }
    } else if (field.templateOptions.filterType === Constants.UNIQUE) {
      // tslint:disable-next-line: no-shadowed-variable
      const hasDuplicated = filtersApplied.filter((filter) => {
        return filter.filterName === currentFilterApplied.filterName;
      });

      if (hasDuplicated.length) {
        this.messageService.error(
          `[Filtro]: <b>${field?.templateOptions?.label}</b> já aplicado!`,
          { nzAnimate: true, nzDuration: 2000 }
        );
        return true;
      }
    }

    return false;
  }

  private toggleFilterOption(
    // tslint:disable-next-line: no-shadowed-variable
    filter: {
      filterName: string;
      filterValue: any;
      field: FormlyFieldConfig;
      valueFormatted: any;
    },
    disabled: boolean
  ): void {
    const { field, filterValue, filterName } = filter;

    if (field.type === 'select') {
      // Navega entre as opções do select e remove
      (field.templateOptions.options as []).map(
        (
          option: { label: string; value: string; disabled: boolean },
          index
        ) => {
          if (option.value === filterValue) {
            getField(
              field.key as string,
              this.fields
            ).templateOptions.options = [
              ...(field.templateOptions.options as []),
            ].map((opt: any) => {
              if (opt.value === filterValue) {
                opt.disabled = disabled;
              }
              return opt;
            });
          }
        }
      );
      // Desabilita a opção no filtro caso tenha todas selecionadas
      if (disabled) {
        if (
          !(getField(field.key as string, this.fields).templateOptions
            .options as []).filter((opt: any) => opt.disabled !== disabled)
            .length
        ) {
          getField(
            this.filterKey,
            this.fields
          ).templateOptions.options = (getField(this.filterKey, this.fields)
            .templateOptions.options as []).map((opt: any) => {
            if (opt.value === filterName) {
              opt.disabled = disabled;
            }
            return opt;
          });
        }
      } else {
        if (
          (getField(field.key as string, this.fields).templateOptions
            .options as []).filter((opt: any) => opt.disabled !== disabled)
            .length
        ) {
          getField(
            this.filterKey,
            this.fields
          ).templateOptions.options = (getField(this.filterKey, this.fields)
            .templateOptions.options as []).map((opt: any) => {
            if (opt.value === filterName) {
              opt.disabled = disabled;
            }
            return opt;
          });
        }
      }
    } else if (field.type === 'checkbox') {
      getField(this.filterKey, this.fields).templateOptions.options = (getField(
        this.filterKey,
        this.fields
      ).templateOptions.options as []).map((opt: any) => {
        if (opt.value === filterName) {
          opt.disabled = disabled;
        }
        return opt;
      });
    } else if (field.type === 'date') {
      getField(this.filterKey, this.fields).templateOptions.options = (getField(
        this.filterKey,
        this.fields
      ).templateOptions.options as []).map((opt: any) => {
        if (opt.value === filterName) {
          opt.disabled = disabled;
        }
        return opt;
      });
    }
  }
}
