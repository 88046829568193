import { NzModalRef } from '../../antd';

interface IModal {
    destroyModal(result: any): void;
}

export abstract class Modal implements IModal {

    constructor(protected modal: NzModalRef) {

    }
    destroyModal(result: any) {
        this.modal.destroy(result);
    }
}
