import { map } from 'rxjs/operators';
import { IPessoaFisica } from './../shared/interfaces/pessoa-fisica';
import { AuthService, IMenuButton } from 'src/@lib';
import { PessoaJuridicaService } from './../shared/services/pessoa-juridica.service';
import { NzModalRef, NzModalService } from 'ng-zorro-antd/modal';
import { FormGroup, FormBuilder, Validators, FormArray } from '@angular/forms';
import {
  Component,
  OnInit,
  Input,
  Optional,
  AfterContentInit,
} from '@angular/core';
import {
  IColumnsFieldsProps,
  ISearchFieldsProps,
} from '../shared/interfaces/shared.interface';
import { Helper } from '../shared/helper';
import { PessoaFisicaComponent } from '../pessoa-fisica/pessoa-fisica.component';
import { PessoaFisicaService } from '../shared/services/pessoa-fisica.service';

@Component({
  selector: 'app-pessoa-juridica',
  templateUrl: './pessoa-juridica.component.html',
  styleUrls: ['./pessoa-juridica.component.scss'],
})
export class PessoaJuridicaComponent implements OnInit, AfterContentInit {
  @Input() openModal = false;
  @Input() entidadePessoaJuridica: any = null;
  loading = false;
  loadingEmpresas = false;

  loadingPessoaFisica = false;

  searchInput: any;
  displayData = [];
  pessoaJuridicaList: any[] = [];

  searchColumns: ISearchFieldsProps[] = [];
  tableColumns: IColumnsFieldsProps[] = [];

  tabIndex = 0;

  formB: FormGroup = new FormGroup({});
  searchForm: FormGroup = new FormGroup({});
  page = 1;
  pageSize = 10;
  resultLength: number;

  intervaloDigitando: any;

  checkList = {
    cnpj: true,
    nomPessoa: true,
  };

  checkListPF = {
    responsaveis: true,
    acoes: true,
  };

  fabButtons: IMenuButton[];
  public innerWidth: any;
  public tooltipPessoaJuridica: boolean = false;

  constructor(
    @Optional()
    private modalRef: NzModalRef<PessoaJuridicaComponent>,
    public service: PessoaJuridicaService,
    public pessoaFisicaService: PessoaFisicaService,
    private modalService: NzModalService,
    private formBuilder: FormBuilder,
    private authService: AuthService,
  ) {
    this.reset();
    this.resetFabButtons();
  }

  async ngAfterContentInit() {
    await this.setConfigTable();
    this.reset();
  }

  reset() {
    this.formB = this.formBuilder.group({
      id: [null],
      cnpj: [null, [Validators.required, Validators.minLength(14)]],
      razaoSocial: [null, Validators.required],
      aplicacaoOrigem: [3],
      dtCriacao: [this.authService.getDateSelected()],
      usuarioCriacao: [this.authService.getIdUser()],
      idEndereco: [null],
      cep: [null, [Validators.required, Validators.minLength(8)]],
      numero: [null],
      complemento: [null],
      nomLogradouro: [null, Validators.required],
      nomBairro: [{ value: null, disabled: true }, Validators.required],
      codigoIbgeCidade: [null],
      nomCidade: [{ value: null, disabled: true }, Validators.required],
      nomUf: [{ value: null, disabled: true }, Validators.required],
      principal: [null],
      enderecos: this.formBuilder.array([]),
      entidadePessoaFisica: [null],
      nome: [{ value: null, disabled: true }],
      email: [{ value: null, disabled: true }],
      cpf: [null, Validators.required],
      pessoaResponsaveis: this.formBuilder.array([]),
      idPessoa: [null],
    });
    this.searchForm = this.formBuilder.group({
      field: [{ texto: 'Todos', valor: 'todos' }],
      value: [null, Validators.required],
      size: [this.pageSize],
      page: [this.page],
    });
  }

  ngOnInit(): void {
    if (this.openModal) {
      this.changeTabIndex(1);
    }
    this.innerWidth = window.innerWidth;
  }
  searchPessoaJuridica() {
    this.tooltipPessoaJuridica = true;
    setTimeout(() => {
      this.tooltipPessoaJuridica = false;
    }, 2000);
  }

  buildFabButton(fabButtons: IMenuButton[]): void {
    this.fabButtons = fabButtons;
  }

  resetForm = async () => {
    this.resetState();
    this.setPessoaJuridicaData([]);
    this.entidadePessoaJuridica = null;
    this.changeTabIndex(0);
  };

  async setConfigTable() {
    this.loading = true;
    await this.service.getDocumentTableProps().then((result) => {
      Object.assign(this, result);
      this.setCompareToTableColumns();
    });
    this.loading = false;
  }

  setCompareToTableColumns() {
    this.tableColumns.map((column) => {
      column[`compare`] =
        this.displayData.length > 0 &&
        typeof this.displayData[0][column.value] === 'string'
          ? (a: any, b: any) => a[column.value].localeCompare(b[column.value])
          : (a: any, b: any) => a[column.value] - b[column.value];
    });
  }

  getTableColumns() {
    return this.tableColumns;
  }

  async getPessoasJuridicas(texto = '') {
    const regex = /\d/;
    const doesItHaveNumber = regex.test(texto);

    doesItHaveNumber
      ? this.searchForm.patchValue({
          value: texto,
          field: { texto: 'CNPJ', valor: 'cnpj' },
        })
      : this.searchForm.patchValue({
          value: texto,
          field: { texto: 'Razão Social', valor: 'razaoSocial' },
        });
    clearTimeout(this.intervaloDigitando);
    this.intervaloDigitando = await setTimeout(async () => {
      this.loading = true;
      if (!texto) {
        this.searchForm.patchValue({
          value: texto,
          field: { texto: 'Todos', valor: 'todos' },
        });
        this.consultar();
      }
      // 09.074.345/0001-64
      await this.service
        .getPessoasJuridicas(this.searchForm.value)
        .then((result: { pessoa: IPessoaFisica[]; params: any }) => {
          this.resultLength = result.params[0];
          this.formatResultGetPessoasJuridicas(result.pessoa);
        })
        .catch(() => (this.loading = false));
      this.loading = false;
    }, 1000);
  }

  async searchPessoaByCnpj(value) {
    if (
      !value ||
      value.length < 14 ||
      this.entidadePessoaJuridica?.cnpj === value
    ) {
      return;
    }

    this.loading = true;
    await this.service
      .getPessoasJuridicasByCnpj(value)
      .then((result: any) => {
        if (result) {
          this.formatResultGetPessoasJuridicas([result]);
          this.entidadePessoaJuridica = this.pessoaJuridicaList[0];
          this.resetState();
          this.service.notification.warning(
            'Formulário',
            'Pessoa jurídica já cadastada',
          );
          this.changeTabIndex(1);
        }
        this.loading = false;
      })
      .catch(() => (this.loading = false));
  }

  formatResultGetPessoasJuridicas(result: any[]) {
    result.map((tipo: any) => (tipo.checked = false));

    this.setPessoaJuridicaData(result);
  }

  setPessoaJuridicaData(pessoas: any[]) {
    this.pessoaJuridicaList = pessoas;
    this.displayData = pessoas;
  }

  getResult(): void {
    this.displayData = this.service.documentService.search(
      this.pessoaJuridicaList,
      this.searchInput,
    );
  }

  prepareColumnValue(item, column) {
    const value = column.objectChildren
      ? item[column.objectChildren][column.value]
      : item[column.value];
    if (value && column.mask) {
      return Helper.addMask(value, column.mask);
    }
    return value;
  }

  async changeTabIndex(value) {
    this.tabIndex = value;
    this.resetFabButtons();
    if (this.entidadePessoaJuridica) {
      const idResponsaveis =
        await this.entidadePessoaJuridica.pessoaResponsaveis.map(
          (item) => item.id,
        );
      for (const ids of idResponsaveis) {
        await this.service
          .getPessoasResponsavelById(ids)
          .then((resp: string[]) => {
            this.addPF(resp);
          });
      }
    }
    if (this.tabIndex === 1) {
      this.resetState();
      this.setPessoaJuridicaData([]);
      this.entidadePessoaJuridica = null;
      this.consultar();
    }
  }

  resetFabButtons() {
    const buttons: IMenuButton[] = [
      {
        icon: 'plus',
        tooltip: 'Novo Cadastro',
        condition:
          ((!this.openModal || this.openModal) && this.tabIndex === 0) ||
          this.tabIndex === 1,
        onClick: this.implantar,
      },
      {
        icon: 'save',
        color: 'green',
        tooltip: this.formB.value.id ? 'Editar' : 'Salvar',
        condition: this.tabIndex === 0,
        onClick: this.gravar,
      },
      {
        icon: 'reload',
        tooltip: 'Resetar',
        condition: true,
        onClick: this.resetForm,
      },
      {
        icon: 'edit',
        color: 'orange',
        tooltip: 'Editar',
        condition: (!this.openModal || this.openModal) && this.tabIndex === 1,
        onClick: this.editar,
      },
      {
        icon: 'search',
        tooltip: 'Consultar',
        condition: true,
        onClick: this.consultar,
      },
      {
        icon: 'select',
        tooltip: 'Selecionar',
        condition:
          this.openModal && (this.tabIndex === 0 || this.tabIndex === 1),
        onClick: this.selecionar,
      },
    ];
    const buttonsFiltered = buttons.filter((button) => button.condition);
    this.buildFabButton(buttonsFiltered);
  }

  async selectItem(entidadePessoa) {
    entidadePessoa = await this.formatPessoaJuridicaToForm(entidadePessoa);
    this.entidadePessoaJuridica = this.pessoaJuridicaList.every(
      (tipo) => !tipo.checked,
    )
      ? null
      : entidadePessoa;

    this.entidadePessoaJuridica
      ? this.setDataFormPessoaJuridica()
      : this.resetState();
  }

  setDataFormPessoaJuridica() {
    this.reset();
    this.formB.patchValue(this.entidadePessoaJuridica);
  }

  resetState() {
    this.formB.reset();
    this.reset();
  }

  async formatPessoaJuridicaToForm(registro): Promise<any> {
    this.checkItemPesquisa(registro);
    const pessoa = {
      ...registro.enderecos[0],
      ...registro.responsaveis,
      ...registro,
    };

    return pessoa;
  }

  dbClickItem(item: any) {
    if (!item.checked) {
      this.entidadePessoaJuridica = this.formatPessoaJuridicaToForm(item);
    }

    this.openModal
      ? this.modalRef.destroy(this.entidadePessoaJuridica)
      : this.editar();
  }

  checkItemPesquisa(entidadePessoa) {
    this.pessoaJuridicaList.map((pessoaFisica) => {
      pessoaFisica.checked =
        pessoaFisica.id !== entidadePessoa?.id ? false : true;
    });
  }

  implantar = () => this.resetForm();

  selecionar = () => {
    if (
      !this.entidadePessoaJuridica ||
      this.pessoaJuridicaList.every((tipo) => !tipo.checked)
    ) {
      return this.service.notification.warning(
        'Alterar',
        'Nenhum item selecionado!',
      );
    }

    this.modalRef.destroy(this.entidadePessoaJuridica);
  };

  editar = () => {
    if (
      !this.entidadePessoaJuridica ||
      this.pessoaJuridicaList.every((tipo) => !tipo.checked)
    ) {
      return this.service.notification.warning(
        'Alterar',
        'Nenhum item selecionado!',
      );
    }

    this.setDataFormPessoaJuridica();
    this.changeTabIndex(0);
    this.displayData = this.displayData.map((cnpj) => {
      return { ...cnpj, checked: false };
    });
    this.pessoaJuridicaList = this.displayData;
  };

  gravar = async () => {
    const form = this.formB.controls;
    form.cpf.clearValidators();
    form.cpf.updateValueAndValidity();

    if (this.formB.invalid) {
      return this.service.notification.warning(
        'Formulário',
        'Por favor, preencha todos os campos corretamente',
      );
    }
    this.loading = true;
    const ugSelected = await this.authService.getUgSelected();
    if (this.formB.value.id && this.formB.value.id > 0) {
      return await this.service
        .alterar(this.formB)
        .then(() => {
          this.loading = false;
          this.resetForm();
          this.changeTabIndex(1); //quando salvar os dados a visualização volta para a grid
        })
        .catch((err) => {
          this.loading = false;
          this.service.notification.error(
            'Alterar',
            'Error ao alterar pessoa jurídica',
          );
        });
    }

    await this.service
      .inserir(this.formB)
      .then(() => {
        this.loading = false;
        this.resetForm();
        this.changeTabIndex(1); //quando salvar os dados a visualização volta para a grid
      })
      .catch((err) => {
        this.service.notification.error(
          'Cadastro',
          'Error ao cadastrar pessoa jurídica',
        );
      });

    this.loading = false;
  };

  consultar = async () => {
    this.loading = true;
    this.searchInput = null;
    await this.service
      .getPessoasJuridicas(this.searchForm.value)
      .then((result: { pessoa: IPessoaFisica[]; params: any }) => {
        this.resultLength = result.params[0];
        this.formatResultGetPessoasJuridicas(result.pessoa);
      })
      .catch(() => (this.loading = false));
    this.loading = false;
  };

  pageChanged(evento) {
    this.searchForm.patchValue({ page: evento });
    this.consultar();
  }

  setDataPessoaJuridica(data) {
    this.formB.patchValue({
      entidadePessoaJuridica: data,
      ...data.pessoa.enderecos[0],
      ...data.pessoa.responsaveis[0],
      ...data,
    });
  }

  async consultaCep(cep) {
    this.service
      .consultaEndereco(cep)
      .then((res: any) => {
        const result = { data: res.data };
        this.formB.patchValue({
          idEndereco: result?.data[0].id,
          cep: result?.data[0].cep,
          nomCidade: result?.data[0].localidade,
          nomBairro: result?.data[0].bairro,
          codigoIbgeCidade: result?.data[0].codigoIbgeCidade,
          nomLogradouro: result?.data[0].logradouro,
          nomUf: result?.data[0].uf,
          principal: 1,
        });
        this.formB.controls.nomCidade.disable(),
          this.formB.controls.nomBairro.disable(),
          this.formB.controls.nomUf.disable();
      })
      .catch((err) => {
        if (err) {
          this.service.notification.error('Error', 'CEP não encontrado.');
        }
        this.enableEndereco();
        this.formB.patchValue({
          nomCidade: null,
          nomBairro: null,
          nomLogradouro: null,
          nomUf: null,
        });
      });
  }

  // if (!this.getProprietario().controls.proprietarioDesconhecido.value) {

  enableEndereco() {
    this.formB.controls.nomCidade.enable(),
      this.formB.controls.nomBairro.enable(),
      this.formB.controls.nomUf.enable();
  }

  async consultarResponsavel(value) {
    if (
      (this.formB.get('entidadePessoaFisica').value &&
        this.formB.get('entidadePessoaFisica').value.cpf === value) ||
      value?.length < 11 ||
      !value
    ) {
      return;
    }

    clearTimeout(this.intervaloDigitando);
    this.intervaloDigitando = await setTimeout(async () => {
      this.pessoaFisicaService
        .getPessoasFisicaByCpf(value)
        .then((result: any) => {
          this.setDataPessoaFisica(result);
          this.loadingPessoaFisica = false;
        })
        .catch(() => (this.loadingEmpresas = false));
    }, 1000);
  }

  setDataPessoaFisica(data) {
    this.formB.patchValue({
      entidadePessoaFisica: data,
      cpf: data.cpf,
      nome: data.pessoa.nomPessoa,
      email: data.pessoa.emailPrincipal,
      aplicacaoOrigem: 3,
      usuarioCriacao: this.authService.getIdUser(),
    });
  }

  showModalPF() {
    const pessoaFisicaModal = this.modalService.create({
      nzTitle: 'Selecione uma pessoa física',
      nzContent: PessoaFisicaComponent,
      nzComponentParams: {
        openModal: true,
      },
      nzFooter: null,
      nzWidth: 1000,
    });

    pessoaFisicaModal.afterClose.subscribe((result) =>
      this.setDataPessoaFisica(result),
    );
  }

  async addPF(formValue) {
    let object;

    if (
      this.formB.value.pessoaResponsaveis.some(
        (ass) => ass.cpf === this.formB.value.entidadePessoaFisica?.cpf,
      )
    ) {
      return this.service.notification.warning(
        'Pessoa Física',
        'Pessoa Física já cadastrado no documento!',
      );
    }

    const responsaveisForm: FormArray = this.formB.get(
      'pessoaResponsaveis',
    ) as FormArray;

    if (formValue.idPessoaFisica) {
      await this.service
        .getPessoasFisicaById(formValue.idPessoaFisica)
        .then((resp) => {
          object = Object.assign({}, formValue, resp);
        });
    }

    responsaveisForm.push(
      this.formBuilder.group({
        id: [
          this.formB.value.entidadePessoaFisica?.pessoaResponsaveis?.id ||
            object?.id,
        ],
        nome: [
          this.formB.value.entidadePessoaFisica?.pessoa.nomPessoa ||
            object?.pessoa?.nomPessoa,
        ],
        email: [
          this.formB.value.entidadePessoaFisica?.pessoa.emailPrincipal ||
            object?.pessoa?.emailPrincipal,
        ],
        cpf: [this.formB.value.entidadePessoaFisica?.cpf || object?.cpf],
        idPessoaFisica: [
          this.formB.value.entidadePessoaFisica?.id || object?.idPessoaFisica,
        ],
        idPessoa: [
          this.formB.value.entidadePessoaFisica?.pessoa.id || object?.pessoa.id,
        ],
      }),
    );
    this.limparPF();
  }

  async removePF(cpf) {
    const pessoaFisicaFormb: FormArray = this.formB.get(
      'pessoaResponsaveis',
    ) as FormArray;

    if (pessoaFisicaFormb.value.length === 1) {
      return this.service.notification.info(
        'Responsável',
        `Não foi possivel apagar o responsável, pois é obrigatório ter pelo menos um representante legal!`,
        { nzDuration: 7000 },
      );
    }
    const responsavel =
      this.pessoaJuridicaList?.find((tipo) => tipo.checked) ||
      this.pessoaJuridicaList?.find((tipo) => tipo.id === this.formB.value.id);

    const index = this.formB.value.pessoaResponsaveis.indexOf(
      this.formB.value.pessoaResponsaveis.find((pf) => pf.cpf === cpf),
    );

    this.formB.value.pessoaResponsaveis[index].id
      ? await this.service
          .getPessoasResponsavelById(responsavel.pessoaResponsaveis[index].id)
          .then((result) => {
            this.removerResponsavel(result.id, pessoaFisicaFormb, index);
          })
      : pessoaFisicaFormb.removeAt(index);
  }

  removerResponsavel(idResponsavel, responsable, index) {
    const title = `<span> Deseja remover este responsável?</span>`;
    const okText = 'Sim';
    const cancelText = 'Não';
    const onOk = async () => {
      await this.service
        .deletePessoasResponsavelById(idResponsavel)
        .then(async (resultado) => {
          responsable.removeAt(index);
          modal.close();
          this.service.notification.success(
            'Responsável',
            `${resultado.message}`,
          );
        })
        .catch(() => {
          this.service.notification.error(
            'Responsável',
            'Error ao apagar responsável.',
          ),
            modal.close();
        });
    };

    const modal = this.modalService.create({
      nzContent: title,
      nzFooter: [
        { label: cancelText, onClick: () => modal.close() },
        {
          label: okText,
          onClick: () => onOk(),
        },
      ],
    });

    modal.afterClose.subscribe((resultClose: any) => {
      if (!resultClose) {
        modal.destroy();
      }
    });
  }

  limparPF() {
    this.formB.controls.entidadePessoaFisica.reset();
    this.formB.controls.cpf.reset();
    this.formB.controls.email.reset();
    this.formB.controls.nome.reset();
  }

  verificaPFVazia() {
    return this.formB.value.cpf;
  }

  getWidthContent() {
    return window.innerWidth;
  }

  resetar() {
    this.checkList = {
      cnpj: true,
      nomPessoa: true,
    };
  }
}
