import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { NzUploadFile } from 'ng-zorro-antd/upload';
import { NzNotificationService } from 'ng-zorro-antd/notification';
import { NzModalService } from 'ng-zorro-antd/modal';
import { AuthService, IMenuButton } from 'src/@lib';
import { PessoaFisicaService } from '../shared/services/pessoa-fisica.service';
import { AssinantesService } from '../shared/services/assinantes.service';
import { IPessoaFisica } from '../shared/interfaces/pessoa-fisica';
import { Helper } from '../shared/helper';
import { CookieService } from 'ngx-cookie-service';

export interface EmpresaAssinante {
  razaoSocial: string;
  cnpj: string;
  nomCidade: string;
  uf: string;
}

@Component({
  selector: 'app-perfil',
  templateUrl: './perfil.component.html',
  styleUrls: ['./perfil.component.scss'],
})
export class PerfilComponent implements OnInit {
  formEdit: FormGroup = new FormGroup({});

  cpfVisible = false;
  isEditEmail = false;
  isEditPass = false;
  hasNewData = false;

  perfilAvatar: any;
  avatarFilename: any;

  loading = true;
  tipoDocumento: string;

  fabButtons: IMenuButton[];

  constructor(
    protected authService: AuthService,
    private formBuilder: FormBuilder,
    private notification: NzNotificationService,
    private modal: NzModalService,
    private cookierService: CookieService,
    private pessoaFisicaService: PessoaFisicaService,
    private assinanteService: AssinantesService,
  ) {
    this.formEdit = this.formBuilder.group({
      emailAtual: this.formBuilder.control(
        {
          value: this.authService.getEmailUser(),
          disabled: true,
        },
        [Validators.email],
      ),
      novoEmail: this.formBuilder.control('', [Validators.email]),
      confirmarEmail: this.formBuilder.control('', [Validators.email]),

      senhaAtual: this.formBuilder.control({ value: '', disabled: true }, [
        Validators.min(8),
      ]),
      senha: this.formBuilder.control('', [Validators.min(8)]),
      confirmarSenha: this.formBuilder.control('', [Validators.min(8)]),

      avatar: this.formBuilder.control(''),
    });

    this.buildFabButton([
      {
        icon: 'save',
        tooltip: 'Salvar',
        condition: true,
        onClick: this.salvar,
      },
    ]);
  }

  ngOnInit(): void {
    this.perfilAvatar = this.authService.getAvatar();
  }

  buildFabButton(fabButtons: IMenuButton[]) {
    this.fabButtons = fabButtons;
  }

  // selecionar arquivos -------------------------------------------------------

  beforeUploadAvatar = (file): boolean => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onloadend = (e) => {
      this.perfilAvatar = e.target.result.toString();
      this.avatarFilename = file.name;
    };

    this.formEdit.get('avatar').setValue(file);

    return false;
  };

  // habilitar/desabilitar formulario para editar email e senha -------------------------------------------------------

  toggleEmail() {
    this.isEditEmail = !this.isEditEmail;

    if (this.isEditEmail) {
      this.formEdit
        .get('novoEmail')
        .setValidators([Validators.required, Validators.email]);
      this.formEdit
        .get('confirmarEmail')
        .setValidators([Validators.required, Validators.email]);

      return;
    }
    this.formEdit.get('novoEmail').clearValidators();
    this.formEdit.get('confirmarEmail').clearValidators();
  }

  getCpfMasked() {
    return Helper.cpfCnpjMascarado(this.authService.getCpfUser());
  }

  getCpf() {
    return Helper.mascaraCpfCnpj(this.authService.getCpfUser());
  }

  togglePass() {
    this.isEditPass = !this.isEditPass;

    const passControl = this.formEdit.get('senhaAtual');

    if (passControl.disabled) {
      passControl.enable();
      passControl.setValidators([Validators.required]);

      this.formEdit.get('senha').setValidators([Validators.required]);
      this.formEdit.get('confirmarSenha').setValidators([Validators.required]);

      return;
    }

    passControl.disable();
    passControl.clearValidators();
    this.formEdit.get('senha').clearValidators();
    this.formEdit.get('confirmarSenha').clearValidators();
  }

  // salvar informações no banco de dados -------------------------------------------------------

  checkEqualEmail({ novoEmail, confirmarEmail }) {
    return novoEmail === confirmarEmail;
  }

  async alterarEmail() {
    const pf = await this.pessoaFisicaService.getPessoasFisicaByCpf(
      this.authService.getCpfUser(),
    );
    const form = {
      email: this.formEdit.value.novoEmail,
      telefone: pf.telefone,
      nome: pf.nome,
      id: pf.id,
      aplicacaoOrigem: pf.aplicacaoOrigem,
      usuarioCriacao: pf.usuarioCriacao,
    } as IPessoaFisica;

    if (!this.checkEqualEmail(this.formEdit.value)) {
      this.notification.info('Atenção', 'Emails não são iguais');
      return;
    }

    if (!Helper.validaEmail(this.formEdit.value.novoEmail)) {
      this.notification.info('Atenção', 'Email inválido');
      return;
    }

    await this.pessoaFisicaService
      .alterar(form)
      .then(() => {
        this.notification.success(
          'Alterar email',
          'Email atualizado com sucesso',
        );
      })
      .catch((err) => {
        this.notification.error('Erro', err.message);
      });
  }

  checkSenha(form) {
    return form.nova_senha === form.confirmar_senha;
  }

  async alterarSenha() {
    if (!this.checkSenha(this.formEdit.value)) {
      this.notification.info('Atenção', 'Senhas não são iguais');
      return;
    }

    const passCheck = Helper.validaSenha(this.formEdit.value.senha);

    if (passCheck.erro) {
      this.notification.info('Atenção', passCheck.mensagem);
      return;
    }

    await this.pessoaFisicaService
      .alterarSenha(this.formEdit.value)
      .then(() => {
        this.notification.success(
          'Alterar senha',
          'Senha atualizada com sucesso',
        );
      })
      .catch((err) => {
        this.notification.error('Erro', err.message);
      });
  }

  salvar = async () => {
    if (this.formEdit.invalid) {
      this.notification.error('Erro', 'Não foi possível salvar os dados');
      return;
    }

    this.loading = true;

    if (this.perfilAvatar) {
      await this.pessoaFisicaService
        .uploadAvatar(this.formEdit.value.avatar)
        .then((url) => {
          this.pessoaFisicaService
            .alterarAvatar({ avatarPath: url }, this.authService.getIdUser())
            .then((response) => {
              localStorage.setItem('avatar', url);
              this.cookierService.set('user-avatar', url, 1, '/');
              this.notification.success('Alterar avatar', response);
            })
            .catch((err) => this.notification.error('Erro', err.message));
        });
    }

    if (this.isEditEmail) {
      await this.alterarEmail();
    }

    if (this.isEditPass) {
      await this.alterarSenha();
    }

    this.formEdit.get('senhaAtual').disable();
    this.isEditPass = false;
    this.isEditEmail = false;
    this.formEdit.reset();
  };

  isValidEmail(value) {
    if (!value) {
      return true;
    }

    return Helper.validaEmail(value);
  }
}
