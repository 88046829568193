import { Component, Inject, OnInit } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { distinctUntilChanged, filter, map, startWith } from 'rxjs/operators';
import { ThemeService } from '../../services';
import { MenuService } from '../../services/menu.service';
import { PS_CONFIG, PsUiConfig } from '../../config/ps-config';

export interface IBreadcrumb {
  label: string;
  url: string;
}

export interface IconFooter {
  type: string;
  url: string;
}

export interface CompanyFooter {
  name: string;
  url: string;
}

export interface FooterConfig {
  company: CompanyFooter;
  hasIcons: boolean;
  icons?: IconFooter[];
}

@Component({
  // tslint:disable-next-line: component-selector
  selector: 'ps-common-layout',
  templateUrl: './common-layout.component.html',
  styleUrls: ['./common-layout.component.scss'],
})
export class CommonLayoutComponent implements OnInit {
  breadcrumbs$: Observable<IBreadcrumb[]>;
  contentHeaderDisplay: string;
  isFolded: boolean;
  isSideNavDark: boolean;
  isExpand: boolean;
  isScrolled: boolean;
  selectedHeaderColor: string;
  menuItems: any[] = [];
  appVersion: string;
  footerConfig: FooterConfig;

  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private themeService: ThemeService,
    private menuService: MenuService,
    @Inject(PS_CONFIG) private psConfig: PsUiConfig,
  ) {
    this.router.events
      .pipe(
        filter((event) => event instanceof NavigationEnd),
        map(() => {
          let child = this.activatedRoute.firstChild;
          while (child) {
            if (child.firstChild) {
              child = child.firstChild;
            } else if (
              child.snapshot.data &&
              child.snapshot.data.headerDisplay
            ) {
              return child.snapshot.data.headerDisplay;
            } else {
              return null;
            }
          }
          return null;
        }),
      )
      .subscribe((data: any) => {
        this.contentHeaderDisplay = data;
      });
  }
  onToggleFolder() {
    window.scrollTo({ top: 3, left: 0 });
  }

  ngOnInit() {
    this.breadcrumbs$ = this.router.events.pipe(
      startWith(new NavigationEnd(0, '/', '/')),
      filter((event) => event instanceof NavigationEnd),
      distinctUntilChanged(),
      map((data) => this.buildBreadCrumb(this.activatedRoute.root)),
    );

    this.themeService.isMenuFoldedChanges.subscribe(
      (isFolded) => (this.isFolded = isFolded),
    );
    this.themeService.isSideNavDarkChanges.subscribe(
      (isDark) => (this.isSideNavDark = isDark),
    );
    this.themeService.selectedHeaderColor.subscribe(
      (color) => (this.selectedHeaderColor = color),
    );
    this.themeService.isExpandChanges.subscribe(
      (isExpand) => (this.isExpand = isExpand),
    );
    this.themeService.isScrolledPageBottomChanges.subscribe(
      (isScrolled) => (this.isScrolled = isScrolled),
    );
    this.menuService.menuItems.subscribe(
      (menuItems) => (this.menuItems = menuItems),
    );

    this.appVersion = this.psConfig.appVersion;
    this.footerConfig = {
      hasIcons: true,
      company: {
        name: 'Publicsoft',
        url: 'https://www.publicsoft.com.br/',
      },
      icons: [
        {
          type: 'instagram',
          url: 'https://www.instagram.com/publicsoft/?hl=pt-br',
        },
        {
          type: 'facebook',
          url: 'https://www.facebook.com/PublicSoftOficial/',
        },
        {
          type: 'twitter',
          url: 'https://twitter.com/publicsoft',
        },
      ],
    };
  }

  private buildBreadCrumb(
    route: ActivatedRoute,
    url: string = '',
    breadcrumbs: IBreadcrumb[] = [],
  ): IBreadcrumb[] {
    let label = '';
    let path = '/';

    if (route.routeConfig) {
      if (route.routeConfig.data) {
        label = route.routeConfig.data.title;
        path += route.routeConfig.path;
      }
    } else {
      label = 'Dashboard';
      path += 'dashboard';
    }

    const nextUrl = path && path !== '/dashboard' ? `${url}${path}` : url;
    const breadcrumb = {
      label,
      url: nextUrl,
    } as IBreadcrumb;

    const newBreadcrumbs = label
      ? [...breadcrumbs, breadcrumb]
      : [...breadcrumbs];
    if (route.firstChild) {
      return this.buildBreadCrumb(route.firstChild, nextUrl, newBreadcrumbs);
    }
    return newBreadcrumbs;
  }

  toggleFold() {
    this.isFolded = !this.isFolded;
    this.themeService.toggleFold(this.isFolded);
  }
}
