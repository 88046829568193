import {
  Component,
  EventEmitter,
  Input,
  NgZone,
  Output,
  OnInit,
} from '@angular/core';
import { Modal } from './base-modal';
import { NzModalRef } from '../../antd';
import { Setting } from '../ps-grid/setting.interface';
import { Column } from '../ps-grid/models/column.model';
import { PsColDef } from '../ps-grid/types/ps-col-def';
import { GridOptions, ColDef } from 'ag-grid-community';
import { FormlyFieldConfig } from '@ngx-formly/core';
import { FormGroup, NgForm } from '@angular/forms';
import { ClassGetter } from '@angular/compiler/src/output/output_ast';

@Component({
  // tslint:disable-next-line: component-selector
  selector: 'ps-modal-lupa',
  templateUrl: './ps-modal-lupa.component.html',
  styleUrls: ['./ps-modal-lupa.scss'],
})
export class PsModalLupaComponent extends Modal implements OnInit {
  @Input() columnDefs: PsColDef[];
  @Input() rowData: any[];
  @Input() fields: FormlyFieldConfig[];
  @Input() service: any;

  form: FormGroup = new FormGroup({});
  model: any;

  tabIndex = 1;
  settingsGrid: Partial<Setting> = {
    size: 'small',
    pagination: true,
    header: true,
    fixHeader: true,
    checkbox: true,
    position: 'bottom',
  };

  gridOptions: GridOptions = {
    onRowDoubleClicked: this.handleDblClick.bind(this),
    onRowClicked: this.handleSelectRow.bind(this),
  };
  defaultColDefs: ColDef = {
    flex: 1,
    minWidth: 150,
    sortable: true,
    resizable: true,
  };

  private rowSelected: any;

  constructor(modal: NzModalRef, private ngZone: NgZone) {
    super(modal);
  }

  ngOnInit() {}

  destroyModal() {
    this.modal.destroy({ data: this.rowSelected });
  }

  handleDblClick(row: any) {
    this.rowSelected = row.data;
    this.destroyModal();
  }

  handleSelectRow(row: any) {
    this.rowSelected = row.data;
  }

  implantar = (form: NgForm) => {
    this.service.createBulk(form);
  };
  selecionar = () => {
    this.destroyModal();
  };
}
