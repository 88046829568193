import { PeriodVisualizeDoc } from './../shared/types/document.types';
import { Router } from '@angular/router';
import {
  IAssinante,
  IDocumento,
  IReponseCountDocs,
  IResponseCountOnlyDocsPerPeriod,
} from './../shared/interfaces/document-interfaces';
import {
  IDocumentsChartData,
  IDocumentsChartColors,
  IDocumentChartProps,
  IDocumentResumeChartColors,
  IDocumentResumeChartOptions,
  IDocumentResumeChartProps,
} from './../shared/interfaces/dashboard';
import { Component, OnInit, ViewChild } from '@angular/core';
import { AuthService, IMenuButton, ThemeService } from 'src/@lib';
import { DashboardService } from '../shared/services/dashboard.service';
import { NgChartjsDirective } from 'ng-chartjs';
import { DashboardMocks } from '../shared/mocks/dashboard';
import {
  IntervalTimes,
  IntervalTimeSelected,
  StatusDocumento,
} from '../shared/enum/const.enum';
import { DocumentService } from '../shared/services/document.service';
import { DocumentsUploadService } from '../shared/services/documents-upload.service';
import * as moment from 'moment';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss'],
})
export class DashboardComponent implements OnInit {
  public numberDays = {
    Day: -1,
    Week: -7,
    Month: -30,
    Year: -365,
  };

  customInterval: Date[];
  customIntervalFormated: string[];

  switchValue = false;
  loading = false;

  baseHeigth = (window.innerHeight * 60) / 100;
  barHeigth = this.baseHeigth * 0.6;

  divChartBarHeigth = `${this.barHeigth}px`;
  divChartCircleHeigth = `${this.barHeigth - 80}px`;

  formLoading = false;
  chartTooltipsMessage: string = 'Clique para visualizar os documentos';
  public tooltipVisibleDocumentsCharts: any = [
    { chart: false },
    { chart: false },
    { chart: false },
    { chart: false },
    { chart: false },
    { chart: false },
    { chart: false },
  ];
  intervalTimeSelected = {
    Day: IntervalTimeSelected.Day,
    Week: IntervalTimeSelected.Week,
    Month: IntervalTimeSelected.Month,
    Year: IntervalTimeSelected.Year,
    Others: IntervalTimeSelected.Others,
  };

  resultDashBoardDocuments: IReponseCountDocs;

  resultDashBoardAnexos: IReponseCountDocs;

  periodoTypeSelected: PeriodVisualizeDoc = 'Day';

  listDocuments: IDocumento[] = [];

  themeColors = this.colorConfig.get().colors;

  documentsChartData: IDocumentsChartData[] =
    DashboardMocks.getDocumentsChartProps().optionsData;
  documentsChartLabels: string[] =
    DashboardMocks.getDocumentsChartProps().labels;
  documentsChartOptions: any =
    DashboardMocks.getDocumentsChartProps().optionsBuild;
  documentsChartColors: IDocumentsChartColors[] =
    DashboardMocks.getDocumentsChartProps().optionsColors;
  documentsChartType: string =
    DashboardMocks.getDocumentsChartProps().typeChart;

  getDateNow: any = this.getDate();

  documentsResumeChartData: number[] =
    DashboardMocks.getDocumentsResumeChartData();
  documentsResumeChartLabels: string[] =
    DashboardMocks.getDocumentsResumeChartLabels();
  documentsResumeChartColors: IDocumentResumeChartColors[] =
    DashboardMocks.getDocumentsResumeChartColors();
  documentsResumeChartOptions: IDocumentResumeChartOptions =
    DashboardMocks.getDocumentsResumeChartOptions();
  documentsResumeChartType: string =
    DashboardMocks.getDocumentsResumeChartType();

  documentsOrderType: number[] = DashboardMocks.getOrderTypeDocuments();

  cardAnexosData: number[] = DashboardMocks.getDocumentsResumeChartData();
  cardAnexosLabels: string[] = DashboardMocks.getDocumentsResumeChartLabels();
  cardAnexosColors: IDocumentResumeChartColors[] =
    DashboardMocks.getDocumentsResumeChartColors();
  cardAnexosOptions: IDocumentResumeChartOptions =
    DashboardMocks.getDocumentsResumeChartOptions();
  cardAnexosOrderType: number[] = DashboardMocks.getOrderTypeDocuments();

  periodsOrderType: number[] = DashboardMocks.getPeriodChartDocumentsOrder();

  documentsResumeExpiration = [];
  documentsTotalSubscriptions: any = 0;

  assinanteSelected;
  assinantesSelected = [];
  allAssinantes = [];

  adm: boolean;

  fabButtons: IMenuButton[];
  listSignatarios: IAssinante[];
  signatarios = [];

  panels = [
    {
      active: true,
      name: 'Filtros',
    },
  ];

  tooltipVisibleDocumentsChart: boolean = false;

  public innerWidth: any;

  constructor(
    private router: Router,
    private colorConfig: ThemeService,
    public documentService: DocumentService,
    public service: DocumentsUploadService,
    private dashboardService: DashboardService,
    protected authService: AuthService,
  ) {
    this.buildFabButton([
      {
        icon: 'reload',
        tooltip: 'Atualizar',
        condition: true,
        onClick: this.attDashboard,
      },
      // {
      //   icon: 'plus',
      //   tooltip: 'Sincronizar Prefeitura Digital',
      //   condition: true,
      //   onClick: this.insertDocumentViaConcentrator,
      // },
    ]);
  }

  @ViewChild(NgChartjsDirective)
  public chart: NgChartjsDirective;

  ngOnInit(): void {
    this.innerWidth = window.innerWidth;
    this.admUser();
    this.getDate();
    this.reset();
  }

  async getSignatarios(idDoc: number) {
    const signers = await this.documentService.getSignatarioByIdDocumento(
      idDoc,
    );
    this.listSignatarios = signers
      .filter((el) => {
        return (
          el.hasOwnProperty('cpf') && el.hasOwnProperty('cpfRepresentante')
        );
      })
      .sort((a, b) => {
        if (a.nome < b.nome) {
          return -1;
        }
        if (a.nome > b.nome) {
          return 1;
        }
        return 0;
      });
  }

  async reset() {
    this.formLoading = true;
    await this.admUser();
    await this.resetChart();
    await this.resetChartResume();
    await this.cardAnexos();
    await this.getDocumentsPerPeriod(this.switchValue);
    await this.getAnexosPerPeriod(this.switchValue);
    await this.getAllAssinantes();
    this.formLoading = false;
    // await this.dashboardService.getDocumentFromConcentrator();
    // await this.getTotalSubscriptions();
  }

  async getAllSignatarios(avulsos) {
    return await this.documentService.getAllSignatarios(avulsos);
  }

  setCustomDate(value = [], typePeriod?: PeriodVisualizeDoc) {
    this.customInterval = value;
    this.periodoTypeSelected = typePeriod;
    if (value.length > 0) {
      this.customIntervalFormated = [
        this.customInterval[0].toString(),
        this.customInterval[1].toString(),
      ];
      this.getDocumentsPerPeriod(this.switchValue);
      this.getAnexosPerPeriod(this.switchValue);
    }
  }

  formatColorChart() {
    this.documentsChartColors?.map(
      (optColors) =>
        (optColors.backgroundColor =
          this.themeColors[optColors.backgroundColor]),
    );
  }

  async changeToDetached() {
    this.loading = true;
    this.switchValue = !this.switchValue;
    await this.getAllAssinantes(this.switchValue);
    return await this.getDocumentsPerPeriod(this.switchValue).then(async () => {
      await this.getAnexosPerPeriod(this.switchValue);
      this.loading = false;
    });
  }

  setPeriodTypeSelected(typePeriod: PeriodVisualizeDoc) {
    this.customIntervalFormated = null;
    this.customInterval = null;
    this.periodoTypeSelected = typePeriod;
    this.getDocumentsPerPeriod(this.switchValue);
    this.getAnexosPerPeriod(this.switchValue);
  }

  formatColorChartResume() {
    this.documentsResumeChartColors?.map((optColors) => {
      optColors.backgroundColorName.map((nameColor) => {
        optColors.backgroundColor.push(this.themeColors[nameColor]);
        optColors.pointBackgroundColor.push(this.themeColors[nameColor]);
      });
    });
  }

  // async getTotalSubscriptions() {
  //   return await this.dashboardService
  //     .getTotalSubscriptions()
  //     .then(
  //       (response: { message: string; data: any }) =>
  //         (this.documentsTotalSubscriptions = response?.data),
  //     );
  // }

  async resetChart() {
    await this.dashboardService
      .getDocumentsChartProps()
      .then((documentProps: IDocumentChartProps) => {
        this.documentsChartData = documentProps.optionsData;
        this.documentsChartLabels = documentProps.labels;
        this.documentsChartOptions = documentProps.optionsBuild;

        this.documentsChartColors = documentProps.optionsColors;
        this.formatColorChart();

        this.documentsChartType = documentProps.typeChart;
      })
      .catch(() => {
        this.formLoading = false;
        this.dashboardService.notification.error(
          'Error',
          'Error ao carregar propriedades do gráfico.',
        );
      });
  }

  async resetChartResume() {
    await this.dashboardService
      .getDocumentsResumeChartProps()
      .then((documentProps: IDocumentResumeChartProps) => {
        this.documentsResumeChartData = documentProps.optionsData;
        this.documentsResumeChartLabels = documentProps.labels;
        this.documentsResumeChartOptions = documentProps.optionsBuild;

        this.formatColorChartResume();
        this.documentsResumeChartColors = documentProps.optionsColors;
        this.formatColorChartResume();

        this.documentsResumeChartType = documentProps.typeChart;
        this.documentsOrderType = documentProps.orderType;
      })
      .catch(() => {
        this.formLoading = false;
        this.dashboardService.notification.error(
          'Error',
          'Error ao carregar propriedades do gráfico.',
        );
      });
  }

  async cardAnexos() {
    await this.dashboardService
      .getCardAnexos()
      .then((documentProps: IDocumentResumeChartProps) => {
        this.cardAnexosData = documentProps.optionsData;
        this.cardAnexosLabels = documentProps.labels;
        this.cardAnexosOptions = documentProps.optionsBuild;
        this.formatColorChartResume();
        this.cardAnexosColors = documentProps.optionsColors;
        this.formatColorChartResume();
        this.cardAnexosOrderType = documentProps.orderType;

        // this.documentsResumeChartType = documentProps.typeChart;
      })
      .catch(() => {
        this.formLoading = false;
        this.dashboardService.notification.error(
          'Error',
          'Error ao carregar propriedades dos cards anexos.',
        );
      });
  }

  async getDocumentsPerPeriod(avulso) {
    this.formLoading = true;
    await this.dashboardService
      .getCountDocumentsPerPeriod(
        this.intervalTimeSelected[this.periodoTypeSelected],
        this.periodoTypeSelected,
        this.customIntervalFormated,
        avulso,
        this.assinantesSelected,
      )
      .then(async (documentsPerPeriod: IResponseCountOnlyDocsPerPeriod) => {
        const allValues = Object.values(documentsPerPeriod.data.count);

        const maxLength = allValues.reduce((a, b) => {
          return Math.max(a, b);
        });

        await this.getDocumentsPerPeriodChart(
          this.periodoTypeSelected,
          avulso,
          this.intervalTimeSelected[this.periodoTypeSelected],
          maxLength,
          documentsPerPeriod.data,
        );

        this.resultDashBoardDocuments = documentsPerPeriod.data;

        this.documentsResumeChartData = [
          this.documentsResumeChartData[0] +
            documentsPerPeriod.data.count.pendentes,
          this.documentsResumeChartData[1] +
            documentsPerPeriod.data.count.assinados,
          this.documentsResumeChartData[2] +
            documentsPerPeriod.data.count.confirmados,
          this.documentsResumeChartData[3] +
            documentsPerPeriod.data.count.desacordos,
          this.documentsResumeChartData[4] +
            documentsPerPeriod.data.count.expirados,
          this.documentsResumeChartData[5] +
            documentsPerPeriod.data.count.cancelados,
        ];

        this.formatResumeExpiration(this.documentsResumeExpiration);
        this.formLoading = false;
      });
    this.chart.ngOnInit();
  }

  async getAnexosPerPeriod(avulso) {
    this.formLoading = true;

    await this.dashboardService
      .getCountAnexosPerPeriod(
        this.intervalTimeSelected[this.periodoTypeSelected],
        this.periodoTypeSelected,
        this.customIntervalFormated,
        avulso,
        this.assinantesSelected,
      )
      .then(async (documentsPerPeriod: IResponseCountOnlyDocsPerPeriod) => {
        this.cardAnexosData = [0, 0, 0, 0, 0, 0];
        this.resultDashBoardAnexos = documentsPerPeriod.data;

        this.cardAnexosData = [
          this.cardAnexosData[0] + documentsPerPeriod.data.count.pendentes,
          this.cardAnexosData[1] + documentsPerPeriod.data.count.assinados,
          this.cardAnexosData[2] + documentsPerPeriod.data.count.confirmados,
          this.cardAnexosData[3] + documentsPerPeriod.data.count.desacordos,
          this.cardAnexosData[4] + documentsPerPeriod.data.count.expirados,
          this.cardAnexosData[5] + documentsPerPeriod.data.count.cancelados,
        ];

        // this.formatResumeExpiration(this.documentsResumeExpiration);
      });
    this.chart.ngOnInit();
    this.formLoading = false;
  }

  returnDateFromQtdDays(date: Date, day: number): Date {
    return new Date(new Date(date).setDate(date.getDate() + day));
  }

  async getDocumentsPerPeriodChart(
    typePeriod,
    avulso,
    interval = 0,
    maxLength,
    qtdeDocs: IReponseCountDocs,
  ) {
    this.formLoading = true;
    let finalDateFilter;
    let initialDateFilter;
    let name;

    await this.dashboardService
      .getCountDocumentsPerPeriodChart(
        this.intervalTimeSelected[this.periodoTypeSelected],
        this.periodoTypeSelected,
        this.customIntervalFormated,
        avulso,
        this.assinantesSelected,
        true,
      )
      .then(async (documentsPerPeriod: any) => {
        this.resultDashBoardDocuments = documentsPerPeriod.data;
        this.documentsChartData.map((data) => (data.data.length = 0));
        this.documentsResumeChartData = [0, 0, 0, 0, 0, 0];
        this.documentsChartLabels = [];
        this.documentsResumeExpiration = [];
        let lengthMaxChart = 0;

        for (let i = 0; i <= interval; i++) {
          const qtdDaysForFilter = this.numberDays[typePeriod] * i;

          finalDateFilter = this.returnDateFromQtdDays(
            new Date(Date.parse(new Date().toISOString())),
            qtdDaysForFilter,
          );

          finalDateFilter =
            typePeriod === 'Year'
              ? new Date(
                  new Date(finalDateFilter.getFullYear(), 11, 31).setSeconds(
                    -1,
                  ),
                )
              : new Date(
                  new Date(
                    finalDateFilter.getFullYear(),
                    finalDateFilter.getMonth(),
                    finalDateFilter.getDate(),
                  ).setSeconds(-1),
                );

          initialDateFilter = this.returnDateFromQtdDays(
            new Date(Date.parse(new Date().toISOString())),
            this.numberDays[typePeriod] * (i + 1),
          );

          initialDateFilter =
            typePeriod === 'Year'
              ? new Date(initialDateFilter.getFullYear(), 11, 31)
              : new Date(
                  initialDateFilter.getFullYear(),
                  initialDateFilter.getMonth(),
                  initialDateFilter.getDate(),
                );

          initialDateFilter = this.returnDateFromQtdDays(initialDateFilter, 1);

          finalDateFilter = this.returnDateFromQtdDays(finalDateFilter, 1);

          const namesPerTypePeriod = {
            Day: moment(initialDateFilter).format('DD/MM/YYYY'),
            Week: `${moment(initialDateFilter).format('DD/MM/YYYY')} - ${moment(
              finalDateFilter,
            ).format('DD/MM/YYYY')}`,
            Month: moment(finalDateFilter).locale('pt-BR').format('MMM/YYYY'),
            Year: moment(finalDateFilter).format('YYYY'),
          };

          name = namesPerTypePeriod[typePeriod];
          this.documentsChartLabels.push(name);

          if (typePeriod === 'Week') {
            const disagreedWeekOthDocs = documentsPerPeriod.data
              .filter(
                (key) =>
                  key.statusDoc === StatusDocumento.Desacordo &&
                  new Date(key?.dataDocumento) >=
                    new Date(moment(initialDateFilter).format('YYYY-MM-DD')) &&
                  new Date(key?.dataDocumento) <=
                    new Date(moment(finalDateFilter).format('YYYY-MM-DD')),
              )
              .map((itm) => {
                return itm.qtde;
              });

            const pendingWeekOthDocs = documentsPerPeriod.data
              .filter(
                (key) =>
                  key.statusDoc === StatusDocumento.Pendente &&
                  new Date(key?.dataDocumento) >=
                    new Date(moment(initialDateFilter).format('YYYY-MM-DD')) &&
                  new Date(key?.dataDocumento) <=
                    new Date(moment(finalDateFilter).format('YYYY-MM-DD')),
              )
              .map((itm) => {
                return itm.qtde;
              });

            const confirmedWeekOthDocs = documentsPerPeriod.data
              .filter(
                (key) =>
                  key.statusDoc === StatusDocumento.Confirmado &&
                  new Date(key?.dataDocumento) >=
                    new Date(moment(initialDateFilter).format('YYYY-MM-DD')) &&
                  new Date(key?.dataDocumento) <=
                    new Date(moment(finalDateFilter).format('YYYY-MM-DD')),
              )
              .map((itm) => {
                return itm.qtde;
              });

            const signedWeekOthDocs = documentsPerPeriod.data
              .filter(
                (key) =>
                  key.statusDoc === StatusDocumento.Assinado &&
                  new Date(key?.dataDocumento) >=
                    new Date(moment(initialDateFilter).format('YYYY-MM-DD')) &&
                  new Date(key?.dataDocumento) <=
                    new Date(moment(finalDateFilter).format('YYYY-MM-DD')),
              )
              .map((itm) => {
                return itm.qtde;
              });

            const expiredWeekOthDocs = documentsPerPeriod.data
              .filter(
                (key) =>
                  key.statusDoc === StatusDocumento.Expirado &&
                  new Date(key?.dataDocumento) >=
                    new Date(moment(initialDateFilter).format('YYYY-MM-DD')) &&
                  new Date(key?.dataDocumento) <=
                    new Date(moment(finalDateFilter).format('YYYY-MM-DD')),
              )
              .map((itm) => {
                return itm.qtde;
              });

            const canceledWeekOthDocs = documentsPerPeriod.data
              .filter(
                (key) =>
                  key.statusDoc === StatusDocumento.Cancelado &&
                  new Date(key?.dataDocumento) >=
                    new Date(moment(initialDateFilter).format('YYYY-MM-DD')) &&
                  new Date(key?.dataDocumento) <=
                    new Date(moment(finalDateFilter).format('YYYY-MM-DD')),
              )
              .map((itm) => {
                return itm.qtde;
              });

            // const anexosWeekOthDocs = documentsPerPeriod.data
            //   .filter(
            //     (key) =>
            //       key?.statusDoc === StatusDocumento.Anexos &&
            //       new Date(key?.dataDocumento) >=
            //         new Date(moment(initialDateFilter).format('YYYY-MM-DD')) &&
            //       new Date(key?.dataDocumento) <=
            //         new Date(moment(finalDateFilter).format('YYYY-MM-DD')),
            //   )
            //   .map((itm) => {
            //     return itm.qtde;
            //   });

            if (disagreedWeekOthDocs.length > 0) {
              this.documentsChartData[0].data.push(
                disagreedWeekOthDocs.reduce((a, b) => a + b),
              );
            }

            if (pendingWeekOthDocs.length > 0) {
              this.documentsChartData[1].data.push(
                pendingWeekOthDocs.reduce((a, b) => a + b),
              );
            }

            if (confirmedWeekOthDocs.length > 0) {
              this.documentsChartData[2].data.push(
                confirmedWeekOthDocs.reduce((a, b) => a + b),
              );
            }
            if (signedWeekOthDocs.length > 0) {
              this.documentsChartData[3].data.push(
                signedWeekOthDocs.reduce((a, b) => a + b),
              );
            }
            if (expiredWeekOthDocs.length > 0) {
              this.documentsChartData[4].data.push(
                expiredWeekOthDocs.reduce((a, b) => a + b),
              );
            }
            if (canceledWeekOthDocs.length > 0) {
              this.documentsChartData[5].data.push(
                canceledWeekOthDocs.reduce((a, b) => a + b),
              );
            }
            // if (anexosWeekOthDocs.length > 0) {
            //   this.documentsChartData[6].data.push(
            //     anexosWeekOthDocs.reduce((a, b) => a + b),
            //   );
            // }
          }

          if (typePeriod !== 'Week' || typePeriod !== 'Others') {
            const disagreedDocs = documentsPerPeriod.data
              .filter(
                (key) =>
                  key.statusDoc === StatusDocumento.Desacordo &&
                  key?.dataDocumento === name,
              )
              .map((itm) => {
                return itm.qtde;
              });

            const pendingDocs = documentsPerPeriod.data
              .filter(
                (key) =>
                  key.statusDoc === StatusDocumento.Pendente &&
                  key.dataDocumento === name,
              )
              .map((itm) => {
                return itm.qtde;
              });

            const confirmedDocs = documentsPerPeriod.data
              .filter(
                (key) =>
                  key.statusDoc === StatusDocumento.Confirmado &&
                  key?.dataDocumento === name,
              )
              .map((itm) => {
                return itm.qtde;
              });

            const signedDocs = documentsPerPeriod.data
              .filter(
                (key) =>
                  key.statusDoc === StatusDocumento.Assinado &&
                  key?.dataDocumento === name,
              )
              .map((itm) => {
                return itm.qtde;
              });

            const expiredDocs = documentsPerPeriod.data
              .filter(
                (key) =>
                  key.statusDoc === StatusDocumento.Expirado &&
                  key?.dataDocumento === name,
              )
              .map((itm) => {
                return itm.qtde;
              });

            const canceledDocs = documentsPerPeriod.data
              .filter(
                (key) =>
                  key.statusDoc === StatusDocumento.Cancelado &&
                  key?.dataDocumento === name,
              )
              .map((itm) => {
                return itm.qtde;
              });

            // const anexosDocs = documentsPerPeriod?.data
            //   .filter(
            //     (key) =>
            //       key?.statusDoc === StatusDocumento.Anexos &&
            //       key?.dataDocumento === name,
            //   )
            //   .map((itm) => {
            //     return itm?.qtde;
            //   });

            this.documentsChartData[0].data.push(
              disagreedDocs.reduce((a, b) => a + b, 0),
            );
            this.documentsChartData[1].data.push(
              pendingDocs.reduce((a, b) => a + b, 0),
            );
            this.documentsChartData[2].data.push(
              confirmedDocs.reduce((a, b) => a + b, 0),
            );
            this.documentsChartData[3].data.push(
              signedDocs.reduce((a, b) => a + b, 0),
            );
            this.documentsChartData[4].data.push(
              expiredDocs.reduce((a, b) => a + b, 0),
            );
            this.documentsChartData[5].data.push(
              canceledDocs.reduce((a, b) => a + b, 0),
            );
            // this.documentsChartData[6].data.push(
            //   anexosDocs.reduce((a, b) => a + b, 0),
            // );
          }
        }

        if (typePeriod === 'Others') {
          finalDateFilter = this.customInterval[1];
          initialDateFilter = this.customInterval[0];

          this.documentsChartLabels.push(
            `${moment(initialDateFilter).format('DD/MM/YYYY')} - ${moment(
              finalDateFilter,
            ).format('DD/MM/YYYY')}`,
          );
          this.documentsChartData[0].data.push(qtdeDocs.count.desacordos);
          this.documentsChartData[1].data.push(qtdeDocs.count.pendentes);
          this.documentsChartData[2].data.push(qtdeDocs.count.confirmados);
          this.documentsChartData[3].data.push(qtdeDocs.count.assinados);
          this.documentsChartData[4].data.push(qtdeDocs.count.expirados);
          this.documentsChartData[5].data.push(qtdeDocs.count.cancelados);
          // this.documentsChartData[6].data.push(qtdeDocs.count.anexos);
        }

        const lengths = [
          maxLength,
          // period.count?.anexos?,
        ];

        if (lengths.sort((a, b) => b - a)[0] > lengthMaxChart) {
          lengthMaxChart = lengths.sort((a, b) => b - a)[0];
        }

        // this.documentsResumeExpiration =
        //   this.documentsResumeExpiration.length === 0
        //     ? period.resumeExpirated
        //     : [
        //         {
        //           ...this.documentsResumeExpiration[0],
        //           qtd:
        //             this.documentsResumeExpiration[0].qtd +
        //             period.resumeExpirated[0].qtd,
        //         },
        //         {
        //           ...this.documentsResumeExpiration[1],
        //           qtd:
        //             this.documentsResumeExpiration[1].qtd +
        //             period.resumeExpirated[1].qtd,
        //         },
        //         {
        //           ...this.documentsResumeExpiration[2],
        //           qtd:
        //             this.documentsResumeExpiration[2].qtd +
        //             period.resumeExpirated[2].qtd,
        //         },
        //         {
        //           ...this.documentsResumeExpiration[3],
        //           qtd:
        //             this.documentsResumeExpiration[3].qtd +
        //             period.resumeExpirated[3].qtd,
        //         },
        //       ];
        // }
        this.documentsResumeExpiration.sort((a, b) => a.qtd - b.qtd);

        const yMax = Math.round(lengthMaxChart + lengthMaxChart * 0.1);

        this.documentsChartOptions.scales.yAxes[0].ticks.max = yMax;

        this.documentsChartOptions.scales.yAxes[0].ticks.stepSize = (
          Math.sqrt(yMax) > 10 ? 10 : Math.sqrt(yMax)
        ).toFixed(0);

        this.formatResumeExpiration(this.documentsResumeExpiration);
      });
    this.chart.ngOnInit();
    this.formLoading = false;
  }

  formatResumeExpiration(documentsResumeExpiration: any[]) {
    documentsResumeExpiration = documentsResumeExpiration.sort(
      (a, b) => b.qtd - a.qtd,
    );

    const qtdAll = documentsResumeExpiration.reduce(
      (total, row) => total + row.qtd,
      0,
    );
    documentsResumeExpiration.map((resume, index) => {
      resume[`width`] = `${(resume.qtd / qtdAll) * 100}%`;
    });
  }

  navigateToSListDocument(type: number) {
    this.tooltipVisibleDocumentsCharts[type].chart = true;

    setTimeout(() => {
      this.tooltipVisibleDocumentsCharts[type].chart =
        !this.tooltipVisibleDocumentsCharts[type].chart;
    }, 1);
    setTimeout(() => {
      const reduceToId = this.assinantesSelected?.map(
        (keys) => keys.idPessoaFisica || keys.idResponsavel,
      );

      this.addTabDocument(
        'Todos documentos - Assinar, Discordar...',
        '/session/documentos',
        type,
        this.switchValue,
        reduceToId,
        this.customInterval ? this.customInterval[0].toString() : null,
        this.customInterval ? this.customInterval[1].toString() : null,
        this.intervalTimeSelected[this.periodoTypeSelected],
        null,
        this.adm,
      );
    }, 2);
  }

  navigateToSListAnexos(type: number) {
    this.tooltipVisibleDocumentsCharts[type].chart = true;

    setTimeout(() => {
      this.tooltipVisibleDocumentsCharts[type].chart =
        !this.tooltipVisibleDocumentsCharts[type].chart;
    }, 1);
    setTimeout(() => {
      const reduceToId = this.assinantesSelected?.map(
        (keys) => keys.idPessoaFisica || keys.idResponsavel,
      );

      this.addTabDocument(
        'Todos anexos - Assinar, Discordar...',
        '/session/anexos',
        type,
        this.switchValue,
        reduceToId,
        this.customInterval ? this.customInterval[0].toString() : null,
        this.customInterval ? this.customInterval[1].toString() : null,
        this.intervalTimeSelected[this.periodoTypeSelected],
        null,
        this.adm,
      );
    }, 2);
  }

  addTabDocument(
    name,
    url,
    tipo,
    docAvulso,
    assinantes?,
    initialDate?,
    finalDate?,
    interval?,
    colorExpired = null,
    adm?,
  ) {
    this.router.navigate([url], {
      queryParams: {
        tipo,
        docAvulso,
        assinantes,
        initialDate,
        finalDate,
        interval,
        trasaction: (Math.random() * 100).toPrecision(1),
        colorExpired,
        adm,
      },
    });
    this.dashboardService.tabSessionService.addTab({ name, url });
  }

  async admUser() {
    this.adm = await this.documentService.isAdmUser();
  }

  getNameColorDashboard(colorName) {
    const index = colorName?.lastIndexOf('Light');

    return index !== -1
      ? colorName?.slice(0, colorName?.lastIndexOf('Light'))
      : colorName;
  }

  getWidthContent() {
    return window.innerWidth;
  }

  buildFabButton(fabButtons: IMenuButton[]) {
    this.fabButtons = fabButtons;
  }

  async getAllAssinantes(avulso = false) {
    this.formLoading = true;
    // this.listSignatarios = [];
    const signers = await this.getAllSignatarios(avulso);
    this.listSignatarios = signers
      .filter((el) => {
        return (
          el.hasOwnProperty('cpf') && el.hasOwnProperty('cpfRepresentante')
        );
      })
      .sort((a, b) => {
        if (a.nome < b.nome) {
          return -1;
        }
        if (a.nome > b.nome) {
          return 1;
        }
        return 0;
      });

    this.formLoading = false;
  }

  addFiltroPorAssinante() {
    if (this.assinanteSelected) {
      Object.assign(
        this,
        this.documentService.addSubscriberToFilter(
          this.assinanteSelected,
          this.assinantesSelected,
          this.listSignatarios,
        ),
      );
      this.getDocumentsPerPeriod(this.switchValue);
      this.getAnexosPerPeriod(this.switchValue);
    }

    setTimeout(() => (this.assinanteSelected = null), 0);
  }

  removeFiltroPorAssinante(cpf) {
    this.documentService.removeSubscriberToFilter(
      this.assinantesSelected,
      this.allAssinantes,
      cpf,
    );
    this.getDocumentsPerPeriod(this.switchValue);
    this.getAnexosPerPeriod(this.switchValue);
  }

  attDashboard = async () => {
    // this.formLoading = true;
    this.getDate();
    // this.documentService.clickedEvent(true);
    await this.resetChart();
    await this.resetChartResume();
    await this.getDocumentsPerPeriod(this.switchValue);
    await this.getAnexosPerPeriod(this.switchValue);
    // this.captureEvent();
  };

  // captureEvent() {
  //   this.authService.aClickedEvent.subscribe(async (data) => {
  //     this.formLoading = data;
  //   });
  // }

  getDate() {
    this.getDateNow = new Date().toLocaleString();
  }
}
