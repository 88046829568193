import { NgModule } from '@angular/core';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { CommonModule } from '@angular/common';

import {
  NzInputModule,
  NzButtonModule,
  NzCardModule,
  NzFormModule,
  NzIconModule,
  NzAvatarModule,
  NzToolTipModule,
  NzSwitchModule,
  NzSpinModule,
} from '../antd';

import { PsDynamicFormModule } from '../components';
import { PsDirectiveModule } from '../directives';
import { AuthService } from '../services';
import { Page404Component } from './page-feedbacks';
import { SistemaService } from '../services/api/core/sistema.service';

@NgModule({
  declarations: [Page404Component],
  imports: [
    CommonModule,
    RouterModule,
    ReactiveFormsModule,
    PsDirectiveModule,
    FormsModule,
    NzInputModule,
    NzButtonModule,
    NzCardModule,
    NzFormModule,
    PsDynamicFormModule,
    NzIconModule,
    NzAvatarModule,
    NzToolTipModule,
    NzSwitchModule,
    NzSpinModule,
  ],
  exports: [],
  providers: [AuthService, SistemaService],
})
export class PsPagesModule {}
