import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import * as moment from 'moment';
import { NzModalService } from 'ng-zorro-antd/modal';
import { PDFDocument } from 'pdf-lib';
import printJS from 'print-js';
import { AuthService } from 'src/@lib';
import { IMenuButton } from '../../@lib/components/speed-dial-fab/speed-dial-fab.component';
import { DocumentsDetailComponent } from '../documents-detail/documents-detail.component';
import { ModalDialogComponent } from '../modal-dialog/modal-dialog.component';
import {
  ColorStatusDoc,
  IntervalsTime,
  IntervalsTimeTranslate,
  StatusDoc,
  StatusDocLabel,
  TypeActions,
  TypeAnexoView,
  TypeDocDownload,
  TypeDocView,
} from '../shared/consts/document';
import { CodigoModulos, ModulosPublicSoft } from '../shared/consts/modulos';
import { StatusCertificado, StatusDocumento } from '../shared/enum/const.enum';
import {
  IColumnsFieldsProps,
  ISearchFieldsProps,
} from '../shared/interfaces/shared.interface';
import { AssinantesService } from '../shared/services/assinantes.service';
import { DocumentService } from '../shared/services/document.service';
import { DocumentsPendingService } from '../shared/services/documents-pending.service';
import { DocumentsUploadService } from '../shared/services/documents-upload.service';
import { SetorService } from '../shared/services/setor.service';
import { TipoDocumentoService } from '../shared/services/tipo-documento.service';
import { PeriodVisualizeDoc } from '../shared/types/document.types';
import {
  IAssinante,
  IDocumento,
  IDocumentoAnexo,
  ISetor,
} from './../shared/interfaces/document-interfaces';

@Component({
  selector: 'app-documents',
  templateUrl: './documents.component.html',
  styleUrls: ['./documents.component.scss'],
})
export class DocumentsComponent implements OnInit {
  assinanteLogado;
  pageTotal: number;
  idDocSelected: number;
  assinanteSelected: any;
  assinantesSelected = [];
  allAssinantes = [];
  peopleArray = [];
  adm: boolean;
  showDocsAvulsos = false;
  filterType: number[];
  colorExpiredDocSelected: string;
  dataDocuments: Array<{ pag: number; documents: Array<IDocumento> }> = [];

  assinarAnexos: boolean;

  ratificarAnexo: boolean;

  getDateNow: any = this.getDate();

  loading = false;
  loadingSignatarios = false;
  formLoading = false;

  paramsWhere: any;

  checkedAll = false;
  checkAllIndeterminate = true;

  searchInput: any;
  displayData: IDocumento[] = [];

  allDocsChecks: IDocumento[] = [];

  showComboTags = false;

  fieldSelected;

  fieldsSelected = [];

  selectedValues = [];

  arrColumnsSelect = [];

  searchColumns: ISearchFieldsProps[] = [];
  tableColumns: IColumnsFieldsProps[] = [];
  dataTableColumnProps;

  intervalsTime = Object.entries(IntervalsTime);
  intervalTimeSelected = this.intervalsTime[0][0];

  documentList = [];
  docsChecked = [];

  listSignatarios: IAssinante[];
  listSignatariosToSign = [];
  certificadoAssinante: any[];
  newDocument: IDocumento[] = [];

  listedAttachs: boolean;

  statusDoc = StatusDoc;
  statusDocLabel = StatusDocLabel;

  panels = [
    {
      active: true,
      name: 'Filtros',
    },
  ];

  checkList = {
    tipoDoc: true,
    numeroDoc: true,
    numeroDocOriginal: true,
    status: true,
    chancela: true,
    ratificado: true,
    nomeUnidadeOrc: true,
    idSetor: true,
    nomeFav: true,
    tipoDocumento: true,
    valorDoc: true,
    numProcesso: true,
    dataDocumentoFormated: true,
    dataLimiteAssinatura: true,
    aplicacaoOrigem: true,
    numeroLicitacao: true,
    modalidadeLicitacao: true,
    numeroObra: true,
    numeroContrato: true,
    numeroEvento: true,
    numeroConvenio: true,
    numeroProgramaInstitucional: true,
    usuarioCriacao: true,
    psHash: true,
    acoes: true,
  };

  flagQueryParams = false;

  fabButtons: IMenuButton[];

  colorStatusDoc = ColorStatusDoc;
  verification: boolean;
  identifyer: boolean = false;
  ratificarDoc: boolean = false;
  ratificarTodos: boolean;
  labelToaster: string;
  hasCertificado: boolean;
  listaPessoas = [];
  signatarios = [];
  customInterval: string[];

  emails: Map<number, string>;

  tooltipVisible: object = {
    filter1: false,
    filter2: false,
    filter3: false,
    filter4: false,
    filter5: false,
    filter6: false,
  };

  public innerWidth: any;

  constructor(
    protected authService: AuthService,
    private activateRouter: ActivatedRoute,
    public documentService: DocumentService,
    private tipoDocService: TipoDocumentoService,
    public service: DocumentsUploadService,
    private documentsPendingService: DocumentsPendingService,
    private modal: NzModalService,
    private router: Router,
    private assinantesService: AssinantesService,
    private readonly setorService: SetorService,
  ) {
    this.getDate();
    this.admUser();
    this.activateRouter.queryParamMap.subscribe(async (queryParams) => {
      this.fieldsSelected = [];
      this.resetPagination();
      this.filterPreSet(queryParams);
      this.showComboTags = false;
      this.intervalTimeSelected = queryParams.get('interval')
        ? queryParams.get('interval')
        : null;

      this.filterType = Number(queryParams.get('tipo'))
        ? [Number(queryParams.get('tipo'))]
        : [];

      const adm = queryParams.get('adm') === 'false' ? false : true;

      this.adm = adm;

      this.flagQueryParams = true;

      await Promise.all([this.setConfigTable(), this.getAllAssinantes()]);

      this.columnsSelect(this.tableColumns);

      this.colorExpiredDocSelected = queryParams.get('colorExpired');

      if (queryParams.keys.length > 0) {
        this.changeViewDocsAvulsos(JSON.parse(queryParams.get('docAvulso')));

        if (queryParams.getAll('assinantes').length > 0) {
          this.assinanteSelected = null;
          this.assinantesSelected = [];
          const arrAssinantes = [];
          this.getAllAssinantes();

          for (const ids of queryParams.getAll('assinantes')) {
            this.assinanteSelected = this.listSignatarios
              ?.filter((key) => key.idPessoaFisica === Number(ids))
              .map((newArr) => {
                return {
                  ...newArr,
                  queryParams: true,
                };
              });
            arrAssinantes.push(...this.assinanteSelected);
          }
          this.addFiltroPorAssinante(arrAssinantes);
        }

        if (queryParams.get('initialDate')) {
          const initialDate = queryParams.get('initialDate');
          const finalDate = queryParams.get('finalDate');
          this.customInterval = [initialDate, finalDate];
        }

        this.getDocumentsPerPeriod(this.showDocsAvulsos);
      }
    });
    this.resetFabButtons();

    // this.documentService.updateDsp.subscribe(async (msg) => {
    //   if (Boolean(msg) === true) {
    //     await this.getDocumentsPerPeriod();
    //   }
    // });
  }

  resetPagination(): void {
    this.dataDocuments = [];
    this.documentList = [];
    this.docsChecked = [];
    this.checkedAll = false;
    this.checkAllIndeterminate = true;
  }

  public visiClick(): boolean {
    let visible: boolean = false;
    setTimeout(() => {
      visible = false;
    }, 2000);
    return visible;
  }

  ngOnInit(): void {
    // setInterval(() => { //caso seja necessário atualizar a resolução em tempo de execução
    this.innerWidth = window.innerWidth;
    // }, 2000);
    this.displayData.map((dp) => {
      return {
        ...dp,
        tooltipeVisibleTableRow: false,
        tooltipeVisibleEye: false,
      };
    });
    this.filterPreSet();
  }

  filterPreSet(queryParams?) {
    const date = new Date();
    let primeiroDia: any = moment(
      new Date(date.getFullYear(), date.getMonth(), 1),
    ).format('DD/MM/YYYY');
    let diaAtual: any = moment().format('DD/MM/YYYY');

    //https://pt.stackoverflow.com/questions/226086/como-retornar-o-primeiro-e-%C3%BAltimo-dia-do-m%C3%AAs-corrente-em-javascript
    if (queryParams.keys?.length > 0) {
      const { initialDate, finalDate, interval } = queryParams?.params;

      // const primeiroDiaIso = initialDate;
      primeiroDia = moment(initialDate).format('DD/MM/YYYY');
      diaAtual = moment(finalDate).format('DD/MM/YYYY');

      this.fieldsSelected = [];

      if (interval && !(initialDate && finalDate)) {
        return this.fieldsSelected.push({
          field: 'Data do documento',
          props: 'dataDocumentoFormated',
          text: `Período - ${IntervalsTime[interval]}`,
        });
      }
    }
    return this.fieldsSelected.push({
      field: 'Data do documento',
      props: 'dataDocumentoFormated',
      text: `Data do documento - ${primeiroDia} - ${diaAtual}`,
      value: `${primeiroDia} - ${diaAtual}`,
    });

    // this.showComboTags = this.fieldsSelected.length != 0;
  }

  toggleTooltipVisible(item) {
    item.tooltipeVisibleTableRow = !item.tooltipeVisibleTableRow;

    setTimeout(() => {
      item.tooltipeVisibleTableRow = false;
    }, 2000);
  }

  toggleTooltipVisibleEye(item) {
    item.tooltipeVisibleEye = !item.tooltipeVisibleEye;

    setTimeout(() => {
      item.tooltipeVisibleEye = false;
    }, 2000);
  }

  async getAllSignatarios(avulso) {
    return await this.documentService.getAllSignatarios(avulso);
  }

  resetFabButtons() {
    this.buildFabButtons([
      {
        icon: 'form',
        tooltip: 'Assinar',
        condition: true,
        color: 'blue',
        onClick: this.assinar,
      },
      {
        icon: 'close-circle',
        tooltip: 'Discordar',
        color: 'red',
        condition: true,
        onClick: this.discordar,
      },
      {
        icon: 'rollback',
        tooltip: 'Desfazer Desacordo',
        condition: true,
        color: 'yellow',
        onClick: this.desfazer,
      },
      {
        icon: 'download',
        tooltip: 'Download',
        condition: true,
        onClick: this.downloadDocuments,
      },
      {
        icon: 'stop',
        tooltip: 'Cancelar',

        condition: true,
        onClick: this.cancelar,
      },
      {
        icon: 'reload',
        tooltip: 'Atualizar',
        condition: true,
        onClick: this.attDashboard,
      },
      {
        icon: 'diff',
        tooltip: 'Ratificar Documento',
        condition: true,
        color: 'green',
        onClick: this.ratificar,
      },
      {
        icon: 'printer',
        tooltip: 'Imprimir',
        condition: true,
        // color: 'blue',
        onClick: this.imprimirArquivos,
      },
      {
        icon: 'history',
        tooltip: 'Reativar',
        condition: true,
        color: 'orange',
        onClick: this.reativarDocs,
      },
    ]);
  }

  reativarDocs = async () => {
    this.listSignatariosToSign = [];

    let newDocs: IDocumento[];
    if (this.checkedAll && this.documentList.length > 9) {
      await this.getDocumentsPerPeriod(this.showDocsAvulsos, this.checkedAll);
      newDocs = this.documentList.map((itm) => {
        return {
          ...itm,
          checked: true,
        };
      });
    }

    const docCheckeds = newDocs?.length ?? this.dataDocumentsCheckeds().length;

    const confirmedDocs =
      newDocs ?? this.getDocsToDataDocuments().filter((doc) => doc.checked);

    if (docCheckeds === 0) {
      return this.documentService.notification.info(
        'Reativar Documento',
        'Não foi possivel reativar o(s) documento(s), pois nenhum documento foi selecionado!',
        { nzDuration: 7000 },
      );
    }
    this.loading = true;

    for (const docs of confirmedDocs) {
      const expired: boolean = this.documentService.docIsExpirado(
        docs.dataLimiteAssinatura,
      )
        ? true
        : false;
      this.loading = false;

      if (!expired) {
        return this.documentService.notification.info(
          `Reativar Documento`,
          `Não foi possivel reativar o documento, pois o documento não está Expirado!`,
          { nzDuration: 7000 },
        );
      }
    }

    this.service.assinarAnexo.subscribe(async (data) => {
      this.assinarAnexos = data;
    });
    const idPrivadoDocs: number[] = this.documentService
      .getDocsSelecionadosParaAssinar(
        confirmedDocs ?? this.dataDocumentsCheckeds(),
      )
      .map((doc) => doc.idPrivado);

    const title = `<i nz-icon nzType="warning" nzTheme="outline"> Confirma a reativação do(s) documento(s) selecionado(s)?</i>`;
    const okText = 'Sim';
    const cancelText = 'Não';
    const onOk = async (componentInstance) => {
      const dataExp = new Date(
        componentInstance.formB.value.dataExpiracao,
      ).toISOString();

      const idsDocsParaAssinar: number[] = idPrivadoDocs;

      await this.documentService
        .reativarDocs(idsDocsParaAssinar, dataExp)
        .then(async () => {
          this.getDocumentsPerPeriod();
          this.updateDisplayDocs(idsDocsParaAssinar);
          modal.close();
          this.loading = false;
        })
        .catch(() => {
          this.documentService.notification.error(
            'Reativar Documento',
            'Error ao reativar documento',
          );
          this.resetAllCheckbox();
        });
    };

    const modal = this.modal.create({
      nzTitle: title,
      nzContent: ModalDialogComponent,
      nzFooter: [
        {
          label: cancelText,
          onClick: () => {
            modal.close();
            this.loading = false;
          },
        },
        {
          label: okText,
          disabled: (componentInstance) => !componentInstance.reativar,
          type: 'primary',
          onClick: (componentInstance) => onOk(componentInstance),
        },
      ],
      nzComponentParams: {
        valorSelecionado: this.documentService.getValorSelecionado(
          this.getDocsToDataDocuments(),
        ),
        qtdSelecionados: docCheckeds,
        reativarDocs: true,
      },
    });

    modal.afterClose.subscribe((resultClose: any) => {
      this.attDashboard();
      if (!resultClose) {
        modal.destroy();
        this.loading = false;
      }
    });
    // this.resetAllCheckbox();
    this.attDashboard();
  };

  imprimirArquivos = async () => {
    if (this.dataDocumentsCheckeds().length === 0) {
      return this.service.notification.info(
        'Imprimir arquivos',
        'Selecione algum arquivo para imprimir',
      );
    }

    const title = `<i nz-icon nzType="warning" nzTheme="outline">Confirma impressão de Documentos?</i>`;
    const okText = 'confirmar';
    const cancelText = 'Cancelar';
    const onOk = async (motivo) => {
      await this.imprimirDocs({
        docs: motivo.docsPrintChecked,
        anxs: motivo.anxsPrintChecked,
      });
      this.modal.closeAll();
    };

    //provisorio: será esse o modal apenas enquanto só os documentos forem impressos
    this.modal.confirm({
      nzTitle:
        '<i nz-icon nzType="warning" nzTheme="outline">Confirma impressão de Documentos?</i>',
      // nzContent: '<b>Some descriptions</b>',
      nzOnOk: async () => {
        await this.imprimirDocs({
          docs: true,
          anxs: false,
        });
        this.modal.closeAll();
      },
    });

    //provisorio: será esse o modal somente quando houver escolha entre anexos e documentos ou os dois
    // const modal = this.modal.create({
    //   nzTitle: title,
    //   nzContent: ModalDialogComponent,
    //   nzFooter: [
    //     {
    //       label: cancelText,
    //       onClick: () => {
    //         modal.destroy();
    //         this.loading = false;
    //       },
    //     },
    //     {
    //       label: okText,
    //       disabled: (componentInstance) =>
    //         !(
    //           componentInstance.docsPrintChecked ||
    //           componentInstance.anxsPrintChecked
    //         ),
    //       type: 'primary',
    //       onClick: (componentInstance) => onOk(componentInstance),
    //     },
    //   ],
    //   nzComponentParams: { isPrinter: true },
    // });

    // modal.afterClose.subscribe((resultClose: any) => {
    //   if (!resultClose) {
    //     modal.destroy();
    //   }
    // });
  };

  async imprimirDocs(print) {
    const urlDocumentos = [];
    const urlAnexos = [];
    const anexos = [];
    let files = this.dataDocumentsCheckeds();
    let printFiles;
    if (
      this.checkedAll &&
      this.documentList.length > 9 &&
      this.ratificarDoc === false
    ) {
      await this.getDocumentsPerPeriod(this.showDocsAvulsos, this.checkedAll);
      this.allDocsChecks = this.documentList.map((itm) => {
        return {
          ...itm,
          checked: true,
        };
      });
      files = this.documentList;
    }

    for (const file of files) {
      const typeView = TypeDocView[file.statusDocumento];

      const uuid = await this.documentService.getUuidFile(typeView, file);

      const typeAction = TypeActions[file.statusDocumento];

      const urlDocToView = await this.documentService.getUrlDocToUploadApi(
        uuid,
        typeAction,
      );

      urlDocumentos.push(urlDocToView.pdfURL);

      file.documentoAnexo.forEach(async (anexo) => {
        const typeViewAnexo = TypeAnexoView[anexo.status];

        const uuidAnexo = await this.documentService.getUuidFileAnexo(
          typeViewAnexo,
          anexo,
        );

        const urlAnexo = await this.documentService.getUrlDocToUploadApi(
          uuidAnexo,
          typeViewAnexo,
        );

        urlAnexos.push(urlAnexo.pdfURL), anexos.push(anexo);
      });
    }
    if (print.docs && !print.anxs) {
      printFiles = [{ tipo: 'documento', urlFiles: urlDocumentos }];
    }
    if (print.anxs && !print.docs) {
      printFiles = [{ tipo: 'anexo', urlFiles: urlAnexos }];
    }
    if (print.anxs && print.docs) {
      printFiles = [
        { tipo: 'anexo', urlFiles: urlAnexos },
        { tipo: 'documento', urlFiles: urlDocumentos },
      ];
    }

    let docsFilesLinks;
    let anxsFilesLinks;
    for (const printFile of printFiles) {
      printFile.tipo == 'documento'
        ? (docsFilesLinks = printFile.urlFiles)
        : (anxsFilesLinks = printFile.urlFiles);
    }
    const filesLinks =
      anxsFilesLinks && docsFilesLinks
        ? [docsFilesLinks, anxsFilesLinks]
        : anxsFilesLinks
        ? [anxsFilesLinks]
        : [docsFilesLinks];

    if (print.anxs) {
      for (const anexo of anexos) {
        this.getURLAnexo(anexo, true);
      }
    }

    if (print.docs) {
      await this.mergeAllPDFs(docsFilesLinks);
    }

    // const base64PDFMerged = await this.mergeAllPDFs(filesLinks);
    //https://printjs.crabbly.com/#documentation
  }

  async getURLAnexo(anexo: IDocumentoAnexo, download = false) {
    // this.pageLoading = true;
    return await this.documentService
      .getURLAnexo(anexo, download)
      .then((result) => {
        this.service.notification.success('Download success', 'Download');
      })
      .catch((e) => this.service.notification.error('Download Error', e));
  }

  async mergeAllPDFs(urls) {
    //https://stackoverflow.com/questions/21478738/how-can-we-do-pdf-merging-using-javascript
    const pdfDoc = await PDFDocument.create();
    const numDocs = urls.length;

    for (var i = 0; i < numDocs; i++) {
      const donorPdfBytes = await fetch(urls[i]).then((res) =>
        res.arrayBuffer(),
      );
      const donorPdfDoc = await PDFDocument.load(donorPdfBytes);
      const docLength = donorPdfDoc.getPageCount();
      for (var k = 0; k < docLength; k++) {
        const [donorPage] = await pdfDoc.copyPages(donorPdfDoc, [k]);
        pdfDoc.addPage(donorPage);
      }
    }

    if (numDocs === 0) {
      return this.service.notification.info(
        'Impressão de Documentos',
        'nenhum anexo encontrado',
      );
    }

    // if (docPagesTotal > 200) {
    //   return this.service.notification.info(
    //     'Impressão de Documentos',
    //     'Os Documentos selecionados possuem muitas páginas: ' + docPagesTotal,
    //     { nzDuration: 20000 },
    //   );
    // }

    const pdfDataUri = await pdfDoc.saveAsBase64({ dataUri: true });

    // strip off the first part to the first comma "data:image/png;base64,iVBORw0K..."
    const data_pdf = pdfDataUri.substring(pdfDataUri.indexOf(',') + 1);

    function printEmLotes(b64PDF) {
      //b64PDF => arquivos do lote
      //number => numero do lote
      return new Promise((resolve, reject) => {
        printJS({
          printable: b64PDF,
          type: 'pdf',
          showModal: false,
          onPrintDialogClose: async () => {
            resolve(true);
          },
          onError: (error) => {
            this.service.notification.error('Imprimir arquivos', error);
            reject(error);
          },
          base64: true,
        });
      });
    }

    await printEmLotes(data_pdf).then((bool) => {});
  }

  verifyStatusCheckAll(): boolean {
    return !this.displayData.every((item) =>
      this.documentService.getStatusCheckDocument(item),
    );
  }

  // canCancelDoc() {
  //   if (this.documentService.isUserAdm()) {
  //     return true;
  //   }

  //   return this.documentService
  //     .getDocsSelecionados(this.displayData)
  //     .every(
  //       (item: IDocumento) =>
  //         item.usCriacao === this.documentService.authService.getIdUser(),
  //     );
  // }

  buildFabButtons(fabButtons: IMenuButton[]) {
    this.fabButtons = fabButtons;
  }

  downloadDocuments = async () => {
    this.loading = true;
    let newDocs: IDocumento[] = [];
    if (this.checkedAll && this.documentList.length > 9) {
      await this.getDocumentsPerPeriod(this.showDocsAvulsos, this.checkedAll);
      newDocs = this.documentList.map((itm) => {
        return {
          ...itm,
          checked: true,
        };
      });
    }

    const docCheckeds: number =
      newDocs.length ?? this.dataDocumentsCheckeds().length;

    if (!docCheckeds) {
      this.loading = false;
      return this.documentService.notification.info(
        'Download',
        'Selecione algum arquivo para realizar o download!',
      );
    }
    const doc = newDocs ?? this.dataDocumentsCheckeds();
    for (const documents of doc) {
      const typeDownload = TypeDocDownload[documents.statusDocumento];

      const event = await this.documentService.downloadDocWithUrlToUploadApi(
        await this.documentService.getUuidFile(typeDownload, documents),
        typeDownload,
        documents.tipoDocumento.tipo,
        documents.numeroDoc,
      );

      documents.eventos.push(event);
      this.loading = false;
    }
  };

  async getAllAssinantes(avulso = false, idDocumento?) {
    this.loadingSignatarios = true;
    this.getAllSignatarios(avulso).then((allSignatarios) => {
      const newAss = allSignatarios
        .filter((el) => {
          return (
            el.hasOwnProperty('cpf') && el.hasOwnProperty('cpfRepresentante')
          );
        })
        .sort((a, b) => {
          if (a.nome < b.nome) {
            return -1;
          }
          if (a.nome > b.nome) {
            return 1;
          }
          return 0;
        });

      if (idDocumento) {
        this.documentService
          .getSignatarioByIdDocumento(idDocumento)
          .then((data) => (this.listSignatariosToSign = data));
      }

      idDocumento
        ? this.listSignatariosToSign
        : (this.listSignatarios = newAss);
      this.loadingSignatarios = false;
    });
  }

  addFiltroPorAssinante(arrAssinantes = []) {
    if (arrAssinantes.length === 0) {
      this.assinantesSelected.push(this.assinanteSelected);

      this.fieldsSelected.push({
        props: 'assinante',
        field: 'Assinante',
        value: this.assinanteSelected.cpf,
        text: `${this.assinanteSelected.cpf} - ${this.assinanteSelected.nome}`,
      });

      this.showComboTags = true;
    }

    if (arrAssinantes.length > 0) {
      for (const assinante of arrAssinantes) {
        this.assinantesSelected.push(assinante);

        this.fieldsSelected.push({
          props: 'assinante',
          field: 'Assinante',
          value: assinante.cpf,
          text: `${assinante.cpf} - ${assinante.nome}`,
        });
      }
      this.showComboTags = true;
    }

    setTimeout(() => (this.assinanteSelected = null), 0);
  }

  addFiltroPorCampo() {
    if (this.fieldSelected && this.searchInput) {
      if (this.fieldSelected.type === 'range-picker') {
        this.fieldsSelected = this.fieldsSelected.filter((el) => {
          return el.field != 'Data do documento';
        });
        this.searchInput = `${moment(this.searchInput[0]).format(
          'DD/MM/yyyy',
        )} - ${moment(this.searchInput[1]).format('DD/MM/yyyy')}`;
      }

      if (this.fieldSelected.type === 'date-picker') {
        this.searchInput = moment(this.searchInput).format('DD/MM/yyyy');
      }

      this.fieldsSelected.push({
        props: this.fieldSelected.value,
        field: this.fieldSelected.text,
        value: this.searchInput.text ? this.searchInput.text : this.searchInput,
        text: `${this.fieldSelected.text} - ${
          this.searchInput.text ? this.searchInput.text : this.searchInput
        }`,
      });

      const index = this.arrColumnsSelect.findIndex(
        (a) => a.value === this.fieldSelected.value,
      );

      this.arrColumnsSelect.splice(index, 1);

      this.showComboTags = true;
      this.fieldSelected = '';
      this.searchInput = '';
      this.selectedValues = [];
    }
  }

  // getMask(column) {
  //   this.getOptionsSelect(column);
  //   this.searchInput = '';
  //   clearTimeout(this.intervaloDigitando);
  //   this.intervaloDigitando = setTimeout(() => {
  //     if (column.mask) {
  //       this.getMaskReturned = Helper.returnMask(column.mask);
  //     }
  //   }, 1000);
  // }

  async getOptionsSelect(column) {
    let arrTypes = [];
    this.selectedValues = [];

    if (column.objectChildren === 'tag') {
      arrTypes = await this.tipoDocService.getTipoDocByTag();
    }

    if (column.value === 'tipoDoc') {
      arrTypes = await this.tipoDocService.getTipoDocs(null, true);
    }

    if (column.value === 'tipoDoc' || column.objectChildren === 'tag') {
      for (const teste of arrTypes) {
        this.selectedValues.push({
          field: this.fieldSelected.text,
          value: column.objectChildren === 'tag' ? teste.tag : teste.tipo,
          text:
            column.objectChildren === 'tag'
              ? teste.tag
              : `${teste.idPublico} - ${teste.tipo}`,
        });
      }
    }
    if (column.value === 'idSetor') {
      const setores: ISetor[] = await this.setorService.listarSetor();
      this.selectedValues = setores
        .map((setor: ISetor) => ({
          field: this.fieldSelected.text,
          value: setor.idPublico,
          text: `${setor.idPublico} - ${setor.descricao}`,
        }))
        .reverse();
    }
  }

  columnsSelect(tableColumns) {
    this.arrColumnsSelect = this.documentService.getColumnsSelect(tableColumns);
  }

  removeFiltroPorField(index) {
    const indx = this.assinantesSelected.findIndex(
      (a) => a.cpf === this.fieldsSelected[index].value,
    );

    if (indx > -1) {
      this.assinantesSelected.splice(indx, 1);
    }

    this.fieldsSelected.splice(index, 1);

    this.columnsSelect(this.tableColumns);

    if (this.fieldsSelected.length === 0) {
      this.showComboTags = false;
    }
  }

  removeFiltroPorAssinante(cpf) {
    this.documentService.removeSubscriberToFilter(
      this.assinantesSelected,
      this.listSignatarios,
      cpf,
    );
  }

  clearFilterByDashboard() {
    this.intervalTimeSelected = this.intervalsTime[0][0];
  }

  async reset() {
    await this.getDocumentsPerPeriod();
    await this.setConfigTable();
  }

  setFilterType(type: number) {
    this.filterType = this.documentService.updateFilterType(
      this.filterType,
      type,
    );
    if (this.innerWidth <= 748) {
      this.tooltipVisible[`filter${type}`] = true;
      setTimeout(() => {
        this.tooltipVisible[`filter${type}`] = false;
      }, 2000);
    }
  }

  filterDocuments() {
    if (this.filterType.length === 0) {
      return this.documentService.notification.info(
        'Selecionar Status',
        'Selecione algum status para poder aplicar os filtros!',
        { nzDuration: 7000 },
      );
    }

    if (this.fieldsSelected.length === 0) {
      return this.documentService.notification.info(
        'Buscar',
        'Selecione ao menos algum filtro combinado.',
        { nzDuration: 7000 },
      );
    }

    if (
      !this.fieldsSelected.find((key) => key.props === 'dataDocumentoFormated')
    ) {
      return this.documentService.notification.info(
        'Buscar',
        'A seleção do filtro Data do Documento é obrigatória!',
        { nzDuration: 7000 },
      );
    }

    this.dataDocuments = [];
    // this.intervalTimeSelected = null;
    this.getDocumentsPerPeriod(this.showDocsAvulsos);
    this.resetAllCheckbox();
  }

  async changeViewDocsAvulsos(avulso) {
    if (avulso === undefined) {
      this.showDocsAvulsos = !this.showDocsAvulsos;
      return;
    }
    this.showDocsAvulsos = avulso;
  }

  addFilter(avulsos, interval) {
    this.intervalTimeSelected = interval;
  }

  async getDocumentsPerPeriod(
    avulsos = false,
    checkAll = false,
    typePeriod?: PeriodVisualizeDoc,
    skip?: number,
    take?: number,
  ) {
    const types = IntervalsTime[Number(this.intervalTimeSelected)];
    typePeriod = IntervalsTimeTranslate[types];

    if (
      this.dataDocuments.find((data) => {
        return data.pag === skip;
      })
    ) {
      const { documents } = this.dataDocuments.find((data) => {
        return data.pag === skip;
      });

      const docs = this.checkedAll
        ? documents.map((doc) => {
            return { ...doc, checked: this.checkedAll };
          })
        : documents;

      return await this.formatResultGetDocuments(docs);
    }

    this.formLoading = true;

    await this.documentService
      .getAllDocumentsInInterval(
        typePeriod,
        this.assinantesSelected,
        Number(this.intervalTimeSelected),
        avulsos,
        this.customInterval,
        this.filterType,
        this.fieldsSelected,
        checkAll,
        skip,
        take,
      )
      .then(async (documents: any) => {
        const pag = skip || 1;

        const documentsData = this.checkedAll
          ? documents.data.resultado.map((doc) => {
              return { ...doc, checked: this.checkedAll };
            })
          : documents.data.resultado;

        if (
          this.dataDocuments.length === 0 ||
          !this.dataDocuments.find((data) => {
            return data.pag === pag;
          })
        ) {
          this.dataDocuments.push({
            pag,
            documents: documentsData,
          });
        }

        this.paramsWhere = documents.where;
        if (!this.identifyer) {
          this.pageTotal = documents.data.total;
        }
        await this.formatResultGetDocuments(documentsData);
        this.formLoading = false;
      })
      .catch((err) => {
        this.formLoading = false;
      });
  }

  async pageChange(valor) {
    if (!this.identifyer) {
      await this.getDocumentsPerPeriod(
        this.showDocsAvulsos,
        false,
        null,
        Number(valor),
        10,
      );
    }
  }

  async getAnyPeoples(assinante) {
    const pessoaFisica =
      assinante.idPessoaFisica !== null &&
      assinante.idPessoaFisica !== undefined
        ? await this.documentService.getPessoasById(assinante.idPessoaFisica)
        : null;

    const pessoaJuridica =
      assinante.idPessoaJuridica !== null &&
      assinante.idPessoaJuridica !== undefined
        ? await this.documentService.getPessoaJuridicaById(
            assinante.idPessoaJuridica,
          )
        : null;

    const pessoaJuridicaResponsavel =
      assinante.idResponsavel !== null && assinante.idResponsavel !== undefined
        ? await this.service.getPessoaFisicaByPessoaId(assinante.idResponsavel)
        : null;

    this.peopleArray.push({
      nome: pessoaFisica?.pessoa.nome,
      idPessoaFisica: pessoaFisica?.id,
      idPessoaJuridica: pessoaJuridica?.id,
      cpf: pessoaFisica?.cpf,
      cnpj: pessoaJuridica?.pessoa.pessoaJuridica?.cnpj,
      nomeRepresentante: pessoaJuridicaResponsavel?.pessoa?.nome,
      cpfRepresentante: pessoaJuridicaResponsavel?.cpf,
      razaoSocial: pessoaJuridica?.razaoSocial,
      email:
        pessoaFisica?.pessoa?.emailPrincipal ||
        pessoaJuridicaResponsavel?.pessoa?.emailPrincipal,
      text: pessoaFisica
        ? `${pessoaFisica?.cpf} - ${pessoaFisica?.pessoa.nome}`
        : `${pessoaJuridica?.cnpj} - ${pessoaJuridica?.razaoSocial}`,
    });
  }

  async modifyDocument(document: IDocumento, expand: boolean, index = 0) {
    this.loadingSignatarios = true;
    try {
      if (document.signatarios.some((a) => a.nome)) {
        this.newDocument.push(this.displayData[index]);
        this.loadingSignatarios = false;
        return;
      }
      if (expand === true) {
        const pessoaReturned =
          await this.documentService.getSignatarioByIdDocumento(
            document.idPrivado,
          );

        const documentFormatted = Object.assign(document, {
          signatarios: pessoaReturned.sort((a, b) => {
            if (a.nome < b.nome) {
              return -1;
            }
            if (a.nome > b.nome) {
              return 1;
            }
            return 0;
          }),
        });
        this.loadingSignatarios = false;
        this.newDocument.push(documentFormatted);
      }
    } catch (error) {
      this.loadingSignatarios = false;
    }
  }

  async formatResultGetDocuments(documents: IDocumento[], identifyer = false) {
    this.formLoading = true;
    const docs = documents.filter(async (doc) => {
      const dataLimiteAssinatura = doc.dataLimiteAssinatura?.slice(
        0,
        doc.dataLimiteAssinatura.lastIndexOf('.'),
      );

      return (
        doc.signatarios.some(
          (ass) => ass.status === StatusDocumento.Assinado,
        ) ||
        doc.statusDocumento === StatusDocumento.Pendente ||
        doc.statusDocumento === StatusDocumento.Confirmado ||
        doc.statusDocumento === StatusDocumento.Desacordo ||
        doc.statusDocumento === StatusDocumento.Cancelado ||
        new Date(dataLimiteAssinatura) < new Date()
      );
    });

    docs.map(async (doc: IDocumento) => {
      await this.documentService.formatColumnsDocForDysplay(
        doc,
        this.listSignatarios,
        doc.checked,
      );
    });

    this.displayData = await documents.map((itm) => {
      return {
        ...itm,
        dataDocumentoFormated: moment(itm.dataDocumento)
          .utcOffset(0, true)
          .format(),
        tipoDoc: `${itm.tipoDocumento.idPublico} - ${itm.tipoDocumento.tipo}`,
        idSetor: itm.setor
          ? `${itm.setor.idPublico} - ${itm.setor.descricao}`
          : '',
        aplicacaoOrigemDetalhe:
          ModulosPublicSoft['Assinatura Digital'] === itm.aplicacaoOrigem
            ? 'Avulso'
            : CodigoModulos[itm.aplicacaoOrigem],
        expand: false,
        numeroDocOriginal: itm.numeroDocOriginal
          ? itm.numeroDocOriginal
          : itm.hashDocOriginal,
      };
    });

    if (this.flagQueryParams === true) {
      this.displayData.sort((a, b) => b.idPrivado - a.idPrivado);
    }

    if (identifyer) {
      this.pageTotal = this.displayData.length;
    }

    this.documentList = this.displayData;

    if (this.docsChecked.length > 0) {
      for (const newDoc of this.docsChecked) {
        const index = this.displayData.findIndex(
          (a) => a.idPrivado === newDoc.idPrivado,
        );

        if (index !== -1) {
          this.displayData.splice(index, 1);

          this.displayData.push(newDoc);
          this.displayData.sort((a, b) => b.idPrivado - a.idPrivado);
        }
      }
    }
    await this.setConfigTable();
    this.formLoading = false;
    this.resetFabButtons();
  }

  async setConfigTable() {
    // if (this.dataTableColumnProps) {
    //   this.tableColumns = this.dataTableColumnProps.tableColumns;
    //   return this.documentService.setCompareToTableColumns(
    //     this.displayData,
    //     this.tableColumns,
    //   );
    // }

    this.loading = true;
    await this.documentsPendingService
      .getDocumentTableProps()
      .then((result) => {
        this.dataTableColumnProps = result;
        Object.assign(this, result);
        this.documentService.setCompareToTableColumns(
          this.displayData,
          this.tableColumns,
        );
        this.loading = false;
      });
  }

  isSigned(doc: IDocumento) {
    return doc.signatarios.some(
      (key) => key.status === StatusDocumento.Assinado,
    );
  }

  getEmail(id: number, signatario: IAssinante) {
    let email = 'Email não encontrado';
    if (signatario.idPessoaFisica === id) {
      email = signatario.email;
    }
    return email;
  }

  check(doc: IDocumento, check) {
    this.resetFabButtons();
    this.displayData
      .filter((key) => key.idPrivado === doc.idPrivado)
      .map((itm) => ({
        ...itm,
        checked: check,
      }));

    this.dataDocuments = this.dataDocuments.map((dataDocuments) => {
      let dataDoc = dataDocuments.documents.map((document) => {
        if (document.idPrivado === doc.idPrivado) {
          return {
            ...document,
            checked: check,
          };
        }
        return {
          ...document,
        };
      });

      return {
        pag: dataDocuments.pag,
        documents: dataDoc,
      };
    });

    this.checkAllIndeterminate = !(
      this.dataDocumentsCheckeds().length === this.pageTotal
    );

    this.checkedAll = this.dataDocumentsCheckeds().length === this.pageTotal;
    // if (
    // !this.documentList.find(
    //   (registro) =>
    //     registro.checked === false &&
    //     registro.statusDocumento !== StatusDocumento.Assinado,
    // ) ||
    // (this.documentList.filter(
    //   (registro) =>
    //     registro.checked === false &&
    //     registro.statusDocumento !== StatusDocumento.Assinado,
    // ).length === 1 &&
    //   this.checkedAll)
    // ) {
    //   this.checkAllIndeterminate = !this.checkAllIndeterminate;
    //   this.checkedAll = !this.checkedAll;
    // }
  }

  async updateAllChecked() {
    this.dataDocuments = this.dataDocuments.map((dataDocuments) => {
      let dataDoc = dataDocuments.documents.map((document) => {
        return {
          ...document,
          checked: !this.checkedAll && this.checkAllIndeterminate,
        };
      });

      return {
        pag: dataDocuments.pag,
        documents: dataDoc,
      };
    });

    this.displayData =
      // !this.checkedAll && this.checkAllIndeterminate ?
      this.displayData.map((doc) => {
        return {
          ...doc,
          checked: !this.checkedAll && this.checkAllIndeterminate,
        };
      });
    // : this.displayData.map((doc) => {
    //     return { ...doc, checked: false };
    //   });

    await this.documentService
      .updateAllChecked(
        this.documentList,
        this.checkedAll,
        this.checkAllIndeterminate,
        this.listSignatarios,
      )
      .then((result) => Object.assign(this, result));
  }

  navigateToDocumentDetail(item: IDocumento, index) {
    this.idDocSelected = item.idPrivado;
    this.modifyDocument(item, true, index);
    this.showModalDetailDocument(item);
  }

  async previewDocVinculado(item) {
    if (!(item.numeroDocOriginal || item.hashDocOriginal)) {
      return;
    }
    this.loading = true;
    const document = await this.documentService.getDocumentByDocOriginal({
      numeroDocOriginal: item.numeroDocOriginal,
      hashDocOriginal: item.hashDocOriginal,
    });

    if (!document) {
      this.loading = false;
      return this.service.notification.warning(
        'Documento',
        'Documento cancelado, em desacordo ou inexistente',
      );
    }

    const pessoaReturned =
      await this.documentService.getSignatarioByIdDocumento(document.idPrivado);

    const documentFormatted = Object.assign(document, {
      signatarios: pessoaReturned.sort((a, b) => {
        if (a.nome < b.nome) {
          return -1;
        }
        if (a.nome > b.nome) {
          return 1;
        }
        return 0;
      }),
    });
    if (!document) {
      this.loading = false;
      return this.documentService.notification.warning(
        'Todos os documentos',
        'Documento em desacordo ou cancelado',
      );
    }

    const filterType = this.filterType;
    this.modal.create({
      nzTitle: `<h4>Detalhes do documento</h4>`,
      nzWidth: window.innerWidth * 0.7,
      nzContent: DocumentsDetailComponent,
      nzComponentParams: {
        document: {
          ...documentFormatted,
          tipoDoc: document.tipoDocumento.descricao,
        },
        filterType,
      },
      nzFooter: [],
    });

    this.loading = false;
  }

  showModalDetailDocument(document: IDocumento) {
    const filterType = this.filterType;
    this.modal.create({
      nzTitle: `<h4>Detalhes do documento</h4>`,
      nzWidth: window.innerWidth * 0.7,
      nzContent: DocumentsDetailComponent,
      nzComponentParams: { document, filterType },
      nzFooter: [],
    });
    this.modal.afterAllClose.subscribe(() => {
      // this.updateDisplayDocs(this.idDocSelected ? [this.idDocSelected] : []);
      this.idDocSelected = null;
    });
  }

  async updateDisplayDocs(idsDocument: number[]) {
    if (this.documentList.length === 0 || idsDocument?.length === 0) {
      return;
    }

    this.loading = true;
    const listDocs = this.documentList;
    this.documentList = [];
    this.displayData = [];

    const indexBeforeDocs: number[] = [];
    const newDocs: IDocumento[] = [];
    for (const id of idsDocument) {
      // const newDoc = await this.documentService.getDocumentById(id);

      indexBeforeDocs.push(listDocs.findIndex((doc) => doc.idPrivado === id));
      // newDocs.push(newDoc);

      // const newDoc = await this.documentService.getDocumentById(id);
      // const indexDoc = listDocs.findIndex((doc) => doc._id === id);

      // if (newDoc.statusDocumento === StatusDocumento.Pendente) {
      //   indexBeforeDocs.push(indexDoc);
      //   newDocs.push(newDoc);
      // }

      // if (
      //   newDoc.statusDocumento === StatusDocumento.Confirmado ||
      //   newDoc.statusDocumento === StatusDocumento.Desacordo
      // ) {
      //   listDocs.splice(indexDoc, 1);
      // }
    }

    newDocs.forEach((doc, index) => {
      this.documentService.formatColumnsDocForDysplay(
        doc,
        this.listSignatarios,
      );
      listDocs.splice(indexBeforeDocs[index], 1, doc);
    });

    setTimeout(() => {
      this.documentService
        .setDocumentsData(
          listDocs,
          this.filterType,
          this.documentList,
          this.newDocument,
          this.listSignatarios,
          this.assinanteLogado,
        )
        .then((result) => Object.assign(this, result));
      this.loading = false;
    }, 0);
  }

  async viewDoc(doc: IDocumento) {
    this.loading = true;
    let typeView = TypeDocView[doc.statusDocumento];
    let typeAction = TypeActions[doc.statusDocumento];

    if (doc.statusDocumento === StatusDocumento.Confirmado && doc.ratificado) {
      typeView = 'VisualizarRatificado';
      typeAction = 'VisualizarRatificado';
    }

    const uuid = await this.documentService.getUuidFile(typeView, doc);

    const urlDocToView = await this.documentService.getUrlDocToUploadApi(
      uuid,
      typeAction,
    );
    this.loading = false;

    if (this.innerWidth <= 768) {
      uuid ? window.location.assign(urlDocToView.pdfURL) : null;
    } else {
      uuid ? window.open(urlDocToView.pdfURL, '_blank') : null;
    }
    if (urlDocToView.events) {
      doc.eventos.push(urlDocToView.events);
    }
  }

  isUserCreateDoc(doc: IDocumento) {
    return doc.usCriacao === this.documentService.authService.getIdUser();
  }

  async downloadDoc(doc: IDocumento, type: string) {
    this.loading = true;
    const event = await this.documentService.downloadDocWithUrlToUploadApi(
      await this.documentService.getUuidFile(type, doc),
      type,
      doc.tipoDocumento.tipo,
      doc.numeroDoc,
    );

    doc.eventos.push(event);
    setTimeout(() => (this.loading = false), 3000);
  }

  getDocsSelecionados(documentList: IDocumento[]) {
    return documentList.filter((doc) => doc.checked);
  }

  getQtdSelecionados(): number {
    return this.documentList?.filter((doc) => doc.checked).length;
  }

  // downloadDocuments = async () => {
  //   this.loading = true;
  //   const docCheckeds = this.getQtdSelecionados();

  //   if (!docCheckeds) {
  //     this.loading = false;
  //     return this.documentService.notification.info(
  //       'Download',
  //       'Selecione algum arquivo para realizar o download!',documento
  //     );
  //   }

  //   const doc = this.documentService.getDocsSelecionados(this.documentList);
  //   for (const documents of doc) {
  //     const typeDownload = TypeDocDownload[documents.statusDocumento];
  //     await this.documentService.downloadDocWithUrlToUploadApi(
  //       this.documentService.getUuidFile(typeDownload, documents),
  //       documents.idTipoDocumento,
  //       documents.numeroDoc,
  //     );
  //     this.loading = false;
  //   }
  // };

  async notifySigner(idPessoa: number, idDocumento: number) {
    const pessoaFisica = await this.service.getPessoasFisicasById(idPessoa);
    this.loading = true;
    await this.documentService.notifySubscriber(pessoaFisica, [idDocumento]);
    this.loading = false;
  }

  ratificar = async () => {
    if (this.checkedAll === true && this.documentList.length > 9) {
      await this.getDocumentsPerPeriod(this.showDocsAvulsos, this.checkedAll);
      this.allDocsChecks = this.documentList.map((itm) => {
        return {
          ...itm,
          checked: true,
        };
      });
    }
    const documentList =
      this.allDocsChecks.length > 0
        ? this.allDocsChecks
        : this.getDocsToDataDocuments();

    const confirmedDocs = documentList.filter((doc) => doc.checked);

    if (
      confirmedDocs.some(
        (itm) =>
          (itm.statusDocumento === StatusDocumento.Pendente ||
            itm.statusDocumento === StatusDocumento.Assinado ||
            itm.statusDocumento === StatusDocumento.Desacordo ||
            itm.statusDocumento === StatusDocumento.Cancelado) &&
          itm.ratificado === false,
      )
    ) {
      this.ratificarDoc = false;
      return this.documentService.notification.info(
        'Ratificar documento',
        'Não foi possivel ratificar o documento, pois somente documentos com o status Confirmado podem ser enviados para ratificação!',
        { nzDuration: 7000 },
      );
    }
    const docCheckeds = this.documentService.getQtdSelecionados(documentList);

    if (docCheckeds === 0) {
      this.ratificarDoc = false;
      return this.documentService.notification.info(
        'Ratificar documento',
        'Não foi possivel ratificar o documento, pois não foi selecionado nenhum documento!',
        { nzDuration: 7000 },
      );
    }

    this.ratificarDoc = true;
    this.labelToaster = 'Ratificar';
    this.assinar();
  };

  assinar = async () => {
    this.listSignatariosToSign = [];
    this.allDocsChecks = [];
    let newDocs: IDocumento[];
    let body = [];

    if (
      this.checkedAll &&
      this.documentList.length > 9 &&
      this.ratificarDoc === false
    ) {
      await this.getDocumentsPerPeriod(this.showDocsAvulsos, this.checkedAll);
      this.allDocsChecks = this.documentList.map((itm) => {
        return {
          ...itm,
          checked: true,
        };
      });
    }
    const documentList =
      this.allDocsChecks.length > 0
        ? this.allDocsChecks
        : this.getDocsToDataDocuments();

    const docCheckeds = this.dataDocumentsCheckeds().length;

    const confirmedDocs = documentList.filter((doc) => doc.checked);

    if (docCheckeds === 0) {
      return this.documentService.notification.info(
        'Assinar documento',
        'Não foi possivel assinar o documento, pois não foi selecionado nenhum documento!',
        { nzDuration: 7000 },
      );
    }

    if (
      confirmedDocs.some((key) => key.ratificado === true) &&
      this.ratificarDoc === false
    ) {
      this.resetAllCheckbox();

      return this.documentService.notification.info(
        'Assinar documento',
        'Não foi possivel assinar o documento, pois o documento está em processo de ratificação, contudo apenas sendo possível ratificá-lo!', //to-do: melhorar a última parte da messagem
        { nzDuration: 7000 },
      );
    }

    this.loading = true;

    for (const ext of confirmedDocs) {
      this.listedAttachs = confirmedDocs.some(
        (a) =>
          a.documentoAnexo.length > 0 &&
          ext.documentoAnexo.every(
            (b) =>
              b.status === StatusDoc.Confirmado ||
              b.status === StatusDoc.Pendente,
          ),
      )
        ? true
        : false;

      if (
        ext.statusDocumento === StatusDocumento.Confirmado &&
        this.ratificarDoc === false
      ) {
        this.loading = false;
        return this.documentService.notification.info(
          `Assinar documento`,
          'Não foi possivel assinar o documento, pois o(s) documento(s) selecionado(s) está(ão) com status Concluído!',
          { nzDuration: 7000 },
        );
      }

      if (ext.statusDocumento === StatusDocumento.Cancelado) {
        this.loading = false;
        return this.documentService.notification.info(
          `${
            this.ratificarDoc === true ? this.labelToaster : 'Assinar'
          } documento`,
          'Não foi possivel assinar o documento, pois o(s) documento(s) selecionado(s) está(ão) com status Cancelado!',
          { nzDuration: 7000 },
        );
      }

      if (ext.statusDocumento === StatusDocumento.Desacordo) {
        this.loading = false;
        return this.documentService.notification.info(
          `${
            this.ratificarDoc === true ? this.labelToaster : 'Assinar'
          } documento`,
          'Não foi possivel assinar o documento, pois o(s) documento(s) selecionado(s) está(ão) com status Desacordo!',
          { nzDuration: 7000 },
        );
      }

      const arrSigners = await this.documentService.getSignatarioByIdDocumento(
        ext.idPrivado,
      );

      this.listSignatariosToSign.push(...arrSigners);
    }

    newDocs = await confirmedDocs.map((itm) => {
      const pessoa = this.listSignatariosToSign.filter(
        (key) => key.idDocumento === itm.idPrivado,
      );
      return {
        ...itm,
        signatarios: (itm.signatarios = pessoa),
      };
    });

    newDocs = newDocs.filter((doc) => {
      return !doc.signatarios
        .filter(
          (item) =>
            (item.cpf === this.documentService.authService.getCpfUser() &&
              !this.ratificarDoc) ||
            (item.cpfRepresentante ===
              this.documentService.authService.getCpfUser() &&
              !this.ratificarDoc),
        )
        .some((stats) => stats.status === StatusDocumento.Assinado);
    });

    if (newDocs.length === 0) {
      this.loading = false;
      return this.documentService.notification.info(
        `Assinar documento`,
        `Não foi possível assinar ${
          this.checkedAll ? 'os documentos' : 'o documento'
        }, pois o signatário está com o status assinado ${
          this.checkedAll ? 'em todos' : ''
        }!`,
        { nzDuration: 10000 },
      );
    }
    for (const docs of newDocs) {
      if (
        !docs.signatarios.some(
          (item) =>
            item.cpf === this.documentService.authService.getCpfUser() ??
            item.cpfRepresentante ===
              this.documentService.authService.getCpfUser(),
        )
      ) {
        this.loading = false;
        return this.documentService.notification.info(
          `${
            this.ratificarDoc === true ? this.labelToaster : 'Assinar'
          } documento`,
          'Não foi possivel assinar o documento, pois o usuário logado não é signatário desse documento!',
          { nzDuration: 7000 },
        );
      }

      if (this.ratificarDoc === false) {
        if (
          docs.signatarios
            .filter(
              async (item) =>
                item.cpf === this.documentService.authService.getCpfUser() ??
                item.cpfRepresentante ===
                  this.documentService.authService.getCpfUser(),
            )
            .find(
              (stats) =>
                (stats.status === StatusDocumento.Desacordo ||
                  stats.status === StatusDocumento.Assinado) &&
                stats.cpf === this.documentService.authService.getCpfUser(),
            ) ||
          this.documentService.docIsExpirado(docs.dataLimiteAssinatura)
        ) {
          const confirmed = docs.signatarios
            .filter(
              (item) =>
                item.cpf === this.documentService.authService.getCpfUser() ??
                item.cpfRepresentante ===
                  this.documentService.authService.getCpfUser(),
            )
            .some((stats) => stats.status === StatusDocumento.Confirmado)
            ? 'signatário está com status Confirmado'
            : '';
          const signed = docs.signatarios
            .filter(
              (item) =>
                item.cpf === this.documentService.authService.getCpfUser() ??
                item.cpfRepresentante ===
                  this.documentService.authService.getCpfUser(),
            )
            .some((stats) => stats.status === StatusDocumento.Assinado)
            ? 'signatário está com status Assinado'
            : '';
          const disagreed = docs.signatarios
            .filter(
              (item) =>
                item.cpf === this.documentService.authService.getCpfUser() ??
                item.cpfRepresentante ===
                  this.documentService.authService.getCpfUser(),
            )
            .some((stats) => stats.status === StatusDocumento.Desacordo)
            ? 'signatário está com status Desacordo'
            : '';
          const expired = this.documentService.docIsExpirado(
            docs.dataLimiteAssinatura,
          )
            ? 'documento está Expirado'
            : '';
          this.loading = false;

          return this.documentService.notification.info(
            `Assinar documento`,
            `Não foi possivel assinar o documento, pois o ${disagreed}${signed}${confirmed}${expired}!`,
            { nzDuration: 7000 },
          );
        }
      }
    }

    const searchCert = await this.getCertificados();

    if (searchCert) {
      return;
    }

    this.service.assinarAnexo.subscribe(async (data) => {
      this.assinarAnexos = data;
    });

    this.service.ratificacao.subscribe(async (data) => {
      this.ratificarTodos = data;
    });

    this.service.ratificacaoAnexo.subscribe(async (data) => {
      this.ratificarAnexo = data;
    });

    const title = `<i nz-icon nzType="warning" nzTheme="outline"> Confirma a ${
      this.ratificarDoc === true ? 'ratificação' : 'assinatura'
    } dos documentos selecionados?</i>`;
    const okText = 'Sim';
    const cancelText = 'Não';
    const onOk = async (componentInstance) => {
      if (
        confirmedDocs.some((key) => key.ratificado === true) &&
        this.ratificarDoc === true
      ) {
        this.ratificarTodos = false;
      }

      if (this.ratificarDoc === false) {
        this.ratificarTodos = false;
      }

      const newComponent = componentInstance.certByDocumentForSign.map((a) => {
        return {
          ...a,
          passwordCert: this.documentService.encryptUsingAES256(a.passwordCert),
        };
      });

      modal.close();
      // this.service.loadingEvent(true);
      // this.service.sendLabelTip(
      //   'Aguarde o processamento da fila de assinatura em lote ser executada...',
      // );
      // this.formB.controls.assinarAnexos.setValue(value);
      const idsDocsParaAssinar: number[] = newComponent.map((row) => row.idDoc);

      const dateUser = new Date().toLocaleString();

      if (!componentInstance.password) {
        return this.service.notification.warning(
          'Formulário',
          'Por favor, preencha o campo Senha com a senha do certificado digital!',
        );
      }

      if (
        this.ratificarDoc === true &&
        !componentInstance.certByDocumentForSign[0].motivo
      ) {
        return this.service.notification.warning(
          'Formulário',
          'Por favor, preencha o campo Motivo com o motivo da ratificação!',
        );
      }

      for (const a of newComponent) {
        body.push({ idPrivado: a.idDoc });
      }

      await this.documentService
        .assinar(
          newComponent,
          dateUser,
          this.ratificarDoc === true ? false : this.assinarAnexos,
          this.paramsWhere,
          this.checkedAll,
          // this.checkedAll ? this.pageTotal : this.docsChecked.length,
          newDocs.length,
          this.ratificarTodos,
          this.ratificarDoc === true ? false : true,
          this.listedAttachs && this.ratificarDoc ? this.ratificarAnexo : false,
          true,
        )
        .then(async () => {
          this.service.loadingEvent(false);
          this.getDocumentsPerPeriod();
          this.updateDisplayDocs(idsDocsParaAssinar);
          this.docsChecked = [];
          this.ratificarDoc = false;
          this.ratificarAnexo = false;
          modal.close();
        })
        .catch((err) => {
          this.documentsPendingService.notification.error(
            `${
              this.ratificarDoc === true ? this.labelToaster : 'Assinar'
            } documento`,
            err,
          );
          this.service.loadingEvent(false);
          this.resetAllCheckbox();
          modal.close();
          this.ratificarDoc = false;
          this.ratificarAnexo = false;
          this.loading = false;
        });
    };
    const modal = this.modal.create({
      nzTitle: title,
      nzWidth: 850,
      nzContent: ModalDialogComponent,
      nzFooter: [
        { label: cancelText, onClick: () => modal.close() },
        {
          label: okText,
          type: 'primary',
          disabled: this.ratificarDoc
            ? (componentInstance) =>
                !componentInstance.certByDocumentForSign[0].motivo
            : false,
          onClick: (componentInstance) => onOk(componentInstance),
        },
      ],
      nzComponentParams: {
        valorSelecionado: this.documentService.getValorSelecionado(newDocs),
        qtdSelecionados: newDocs.length,
        docsSelecionados: newDocs,
        assinanteLogado: this.certificadoAssinante,
        signatarios: this.listSignatarios,
        attachToSign: this.listedAttachs,
        allChecked: this.checkedAll,
        docsChecked: this.pageTotal,
        labelAnexo: this.ratificarDoc === true ? 'Ratificar' : 'Assinar',
        ratificarAnexo: this.ratificarAnexo,
        ratificarDoc: this.ratificarDoc,
      },
    });

    modal.afterClose.subscribe((resultClose: any) => {
      // if (!resultClose) {
      modal.destroy();
      this.ratificarDoc = false;
      this.ratificarAnexo = false;
      this.loading = false;
      // }
      // this.resetAllCheckbox();
      this.attDashboard();
    });
  };

  discordar = async () => {
    this.listSignatariosToSign = [];
    let newDocs: IDocumento[];

    // let docsChecks: IDocumento[];

    if (this.checkedAll && this.documentList.length > 9) {
      this.loading = true;
      await this.getDocumentsPerPeriod(this.showDocsAvulsos, this.checkedAll);
      newDocs = this.documentList.map((itm) => {
        return {
          ...itm,
          checked: true,
        };
      });
      this.loading = false;
    }

    const docCheckeds = newDocs?.length ?? this.dataDocumentsCheckeds().length;
    const confirmedDocs =
      newDocs ?? this.getDocsToDataDocuments().filter((doc) => doc.checked);

    if (docCheckeds === 0) {
      return this.documentService.notification.info(
        'Discordar',
        'Não foi possivel discordar do documento, pois não foi selecionado nenhum documento!',
        { nzDuration: 7000 },
      );
    }
    this.loading = true;
    for (const ext of confirmedDocs) {
      this.listedAttachs = ext.documentoAnexo.length > 0 ? true : false;

      if (ext.statusDocumento === StatusDocumento.Confirmado) {
        return this.documentService.notification.info(
          'Discordar documento',
          'Não foi possivel discordar o documento, pois o(s) documento(s) selecionado(s) está(ão) com status Concluído!',
          { nzDuration: 7000 },
        );
      }

      if (ext.statusDocumento === StatusDocumento.Cancelado) {
        return this.documentService.notification.info(
          'Discordar documento',
          'Não foi possivel discordar o documento, pois o(s) documento(s) selecionado(s) está(ão) com status Cancelado!',
          { nzDuration: 7000 },
        );
      }
      const arraySigners =
        await this.documentService.getSignatarioByIdDocumento(ext.idPrivado);

      this.listSignatariosToSign.push(...arraySigners);
    }
    this.loading = false;

    newDocs = await confirmedDocs.map((itm) => {
      const pessoa = this.listSignatariosToSign.filter(
        (key) => key.idDocumento === itm.idPrivado,
      );
      return {
        ...itm,
        signatarios: (itm.signatarios = pessoa),
      };
    });

    this.loading = true;

    for (const docs of newDocs) {
      if (
        !docs.signatarios.some(
          (item) =>
            item.cpf === this.documentService.authService.getCpfUser() ??
            item.cpfRepresentante ===
              this.documentService.authService.getCpfUser(),
        )
      ) {
        this.loading = false;

        return this.documentService.notification.info(
          'Discordar documento',
          'Não foi possivel discordar do documento, pois o usuário logado não é signatário desse documento!',
          { nzDuration: 7000 },
        );
      }

      if (
        docs.signatarios
          .filter(
            (item) =>
              item.cpf === this.documentService.authService.getCpfUser(),
          )
          .some(
            (stats) =>
              stats.status === StatusDocumento.Desacordo ||
              stats.status === StatusDocumento.Assinado,
          ) ||
        docs.statusDocumento === StatusDocumento.Desacordo ||
        docs.statusDocumento === StatusDocumento.Confirmado ||
        this.documentService.docIsExpirado(docs.dataLimiteAssinatura)
      ) {
        const disagreed = docs.signatarios.some(
          (stats) => stats.status === StatusDocumento.Desacordo,
        )
          ? 'signatário está com status Desacordo'
          : '';
        const signed = docs.signatarios.some(
          (stats) => stats.status === StatusDocumento.Assinado,
        )
          ? 'signatário está com status Assinado'
          : '';
        const expired = this.documentService.docIsExpirado(
          docs.dataLimiteAssinatura,
        )
          ? 'documento está Expirado'
          : '';
        this.loading = false;

        return this.documentService.notification.info(
          'Discordar documento',
          `Não foi possivel discordar do documento, pois o ${disagreed}${signed}${expired}!`,
          { nzDuration: 7000 },
        );
      }
    }

    this.service.assinarAnexo.subscribe(async (data) => {
      this.assinarAnexos = data;
    });

    const title = `<i nz-icon nzType="warning" nzTheme="outline"> Confirma o desacordo do documento selecionado?</i>`;
    const okText = 'Sim';
    const cancelText = 'Não';
    const onOk = async (motivo) => {
      const idsDocsParaAssinar: number[] = newDocs.map((doc) => doc.idPrivado);

      await this.documentService
        .discordar(idsDocsParaAssinar, motivo, this.assinarAnexos)
        .then(async () => {
          this.getDocumentsPerPeriod();
          this.updateDisplayDocs(idsDocsParaAssinar);
          modal.close();
          this.loading = false;
        })
        .catch(() => {
          this.documentsPendingService.notification.error(
            'Discordar documento',
            'Error ao discordar documento',
          );
          this.resetAllCheckbox();
        });
    };

    const modal = this.modal.create({
      nzTitle: title,
      nzContent: ModalDialogComponent,
      nzFooter: [
        {
          label: cancelText,
          onClick: () => {
            modal.close();
            this.loading = false;
          },
        },
        {
          label: okText,
          disabled: (componentInstance) => !componentInstance.motivo,
          type: 'primary',
          onClick: (componentInstance) => onOk(componentInstance.motivo),
        },
      ],
      nzComponentParams: {
        valorSelecionado: this.documentService.getValorSelecionado(
          this.documentList,
        ),
        qtdSelecionados: newDocs.length,
        existMotivo: true,
        attachToSign: this.listedAttachs,
        labelAnexo: 'Discordar',
      },
    });

    modal.afterClose.subscribe((resultClose: any) => {
      if (!resultClose) {
        modal.destroy();
        this.loading = false;
      }
    });
    // this.resetAllCheckbox();
    this.attDashboard();
  };

  desfazer = async () => {
    this.listSignatariosToSign = [];

    let newDocs: IDocumento[];
    if (this.checkedAll && this.documentList.length > 9) {
      await this.getDocumentsPerPeriod(this.showDocsAvulsos, this.checkedAll);
      newDocs = this.documentList.map((itm) => {
        return {
          ...itm,
          checked: true,
        };
      });
    }
    /*const documentList =
      this.allDocsChecks.length > 0
        ? this.allDocsChecks
        : this.getDocsToDataDocuments();

    const docCheckeds = this.dataDocumentsCheckeds().length;

    const confirmedDocs = documentList.filter((doc) => doc.checked);*/
    const docCheckeds = newDocs?.length ?? this.dataDocumentsCheckeds().length;

    const confirmedDocs =
      newDocs ?? this.getDocsToDataDocuments().filter((doc) => doc.checked);

    if (docCheckeds === 0) {
      return this.documentService.notification.info(
        'Desfazer desacordo',
        'Não foi possivel desfazer a discordância do documento, pois não foi selecionado nenhum documento!',
        { nzDuration: 7000 },
      );
    }
    this.loading = true;

    for (const ext of confirmedDocs) {
      this.listedAttachs = ext.documentoAnexo.length > 0 ? true : false;

      const arraySigners =
        await this.documentService.getSignatarioByIdDocumento(ext.idPrivado);

      this.listSignatariosToSign.push(...arraySigners);
    }

    await confirmedDocs.map((itm) => {
      const pessoa = this.listSignatariosToSign.filter(
        (key) => key.idDocumento === itm.idPrivado,
      );
      return {
        ...itm,
        signatarios: (itm.signatarios = pessoa),
      };
    });

    for (const docs of confirmedDocs) {
      if (
        !docs.signatarios.some(
          (item) =>
            item.cpf === this.documentService.authService.getCpfUser() ??
            item.cpfRepresentante ===
              this.documentService.authService.getCpfUser(),
        )
      ) {
        this.loading = false;

        return this.documentService.notification.info(
          'Desfazer desacordo',
          'Não foi possivel desfazer do documento, pois o usuário logado não é signatário desse documento!',
          { nzDuration: 7000 },
        );
      }

      if (docs.statusDocumento === StatusDocumento.Cancelado) {
        this.loading = false;

        return this.documentService.notification.info(
          'Desfazer desacordo',
          'Não foi possivel desfazer o documento, pois o documento selecionado está(ão) com status Cancelado!',
          { nzDuration: 7000 },
        );
      }

      if (docs.statusDocumento === StatusDocumento.Confirmado) {
        this.loading = false;

        return this.documentService.notification.info(
          'Desfazer desacordo',
          'Não foi possivel desfazer a discordância do documento, pois o documento selecionado está(ão) com status Concluído!',
          { nzDuration: 7000 },
        );
      }

      const signed = docs.signatarios
        .filter(
          (item) => item.cpf === this.documentService.authService.getCpfUser(),
        )
        .some((stats) => stats.status === StatusDocumento.Assinado)
        ? 'Assinado'
        : '';

      const pending = docs.signatarios
        .filter(
          (item) => item.cpf === this.documentService.authService.getCpfUser(),
        )
        .some((stats) => stats.status === StatusDocumento.Pendente)
        ? 'Pendente'
        : '';
      if (pending || signed) {
        this.loading = false;

        return this.documentService.notification.info(
          'Desfazer desacordo',
          `Não foi possivel desfazer a discordância do documento, pois o signatário está com status ${signed}${pending}!`,
          { nzDuration: 7000 },
        );
      }
    }

    this.service.assinarAnexo.subscribe(async (data) => {
      this.assinarAnexos = data;
    });
    const idPrivadoDocs: number[] = this.documentService
      .getDocsSelecionadosParaAssinar(
        confirmedDocs ?? this.dataDocumentsCheckeds(),
      )
      .map((doc) => doc.idPrivado);

    const title = `<i nz-icon nzType="warning" nzTheme="outline"> Confirma o desfazer da discordância do documento selecionado?</i>`;
    const okText = 'Sim';
    const cancelText = 'Não';
    const onOk = async (motivo) => {
      const idsDocsParaAssinar: number[] = idPrivadoDocs;

      await this.documentService
        .desfazer(idsDocsParaAssinar, motivo, this.assinarAnexos)
        .then(async () => {
          this.getDocumentsPerPeriod();
          this.updateDisplayDocs(idsDocsParaAssinar);
          modal.close();
          this.loading = false;
        })
        .catch(() => {
          this.documentService.notification.error(
            'Desfazer desacordo',
            'Error ao desfazer desacordo',
          );
          this.resetAllCheckbox();
        });
    };

    const modal = this.modal.create({
      nzTitle: title,
      nzContent: ModalDialogComponent,
      nzFooter: [
        {
          label: cancelText,
          onClick: () => {
            modal.close();
            this.loading = false;
          },
        },
        {
          label: okText,
          disabled: (componentInstance) => !componentInstance.motivo,
          type: 'primary',
          onClick: (componentInstance) => onOk(componentInstance.motivo),
        },
      ],
      nzComponentParams: {
        valorSelecionado: this.documentService.getValorSelecionado(
          this.getDocsToDataDocuments(),
        ),
        qtdSelecionados: docCheckeds,
        existMotivo: true,
        attachToSign: this.listedAttachs,
        labelAnexo: 'Desfazer desacordo',
      },
    });

    modal.afterClose.subscribe((resultClose: any) => {
      this.attDashboard();
      if (!resultClose) {
        modal.destroy();
        this.loading = false;
      }
    });
    // this.resetAllCheckbox();
    this.attDashboard();
  };

  resetar() {
    this.checkList = {
      tipoDoc: true,
      numeroDoc: true,
      numeroDocOriginal: true,
      status: true,
      chancela: true,
      ratificado: true,
      nomeUnidadeOrc: true,
      idSetor: true,
      nomeFav: true,
      tipoDocumento: true,
      valorDoc: true,
      numProcesso: true,
      dataDocumentoFormated: true,
      dataLimiteAssinatura: true,
      aplicacaoOrigem: true,
      numeroLicitacao: true,
      modalidadeLicitacao: true,
      numeroObra: true,
      numeroContrato: true,
      numeroEvento: true,
      numeroConvenio: true,
      numeroProgramaInstitucional: true,
      usuarioCriacao: true,
      psHash: true,
      acoes: true,
    };
  }

  dataDocumentsCheckeds(): Array<IDocumento> {
    const documentsCheckeds: Array<IDocumento> =
      this.getDocsToDataDocuments().filter(
        (document: IDocumento) => document.checked,
      );

    return documentsCheckeds;
  }

  getDocsToDataDocuments(): Array<IDocumento> {
    const documents = [];
    for (const docPag of this.dataDocuments) {
      docPag.documents.forEach((document) => {
        documents.push(document);
      });
    }
    return documents;
  }

  getWidthContent() {
    return window.innerWidth;
  }

  attDashboard = () => {
    this.resetAllCheckbox();
    this.reset();
    this.getDate();
  };

  resetAllCheckbox() {
    this.dataDocuments = this.dataDocuments.map((pag) => {
      return {
        pag: pag.pag,
        documents: pag.documents.map((doc) => {
          return { ...doc, checked: false };
        }),
      };
    });

    this.documentList = this.documentList.map((itm) => {
      return {
        ...itm,
        checked: false,
      };
    });
    this.displayData = this.displayData.map((itm) => {
      return {
        ...itm,
        checked: false,
      };
    });

    this.ratificarDoc = false;
    this.checkedAll = false;
    this.checkAllIndeterminate = true;
  }

  getDate() {
    this.getDateNow = new Date().toLocaleString();
  }

  getResult() {
    let data;
    this.loading = true;
    const hasDash = /\-/;
    const hasBar = /\//;

    if (hasBar.test(this.searchInput) && this.searchInput?.length === 10) {
      data = moment(this.searchInput, 'DD/MM/yyyy').format('yyyy-MM-DD');
    }

    if (hasBar.test(this.searchInput) && this.searchInput?.length === 5) {
      const getYear = new Date().getFullYear();
      this.searchInput = moment(this.searchInput, 'DD/MM').format('MM-DD');
      data = `${getYear}-${this.searchInput}`;
    }

    Object.assign(
      this,
      this.documentService.getResult(
        this.displayData,
        this.documentList,
        hasDash.test(data) ? data : this.searchInput,
      ),
    );
    this.loading = false;
  }

  async admUser() {
    this.adm = await this.documentService.isAdmUser();
  }

  cancelar = async () => {
    const qtdSelecionados = this.documentService.getQtdSelecionados(
      this.documentList,
    );

    if (qtdSelecionados === 0 || qtdSelecionados > 1) {
      return this.documentService.notification.info(
        'Cancelar documento',
        `Não foi possível cancelar o documento pois exitem muitos documentos selecionados ou nenhum. `,
      );
    }

    const confirmedDocs = await this.documentList.filter((doc) => doc.checked);
    const userDocument = confirmedDocs.find((ass) => ass.idPrivado);
    const userInfo = this.authService.getUserInfo();
    const nomUser = this.authService.getNomUser();

    if (
      userDocument.usCriacao !== userInfo.idUsuario &&
      !this.documentService.isAdmUser()
    ) {
      return this.documentService.notification.info(
        'Cancelar documento',
        'Não foi possivel cancelar o documento, pois você não é criador deste documento!',
        { nzDuration: 7000 },
      );
    }

    for (const docs of confirmedDocs) {
      if (docs.statusDocumento === StatusDocumento.Cancelado) {
        return this.documentService.notification.info(
          'Cancelar documento',
          'Não foi possivel cancelar o documento, pois o documento selecionado está(ão) com status Cancelado!',
          { nzDuration: 7000 },
        );
      }
    }

    const documents = this.documentList.filter(
      (registro) => registro.checked === true,
    );

    const id = documents.map((item) => {
      return item.idPrivado;
    });

    const usuarioCriador = {
      nome: nomUser,
      cpf: userInfo.cpf,
      id: userInfo.idUsuario,
      email: userInfo.login,
      admin: this.adm,
      sistema: true,
    };

    const title = `<i nz-icon nzType="warning" nzTheme="outline"> Confirma o cancelamento do documento selecionado?</i>`;
    const okText = 'Sim';
    const cancelText = 'Não';
    const onOk = async (motivo) => {
      const idsDocsParaAssinar: number[] = this.documentService
        .getDocsSelecionadosParaAssinar(this.documentList)
        .map((doc) => doc.idPublico);

      await this.documentService
        .cancel(Number(id), motivo, usuarioCriador)
        .then(async () => {
          this.getDocumentsPerPeriod();
          this.updateDisplayDocs(idsDocsParaAssinar);
          modal.close();
        })
        .catch((e) => {
          this.documentService.notification.error(
            'Cancelar documento',
            `Error ao cancelar documento \n ${e}`,
          );
          this.resetAllCheckbox();
        });
    };

    const modal = this.modal.create({
      nzTitle: title,
      nzContent: ModalDialogComponent,
      nzFooter: [
        { label: cancelText, onClick: () => modal.close() },
        {
          label: okText,
          disabled: (componentInstance) => !componentInstance.motivo,
          type: 'primary',
          onClick: (componentInstance) => onOk(componentInstance.motivo),
        },
      ],
      nzComponentParams: {
        qtdSelecionados: this.documentService.getQtdSelecionados(
          this.documentList,
        ),
        tipoDocumento: `${documents.map((i) => i.tipoDocumento.tipo)}`,
        existMotivo: true,
      },
    });

    modal.afterClose.subscribe((resultClose: any) => {
      if (!resultClose) {
        modal.destroy();
        this.loading = false;
      }
      this.resetAllCheckbox();
    });
  };

  editDoc(document: IDocumento) {
    const idDocument: any = document.idPrivado;
    this.router.navigate(['/session/documentos-avulso'], {
      queryParams: {
        idDocument,
        trasaction: (Math.random() * 100).toPrecision(1),
      },
    });
    this.documentService.tabSessionService.addTab({
      name: 'Documento avulso',
      url: '/session/documentos-avulso',
    });
  }

  getNomeStatusDocumento(documento: IDocumento): string {
    return StatusDocLabel[this.documentService.getStatusDocumento(documento)];
  }

  showColorRatificado(signatarios: IAssinante[]): boolean {
    if (
      signatarios.some(
        (key) => key.status === StatusDocumento.PendenteRatificacao,
      )
    ) {
      return true;
    }
    return false;
  }

  getQuantidadeDeAssinantesProgressBar(documento: IDocumento) {
    const status = this.documentService.getStatusDocumento(documento);

    const totalDeAssinaturas = documento.signatarios?.filter(
      (doc) => doc.status === StatusDocumento.Assinado,
    ).length;
    if (
      [
        StatusDocumento.Cancelado,
        StatusDocumento.Expirado,
        StatusDocumento.Desacordo,
      ].includes(status)
    ) {
      return '';
    }

    return `${totalDeAssinaturas}/${documento.totalSignatarios}`;
  }

  getCorDocumento(documento: IDocumento): string {
    const status = this.documentService.getStatusDocumento(documento);
    return this.documentService.themeColors[ColorStatusDoc[status]];
  }

  mostarProgressBarAndClock(documento: IDocumento): boolean {
    const status = this.documentService.getStatusDocumento(documento);

    if (
      [
        StatusDocumento.Cancelado,
        StatusDocumento.Expirado,
        StatusDocumento.Desacordo,
        StatusDocumento.Confirmado,
        StatusDocumento.Assinado,
      ].includes(status)
    ) {
      return false;
    }
    return true;
  }

  async getCertificados() {
    // await this.assinantesService
    //   .getCertificadosAssinanteLogado()
    //   .then((result) => {
    //     this.certificadoAssinante = result;
    //
    //   })
    //   .catch(() => (this.hasCertificado = false));

    return await this.assinantesService
      .getCertificadosAssinanteLogado()
      .then((res: any) => {
        if (res.every((cert) => new Date(cert.dtFinalCert) < new Date())) {
          this.documentService.notification.error(
            'Certificado',
            `Renove a validade do seu certificado digital!`,
          );
          this.documentService.tabSessionService.addTab({
            name: 'Meus Certificados',
            url: '/session/meus-certificados',
          });

          return this.router.navigate(['/session/meus-certificados']);
          // return true
          // return (this.hasCertificado = !!res?.length);
        }

        if (res.every((cert) => cert.status === StatusCertificado.Deletado)) {
          this.documentService.notification.info(
            'Certificado',
            `Carregue um certificado digital válido!`,
          );
          this.documentService.tabSessionService.addTab({
            name: 'Meus Certificados',
            url: '/session/meus-certificados',
          });
          return this.router.navigate(['/session/meus-certificados']);
          // return true
          // return (this.hasCertificado = !!res?.length);
        }

        if (res.length === 0) {
          this.authService.createModalTermoAceite();
          return true;
          //   // this.certificadoInvalido = true;
          //   // this.certificadoLabel = 'Carregue um Certificado';
          //   this.notification.info(
          //     'Certificado',
          //     `Carregue um certificado digital válido!`,
          //   );
        }
        this.certificadoAssinante = res;
        return false;
      })
      .catch((err) => {
        return this.documentService.notification.error('Certificado', `${err}`);
      });
  }
}
