import { IResponseCountOnlyDocsPerPeriod } from './../interfaces/document-interfaces';
import { PeriodVisualizeDoc } from './../types/document.types';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
  AuthService,
  NzNotificationService,
  PsTabSessionService,
} from 'src/@lib';
import {
  IDocumentChartProps,
  IDocumentResumeChartProps,
} from '../interfaces/dashboard';
import { AbstractService } from './abstract.service';
import { DocumentService } from './document.service';

@Injectable({
  providedIn: 'root',
})
export class DashboardService extends AbstractService {
  constructor(
    public abstractHttp: HttpClient,
    public abstractAuthService: AuthService,
    public abstractNotification: NzNotificationService,
    public documentService: DocumentService,
    public tabSessionService: PsTabSessionService,
  ) {
    super('dashboard', abstractHttp, abstractAuthService, abstractNotification);
  }

  getDocumentsChartProps(): Promise<IDocumentChartProps> {
    return this.http
      .get(
        this.generateUrlByEnvironment(
          '5017',
          'dashboard/chartPeriod/props',
          'assinatura-documentos',
        ),
        this.headerAuthenticateOptions(),
      )
      .toPromise()
      .then(
        (result: { message: string; data: IDocumentChartProps }) => result.data,
      );
  }

  getDocumentsResumeChartProps(): Promise<IDocumentResumeChartProps> {
    return this.http
      .get(
        this.generateUrlByEnvironment(
          '5017',
          'dashboard/chartResume/props',
          'assinatura-documentos',
        ),
        this.headerAuthenticateOptions(),
      )
      .toPromise()
      .then(
        (result: { message: string; data: IDocumentResumeChartProps }) =>
          result.data,
      );
  }

  getCardAnexos(): Promise<IDocumentResumeChartProps> {
    return this.http
      .get(
        this.generateUrlByEnvironment(
          '5017',
          'dashboard/cardAnexos/props',
          'assinatura-documentos',
        ),
        this.headerAuthenticateOptions(),
      )
      .toPromise()
      .then(
        (result: { message: string; data: IDocumentResumeChartProps }) =>
          result.data,
      );
  }

  async getCountDocumentsPerPeriod(
    interval: number,
    typePeriod: PeriodVisualizeDoc,
    customInterval: string[] = null,
    avulso: boolean,
    assinantesSelected = [],
  ): Promise<IResponseCountOnlyDocsPerPeriod> {
    return await this.documentService.getCountDocumentsPerPeriod(
      interval,
      typePeriod,
      customInterval,
      avulso,
      assinantesSelected,
    );
  }

  async getCountAnexosPerPeriod(
    interval: number,
    typePeriod: PeriodVisualizeDoc,
    customInterval: string[] = null,
    avulso: boolean,
    assinantesSelected = [],
  ): Promise<IResponseCountOnlyDocsPerPeriod> {
    return await this.documentService.getCountDocumentsPerPeriod(
      interval,
      typePeriod,
      customInterval,
      avulso,
      assinantesSelected,
      false,
      true,
    );
  }

  async getCountDocumentsPerPeriodChart(
    interval: number,
    typePeriod: PeriodVisualizeDoc,
    customInterval: string[] = null,
    avulso: boolean,
    assinantesSelected = [],
    chart: boolean,
  ): Promise<IResponseCountOnlyDocsPerPeriod> {
    return await this.documentService.getCountDocumentsPerPeriod(
      interval,
      typePeriod,
      customInterval,
      avulso,
      assinantesSelected,
      chart,
    );
  }

  async getTotalSubscriptions() {
    if (!(await this.isAdmUser())) {
      return;
    }

    const cnpjUg = this.authService.getUserInfo().cnpjUg;
    const month = ('0' + (new Date().getMonth() + 1)).slice(-2);
    const initialDate = `${new Date().getFullYear()}-${month}-01T00:00:00`;
    const finalDate = this.authService.getDateSelected();

    return await this.http
      .get(
        this.generateUrlByEnvironment(
          '5017',
          `conteudo/count/subscriptions/${cnpjUg}?initialDate=${initialDate}&finalDate=${finalDate}`,
          'assinatura-documentos',
        ),
        this.headerAuthenticateOptions(),
      )
      .toPromise()
      .catch((err: any) => {
        this.abstractNotification.error('Total de assinaturas', err);
      });
  }
}
