import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable()
export class SistemaService {
  constructor(private http: HttpClient) {}

  getSistemas(ugId: number): Observable<any> {
    
    return this.http.get(
      `${environment.apiUrl('autorizacao')}/api/licenca/${ugId}/sistemas`,
    );
  }
}
