import { Component, Input, OnInit } from '@angular/core';
import { FormlyFieldConfig } from '@ngx-formly/core';
import { GridOptions, ColDef } from 'ag-grid-community';

@Component({
  // tslint:disable-next-line: component-selector
  selector: 'ps-consulta',
  template: `
    <ps-filter
      [fields]="filterFields"
      [fieldsAvancado]="filterFieldsAvancado"
    ></ps-filter>
    <ps-grid
      [defaultColDef]="defaultColDefs"
      [gridOptions]="gridOptions"
      [columnDefs]="columnDefs"
      [rowData]="rowData"
    >
    </ps-grid>
  `,
})
export class PsConsultaComponent implements OnInit {
  @Input() filterFields: FormlyFieldConfig[];
  @Input() filterFieldsAvancado: FormlyFieldConfig[];

  @Input() gridOptions: GridOptions;
  @Input() rowData: any;
  @Input() defaultColDefs: ColDef;
  @Input() columnDefs: ColDef[];

  constructor() {}

  ngOnInit(): void {}
}
