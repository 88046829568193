import { Injectable } from '@angular/core';
import { TabType } from './tab-type';
import { Router, Route } from '@angular/router';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable()
export class PsTabSessionService {
  constructor(private router: Router) {}
  tabs: TabType[] = [];

  private tabsSubject = new BehaviorSubject<TabType[]>(this.tabs);
  tabs$: Observable<TabType[]> = this.tabsSubject.asObservable();

  addTab(tab: TabType) {
    const markDetatchRouterRecursive = (tabType: TabType, routers: Route[]) => {
      routers.map((route: Route) => {
        if (route.children) {
          markDetatchRouterRecursive(tabType, route.children);
        }
        if (route.path === tabType.url.replace('/session/', '')) {
          route.data = { ...route.data, detatchRoute: true };
        }
      });
    };

    markDetatchRouterRecursive(
      tab,
      this.router.config.filter((route: Route) => route.path === 'session'),
    );

    if (
      this.tabs.findIndex((tabItem: TabType) => tab.url === tabItem.url) < 0
    ) {
      this.tabs.push(tab);
    }
  }
}
