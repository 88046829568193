import { ICertProps } from './../interfaces/assinantes-interface';
import { Injectable } from '@angular/core';
import { AbstractService } from './abstract.service';
import { HttpClient } from '@angular/common/http';
import { AuthService, NzNotificationService } from 'src/@lib';

@Injectable({
  providedIn: 'root',
})
export class AssinantesService extends AbstractService {
  constructor(
    public abstractHttp: HttpClient,
    public abstractAuthService: AuthService,
    public abstractNotification: NzNotificationService,
  ) {
    super('assinante', abstractHttp, abstractAuthService, abstractNotification);
  }

  async getCertificadosAssinanteLogado() {
    return await this.http
      .post(
        `${this.getServer('certificado')}/by-pessoa`,
        { pessoa: this.encryptUsingAES256(this.authService.getCpfUser()) },
        this.headerAuthenticateOptions(),
      )
      .toPromise()
      .then((result: any) => {
        return result.data;
      });
  }

  async cadastrar(object: {
    senhaCert: string;
    certificadoDigitalProps: any;
    idAssinante;
  }): Promise<any> {
    const formData = new FormData();
    const pswEncrpt = this.encryptUsingAES256(object.senhaCert);
    formData.append('senhaCert', pswEncrpt);
    formData.append('certificadoDigitalB64', object.certificadoDigitalProps);
    formData.append('idAssinante', object.idAssinante.toString());

    return await this.http
      .post(
        this.getServer('assinante'),
        formData,
        this.headerAuthenticateOptions(),
      )
      .toPromise()
      .then((data) => {
        return data;
      });
  }

  async deletarCertificado(idCert): Promise<any> {
    return await this.http
      .put(
        `${this.getServer('certificado')}/cert/delete/${idCert}`,
        null,
        this.headerAuthenticateOptions(),
      )
      .toPromise()
      .then((data) => {
        return data as { message: string; result: any };
      });
  }
}
