import { IAssinante } from './../shared/interfaces/document-interfaces';
import { NzModalService } from 'ng-zorro-antd/modal';
import { DocumentsDetailComponent } from './../documents-detail/documents-detail.component';
import { FinishedDocumentService } from './../shared/services/finished-document.service';
import { DocumentService } from './../shared/services/document.service';
import { ActivatedRoute } from '@angular/router';
import {
  IntervalsTime,
  StatusDoc,
  StatusDocLabel,
} from './../shared/consts/document';
import { Component, OnInit, AfterContentInit } from '@angular/core';
import {
  IColumnsFieldsProps,
  ISearchFieldsProps,
} from '../shared/interfaces/shared.interface';
import {
  IDocumento,
  IReponseCountDocs,
} from '../shared/interfaces/document-interfaces';
import { IMenuButton } from 'src/@lib';
import { StatusDocumento } from '../shared/enum/const.enum';
import { TypeActionsDoc } from '../shared/types/events.types';

@Component({
  selector: 'app-documents-invalids',
  templateUrl: './documents-invalids.component.html',
  styleUrls: ['./documents-invalids.component.scss'],
})
export class DocumentsInvalidsComponent implements AfterContentInit {
  assinanteLogado;

  idDocSelected: number;

  assinanteSelected;
  assinantesSelected = [];
  allAssinantes = [];

  filterType: number[];

  loading = false;

  checkedAll = false;
  checkAllIndeterminate = true;

  searchInput: any;
  displayData = [];

  searchColumns: ISearchFieldsProps[] = [];
  tableColumns: IColumnsFieldsProps[] = [];

  intervalsTime = Object.entries(IntervalsTime);
  intervalTimeSelected = this.intervalsTime[0][0];

  documentList: IDocumento[] = [];

  statusDocLabel = StatusDocLabel;
  statusDoc = StatusDoc;

  checkList = {
    idTipoDocumento: true,
    numeroDoc: true,
    idUnidadeGestora: true,
    dadosUnidadeOrc: true,
    dadosFavorecido: true,
    valorDoc: true,
    dataDocumento: true,
    dataLimiteAssinatura: true,
    status: true,
    acoes: true,
  };

  fabButtons: IMenuButton[];

  listSignatarios: IAssinante[];

  constructor(
    private activateRouter: ActivatedRoute,
    public documentService: DocumentService,
    private documentsFinishedService: FinishedDocumentService,
    private modal: NzModalService,
  ) {
    this.activateRouter.queryParamMap.subscribe((queryParams) => {
      this.filterType = Number(queryParams.get('tipo'))
        ? [Number(queryParams.get('tipo'))]
        : [StatusDocumento.Expirado, StatusDocumento.Cancelado];
      if (this.documentService.resultDashBoardDocuments.length > 0) {
        this.formatDashBoardDocuments(
          this.documentService.resultDashBoardDocuments,
        );
        return;
      }
    });

    this.getAllAssinantes();
    this.buildFabButtons([
      {
        icon: 'download',
        tooltip: 'Download',
        condition: true,
        onClick: this.download,
      },
    ]);
  }

  async ngAfterContentInit() {
    await this.getDocumentsPerPeriod();
    await this.setConfigTable();
  }

  buildFabButtons(fabButtons: IMenuButton[]) {
    this.fabButtons = fabButtons;
  }

  async getAllAssinantes() {
    this.allAssinantes = await this.documentService.getAllAssinantes();
    this.documentService.findAllSubscribers(this.allAssinantes);
  }

  addFiltroPorAssinante() {
    if (this.assinanteSelected) {
      Object.assign(
        this,
        this.documentService.addSubscriberToFilter(
          this.assinanteSelected,
          this.assinantesSelected,
          this.allAssinantes,
        ),
      );
      this.getDocumentsPerPeriod();
    }

    setTimeout(() => (this.assinanteSelected = null), 0);
  }

  removeFiltroPorAssinante(cpf) {
    this.documentService.removeSubscriberToFilter(
      this.assinantesSelected,
      this.allAssinantes,
      cpf,
    );
    this.getDocumentsPerPeriod();
  }

  clearFilterByDashboard() {
    this.intervalTimeSelected = this.intervalsTime[0][0];
    this.getDocumentsPerPeriod();
  }

  formatDashBoardDocuments(dashBoardDocuments: IReponseCountDocs[]) {
    const docs = [];
    dashBoardDocuments.map((result) => {
      docs.push(result.count.expirados, result.count.cancelados);
    });
    this.formatResultGetDocuments(docs);
  }

  async reset() {
    await this.getDocumentsPerPeriod();
    await this.setConfigTable();
  }

  setFilterType(type: number) {
    this.documentService.updateFilterType(this.filterType, type);
    this.reset();
  }

  async getDocumentsPerPeriod() {
    this.loading = true;
    await this.documentService
      .getAllDocumentsInInterval(
        'Month',
        this.assinantesSelected,
        Number(this.intervalTimeSelected),
      )
      .then(
        async (documents: IDocumento[]) =>
          await this.formatResultGetDocuments(documents),
      );
    this.loading = false;
  }

  async formatResultGetDocuments(documents: IDocumento[]) {
    const docs = documents.filter(async (doc) => {
      await this.getSignatarios(doc.idPrivado);
      const dataLimiteAssinatura = doc.dataLimiteAssinatura.slice(
        0,
        doc.dataLimiteAssinatura.lastIndexOf('.'),
      );
      return (
        doc.statusDocumento === StatusDocumento.Cancelado ||
        new Date(dataLimiteAssinatura) < new Date()
      );
    });
    docs.map((doc: IDocumento) =>
      this.documentService.formatColumnsDocForDysplay(
        doc,
        this.listSignatarios,
      ),
    );

    await this.documentService
      .setDocumentsData(
        docs,
        this.filterType,
        this.documentList,
        this.displayData,
        this.listSignatarios,
        this.assinanteLogado,
      )
      .then((result) => Object.assign(this, result));
  }

  async setConfigTable() {
    this.loading = true;
    await this.documentsFinishedService
      .getDocumentTableProps()
      .then((result) => {
        Object.assign(this, result);
        this.documentService.setCompareToTableColumns(
          this.displayData,
          this.tableColumns,
        );
      });
    this.loading = false;
  }

  check() {
    if (
      !this.documentList.find((registro) => registro.checked === false) ||
      (this.documentList.filter((registro) => registro.checked === false)
        .length === 1 &&
        this.checkedAll)
    ) {
      this.checkAllIndeterminate = !this.checkAllIndeterminate;
      this.checkedAll = !this.checkedAll;
    }
  }

  async updateAllChecked() {
    await this.documentService
      .updateAllChecked(
        this.documentList,
        this.checkedAll,
        this.checkAllIndeterminate,
        this.listSignatarios,
      )
      .then((result) => Object.assign(this, result));
  }

  async getSignatarios(idDoc: number) {
    this.listSignatarios =
      await this.documentService.getSignatarioByIdDocumento(idDoc);
  }

  navigateToDocumentDetail(item: IDocumento) {
    this.idDocSelected = item.idPrivado;
    this.showModalDetailDocument(this.idDocSelected);
  }

  showModalDetailDocument(idDocument: number) {
    this.modal.create({
      nzTitle: `<h4>Detalhes do documento</h4>`,
      nzWidth: window.innerWidth * 0.7,
      nzContent: DocumentsDetailComponent,
      nzComponentParams: { idDocument },
      nzFooter: [],
    });
    this.modal.afterAllClose.subscribe(() => {
      this.updateDisplayDocs(this.idDocSelected ? [this.idDocSelected] : []);
      this.idDocSelected = null;
    });
  }

  async updateDisplayDocs(idsDocument: number[]) {
    if (this.documentList.length === 0 || idsDocument?.length === 0) {
      return;
    }

    this.loading = true;
    const listDocs = this.documentList;
    this.documentList = [];
    this.displayData = [];

    const indexBeforeDocs: number[] = [];
    const newDocs: IDocumento[] = [];
    for (const id of idsDocument) {
      indexBeforeDocs.push(listDocs.findIndex((doc) => doc.idPublico === id));
      newDocs.push(await this.documentService.getDocumentById(id));
    }

    newDocs.forEach((doc, index) => {
      this.documentService.formatColumnsDocForDysplay(
        doc,
        this.listSignatarios,
      );
      listDocs.splice(indexBeforeDocs[index], 1, doc);
    });

    setTimeout(() => {
      this.documentService
        .setDocumentsData(
          listDocs,
          this.filterType,
          this.documentList,
          this.displayData,
          this.listSignatarios,
          this.assinanteLogado,
        )
        .then((result) => Object.assign(this, result));

      this.loading = false;
    }, 0);
  }

  async viewDoc(doc: IDocumento) {
    this.loading = true;
    const uuid = await this.documentService.getUuidFile('view', doc);
    const urlDoc = await this.documentService.getUrlDocToUploadApi(
      uuid,
      'Visualizar',
    );
    this.loading = false;

    uuid ? window.open(urlDoc.pdfURL, '_blank') : null;
    doc.eventos.push(urlDoc.events);
  }

  async downloadDoc(doc: IDocumento, typeAction) {
    this.loading = true;
    const event = await this.documentService.downloadDocWithUrlToUploadApi(
      this.documentService.getUuidFile(typeAction, doc),
      typeAction,
      doc.tipoDocumento.tipo,
      doc.numeroDoc,
    );
    doc.eventos.push(event);
    setTimeout(() => (this.loading = false), 3000);
  }

  download = async () => {
    this.loading = true;
    const typeAction: TypeActionsDoc = 'DownloadOriginal';

    setTimeout(() => {
      this.documentService
        .getDocsSelecionados(this.documentList)
        .map(async (doc) => {
          const event =
            await this.documentsFinishedService.documentService.downloadDocWithUrlToUploadApi(
              this.documentService.getUuidFile(typeAction, doc),
              typeAction,
              doc.tipoDocumento.tipo,
              doc.numeroDoc,
            );
          doc.eventos.push(event);
        });
      this.loading = false;
    }, 0);
  };

  resetar() {
    this.checkList = {
      idTipoDocumento: true,
      numeroDoc: true,
      idUnidadeGestora: true,
      dadosUnidadeOrc: true,
      dadosFavorecido: true,
      valorDoc: true,
      dataDocumento: true,
      dataLimiteAssinatura: true,
      status: true,
      acoes: true,
    };
  }

  getWidthContent() {
    return window.innerWidth;
  }
}
