import { IMensagem } from './../interfaces/mensagem-interface';
import {
  ISearchFieldsProps,
  IColumnsFieldsProps,
  AbstractError,
} from './../interfaces/shared.interface';
import { HttpErrorInterceptor } from './../../../@lib/services/api/http-error.interceptor';
import { AuthService, NzNotificationService } from 'src/@lib';
import { HttpClient } from '@angular/common/http';
import { AbstractService } from './abstract.service';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class MensagensService extends AbstractService {
  constructor(
    public abstractHttp: HttpClient,
    public abstractAuthService: AuthService,
    public abstractNotification: NzNotificationService,
  ) {
    super('mensagens', abstractHttp, abstractAuthService, abstractNotification);
  }

  async deletarMensagem(id: number | string): Promise<any> {
    return this.http
      .delete(`${this.getServer('mensagem')}/${id}`)
      .toPromise()
      .then((result: any) => {
        this.notification.success('Modelos de mensagens', result.message);
        return result as { message: string; data: any };
      })
      .catch((err) => {
        this.notification.success('Modelos de mensagens', err);
        return err;
      });
  }

  async editarMensagemModel(mensagem: IMensagem) {
    return this.http
      .put<IMensagem>(
        `${this.getServer('mensagem')}?idPublico=${mensagem.id}`,
        mensagem,
        this.headerAuthenticateOptions(),
      )
      .toPromise()
      .then((result: any) => {
        this.notification.success('Modelos de mensagens', result.message);
        return result as { message: string; data: any };
      })
      .catch((err) => {
        this.notification.success('Modelos de mensagens', err);
        return err;
      });
  }

  async saveMensageModel(dataMessage: object) {
    return this.http
      .post(
        this.getServer(`mensagem`),
        dataMessage,
        this.headerAuthenticateOptions(),
      )
      .toPromise()
      .then((result: any) => {
        this.notification.success('Modelos de mensagens', result.message);
        return result as { message: string; data: any };
      })
      .catch((err) => {
        this.notification.success('Modelos de mensagens', err);
        return err;
      });
  }

  async getAllMensagensInInterval(fieldsMensagensSelected: any): Promise<any> {
    return this.http
      .post(
        `${this.getServer('mensagem')}/params`,
        fieldsMensagensSelected,
        this.headerAuthenticateOptions(),
      )
      .toPromise()
      .then((result: any) => {
        this.notification.success('Modelos de mensagens', result.message);
        return result;
      })
      .catch((err) => {
        return this.notification.error('Modelos de mensagens', err);
      });
  }

  async getMensagemTableProps(entidade: string = null): Promise<any> {
    const camposPesquisa:
      | ISearchFieldsProps[]
      | Array<{ text: string; value: any; type: string }> = [];
    const colunasTabela: IColumnsFieldsProps[] = [];
    return await this.http
      .get(
        `${this.getServer(entidade)}/any/props`,
        this.headerAuthenticateOptions(),
      )
      .toPromise()
      .then((response: { message: string; data: any }) => {
        // camposPesquisa.push({
        //   text: 'Todos',
        //   value: 'todos',
        // });

        for (const p in response.data) {
          if (response.data[p].isSearch) {
            camposPesquisa.push({
              text: response.data[p].label,
              value: p,
              type: response.data[p].type,
            });
          }
        }
        for (const p in response.data) {
          if (response.data[p].columnTable) {
            colunasTabela.push({
              text: response.data[p].label,
              value: p,
              mask: response.data[p].mask ? response.data[p].mask : null,
              objectChildren: response.data[p].hasOwnProperty('objectChildren')
                ? response.data[p].objectChildren[0]
                : null,
              type: response.data[p].type,
            });
          }
        }
        return {
          searchColumns: camposPesquisa,
          tableColumnsAnexo: colunasTabela,
        };
      })
      .catch((err: AbstractError) => {
        this.notification.error('Propriedades', err.error.message);
        return {
          searchColumns: camposPesquisa,
          tableColumnsAnexo: colunasTabela,
        };
      });
  }

  // getMensagemTableProps() {
  //   return this.http
  //     .get(this.getServer(`mensagem`), this.headerAuthenticateOptions())
  //     .toPromise()
  //     .then((result: any) => {
  //       this.notification.success('Modelos de Mensagens', result.message);
  //       return result as { message: string; data: any };
  //     })
  //     .catch((err) => {
  //       this.notification.success('Modelos de Mensagens', err);
  //       return err;
  //     });
  // }
}
