import { NgModule, ModuleWithProviders, LOCALE_ID } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PsComponentsModule } from './components/ps-components.module';
import { ThemeService } from './services';
import { TableService } from './services';
import { PsDirectiveModule } from './directives';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NZ_I18N, NzNotificationService, NzIconModule, NzModalService } from './antd';
import { TabSessionCanDeactivateGuard } from './guards';
import { PsPagesModule } from './pages';
import { PsLayoutModule } from './layouts';
import { MenuService } from './services/menu.service';
import { StorageService } from './services/storage.service';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { HttpErrorInterceptor } from './services/api/http-error.interceptor';
import { RouteReuseStrategy } from '@angular/router';
import { JwtInterceptor } from './services/api/jwt.interceptor';
import { AuthGuard } from './guards/auth.guard';
import { CustomReuseStrategy } from './utils/custom-route-reuse';
import { PsUiConfig, PS_CONFIG } from './config/ps-config';
/**
 * Building da lib utilizando os paths registrado no tsconfig gerando undefined
 * https://stackoverflow.com/questions/55672615/cannot-read-property-module-of-undefined-while-building-angular-lib
 *
 * Import de um modulo exportado como default nao funciona com o AOT engine
 * https://stackoverflow.com/questions/45962317/why-isnt-export-default-recommended-in-angular
 */
import { ptBR } from './antd/i18n/languages/pt_BR';
import { AG_GRID_LOCALE, PsLocalizationService } from './services/localization.service';
import pt_br from './i18n/ag-grid/pt_br';

import * as AllIcons from '@ant-design/icons-angular/icons';
import { IconDefinition } from '@ant-design/icons-angular';

import { registerLocaleData } from '@angular/common';
import localePt from '@angular/common/locales/pt';
import { PsAntdModule } from './antd/antd.module';
registerLocaleData(localePt);

const antDesignIcons = AllIcons as {
  [key: string]: IconDefinition;
};
const icons: IconDefinition[] = Object.keys(antDesignIcons).map(key => antDesignIcons[key]);

@NgModule({
  declarations: [
  ],
  imports: [
    CommonModule,
    BrowserAnimationsModule,
    PsComponentsModule,
    PsPagesModule,
    PsLayoutModule,
    PsDirectiveModule,
    PsAntdModule,
    NzIconModule.forRoot(icons)
  ],
  exports: [
    PsComponentsModule,
    PsDirectiveModule,
    PsPagesModule,
    PsLayoutModule,
    PsAntdModule,
    NzIconModule
  ],
  providers: [
    AuthGuard,
    ThemeService,
    MenuService,
    StorageService,
    TableService,
    NzNotificationService,
    NzModalService,
    { provide: NZ_I18N, useValue: ptBR },
    // { provide: NZ_ICONS, useValue: icons },
    { provide: LOCALE_ID, useValue: 'pt-BR' },
    { provide: RouteReuseStrategy, useClass: CustomReuseStrategy },
    { provide: HTTP_INTERCEPTORS, useClass: HttpErrorInterceptor, multi: true, deps: [NzNotificationService]},
    { provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true },
    PsLocalizationService,
    {
      provide: AG_GRID_LOCALE, useValue: pt_br
    },
    TabSessionCanDeactivateGuard
  ]
})
export class PsUiModule {
  static forRoot(config: PsUiConfig): ModuleWithProviders<any> {
    return {
      ngModule: PsUiModule,
      providers: [
        {
          provide: PS_CONFIG, useValue: config
        }
      ]
    };
  }
}
