import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { JwtHelperService } from '@auth0/angular-jwt';
import * as CryptoJS from 'crypto-js';
import { NzNotificationService } from 'ng-zorro-antd/notification';
import { AuthService } from 'src/@lib';
import { CodigoModulos } from '../consts/modulos';
import { environment } from './../../../environments/environment';
import {
  AbstractError,
  AbstractResponse,
  IColumnsFieldsProps,
  IResponseFieldsProps,
  ISearchFieldsProps,
} from './../interfaces/shared.interface';
@Injectable({
  providedIn: 'root',
})
export class AbstractService {
  public entidade: string;
  private urlServer: {};
  public timerId: any;
  public documentos: any;
  tokenFromUI: string = '0123456789123456';
  encrypted: string = '';
  private _adm: boolean;
  constructor(
    entidade = '',
    public http: HttpClient,
    public authService: AuthService,
    public notification: NzNotificationService,
    public helper = new JwtHelperService(),
  ) {
    this.entidade = entidade;

    this.urlServer = {
      [`fileExport`]: this.generateUrlByEnvironment(
        '5017',
        'exportacao/exportar/',
        'assinatura-documentos',
      ),
      // [`editFileExport`]:this.generateUrlByEnvironment(
      //   '5017',
      //   'exporta'
      // ),
      [`pendingDocument`]: this.generateUrlByEnvironment(
        '5017',
        'document/pending',
        'assinatura-documentos',
      ),
      [`finishedDocument`]: this.generateUrlByEnvironment(
        '5017',
        'document/finished',
        'assinatura-documentos',
      ),
      [`document`]: this.generateUrlByEnvironment(
        '5017',
        'document',
        'assinatura-documentos',
      ),
      [`documentoAnexo`]: this.generateUrlByEnvironment(
        '5017',
        'documento-anexo',
        'assinatura-documentos',
      ),
      [`documentConcentrator`]: this.generateUrlByEnvironment(
        '5017',
        'document/concentrador',
        'assinatura-documentos',
      ),
      [`anyDocument`]: this.generateUrlByEnvironment(
        '5017',
        'document/any',
        'assinatura-documentos',
      ),
      [`assinante`]: this.generateUrlByEnvironment(
        '5017',
        'assinante',
        'assinatura-documentos',
      ),
      [`certificado`]: this.generateUrlByEnvironment(
        '5017',
        'certificado',
        'assinatura-documentos',
      ),
      [`tipoDoc`]: this.generateUrlByEnvironment(
        '5017',
        'tipo-documento',
        'assinatura-documentos',
      ),
      [`pessoaFisica`]: this.generateUrlByEnvironment(
        '5017',
        'pessoa-fisica',
        'assinatura-documentos',
      ),
      [`senha`]: this.generateUrlByEnvironment(
        '5010',
        'api/senha',
        'autorizacao',
      ),
      [`avatar`]: this.generateUrlByEnvironment(
        '5010',
        'api/usuario/alterar-avatar',
        'autorizacao',
      ),
      [`uploadAvatar`]: this.generateUrlByEnvironment(
        '5017',
        'pessoa-fisica/avatar',
        'assinatura-documentos',
      ),
      [`deleteAnexo`]: this.generateUrlByEnvironment(
        '5017',
        'documento-anexo/delete',
        'assinatura-documentos',
      ),
      [`deleteSignatario`]: this.generateUrlByEnvironment(
        '5017',
        'signatario/delete',
        'assinatura-documentos',
      ),
      [`signatario`]: this.generateUrlByEnvironment(
        '5017',
        'signatario',
        'assinatura-documentos',
      ),
      [`pessoaContato`]: this.generateUrlByEnvironment(
        '5008',
        'pessoa-contato',
        'pessoa',
      ),
      [`pessoaFisicaGlobal`]: this.generateUrlByEnvironment(
        '5008',
        'pessoa-fisica',
        'pessoa',
      ),
      [`pessoaFisicaByCpf`]: this.generateUrlByEnvironment(
        '5008',
        'pessoa-fisica/consultar-por-parametro?field=cpf&value=',
        'pessoa',
      ),
      [`pessoaFisicaById`]: this.generateUrlByEnvironment(
        '5008',
        'pessoa-fisica/consultar-por-parametro?field=id&value=',
        'pessoa',
      ),
      [`pessoaById`]: this.generateUrlByEnvironment(
        '5008',
        'pessoa-fisica/consultar-por-parametro?field=idPessoa&value=',
        'pessoa',
      ),
      [`pessoaJuridicaById`]: this.generateUrlByEnvironment(
        '5008',
        'pessoa-juridica/consultar-por-parametro?field=id&value=',
        'pessoa',
      ),
      [`pessoaJuridica`]: this.generateUrlByEnvironment(
        '5008',
        'pessoa-juridica',
        'pessoa',
      ),
      [`pessoaJuridicaByCnpj`]: this.generateUrlByEnvironment(
        '5008',
        'pessoa-juridica/consultar-por-parametro?field=cnpj&value=',
        'pessoa',
      ),
      [`pessoaResponsavelById`]: this.generateUrlByEnvironment(
        '5008',
        'pessoa-responsaveis/consultar-por-parametro?field=id&value=',
        'pessoa',
      ),
      [`pessoaResponsavelDelete`]: this.generateUrlByEnvironment(
        '5008',
        'pessoa-responsaveis',
        'pessoa',
      ),
      [`pessoaJuridicaGlobal`]: this.generateUrlByEnvironment(
        '5008',
        'pessoa-juridica',
        'pessoa',
      ),
      [`logradouro`]: this.generateUrlByEnvironment(
        '5003',
        'logradouro',
        'endereco',
      ),
      [`unidadeOrc`]: this.generateUrlByEnvironment(
        '80',
        'unidade-orcamentaria/consultar-por-parametro?field=id&value=',
        'unidade-orcamentaria',
      ),
      [`exportacao`]: this.generateUrlByEnvironment(
        '5017',
        'exportacao',
        'assinatura-documentos',
      ),
      [`analitcs`]: this.generateUrlByEnvironment(
        '5017',
        'analitcs',
        'assinatura-documentos',
      ),
      [`mensagem`]: this.generateUrlByEnvironment(
        '5017',
        'mensagem',
        'assinatura-documentos',
      ),
      [`mensagem-envio`]: this.generateUrlByEnvironment(
        '5017',
        'mensagem-envio',
        'assinatura-documentos',
      ),
      [`setor`]: this.generateUrlByEnvironment(
        '5017',
        'setor',
        'assinatura-documentos',
      ),
    };
    // this.getDocumentFromConcentrator()
    setTimeout(() => {
      this.consultaIsAdmUser().then((adm) => (this.adm = adm));
    }, 10000);
  }

  public get adm() {
    return this._adm;
  }
  public set adm(isAdmin: boolean) {
    this._adm = isAdmin;
  }

  generateUrlByEnvironment(port: string, path?: string, server = ''): string {
    // if (server === 'assinatura-documentos') {
    //   return `http://localhost:3000/${path}`;
    // }

    // if (server === 'assinatura-worker') {
    //   return `http://localhost:3001/${path}`;
    // }

    // if (server === 'exportador') {
    //   return `http://localhost:3030/${path}`;
    // }

    if (
      server === 'upload' ||
      server === 'concentrador' ||
      server === 'autorizacao'
    ) {
      return `${environment.apiOld(server)}/${path}`;
    }

    if (server === 'exportador') {
      return `${environment.apiTools(server)}/${path}`;
    }

    return `${environment.apiAssinatura(path)}`;
  }

  getUrlAppAcesso(): string {
    return `${environment.appUrl('acesso')}`;
  }

  getUrlAppAssinatura(): string {
    return `${environment.appUrl('assinatura')}`;
  }

  getServer(entidade: string = null): string {
    const entity = entidade ? entidade : this.entidade;
    return `${this.urlServer[entity]}`;
  }

  public headerAuthenticateOptions() {
    return {
      headers: new HttpHeaders({
        Authorization: `Bearer ${localStorage.getItem('token')}`,
        'request-date': this.authService.getDateSelected(),
      }),
    };
  }

  isUserAdm() {
    return this.authService.getSubmodulos().some((submodulo) => submodulo.adm);
  }

  async getLoginToken() {
    return await this.http
      .get(
        `${this.getServer('analitcs')}/getLoginToken`,
        this.headerAuthenticateOptions(),
      )
      .toPromise()
      .then((result: any) => {
        return result;
      });
  }

  insert(
    object,
    entidade: string = null,
    query = '',
  ): Promise<AbstractResponse> {
    return new Promise((resolve, reject) => {
      this.http
        .post(
          `${this.getServer(entidade)}${query}`,
          object,
          this.headerAuthenticateOptions(),
        )
        .toPromise()
        .then((response: any) => {
          this.notification.success(
            entidade === 'documentConcentrator'
              ? 'Integração de documentos'
              : 'Cadastrar',
            response.message,
          );
          resolve(response as any);
        })
        .catch((returnError) => {
          // tslint:disable-next-line: no-bitwise
          this.notification.error('Cadastrar', returnError);
        });
    });
  }

  update(
    object: {},
    id: string,
    entidade: string = null,
    query = '',
  ): Promise<AbstractResponse> {
    return new Promise((resolve, reject) => {
      this.http
        .put(
          `${this.getServer(entidade)}/${id}${query}`,
          object,
          this.headerAuthenticateOptions(),
        )
        .toPromise()
        .then((response: AbstractResponse) => {
          this.notification.success('Alterar', response.message);
          resolve(response as AbstractResponse);
        })
        .catch((returnError: any) => {
          this.notification.error('Alterar', returnError);
          reject({
            message: returnError,
            data: undefined,
          } as AbstractResponse);
        });
    });
  }

  pesquisar(parametrosPesquisa, entidade = null) {
    if (
      !parametrosPesquisa.value &&
      parametrosPesquisa.field.valor !== 'todos'
    ) {
      this.notification.warning('Insira um valor para pesquisa.', 'Atenção');
      return;
    }

    return this.pesquisarAvancado(parametrosPesquisa, entidade);
  }

  pesquisarAvancado(parametros, entidade = null): Promise<AbstractResponse> {
    return new Promise((resolve, reject) => {
      this.http
        .get(
          `${this.getServer(entidade)}/global-search?page=${
            parametros.page
          }&size=${parametros.size}&${parametros.field.valor}=${
            parametros.value
          }`,
          this.headerAuthenticateOptions(),
        )
        .toPromise()
        .then((response: any) => {
          response.data.resultado.length === 0
            ? this.notification.info('Pesquisa', 'Nenhum registro encontrado.')
            : this.notification.success('Pesquisa', response.message);

          resolve(response as { message: string; data: any });
        })
        .catch((returnError) => {
          reject({
            message: returnError,
            data: undefined,
          } as AbstractResponse);
        });
    });
  }

  globalSearch(
    value: string,
    all = false,
    entidade: string = null,
  ): Promise<{ message: string; data: any }> {
    return new Promise((resolve, reject) => {
      if (!value && !all) {
        this.notification.warning('Atenção', 'Insira um valor para pesquisa.');
        reject({ message: undefined, data: undefined } as AbstractResponse);
      }

      const paramAll = all ? `&all=${all}` : '';
      this.http
        .get(
          `${this.getServer(entidade)}?value=${value}${paramAll}`,
          this.headerAuthenticateOptions(),
        )
        .toPromise()
        .then((response: { message: string; data: any }) => {
          response.data.length === 0
            ? this.notification.info('Pesquisa', 'Nenhum registro encontrado.')
            : this.notification.success('Pesquisa', response.message);

          resolve(response as { message: string; data: any });
        })
        .catch((err: AbstractError) => {
          this.notification.error('Pesquisa', `${err}`);
          reject({ message: err.error.message, data: undefined } as {
            message: string;
            data: any;
          });
        });
    });
  }

  getByIdPublico(
    value: string,
    entidade: string = null,
  ): Promise<{ message: string; data: any }> {
    return new Promise((resolve, reject) => {
      if (!value) {
        this.notification.warning('Atenção', 'Insira um valor para pesquisa.');
        reject({ message: undefined, data: undefined } as AbstractResponse);
      }

      this.http
        .get(
          `${this.getServer(entidade)}/id-publico/${value}`,
          this.headerAuthenticateOptions(),
        )
        .toPromise()
        .then((response: { message: string; data: any }) => {
          response.data.length === 0
            ? this.notification.info('Pesquisa', 'Nenhum registro encontrado.')
            : this.notification.success('Pesquisa', response.message);

          resolve(response as { message: string; data: any });
        })
        .catch((err: AbstractError) => {
          this.notification.error('Pesquisa', `${err}`);
          reject({ message: err.error.message, data: undefined } as {
            message: string;
            data: any;
          });
        });
    });
  }

  async getDocumentTableProps(
    entidade: string = null,
  ): Promise<IResponseFieldsProps> {
    const camposPesquisa: ISearchFieldsProps[] = [];
    const colunasTabela: IColumnsFieldsProps[] = [];
    return await this.http
      .get(
        `${this.getServer(entidade)}/props`,
        this.headerAuthenticateOptions(),
      )
      .toPromise()
      .then((response: { message: string; data: any }) => {
        camposPesquisa.push({
          text: 'Todos',
          value: 'todos',
        });

        for (const p in response.data) {
          if (response.data[p].isSearch) {
            camposPesquisa.push({
              text: response.data[p].label,
              value: p,
            });
          }
        }
        for (const p in response.data) {
          if (response.data[p].columnTable) {
            colunasTabela.push({
              text: response.data[p].label,
              value: p,
              mask: response.data[p].mask ? response.data[p].mask : null,
              objectChildren: response.data[p].hasOwnProperty('objectChildren')
                ? response.data[p].objectChildren[0]
                : null,
              type: response.data[p].type,
            });
          }
        }
        return { searchColumns: camposPesquisa, tableColumns: colunasTabela };
      })
      .catch((err: AbstractError) => {
        this.notification.error('Propriedades', err.error.message);
        return { searchColumns: camposPesquisa, tableColumns: colunasTabela };
      });
  }

  getPropsEventTableDocMock() {
    return new Promise((resolve) =>
      resolve({
        message: 'Consulta Realizada',
        data: {
          data: {
            isSearch: true,
            label: 'Data',
            columnTable: true,
            mask: 'full-date',
          },
          acao: {
            isSearch: true,
            label: 'Ação',
            columnTable: true,
          },
          usuario: {
            isSearch: true,
            label: 'Usuário',
            columnTable: true,
          },
        },
      }),
    );
  }

  getUserInfoPermissions(infoToken) {
    return this.http
      .get(
        `${this.generateUrlByEnvironment(
          '5010',
          'api',
          'autorizacao',
        )}/usuario/${
          infoToken.userInfo.idUg
        }/consultar-por-parametro?field=id&value=${
          infoToken.userInfo.idUsuario
        }`,
        this.headerAuthenticateOptions(),
      )
      .toPromise()
      .then((resultUser: any) => {
        const usuario = resultUser.data;
        return this.http
          .get(
            `${this.generateUrlByEnvironment(
              '5008',
              'pessoa-fisica',
            )}/consultar-por-parametro?field=id&value=${
              usuario.idPessoaFisica
            }`,
            this.headerAuthenticateOptions(),
          )
          .toPromise()
          .then((resultPessoa: any) => {
            const pessoaFisica = resultPessoa.data;
            return { usuario, pessoaFisica };
          });
      });
  }

  async consultaIsAdmUser() {
    const infoToken = this.helper.decodeToken(this.authService.getToken());

    const userInfo = await this.getUserInfoPermissions(infoToken);

    const isAdm = userInfo.usuario.vinculacoes.some(
      (vinculacao) =>
        (vinculacao.perfil.adm === 1 &&
          vinculacao.perfil.idUnidadeGestora === infoToken.userInfo.idUg) ||
        vinculacao.perfil.perMod.some(
          (perfMod) =>
            perfMod.modulo.codigo === CodigoModulos[3] &&
            perfMod.modulo.codigo === 1,
        ),
    );

    this.adm = isAdm;
    return isAdm;
  }

  async isAdmUser(): Promise<boolean> {
    if (this._adm === null || this._adm === undefined) {
      await this.consultaIsAdmUser();
    }
    return Promise.resolve(this.adm);
  }

  encryptUsingAES256(value: string) {
    let _key = CryptoJS.enc.Utf8.parse(this.tokenFromUI);
    let _iv = CryptoJS.enc.Utf8.parse(this.tokenFromUI);
    let encrypted = CryptoJS.AES.encrypt(JSON.stringify(value), _key, {
      keySize: 16,
      iv: _iv,
      mode: CryptoJS.mode.ECB,
      padding: CryptoJS.pad.Pkcs7,
    });
    this.encrypted = encrypted.toString();
    return this.encrypted;
  }
}
