import { StatusMensagem } from './../enum/mensagem.enum';
import {
  AbstractError,
  IColumnsFieldsProps,
  ISearchFieldsProps,
} from './../interfaces/shared.interface';
import { IMensagemEnvio } from './../interfaces/mensagem-interface';
import { NzNotificationService } from 'src/@lib';
import { AuthService } from './../../../@lib/services/api/auth.service';
import { HttpClient } from '@angular/common/http';
import { AbstractService } from './abstract.service';
import { IAssinante, IDocumento } from './../interfaces/document-interfaces';
import { Injectable } from '@angular/core';
import moment from 'moment';

@Injectable({
  providedIn: 'root',
})
export class MensagensEnvioService extends AbstractService {
  constructor(
    public abstractHttp: HttpClient,
    public abstractAuthService: AuthService,
    public abstractNotification: NzNotificationService,
  ) {
    super(
      'mensagensEnviar',
      abstractHttp,
      abstractAuthService,
      abstractNotification,
    );
  }

  async enviarMensagemNaoEnviadas(mensagem): Promise<any> {
    mensagem = {
      ...mensagem,

      destinatarios: mensagem.destinatarios.map((destinatario) => {
        return {
          idSignatario: destinatario.idPrivado,
          idMensagem: mensagem.idPrivado,
          status: destinatario.status,
        };
      }),
    };

    return await this.http
      .post(
        `${this.getServer('mensagem-envio')}/nao-enviadas`,
        mensagem,
        this.headerAuthenticateOptions(),
      )
      .toPromise()
      .then((response: { message: string; data: any }) => {
        this.notification.success('Envio de mensagem', response.message);
      })
      .catch((err) => {
        this.notification.error('Envio de mensagem', err);
      });
  }

  async getDestinatariosByMensagem(item): Promise<any[]> {
    return await this.http
      .get(
        `${this.getServer('mensagem-envio')}/destinatarios/${item.idPrivado}`,
        this.headerAuthenticateOptions(),
      )
      .toPromise()
      .then((response: { message: String; data: any }) =>
        response.data.map((data: any) => {
          return { ...data, status: StatusMensagem[data.status] };
        }),
      )
      .catch((err) => this.notification.error('Envio de mensagem', err));
  }

  // async getDestinatarios()

  async salvarMensagem(
    mensagem: IMensagemEnvio,
    enviar: boolean,
  ): Promise<any> {
    //revisar forma como a informação de envio está sendo passada
    mensagem = {
      ...mensagem,
      destinatarios: mensagem.destinatarios.map((destinatario) => {
        return { idPrivado: destinatario.idPrivado };
      }),
    };

    return await this.http
      .post(
        `${this.getServer('mensagem-envio')}?enviar=${enviar as boolean}`,
        mensagem,
        this.headerAuthenticateOptions(),
      )
      .toPromise()
      .then((response: { message: string; data: any }) => {
        this.notification.success('Envio de mensagem', response.message);
      })
      .catch((err) => {
        this.notification.error('Envio de mensagem', err);
      });
  }

  async enviarMensagem(mensagem: IMensagemEnvio): Promise<any> {
    mensagem = {
      ...mensagem,
      destinatarios: mensagem.destinatarios.map((destinatario) => {
        return { idPrivado: destinatario.idPrivado };
      }),
    };
    return await this.http
      .post(
        `${this.getServer('mensagem-envio')}/enviar`,
        mensagem,
        this.headerAuthenticateOptions(),
      )
      .toPromise()
      .then((response: { message: string; data: any }) => {
        this.notification.success('Envio de mensagem', response.message);
      })
      .catch((err) => {
        this.notification.error('Envio de mensagem', err);
      });
  }

  async deletarMensagem(mensagem): Promise<any> {
    return await this.http
      .put(
        `${this.getServer('mensagem-envio')}/delete`,
        { idPrivado: mensagem.idPrivado },
        this.headerAuthenticateOptions(),
      )
      .toPromise()
      .then((response: { message: string; data: any }) => {
        this.notification.success('Envio de mensagem', response.message);
      })
      .catch((err) => {
        this.notification.error('Envio de mensagem', err);
      });
  }

  async editarMensagem(
    idPrivado,
    mensagem: IMensagemEnvio,
    enviar: boolean,
  ): Promise<any> {
    mensagem = {
      ...mensagem,
      destinatarios: mensagem.destinatarios.map((destinatario) => {
        return { idPrivado: destinatario.idPrivado };
      }),
    };

    return await this.http
      .put(
        `${this.getServer('mensagem-envio')}?enviar=${enviar as boolean}`,
        mensagem,
        this.headerAuthenticateOptions(),
      )
      .toPromise()
      .then((response: { message: string; data: any }) => {
        this.notification.success('Envio de mensagem', response.message);
      })
      .catch((err) => {
        this.notification.error('Envio de mensagem', err);
      });
  }

  async getAllMensagensEnvioInInterval(
    fieldsMensagensSelected: any,
    skip = 1,
    take = 10,
  ): Promise<any> {
    const paramsPaginator = skip && take ? `skip=${skip}&take=${take}` : '';
    return this.http
      .post(
        `${this.getServer('mensagem-envio')}/params?${paramsPaginator}`,
        fieldsMensagensSelected,
        this.headerAuthenticateOptions(),
      )
      .toPromise()
      .then((result: any) => {
        this.notification.success('Envio de Mensagens', result.message);
        return result;
      })
      .catch((err) => {
        return this.notification.error('Envio de Mensagens', err);
      });
  }

  async getMensagemEnvioTableProps(entidade: string = null): Promise<any> {
    const camposPesquisa:
      | ISearchFieldsProps[]
      | Array<{ text: string; value: any; type: string }> = [];
    const colunasTabela: IColumnsFieldsProps[] = [];
    return await this.http
      .get(
        `${this.getServer(entidade)}/any/props`,
        this.headerAuthenticateOptions(),
      )
      .toPromise()
      .then((response: { message: string; data: any }) => {
        // camposPesquisa.push({
        //   text: 'Todos',
        //   value: 'todos',
        // });

        for (const p in response.data) {
          if (response.data[p].isSearch) {
            camposPesquisa.push({
              text: response.data[p].label,
              value: p,
              type: response.data[p].type,
            });
          }
        }
        for (const p in response.data) {
          if (response.data[p].columnTable) {
            colunasTabela.push({
              text: response.data[p].label,
              value: p,
              mask: response.data[p].mask ? response.data[p].mask : null,
              objectChildren: response.data[p].hasOwnProperty('objectChildren')
                ? response.data[p].objectChildren[0]
                : null,
              type: response.data[p].type,
            });
          }
        }
        return {
          searchColumns: camposPesquisa,
          tableColumnsAnexo: colunasTabela,
        };
      })
      .catch((err: AbstractError) => {
        this.notification.error('Propriedades', err.error.message);
        return {
          searchColumns: camposPesquisa,
          tableColumnsAnexo: colunasTabela,
        };
      });
  }
  updateFilterType(filterType, type) {
    filterType.includes(type)
      ? filterType.splice(filterType.indexOf(type), 1)
      : filterType.push(type);
    return filterType;
  }

  async getSignatarios(): Promise<any> {
    return await this.http
      .get(this.getServer('signatario'), this.headerAuthenticateOptions())
      .toPromise()
      .then((res: { message: string; data: any }) => {
        // this.notification.success('Envio de mensagens', res.message);
        return res.data;
      })
      .catch((err) => {
        this.notification.success('Envio de mensagens', err);
      });
  }
  setCompareToTableColumns(displayData, tableColumns) {
    if (displayData.length === 0) {
      return;
    }

    tableColumns.map((column) => {
      column[`compare`] =
        typeof displayData[0][column.value] === 'string'
          ? (a: IDocumento, b: IDocumento) =>
              a[column.value].localeCompare(b[column.value])
          : (a: IDocumento, b: IDocumento) => a[column.value] - b[column.value];
    });
  }

  getDateFormattedUtcOffset(date) {
    if (date) {
      return moment(date).utcOffset(0, true).format('DD/MM/yyyy HH:mm:ss');
    }
  }

  getDateFormatted(date) {
    if (date) {
      return moment(date).format('DD/MM/yyyy HH:mm:ss');
    }
  }

  getDateFormatted3h(date) {
    if (date) {
      return moment(date).utcOffset(-360).format('DD/MM/yyyy HH:mm:ss');
    }
  }
}
