import { Component } from '@angular/core';

@Component({
    // tslint:disable-next-line: component-selector
    selector: 'ps-error-404',
    template: `
        <div class="container-fluid p-v-20 h-100">
            <div class="d-flex flex-column justify-content-between h-100">
                <div class="d-none d-md-block p-h-40">
                    <img class="img-fluid" alt="" src="assets/images/logo/logo.png" style="width: 12rem;">
                </div>
                <div class="container">
                    <div class="row align-items-center">
                        <div class="col-md-5">
                            <div class="p-v-30">
                                <h1 class="font-weight-semibold display-1 text-primary lh-1-2">404</h1>
                                <h2 class="font-weight-light font-size-30">Opa! Parece que você se perdeu.</h2>
                                <p class="lead m-b-30">Não conseguimos encontrar o que você estava procurando.</p>
                                <a [routerLink]="['/']" nz-button nzType="primary">Voltar</a>
                            </div>
                        </div>
                        <div class="col-md-6 m-l-auto">
                            <img class="img-fluid" src="assets/images/others/error-1.png" alt="">
                        </div>
                    </div>
                </div>
                <div class="d-none d-md-flex p-h-40 justify-content-between">
                    <span class="">© 2020 Publicsoft</span>
                    <ul class="list-inline">
                        <li class="list-inline-item">
                            <a class="text-dark text-link" href="">Legal</a>
                        </li>
                        <li class="list-inline-item">
                            <a class="text-dark text-link" href="">Privacy</a>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    `
})

export class Page404Component {

}
