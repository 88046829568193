import { Component, Input, TemplateRef } from '@angular/core';
import { InputBoolean } from '../../antd';

@Component({
  // tslint:disable-next-line: component-selector
  selector: 'ps-divider',
  exportAs: 'psDivider',
  preserveWhitespaces: false,
  styleUrls: ['./ps-divider.component.scss'],
  template: `
    <ng-container *ngIf="psText">
      <ng-container *nzStringTemplateOutlet="psText">{{ psText }}</ng-container>
    </ng-container>
  `,
  // tslint:disable-next-line: no-host-metadata-property
  host: {
    '[class.ps-divider]': 'true',
    '[class.text-left]': `psOrientation === 'left'`,
    '[class.text-right]': `psOrientation === 'right'`,
    '[class.border-top]': `psBorderTop === true`
  }
})
export class PsDividerComponent {

  @Input() psText?: string | TemplateRef<void>;
  @Input() psType: 'horizontal' | 'vertical' = 'horizontal';
  @Input() psOrientation: 'left' | 'right' | 'center' = 'center';
  @Input() @InputBoolean() psDashed = false;
  @Input() @InputBoolean() psPlain = false;
  @Input() @InputBoolean() psBorderTop = false;

}
