import { Teste } from './../shared/consts/document';
import { PessoaJuridicaComponent } from './../pessoa-juridica/pessoa-juridica.component';
import { PessoaJuridicaService } from './../shared/services/pessoa-juridica.service';
import { PessoaFisicaService } from './../shared/services/pessoa-fisica.service';
import { AuthService } from './../../@lib/services/api/auth.service';
import { NzModalRef, NzModalService } from 'ng-zorro-antd/modal';
import { FormGroup, FormBuilder, Validators, FormArray } from '@angular/forms';
import { IPessoaFisica } from './../shared/interfaces/pessoa-fisica';
import {
  Component,
  Input,
  OnInit,
  Optional,
  AfterContentInit,
} from '@angular/core';
import {
  IColumnsFieldsProps,
  ISearchFieldsProps,
} from '../shared/interfaces/shared.interface';
import { Helper } from '../shared/helper';

import { IMenuButton } from '../../@lib/components/speed-dial-fab/speed-dial-fab.component';
import { DocumentService } from '../shared/services/document.service';

@Component({
  selector: 'app-pessoa-fisica',
  templateUrl: './pessoa-fisica.component.html',
  styleUrls: ['./pessoa-fisica.component.scss'],
})
export class PessoaFisicaComponent implements OnInit, AfterContentInit {
  @Input() openModal = false;
  @Input() entidadePessoaFisica: IPessoaFisica = null;
  resultLength: number;
  loading = false;
  loadingEmpresas = false;

  searchInput: any;
  displayData = [];
  pessoaFisicaList: IPessoaFisica[] = [];

  searchColumns: ISearchFieldsProps[] = [];
  tableColumns: IColumnsFieldsProps[] = [];

  tabIndex = 0;

  formB: FormGroup = new FormGroup({});
  searchForm: FormGroup = new FormGroup({});
  page = 1;
  pageSize = 10;
  intervaloDigitando: any;

  checkList = {
    cpf: true,
    nomPessoa: true,
    emailPrincipal: true,
  };

  fabButtons: IMenuButton[];
  tel: string;

  tooltipPessoaFisica: boolean = false;
  public innerWidth: any;

  constructor(
    @Optional()
    private modalRef: NzModalRef<PessoaFisicaComponent>,
    public service: PessoaFisicaService,
    private formBuilder: FormBuilder,
    private authService: AuthService,
  ) {
    this.reset();

    this.resetFabButtons();
  }

  resetFabButtons() {

    const buttons: IMenuButton[] = [
      {
        icon: 'plus',
        tooltip: 'Novo Cadastro',
        condition:
          ((!this.openModal || this.openModal) && this.tabIndex === 0) ||
          this.tabIndex === 1,
        onClick: this.implantar,
      },
      {
        icon: 'save',
        tooltip: 'Salvar',
        color: 'green',
        condition: this.tabIndex === 0,
        onClick: this.gravar,
      },
      {
        icon: 'reload',
        tooltip: 'Resetar',
        condition: true,
        onClick: this.resetForm,
      },
      {
        icon: 'edit',
        tooltip: 'Editar',
        color: 'orange',
        condition: (!this.openModal || this.openModal) && this.tabIndex === 1,
        onClick: this.editar,
      },
      {
        icon: 'search',
        tooltip: 'Consultar',
        condition: this.tabIndex === 0,
        onClick: this.consultar,
      },
      {
        icon: 'select',
        tooltip: 'Selecionar',
        condition:
          this.openModal && (this.tabIndex === 0 || this.tabIndex === 1),
        onClick: this.selecionar,
      },
    ];
    const buttonsFiltered = buttons.filter((button) => button.condition);
    this.buildFabButton(buttonsFiltered);
  }

  async ngAfterContentInit() {
    await this.setConfigTable();
    this.reset();
  }

  buildFabButton(fabButtons: IMenuButton[]): void {
    this.fabButtons = fabButtons;
  }

  reset() {
    this.formB = this.formBuilder.group({
      id: [null],
      cpf: [null, [Validators.required, Validators.minLength(11)]],
      nome: [null, Validators.required],
      email: [null, [Validators.required, Validators.email]],
      telefone: [null, [Validators.required]],
      aplicacaoOrigem: [3],
      principal: [1],
      dtCriacao: [this.authService.getDateSelected()],
      usuarioCriacao: [this.authService.getIdUser()],
    });
    this.searchForm = this.formBuilder.group({
      field: [{ texto: 'Todos', valor: 'todos' }],
      value: [null, Validators.required],
      size: [this.pageSize],
      page: [this.page],
    });
  }

  ngOnInit(): void {
    if (this.openModal) {
      this.changeTabIndex(1);
    }
    this.innerWidth = window.innerWidth;

    // this.service.getEmpresasAssinanteLogado();
  }
  searchPessoaFisica() {
    this.tooltipPessoaFisica = true;
    setTimeout(() => {
      this.tooltipPessoaFisica = false;
    }, 2000);
  }

  resetForm = (tabIndex = 0) => {
    this.resetState();
    this.setPessoaFisicasData([]);
    this.entidadePessoaFisica = null;
    this.changeTabIndex(tabIndex);
  };

  async setConfigTable() {
    this.loading = true;
    await this.service.getDocumentTableProps().then((result) => {
      Object.assign(this, result);
      this.setCompareToTableColumns();
    });
    this.loading = false;
  }

  setCompareToTableColumns() {
    this.tableColumns.map((column) => {
      column[`compare`] =
        this.displayData.length > 0 &&
        typeof this.displayData[0][column.value] === 'string'
          ? (a: IPessoaFisica, b: IPessoaFisica) =>
              a[column.value].localeCompare(b[column.value])
          : (a: IPessoaFisica, b: IPessoaFisica) =>
              a[column.value] - b[column.value];
    });
  }

  getTableColumns() {
    return this.tableColumns;
  }

  async getPessoasFisicas(texto = '') {
    const regex = /\d/;
    const doesItHaveNumber = regex.test(texto);

    doesItHaveNumber
      ? this.searchForm.patchValue({
          value: texto,
          field: {
            texto: 'CPF',
            valor: 'cpf',
          },
        })
      : this.searchForm.patchValue({
          value: texto,
          field: { texto: 'Nome', valor: 'nomPessoa' },
        });
    clearTimeout(this.intervaloDigitando);
    this.intervaloDigitando = await setTimeout(async () => {
      if (!texto) {
        this.searchForm.patchValue({
          value: texto,
          field: { texto: 'Todos', valor: 'todos' },
        });
        this.consultar();
      }
      this.loading = true;
      await this.service
        .getPessoasFisicas(this.searchForm.value)
        .then((result: { pessoa: any[]; params: any }) => {
          this.resultLength = result.params[0];
          this.formatResultGetPessoasFisicas(result.pessoa);
        })
        .catch(() => (this.loading = false));
      this.loading = false;
    }, 1000);
  }

  async searchPessoaByCpf(value) {
    if (
      !value ||
      value.length < 11 ||
      this.entidadePessoaFisica?.cpf === value
    ) {
      return;
    }

    this.loading = true;
    await this.service
      .getPessoasFisicaByCpf(value)
      .then((result: any) => {
        if (result.data !== null) {
          this.formatResultGetPessoasFisicas([result]);
          this.entidadePessoaFisica = this.pessoaFisicaList[0];
          this.resetState();
          this.service.notification.warning(
            'Formulário',
            'Pessoa física já cadastrada',
          );
          this.changeTabIndex(1, false);
        }
        this.loading = false;
      })
      .catch(() => (this.loading = false));
  }

  formatResultGetPessoasFisicas(result: IPessoaFisica[]) {
    result.map((tipo: IPessoaFisica) => (tipo.checked = false));

    this.setPessoaFisicasData(result);
  }

  setPessoaFisicasData(pessoas: IPessoaFisica[]) {
    this.pessoaFisicaList = pessoas;
    this.displayData = pessoas;
  }

  getResult(): void {
    this.displayData = this.service.documentService.search(
      this.pessoaFisicaList,
      this.searchInput,
    );
  }

  prepareColumnValue(item, column) {
    const value = column.objectChildren
      ? item[column.objectChildren][column.value]
      : item[column.value];
    if (value && column.mask) {
      return Helper.addMask(value, column.mask);
    }
    return value;
  }

  async changeTabIndex(value, search = true) {
    this.tabIndex = value;
    this.resetFabButtons();
    if (this.tabIndex === 1 && search === true) {
      this.consultar();
    }
  }

  selectItem(entidadePessoaFisica) {
    entidadePessoaFisica = this.formatPessoaFisicaToForm(entidadePessoaFisica);
    this.entidadePessoaFisica = this.pessoaFisicaList.every(
      (tipo) => !tipo.checked,
    )
      ? null
      : entidadePessoaFisica;

    this.entidadePessoaFisica
      ? this.setDataFormPessoaFisica()
      : this.resetState();
  }

  resetState() {
    this.formB.reset();
    this.reset();
    this.entidadePessoaFisica = null;
  }

  formatPessoaFisicaToForm(registro): IPessoaFisica {
    this.checkItemPesquisa(registro);
    const pessoaFisica = {
      email: registro.pessoa.emailPrincipal,
      telefone: registro.contatos[0].telefone,
      nome: registro.pessoa.nomPessoa,
      ...registro,
    };
    return pessoaFisica;
  }

  checkItemPesquisa(entidadePessoaFisica) {
    this.pessoaFisicaList.map((pessoaFisica) => {
      pessoaFisica.checked =
        pessoaFisica.id !== entidadePessoaFisica?.id ? false : true;
    });
  }

  implantar = () => this.resetForm();

  selecionar = () => {
    if (
      !this.entidadePessoaFisica ||
      this.pessoaFisicaList.every((tipo) => !tipo.checked)
    ) {
      return this.service.notification.warning(
        'Alterar',
        'Nenhum item selecionado!',
      );
    }

    this.modalRef.destroy(this.entidadePessoaFisica);
  };

  editar = () => {
    if (
      !this.entidadePessoaFisica ||
      this.pessoaFisicaList.every((tipo) => !tipo.checked)
    ) {
      return this.service.notification.warning(
        'Alterar',
        'Nenhum item selecionado!',
      );
    }

    this.setDataFormPessoaFisica();
    this.changeTabIndex(0);
  };

  setDataFormPessoaFisica() {
    this.reset();
    this.formB.patchValue(this.entidadePessoaFisica);
  }

  gravar = async () => {
    const rgx = /\d+/g;
    const a = this.formB.value.telefone;
    a.match(rgx);

    if (this.formB.invalid) {
      return this.service.notification.warning(
        'Formulário',
        'Por favor, preencha todos os campos corretamente',
      );
    }
    this.loading = true;
    const ugSelected = await this.authService.getUgSelected();
    this.formB.get('id').value
      ? await this.service
          .alterar(this.formB.value)
          .then(() => this.resetForm())
          .catch(() =>
            this.service.notification.error(
              'Alterar',
              'Error ao alterar pessoa física',
            ),
          )
      : await this.service
          .inserir(this.formB.value)
          .then(() => this.resetForm())
          .catch(() =>
            this.service.notification.error(
              'Cadastro',
              'Error ao cadastrar pessoa física',
            ),
          );

    this.loading = false;
  };

  consultar = async () => {
    this.loading = true;
    this.searchInput = null;
    await this.service
      .getPessoasFisicas(this.searchForm.value)
      .then((result: { pessoa: any[]; params: any }) => {
        this.resultLength = result.params[0];
        this.formatResultGetPessoasFisicas(result.pessoa);
      })
      .catch(() => (this.loading = false));
    this.loading = false;
  };

  dbClickItem(item: IPessoaFisica) {
    if (!item.checked) {
      this.entidadePessoaFisica = this.formatPessoaFisicaToForm(item);
    }

    this.openModal
      ? this.modalRef.destroy(this.entidadePessoaFisica)
      : this.editar();
  }

  // editPJ(cnpj) {
  //   const empresa = this.formB.value.empresas.find((emp) => emp.cnpj === cnpj);
  //   this.formB.patchValue(empresa);
  // }

  // addPJ(formBValue) {
  //   const form: FormArray = this.formB.get('empresas') as FormArray;

  //   form.push(
  //     this.formBuilder.group({
  //       cnpj: [formBValue.cnpj],
  //       razaoSocial: [formBValue.razaoSocial],
  //       cidade: [formBValue.nomCidade || formBValue.cidade],
  //     }),
  //   );
  //   this.limparPJ();
  // }

  pageChanged(evento) {
    this.searchForm.patchValue({ page: evento });
    this.consultar();
  }

  // limparPJ() {
  //   this.formB.get('entidadePessoaJuridica').reset();
  //   this.formB.get('cnpj').reset();
  //   this.formB.get('razaoSocial').reset();
  //   this.formB.get('nomCidade').reset();
  // }

  // removePJ(cnpj) {
  //   const formb: FormArray = this.formB.get('empresas') as FormArray;
  //   const index = this.formB.value.empresas.indexOf(
  //     this.formB.value.empresas.find((emp) => emp.cnpj === cnpj),
  //   );
  //   formb.removeAt(index);
  // }

  // verificaPJVazia() {
  //   return this.formB.value.cnpj;
  // }

  // setDataPessoaJuridica(data) {
  //   this.formB.patchValue({
  //     entidadePessoaJuridica: data,
  //     ...data,
  //     ...data.enderecos[0],
  //   });
  // }

  // async searchPessoaJuridica(value) {
  //   if (
  //     (this.formB.get('entidadePessoaJuridica').value &&
  //       this.formB.get('entidadePessoaJuridica').value.cnpj === value) ||
  //     value.length < 14 ||
  //     !value
  //   ) {
  //     return;
  //   }

  //   clearTimeout(this.intervaloDigitando);
  //   this.intervaloDigitando = await setTimeout(async () => {
  //     this.loadingEmpresas = true;
  //     await this.pessoaJuridicaService
  //       .getPessoasJuridicasByCnpj(value)
  //       .then((result) => this.setDataPessoaJuridica(result))
  //       .catch(() => (this.loadingEmpresas = false));
  //     this.loadingEmpresas = false;
  //   }, 1000);
  // }

  // showModalPJ() {
  //   const pessoaJuridicaModal = this.modalService.create({
  //     nzTitle: 'Selecione uma pessoa física',
  //     nzContent: PessoaJuridicaComponent,
  //     nzComponentParams: {
  //       openModal: true,
  //     },
  //     nzFooter: null,
  //     nzWidth: 1000,
  //   });

  //   pessoaJuridicaModal.afterClose.subscribe((result) =>
  //     this.setDataPessoaJuridica(result),
  //   );
  // }

  isPeopleLogged() {
    return this.entidadePessoaFisica?.cpf === this.authService.getCpfUser();
  }

  getWidthContent() {
    return window.innerWidth;
  }

  resetar() {
    this.checkList = {
      cpf: true,
      nomPessoa: true,
      emailPrincipal: true,
    };
  }
}
