import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import * as moment from 'moment';
import { AuthService, IMenuButton, NzModalService } from 'src/@lib';
import { ModalDialogComponent } from '../modal-dialog/modal-dialog.component';
import {
  ColorStatusDoc,
  IntervalsTime,
  IntervalsTimeTranslate,
  StatusDoc,
  StatusDocLabel,
  TypeActions,
  TypeDocDownload,
  TypeDocView,
} from '../shared/consts/document';
import { CodigoModulos, ModulosPublicSoft } from '../shared/consts/modulos';
import { StatusCertificado, StatusDocumento } from '../shared/enum/const.enum';
import {
  IAssinante,
  IDocumento,
  IDocumentoAnexo,
} from '../shared/interfaces/document-interfaces';
import {
  IColumnsFieldsProps,
  ISearchFieldsProps,
} from '../shared/interfaces/shared.interface';
import { AssinantesService } from '../shared/services/assinantes.service';
import { DocumentService } from '../shared/services/document.service';
import { DocumentsPendingService } from '../shared/services/documents-pending.service';
import { DocumentsUploadService } from '../shared/services/documents-upload.service';
import { TipoDocumentoService } from '../shared/services/tipo-documento.service';
import { PeriodVisualizeDoc } from '../shared/types/document.types';
import { DocumentsDetailComponent } from './../documents-detail/documents-detail.component';
import { IAnexoSignatarios } from './../shared/interfaces/document-interfaces';

@Component({
  selector: 'app-anexos',
  templateUrl: './anexos.component.html',
  styleUrls: ['./anexos.component.scss'],
})
export class AnexosComponent implements OnInit {
  assinanteLogado;
  pageTotal: number;

  idDocSelected: number;
  assinanteSelected: any;
  assinantesSelected = [];
  allAssinantes = [];
  peopleArray = [];
  adm: boolean;
  showDocsAvulsos = false;
  filterType: number[];
  colorExpiredDocSelected: string;
  ratificarDoc: boolean = false;
  ratificarTodos: boolean = false;
  labelToaster: string;
  assinarAnexos: boolean;

  getDateNow: any = this.getDate();

  loading = false;
  loadingSignatarios = false;
  formLoading = false;

  paramsWhere: any;

  checkedAll = false;
  checkedAllAnexos = false;
  checkAllIndeterminate = true;
  checkAllAnexosIndeterminate = true;

  searchInput: any;
  displayData = [];
  anexos = [];

  anexosData = [];

  showComboTags = false;

  fieldSelected;

  fieldsSelected = [];

  selectedValues = [];

  arrColumnsSelect = [];

  searchColumns: ISearchFieldsProps[] = [];
  tableColumns: IColumnsFieldsProps[] = [];

  tableColumnsAnexo: IColumnsFieldsProps[] = [];

  intervalsTime = Object.entries(IntervalsTime);
  intervalTimeSelected = this.intervalsTime[0][0];

  documentList = [];
  docsChecked = [];

  listSignatarios: IAssinante[];
  listSignatariosToSign = [];
  certificadoAssinante: any[];
  newDocument = [];

  listedAttachs: boolean;

  statusDoc = StatusDoc;
  statusDocLabel = StatusDocLabel;

  panels = [
    {
      active: true,
      name: 'Filtros',
    },
  ];

  checkListAnexos = {
    nome: true,
    nomeOriginal: true,
    descricao: true,
    tipoDocumentoAnexo: true,
    status: true,
    uuid: true,
    acoes: true,
    dtCriacao: true,
    ratificado: true,
    // anexoSignatarios: true,
  };

  checkList = {
    tipoDoc: true,
    numeroDoc: true,
    status: true,
    chancela: true,
    nomeUnidadeOrc: true,
    nomeFav: true,
    tipoDocumento: true,
    valorDoc: true,
    numProcesso: true,
    dataDocumentoFormated: true,
    dataLimiteAssinatura: true,
    aplicacaoOrigem: true,
    numeroLicitacao: true,
    modalidadeLicitacao: true,
    numeroObra: true,
    numeroContrato: true,
    numeroEvento: true,
    numeroConvenio: true,
    numeroProgramaInstitucional: true,
    usuarioCriacao: true,
    acoes: true,
  };

  flagQueryParams = false;

  fabButtons: IMenuButton[];

  colorStatusDoc = ColorStatusDoc;
  verification: boolean;
  identifyer: boolean = false;
  hasCertificado: boolean;
  listaPessoas = [];
  signatarios = [];
  customInterval: string[];

  emails: Map<number, string>;
  allDocsChecks: IDocumentoAnexo[] = [];

  tooltipVisible: object = {
    filter1: false,
    filter2: false,
    filter3: false,
    filter4: false,
    filter5: false,
    filter6: false,
  };

  public innerWidth: any;

  constructor(
    protected authService: AuthService,
    private activateRouter: ActivatedRoute,
    public documentService: DocumentService,
    private tipoDocService: TipoDocumentoService,
    public service: DocumentsUploadService,
    private documentsPendingService: DocumentsPendingService,
    private modal: NzModalService,
    private router: Router,
    private assinantesService: AssinantesService,
  ) {
    this.getDate();
    this.admUser();
    this.resetFabButtons();

    this.activateRouter.queryParamMap.subscribe(async (queryParams) => {
      this.fieldsSelected = [];
      this.filterPreSet(queryParams);
      this.showComboTags = false;
      this.intervalTimeSelected = queryParams.get('interval')
        ? queryParams.get('interval')
        : null;

      this.filterType = Number(queryParams.get('tipo'))
        ? [Number(queryParams.get('tipo'))]
        : [];

      const adm = queryParams.get('adm') === 'false' ? false : true;

      this.adm = adm;

      this.flagQueryParams = true;

      await this.setConfigTable();
      await this.setConfigAnexoTable();
      await this.getAllAssinantes();

      this.columnsSelect(this.tableColumns);

      this.colorExpiredDocSelected = queryParams.get('colorExpired');

      if (queryParams.keys.length > 0) {
        this.changeViewDocsAvulsos(JSON.parse(queryParams.get('docAvulso')));

        if (queryParams.getAll('assinantes').length > 0) {
          this.assinanteSelected = null;
          this.assinantesSelected = [];

          const arrAssinantes = [];
          this.getAllAssinantes();

          for (const ids of queryParams.getAll('assinantes')) {
            this.assinanteSelected = this.listSignatarios
              ?.filter((key) => key.idPessoaFisica === Number(ids))
              .map((newArr) => {
                return {
                  ...newArr,
                  queryParams: true,
                };
              });
            arrAssinantes.push(...this.assinanteSelected);
          }
          this.addFiltroPorAssinante(arrAssinantes);
        }

        if (queryParams.get('initialDate')) {
          const initialDate = queryParams.get('initialDate');
          const finalDate = queryParams.get('finalDate');
          this.customInterval = [initialDate, finalDate];
        }

        this.getDocumentsPerPeriod(this.showDocsAvulsos);
      }

      // this.documentService.updateDsp.subscribe(async (msg) => {
      //   if (Boolean(msg) === true) {
      //     await this.getDocumentsPerPeriod();
      //   }
    });
  }

  async getDocumentsPerPeriod(
    avulsos = false,
    checkAll = false,
    typePeriod?: PeriodVisualizeDoc,
    skip?: number,
    take?: number,
  ) {
    this.anexos = [];
    if (
      this.fieldsSelected.some(
        (key) => key.props === 'dataDocumentoFormated' && key.value,
      )
    ) {
      this.intervalTimeSelected = null;
    }
    const types = IntervalsTime[Number(this.intervalTimeSelected)];

    typePeriod = IntervalsTimeTranslate[types];

    this.formLoading = true;
    await this.documentService
      .getAllDocumentsInInterval(
        typePeriod,
        this.assinantesSelected,
        Number(this.intervalTimeSelected),
        avulsos,
        this.customInterval,
        this.filterType,
        this.fieldsSelected,
        checkAll,
        skip,
        take,
        true,
      )
      .then(async (anexos: any) => {
        this.paramsWhere = anexos.where;
        if (!this.identifyer) {
          this.pageTotal = anexos.data.total;
        }
        this.formLoading = false;
        await this.formatResultGetDocuments(anexos.data.resultado, checkAll);
      })
      .catch((err) => {
        this.formLoading = false;
      });
  }

  async pageChange(valor) {
    if (!this.identifyer) {
      await this.getDocumentsPerPeriod(
        this.showDocsAvulsos,
        false,
        null,
        Number(valor),
        10,
      );
    }
  }

  getWidthContent() {
    return window.innerWidth;
  }

  isSigned(anexo: any) {
    return anexo.anexoSignatarios.some(
      (key) => key.status === StatusDocumento.Assinado,
    );
  }

  async formatResultGetDocuments(
    docs: any[],
    checkAll = false,
    identifyer = false,
  ) {
    this.formLoading = true;
    // const attachs = anexos.filter(async (anx) => {
    //   const dataLimiteAssinatura = anx.dataLimiteAssinatura?.slice(
    //     0,
    //     anx.dataLimiteAssinatura.lastIndexOf('.'),
    //   );

    //   return (
    //     anx.anexoSignatarios.some(
    //       (assAnx) => assAnx.status === StatusDocumento.Assinado,
    //     ) ||
    //     anx.status === StatusDocumento.Pendente ||
    //     anx.status === StatusDocumento.Confirmado ||
    //     anx.status === StatusDocumento.Desacordo ||
    //     anx.status === StatusDocumento.Cancelado ||
    //     new Date(dataLimiteAssinatura) < new Date()
    //   );
    // });

    docs.forEach((keys) => (keys.checked = false));

    // this.anexosData = await anexos.map((itm) => {
    //   return {
    //     ...itm,
    //     expand: false,
    //   };
    // });
    for (const document of docs) {
      for (const anxs of document.documentoAnexo) {
        this.anexos.push(...anxs);
      }
    }

    docs.map(async (keys: any) => {
      await this.documentService.formatColumnsDocForDysplay(
        keys,
        this.listSignatarios,
      );
    });

    this.displayData = await docs.map((itm) => {
      return {
        ...itm,
        dataDocumentoFormated: moment(itm.dataDocumento)
          .utcOffset(0, true)
          .format(),
        tipoDoc: `${itm.tipoDocumento.idPublico} - ${itm.tipoDocumento.tipo}`,
        aplicacaoOrigemDetalhe:
          ModulosPublicSoft['Assinatura Digital'] === itm.aplicacaoOrigem
            ? 'Avulso'
            : CodigoModulos[itm.aplicacaoOrigem],
        expand: false,
        checked: checkAll,
      };
    });

    if (this.flagQueryParams === true) {
      this.displayData.sort((a, b) => b.idPrivado - a.idPrivado);
    }

    if (identifyer) {
      this.pageTotal = this.displayData.length;
    }

    this.documentList = this.displayData;

    if (this.docsChecked.length > 0) {
      for (const newDoc of this.docsChecked) {
        const index = this.displayData.findIndex(
          (a) => a.idPrivado === newDoc.idPrivado,
        );

        if (index !== -1) {
          this.displayData.splice(index, 1);

          this.displayData.push(newDoc);
          this.displayData.sort((a, b) => b.idPrivado - a.idPrivado);
        }
      }
    }

    await Promise.all([this.setConfigTable(), this.setConfigAnexoTable()]);
    this.formLoading = false;
    this.resetFabButtons();
  }

  columnsSelect(tableColumns) {
    this.arrColumnsSelect = this.documentService.getColumnsSelect(tableColumns);
  }

  mostarProgressBarAndClock(documento: IDocumento): boolean {
    const status = this.documentService.getStatusDocumento(documento);

    if (
      [
        StatusDocumento.Cancelado,
        StatusDocumento.Expirado,
        StatusDocumento.Desacordo,
        StatusDocumento.Confirmado,
        StatusDocumento.Assinado,
      ].includes(status)
    ) {
      return false;
    }
    return true;
  }

  getCorDocumento(documento: IDocumento): string {
    const status = this.documentService.getStatusDocumento(documento);
    return this.documentService.themeColors[ColorStatusDoc[status]];
  }

  getNomeStatusDocumento(documento: IDocumento): string {
    return StatusDocLabel[this.documentService.getStatusDocumento(documento)];
  }

  toggleTooltipVisible(item) {
    item.tooltipeVisibleTableRow = !item.tooltipeVisibleTableRow;

    setTimeout(() => {
      item.tooltipeVisibleTableRow = false;
    }, 2000);
  }

  toggleTooltipVisibleEye(item) {
    item.tooltipeVisibleEye = !item.tooltipeVisibleEye;

    setTimeout(() => {
      item.tooltipeVisibleEye = false;
    }, 2000);
  }

  navigateToDocumentDetail(anexo: any, index) {
    this.idDocSelected = anexo.idPrivado;
    this.modifyDocument(anexo, true, index);
    this.showModalDetailDocument(anexo);
  }

  showModalDetailDocument(document: any) {
    // const userInfo = this.authService.getUserInfo();
    const filterType = this.filterType;
    const infoAnexo = false;
    this.modal.create({
      nzTitle: `<h4>Detalhes do documento</h4>`,
      nzWidth: window.innerWidth * 0.7,
      nzContent: DocumentsDetailComponent,
      nzComponentParams: { document, filterType, infoAnexo },
      nzFooter: [],
    });
    this.modal.afterAllClose.subscribe(() => {
      // this.updateDisplayDocs(this.idDocSelected ? [this.idDocSelected] : []);
      this.idDocSelected = null;
    });
  }

  getCorAnexo(anexo: any, doc: IDocumento): string {
    let status: number;

    // if (
    //   this.documentService.docIsExpirado(doc.dataLimiteAssinatura) &&
    //   anexo.status === StatusDocumento.Pendente
    // ) {
    //   status = StatusDocumento.Expirado;
    // }
    status = anexo.status;
    return this.documentService.themeColors[ColorStatusDoc[status]];
  }

  getNomeStatusAnexo(anexo: any, doc: IDocumento): string {
    let status: number;
    // if (
    //   this.documentService.docIsExpirado(doc.dataLimiteAssinatura) &&
    //   anexo.status === StatusDocumento.Pendente
    // ) {
    //   status = StatusDocumento.Expirado;
    // }
    status = anexo.status;
    return StatusDocLabel[status];
  }

  async setConfigAnexoTable() {
    this.loading = true;

    this.service.getDocumentTableAnexoProps('documentoAnexo').then((result) => {
      Object.assign(this, result);
      this.setCompareToTableAnexoColumns();
      this.loading = false;
    });
  }

  setCompareToTableAnexoColumns() {
    this.tableColumnsAnexo.map((column) => {
      column[`compare`] =
        this.displayData?.length > 0 &&
        typeof this.displayData[0][column.value] === 'string'
          ? (a: IDocumentoAnexo, b: IDocumentoAnexo) =>
              a[column.value].localeCompare(b[column.value])
          : (a: IDocumentoAnexo, b: IDocumentoAnexo) =>
              a[column.value] - b[column.value];
    });
  }

  async setConfigTable() {
    this.loading = true;
    await this.documentsPendingService
      .getDocumentTableProps()
      .then((result) => {
        Object.assign(this, result);
        this.documentService.setCompareToTableColumns(
          this.displayData,
          this.tableColumns,
        );
      });
    this.loading = false;
  }

  ngOnInit(): void {
    this.filterPreSet();
  }

  filterPreSet(queryParams?) {
    const date = new Date();
    let primeiroDia: any = moment(
      new Date(date.getFullYear(), date.getMonth(), 1),
    ).format('DD/MM/YYYY');
    let diaAtual: any = moment().format('DD/MM/YYYY');

    //https://pt.stackoverflow.com/questions/226086/como-retornar-o-primeiro-e-%C3%BAltimo-dia-do-m%C3%AAs-corrente-em-javascript
    if (queryParams.keys.length > 0) {
      const { initialDate, finalDate, interval } = queryParams?.params;

      // const primeiroDiaIso = initialDate;
      primeiroDia = moment(initialDate).format('DD/MM/YYYY');
      diaAtual = moment(finalDate).format('DD/MM/YYYY');

      this.fieldsSelected = [];

      if (interval && !(initialDate && finalDate)) {
        return this.fieldsSelected.push({
          field: 'Data do documento',
          props: 'dataDocumentoFormated',
          text: `Período - ${IntervalsTime[interval]}`,
        });
      }
    }
    return this.fieldsSelected.push({
      field: 'Data do documento',
      props: 'dataDocumentoFormated',
      text: `Data do documento - ${primeiroDia} - ${diaAtual}`,
      value: `${primeiroDia} - ${diaAtual}`,
    });

    // this.showComboTags = this.fieldsSelected.length != 0;
  }

  async changeViewDocsAvulsos(avulso) {
    if (avulso === undefined) {
      this.showDocsAvulsos = !this.showDocsAvulsos;
      return;
    }
    this.showDocsAvulsos = avulso;
  }

  async viewDoc(doc: IDocumento) {
    this.loading = true;
    const typeView = TypeDocView[doc.statusDocumento];
    const typeAction = TypeActions[doc.statusDocumento];

    const uuid = await this.documentService.getUuidFile(typeView, doc);

    const urlDocToView = await this.documentService.getUrlDocToUploadApi(
      uuid,
      typeAction,
    );
    this.loading = false;
    uuid ? window.open(urlDocToView.pdfURL, '_blank') : null;

    if (urlDocToView.events) {
      doc.eventos.push(urlDocToView.events);
    }
  }

  async getURLAnexo(anexo: IDocumentoAnexo, download = false) {
    this.loading = true;
    return await this.documentService
      .getURLAnexo(anexo, download)
      .then((result) => {
        // this.eventTableDataList.push(result);
        this.loading = false;
      })
      .catch(() => (this.loading = false));
  }

  addFiltroPorAssinante(arrAssinantes = []) {
    if (arrAssinantes.length === 0) {
      this.assinantesSelected.push(this.assinanteSelected);

      this.fieldsSelected.push({
        props: 'assinante',
        field: 'Assinante',
        value: this.assinanteSelected.cpf,
        text: `${this.assinanteSelected.cpf} - ${this.assinanteSelected.nome}`,
      });

      this.showComboTags = true;
    }

    if (arrAssinantes.length > 0) {
      for (const assinante of arrAssinantes) {
        this.assinantesSelected.push(assinante);

        this.fieldsSelected.push({
          props: 'assinante',
          field: 'Assinante',
          value: assinante.cpf,
          text: `${assinante.cpf} - ${assinante.nome}`,
        });
      }
      this.showComboTags = true;
    }
  }

  addFiltroPorCampo() {
    if (this.fieldSelected && this.searchInput) {
      if (this.fieldSelected.type === 'range-picker') {
        this.fieldsSelected = this.fieldsSelected.filter((el) => {
          return el.field != 'Data do documento';
        });
        this.searchInput = `${moment(this.searchInput[0]).format(
          'DD/MM/yyyy',
        )} - ${moment(this.searchInput[1]).format('DD/MM/yyyy')}`;
      }

      if (this.fieldSelected.type === 'date-picker') {
        this.searchInput = moment(this.searchInput).format('DD/MM/yyyy');
      }

      this.fieldsSelected.push({
        props: this.fieldSelected.value,
        field: this.fieldSelected.text,
        value: this.searchInput.text ? this.searchInput.text : this.searchInput,
        text: `${this.fieldSelected.text} - ${
          this.searchInput.text ? this.searchInput.text : this.searchInput
        }`,
      });

      const index = this.arrColumnsSelect.findIndex(
        (a) => a.value === this.fieldSelected.value,
      );

      this.arrColumnsSelect.splice(index, 1);

      this.showComboTags = true;
      this.fieldSelected = '';
      this.searchInput = '';
      this.selectedValues = [];
    }
  }

  async getOptionsSelect(column) {
    let arrTypes = [];

    column.objectChildren === 'tag'
      ? (arrTypes = await this.tipoDocService.getTipoDocByTag())
      : (arrTypes = await this.tipoDocService.getTipoDocs(null, true));

    for (const teste of arrTypes) {
      this.selectedValues.push({
        field: this.fieldSelected.text,
        value: column.objectChildren === 'tag' ? teste.tag : teste.tipo,
        text:
          column.objectChildren === 'tag'
            ? teste.tag
            : `${teste.idPublico} - ${teste.tipo}`,
      });
    }
  }

  removeFiltroPorField(index) {
    const indx = this.assinantesSelected.findIndex(
      (a) => a.cpf === this.fieldsSelected[index].value,
    );

    if (indx > -1) {
      this.assinantesSelected.splice(indx, 1);
    }

    this.fieldsSelected.splice(index, 1);

    this.columnsSelect(this.tableColumns);

    if (this.fieldsSelected.length === 0) {
      this.showComboTags = false;
    }
  }

  removeFiltroPorAssinante(cpf) {
    this.documentService.removeSubscriberToFilter(
      this.assinantesSelected,
      this.listSignatarios,
      cpf,
    );
  }

  clearFilterByDashboard() {
    this.intervalTimeSelected = this.intervalsTime[0][0];
  }

  getDate() {
    this.getDateNow = new Date().toLocaleString();
  }

  setFilterType(type: number) {
    this.filterType = this.documentService.updateFilterType(
      this.filterType,
      type,
    );
    if (this.innerWidth <= 748) {
      this.tooltipVisible[`filter${type}`] = true;
      setTimeout(() => {
        this.tooltipVisible[`filter${type}`] = false;
      }, 2000);
    }
  }

  resetFabButtons() {
    this.buildFabButtons([
      {
        icon: 'form',
        tooltip: 'Assinar',
        color: 'blue',
        condition: true,
        onClick: this.assinar,
      },
      {
        icon: 'close-circle',
        tooltip: 'Discordar',
        color: 'red',
        condition: true,
        onClick: this.discordar,
      },
      {
        icon: 'rollback',
        tooltip: 'Desfazer Desacordo',
        color: 'yellow',
        condition: true,
        onClick: this.desfazer,
      },
      {
        icon: 'download',
        tooltip: 'Download',
        condition: true,
        onClick: this.downloadDocuments,
      },
      {
        icon: 'diff',
        tooltip: 'Ratificar Documento',
        condition: true,
        color: 'green',
        onClick: this.ratificar,
      },
      {
        icon: 'reload',
        tooltip: 'Atualizar',
        condition: true,
        onClick: this.attDashboard,
      },
    ]);
  }

  buildFabButtons(fabButtons: IMenuButton[]) {
    this.fabButtons = fabButtons;
  }

  filterDocuments() {
    if (this.filterType.length === 0) {
      return this.documentService.notification.info(
        'Selecionar Status',
        'Selecione algum status para poder aplicar os filtros!',
        { nzDuration: 7000 },
      );
    }

    this.getDocumentsPerPeriod(this.showDocsAvulsos);
  }

  async admUser() {
    this.adm = await this.documentService.isAdmUser();
  }

  async getAllAssinantes(avulso = false, idDocumento?) {
    this.formLoading = true;
    const arrPRMS = [];
    arrPRMS.push(
      this.getAllSignatarios(avulso).then((allSignatarios) => {
        const newAss = allSignatarios
          .filter((el) => {
            return (
              el.hasOwnProperty('cpf') && el.hasOwnProperty('cpfRepresentante')
            );
          })
          .sort((a, b) => {
            if (a.nome < b.nome) {
              return -1;
            }
            if (a.nome > b.nome) {
              return 1;
            }
            return 0;
          });
        idDocumento
          ? this.listSignatariosToSign
          : (this.listSignatarios = newAss);
      }),
    );
    if (idDocumento) {
      arrPRMS.push(
        this.documentService
          .getSignatarioByIdDocumento(idDocumento)
          .then((resp) => (this.listSignatariosToSign = resp)),
      );
    }

    Promise.all(arrPRMS).then((e) => {
      this.formLoading = false;
    });
  }

  async getAllSignatarios(avulso) {
    return await this.documentService.getAllSignatarios(avulso);
  }

  getEmail(id: number, anexoSignatarios: IAnexoSignatarios) {
    let email = 'Email não encontrado';
    if (anexoSignatarios.idPessoaFisica === id) {
      email = anexoSignatarios.email;
    }
    return email;
  }

  async modifyDocument(doc: IDocumento, expand: boolean, index = 0) {
    try {
      if (
        doc.documentoAnexo.some((a) => a.anexoSignatarios.some((b) => b.nome))
      ) {
        // this.newDocument.push(this.displayData[index]);
        this.loadingSignatarios = false;
        return;
      }

      if (expand === true) {
        let newAnexo = [];
        this.loadingSignatarios = true;

        const anexoReturned = this.anexos.filter(
          (anx) => anx.idDocumento === doc.idPrivado,
        );
        for (const teste of anexoReturned) {
          const anexoSigReturned =
            await this.documentService.getSignatarioByIdDocumentoAnexo(
              teste.idPrivado,
            );

          newAnexo = anexoReturned.map((itm) => ({
            ...itm,
            checked: doc.checked ? true : false,
            expand: false,
            tipoDocumentoAnexo: `${itm.tipoDocumento.idPublico} - ${itm.tipoDocumento.descricao}`,
            anexoSignatarios: anexoSigReturned,
          }));
        }

        const pessoaReturned =
          await this.documentService.getSignatarioByIdDocumento(doc.idPrivado);

        const documentFormatted = Object.assign(doc, {
          documentoAnexo: newAnexo,
          signatarios: pessoaReturned,
        });
        this.loadingSignatarios = false;
        this.newDocument.push(documentFormatted);
      }
    } catch (error) {
      this.loadingSignatarios = false;
    }
  }

  checkDoc(doc, teste: any) {
    this.resetFabButtons();

    let anexoChecked = doc.documentoAnexo
      .filter((key) => key.idDocumento === doc.idPrivado)
      .map((itm) => ({
        ...itm,
        checked: teste,
      }));

    this.anexos.forEach((element, index) => {
      for (const anx of anexoChecked) {
        if (element.idPrivado === anx.idPrivado) {
          Object.assign(this.anexos[index], anx);
        }
      }
    });

    const docWthAnexo = this.displayData
      .filter((key) => key.idPrivado === doc.idPrivado)
      .map((itm) => ({
        ...itm,
        checked: teste,
        documentoAnexo: anexoChecked,
      }));

    this.displayData.forEach((element, index) => {
      if (element.idPrivado === docWthAnexo[0].idPrivado) {
        Object.assign(this.displayData[index], docWthAnexo[0]);
      }
    });

    if (
      !this.documentList.find(
        (registro) =>
          registro.checked === false &&
          registro.statusDocumento !== StatusDocumento.Assinado,
      ) ||
      (this.documentList.filter(
        (registro) =>
          registro.checked === false &&
          registro.statusDocumento !== StatusDocumento.Assinado,
      ).length === 1 &&
        this.checkedAll)
    ) {
      this.checkAllIndeterminate = !this.checkAllIndeterminate;
      this.checkedAll = !this.checkedAll;
    }
  }

  check(doc: IDocumento, check) {
    this.resetFabButtons();

    this.anexos.map((keys) => ({
      ...keys,
      checked: check,
    }));

    if (
      !doc.documentoAnexo.find(
        (registro) =>
          registro.checked === false &&
          registro.status !== StatusDocumento.Assinado,
      ) ||
      (doc.documentoAnexo.filter(
        (registro) =>
          registro.checked === false &&
          registro.status !== StatusDocumento.Assinado,
      ).length === 1 &&
        this.checkedAllAnexos)
    ) {
      this.checkAllAnexosIndeterminate = !this.checkAllAnexosIndeterminate;
      this.checkedAllAnexos = !this.checkedAllAnexos;
    }
  }

  showColorRatificado(anexoSignatario: IAnexoSignatarios[]): boolean {
    if (
      anexoSignatario.some(
        (key) => key.status === StatusDocumento.PendenteRatificacao,
      )
    ) {
      return true;
    }
    return false;
  }

  async updateDisplayDocs(idsDocument: number[]) {
    if (this.documentList.length === 0 || idsDocument?.length === 0) {
      return;
    }

    this.loading = true;
    const listDocs = this.documentList;
    this.documentList = [];
    this.displayData = [];

    const indexBeforeDocs: number[] = [];
    const newDocs: IDocumento[] = [];
    for (const id of idsDocument) {
      // const newDoc = await this.documentService.getDocumentById(id);

      indexBeforeDocs.push(listDocs.findIndex((doc) => doc.idPrivado === id));
      // newDocs.push(newDoc);

      // const newDoc = await this.documentService.getDocumentById(id);
      // const indexDoc = listDocs.findIndex((doc) => doc._id === id);

      // if (newDoc.statusDocumento === StatusDocumento.Pendente) {
      //   indexBeforeDocs.push(indexDoc);
      //   newDocs.push(newDoc);
      // }

      // if (
      //   newDoc.statusDocumento === StatusDocumento.Confirmado ||
      //   newDoc.statusDocumento === StatusDocumento.Desacordo
      // ) {
      //   listDocs.splice(indexDoc, 1);
      // }
    }

    newDocs.forEach((doc, index) => {
      this.documentService.formatColumnsDocForDysplay(
        doc,
        this.listSignatarios,
      );
      listDocs.splice(indexBeforeDocs[index], 1, doc);
    });

    setTimeout(() => {
      this.documentService
        .setDocumentsData(
          listDocs,
          this.filterType,
          this.documentList,
          this.newDocument,
          this.listSignatarios,
          this.assinanteLogado,
        )
        .then((result) => Object.assign(this, result));
      this.loading = false;
    }, 0);
  }

  getQtdSelecionados(): number {
    return this.documentList?.filter((doc) => doc.checked).length;
  }

  async reset() {
    await this.getDocumentsPerPeriod();
    await this.setConfigTable();
    await this.setConfigAnexoTable();
  }

  attDashboard = () => {
    this.reset();
    this.getDate();
  };

  progressBar(docAnexo: IDocumentoAnexo, documento: IDocumento) {
    const progressSigned = docAnexo.anexoSignatarios.filter(
      (key) => key.status === StatusDocumento.Assinado,
    );
    const percentage = Math.round(
      (100 * progressSigned.length) / documento.totalSignatarios,
    );
    return percentage;
  }

  getQuantidadeDeAssinantesProgressBar(
    docAnexo: IDocumentoAnexo,
    documento: IDocumento,
  ) {
    const status = docAnexo.status;

    const totalDeAssinaturas = docAnexo.anexoSignatarios?.filter(
      (anx) => anx.status === StatusDocumento.Assinado,
    ).length;
    if (
      [
        StatusDocumento.Cancelado,
        StatusDocumento.Expirado,
        StatusDocumento.Desacordo,
      ].includes(status)
    ) {
      return '';
    }

    return `${totalDeAssinaturas}/${documento.totalSignatarios}`;
  }

  downloadDocuments = async () => {
    this.loading = true;
    const docCheckeds = this.getQtdSelecionados();

    if (!docCheckeds && !this.documentList) {
      this.loading = false;
      return this.documentService.notification.info(
        'Download',
        'Selecione algum arquivo para realizar o download!',
      );
    }

    const doc = this.documentService.getDocsSelecionados(this.documentList);
    for (const documents of doc) {
      const typeDownload = TypeDocDownload[documents.statusDocumento];

      const event = await this.documentService.downloadDocWithUrlToUploadApi(
        await this.documentService.getUuidFile(typeDownload, documents),
        typeDownload,
        documents.tipoDocumento.tipo,
        documents.numeroDoc,
      );

      documents.eventos.push(event);
      this.loading = false;
    }
  };

  async updateAllChecked() {
    await this.documentService
      .updateAllAnexosChecked(
        this.documentList,
        this.checkedAll,
        this.checkAllIndeterminate,
        this.anexos,
      )
      .then((result) => {
        Object.assign(this, result);
      });
  }

  resetAllCheckbox(anexos: IDocumentoAnexo[]) {
    this.displayData.forEach((e) => {
      e.checked = false;
      e.documentoAnexo.forEach((a) => (a.checked = false));
    });

    this.allDocsChecks.forEach((b) => (b.checked = false));
    this.anexos.forEach((c) => (c.checked = false));

    // this.ratificarDoc = false;
    this.checkedAll = false;
    this.checkAllIndeterminate = true;
  }

  // getDocsToDataDocuments(): Array<IDocumento> {
  //   const documents = [];
  //   for (const docPag of this.dataDocuments) {
  //     docPag.documents.forEach((document) => {
  //       documents.push(document);
  //     });
  //   }
  //   return documents;
  // }

  ratificar = async () => {
    if (this.checkedAll === true && this.documentList.length > 9) {
      await this.getDocumentsPerPeriod(this.showDocsAvulsos, this.checkedAll);
      this.allDocsChecks = this.documentList.map((itm) => {
        return {
          ...itm,
          checked: true,
        };
      });
    }
    const documentList =
      this.allDocsChecks.length > 0 ? this.allDocsChecks : this.anexos;

    const confirmedDocs = documentList.filter((doc) => doc.checked);

    if (
      confirmedDocs.some(
        (itm) =>
          (itm.status === StatusDocumento.Pendente ||
            itm.status === StatusDocumento.Assinado ||
            itm.status === StatusDocumento.Desacordo ||
            itm.status === StatusDocumento.Cancelado) &&
          !itm.ratificado,
      )
    ) {
      return this.documentService.notification.info(
        'Ratificar documento',
        'Não foi possivel ratificar o documento, pois somente documentos com o status Confirmado podem ser enviados para ratificação!',
        { nzDuration: 7000 },
      );
    }
    const docCheckeds = this.documentService.getQtdSelecionados(documentList);

    if (docCheckeds === 0) {
      return this.documentService.notification.info(
        'Ratificar documento',
        'Não foi possivel ratificar o documento, pois não foi selecionado nenhum documento!',
        { nzDuration: 7000 },
      );
    }

    this.ratificarDoc = true;
    this.labelToaster = 'Ratificar';
    this.assinar();
  };

  assinar = async () => {
    this.listSignatariosToSign = [];
    let newDocs: any[];
    let docsChecks: any[];
    let arrDocsByAnexo: IDocumento[];
    let body = [];

    if (
      this.checkedAll &&
      (this.documentList.length > 8 || this.anexos.length > 8) &&
      this.ratificarDoc === false
    ) {
      await this.getDocumentsPerPeriod(this.showDocsAvulsos, this.checkedAll);
      docsChecks = this.anexos.map((itm) => {
        return {
          ...itm,
          checked: true,
        };
      });
    }

    const documentList = docsChecks ? docsChecks : this.anexos;

    const docCheckeds = documentList.filter((anx) => anx.checked).length;

    const confirmedDocs = documentList.filter((anx) => anx.checked);

    arrDocsByAnexo = this.displayData.filter((key) => {
      return confirmedDocs.some((itm) => {
        return key.idPrivado === itm.idDocumento;
      });
    });
    // const idsDocs = confirmedDocs.map((key) => {
    //   return key.idDocumento;
    // });
    // const docs = [...new Set(idsDocs)];

    // for (const id of docs) {
    //   const documento = await this.documentService.getDocumentById(id)
    //   this.documentSelectedByAnexo.push(documento);
    // }

    if (docCheckeds === 0) {
      return this.documentService.notification.info(
        'Assinar anexo',
        'Não foi possivel assinar o anexo, pois não foi selecionado nenhum anexo!',
        { nzDuration: 7000 },
      );
    }

    if (
      confirmedDocs.some((key) => key.ratificado === true) &&
      this.ratificarDoc === false
    ) {
      this.resetAllCheckbox(confirmedDocs);

      return this.documentService.notification.info(
        'Assinar documento',
        'Não foi possivel assinar o anexo, pois o anexo está em processo de ratificação, contudo apenas sendo possível ratificá-lo!', //to-do: melhorar a última parte da messagem
        { nzDuration: 7000 },
      );
    }

    this.loading = true;
    for (const ext of confirmedDocs) {
      this.listedAttachs = confirmedDocs.length > 0 ? true : false;

      if (
        ext.status === StatusDocumento.Confirmado &&
        this.ratificarDoc === false
      ) {
        this.loading = false;
        return this.documentService.notification.info(
          'Assinar anexo',
          'Não foi possivel assinar o anexo, pois o(s) anexo(s) selecionado(s) está(ão) com status Concluído!',
          { nzDuration: 7000 },
        );
      }

      if (ext.status === StatusDocumento.Cancelado) {
        this.loading = false;
        return this.documentService.notification.info(
          `${this.ratificarDoc === true ? this.labelToaster : 'Assinar'} anexo`,
          'Não foi possivel assinar o anexo, pois o(s) anexo(s) selecionado(s) está(ão) com status Cancelado!',
          { nzDuration: 7000 },
        );
      }
      const arrSigners =
        await this.documentService.getSignatarioByIdDocumentoAnexo(
          ext.idPrivado,
        );
      this.listSignatariosToSign.push(...arrSigners);
    }

    newDocs = await confirmedDocs.map((itm) => {
      const pessoa = this.listSignatariosToSign.filter(
        (key) => key.idDocumentoAnexo === itm.idPrivado,
      );
      return {
        ...itm,
        anexoSignatarios: (itm.anexoSignatarios = pessoa),
      };
    });

    arrDocsByAnexo.map(async (docs) => {
      const signersByIdDoc =
        await this.documentService.getSignatarioByIdDocumento(docs.idPrivado);
      const signers = signersByIdDoc.filter(
        (key) => key.idDocumento === docs.idPrivado,
      );
      return {
        ...docs,
        signatarios: (docs.signatarios = signers),
      };
    });

    for (const anexo of newDocs) {
      if (
        !anexo.anexoSignatarios.some(
          (item) =>
            item.cpf === this.documentService.authService.getCpfUser() ??
            item.cpfRepresentante ===
              this.documentService.authService.getCpfUser(),
        )
      ) {
        this.loading = false;
        return this.documentService.notification.info(
          `${this.ratificarDoc === true ? this.labelToaster : 'Assinar'} anexo`,
          `Não foi possivel assinar o anexo, pois o usuário logado não é signatário do(s) anexo(s)!`,
          { nzDuration: 7000 },
        );
      }

      if (this.ratificarDoc === false) {
        if (
          anexo.anexoSignatarios
            .filter(
              async (item) =>
                item.cpf === this.documentService.authService.getCpfUser() ??
                item.cpfRepresentante ===
                  this.documentService.authService.getCpfUser(),
            )
            .find(
              (stats) =>
                (stats.status === StatusDocumento.Desacordo ||
                  stats.status === StatusDocumento.Assinado) &&
                stats.cpf === this.documentService.authService.getCpfUser(),
            ) ||
          anexo.status === StatusDocumento.Desacordo ||
          anexo.status === StatusDocumento.Confirmado
        ) {
          const confirmed = anexo.anexoSignatarios
            .filter(
              (item) =>
                item.cpf === this.documentService.authService.getCpfUser() ??
                item.cpfRepresentante ===
                  this.documentService.authService.getCpfUser(),
            )
            .some((stats) => stats.status === StatusDocumento.Confirmado)
            ? 'signatário está com status Confirmado'
            : '';
          const signed = anexo.anexoSignatarios
            .filter(
              (item) =>
                item.cpf === this.documentService.authService.getCpfUser() ??
                item.cpfRepresentante ===
                  this.documentService.authService.getCpfUser(),
            )
            .some((stats) => stats.status === StatusDocumento.Assinado)
            ? 'signatário está com status Assinado'
            : '';
          const disagreed = anexo.anexoSignatarios
            .filter(
              (item) =>
                item.cpf === this.documentService.authService.getCpfUser() ??
                item.cpfRepresentante ===
                  this.documentService.authService.getCpfUser(),
            )
            .some((stats) => stats.status === StatusDocumento.Desacordo)
            ? 'signatário está com status Desacordo'
            : '';

          const docFailed = arrDocsByAnexo.find(
            (key) => key.idPrivado === anexo.idDocumento,
          );

          this.loading = false;

          return this.documentService.notification.info(
            'Assinar anexo',
            `Não foi possivel assinar o anexo <b>${anexo.nomeOriginal}</b> do documento <b>${docFailed.numeroDoc}</b>, pois o ${disagreed}${signed}${confirmed}!`,
            { nzDuration: 7000 },
          );
        }
      }
    }

    const searchCert = await this.getCertificados();

    if (searchCert) {
      return;
    }

    this.service.ratificacao.subscribe(async (data) => {
      this.ratificarTodos = data;
    });

    const title = `<i nz-icon nzType="warning" nzTheme="outline"> Confirma a ${
      this.ratificarDoc === true ? 'ratificação' : 'assinatura'
    } dos anexos selecionados?</i>`;
    const okText = 'Sim';
    const cancelText = 'Não';
    const onOk = async (componentInstance) => {
      // if (
      //   confirmedDocs.some((key) => key.ratificado === true) &&
      //   this.ratificarDoc === true
      // ) {
      //   this.ratificarTodos = false;
      // }

      if (this.ratificarDoc === false) {
        this.ratificarTodos = false;
      }

      const newComponent = componentInstance.certByDocumentForSign.map((a) => {
        return {
          ...a,
          passwordCert: this.documentService.encryptUsingAES256(a.passwordCert),
        };
      });

      modal.close();
      // this.service.loadingEvent(true);
      // this.service.sendLabelTip(
      //   'Aguarde o processamento da fila de assinatura em lote ser executada...',
      // );
      // this.formB.controls.assinarAnexos.setValue(value);
      const idsDocsParaAssinar: number[] = newComponent.map((row) => row.idDoc);

      const dateUser = new Date().toLocaleString();

      if (!componentInstance.password) {
        return this.service.notification.warning(
          'Formulário',
          'Por favor, preencha o campo com a senha do certificado digital!',
        );
      }

      if (
        this.ratificarDoc === true &&
        !componentInstance.certByDocumentForSign[0].motivo
      ) {
        return this.service.notification.warning(
          'Formulário',
          'Por favor, preencha o campo Motivo com o motivo da ratificação!',
        );
      }

      // for (const a of newComponent) {
      //   body.push({ idPrivado: a.idDoc });
      // }

      await this.documentService
        .assinar(
          newComponent,
          dateUser,
          this.ratificarDoc === true ? false : true,
          this.paramsWhere,
          this.checkedAll,
          this.checkedAll ? this.pageTotal : this.docsChecked.length,
          this.ratificarTodos,
          this.ratificarDoc === true ? false : true,
          this.ratificarDoc,
          false,
        )
        .then(async () => {
          this.service.loadingEvent(false);
          this.getDocumentsPerPeriod();
          this.updateDisplayDocs(idsDocsParaAssinar);
          this.docsChecked = [];
          this.ratificarDoc = false;
          modal.close();
        })
        .catch((err) => {
          this.documentsPendingService.notification.error(
            `${
              this.ratificarDoc === true ? this.labelToaster : 'Assinar'
            } documento`,
            err,
          );
          this.resetAllCheckbox(confirmedDocs);
          this.service.loadingEvent(false);
          modal.close();
          this.ratificarDoc = false;
          this.loading = false;
        });
    };

    const modal = this.modal.create({
      nzTitle: title,
      nzWidth: 850,
      nzContent: ModalDialogComponent,
      nzFooter: [
        { label: cancelText, onClick: () => modal.close() },
        {
          label: okText,
          type: 'primary',
          disabled: this.ratificarDoc
            ? (componentInstance) =>
                !componentInstance.certByDocumentForSign[0].motivo
            : false,
          onClick: (componentInstance) => onOk(componentInstance),
        },
      ],
      nzComponentParams: {
        valorSelecionado: this.documentService.getValorSelecionado(newDocs),
        qtdSelecionados: newDocs.length,
        docsSelecionados: arrDocsByAnexo,
        assinanteLogado: this.certificadoAssinante,
        signatarios: this.listSignatarios,
        attachToSign: false,
        allChecked: this.checkedAll,
        docsChecked: this.pageTotal,
        labelAnexo: this.ratificarDoc === true ? 'Ratificar' : 'Assinar',
        ratificarAnexo: this.ratificarDoc,
        ratificarDoc: false,
      },
    });

    modal.afterClose.subscribe((resultClose: any) => {
      if (!resultClose) {
        modal.destroy();
        this.ratificarDoc = false;
        this.loading = false;
      }
      this.resetAllCheckbox(confirmedDocs);
    });
  };

  discordar = async () => {
    this.listSignatariosToSign = [];
    let newDocs: any[];
    // let docsChecks: IDocumento[];

    // if (this.checkedAll && this.documentList.length > 9) {
    //   await this.getDocumentsPerPeriod(this.showDocsAvulsos, this.checkedAll);
    //   docsChecks = this.documentList.map((itm) => {
    //     return {
    //       ...itm,
    //       checked: true,
    //     };
    //   });
    // }
    // const documentList = docsChecks ? docsChecks : this.docsChecked;

    const docCheckeds = this.anexos.filter((anx) => anx.checked).length;

    const confirmedDocs = this.anexos.filter((doc) => doc.checked);

    this.loading = true;

    this.displayData.filter((key) => {
      return confirmedDocs.some((itm) => {
        return key.idPrivado === itm.idDocumento;
      });
    });

    if (docCheckeds === 0) {
      return this.documentService.notification.info(
        'Discordar',
        'Não foi possivel discordar do documento, pois não foi selecionado nenhum documento!',
        { nzDuration: 7000 },
      );
    }

    for (const ext of confirmedDocs) {
      if (ext.status === StatusDocumento.Confirmado) {
        this.loading = false;

        return this.documentService.notification.info(
          'Discordar documento',
          'Não foi possivel discordar o documento, pois o(s) documento(s) selecionado(s) está(ão) com status Concluído!',
          { nzDuration: 7000 },
        );
      }

      if (ext.status === StatusDocumento.Cancelado) {
        this.loading = false;

        return this.documentService.notification.info(
          'Discordar documento',
          'Não foi possivel discordar o documento, pois o(s) documento(s) selecionado(s) está(ão) com status Cancelado!',
          { nzDuration: 7000 },
        );
      }
      const arraySigners =
        await this.documentService.getSignatarioByIdDocumentoAnexo(
          ext.idPrivado,
        );

      this.listSignatariosToSign.push(...arraySigners);
    }

    await confirmedDocs.map((itm) => {
      const pessoa = this.listSignatariosToSign.filter(
        (key) => key.idDocumentoAnexo === itm.idPrivado,
      );
      return {
        ...itm,
        anexoSignatarios: (itm.anexoSignatarios = pessoa),
      };
    });

    for (const docs of confirmedDocs) {
      if (
        !docs.anexoSignatarios.some(
          (item) =>
            item.cpf === this.documentService.authService.getCpfUser() ??
            item.cpfRepresentante ===
              this.documentService.authService.getCpfUser(),
        )
      ) {
        this.loading = false;

        return this.documentService.notification.info(
          'Discordar documento',
          'Não foi possivel discordar do documento, pois o usuário logado não é signatário desse documento!',
          { nzDuration: 7000 },
        );
      }

      if (
        docs.anexoSignatarios
          .filter(
            (item) =>
              item.cpf === this.documentService.authService.getCpfUser(),
          )
          .some(
            (stats) =>
              stats.status === StatusDocumento.Desacordo ||
              stats.status === StatusDocumento.Assinado,
          ) ||
        docs.status === StatusDocumento.Desacordo ||
        docs.status === StatusDocumento.Confirmado ||
        this.documentService.docIsExpirado(docs.dataLimiteAssinatura)
      ) {
        const disagreed = docs.anexoSignatarios.some(
          (stats) => stats.status === StatusDocumento.Desacordo,
        )
          ? 'signatário está com status Desacordo'
          : '';
        const signed = docs.anexoSignatarios.some(
          (stats) => stats.status === StatusDocumento.Assinado,
        )
          ? 'signatário está com status Assinado'
          : '';
        const expired = this.documentService.docIsExpirado(
          docs.documento.dataLimiteAssinatura,
        )
          ? 'documento está Expirado'
          : '';
        this.loading = false;

        return this.documentService.notification.info(
          'Discordar documento',
          `Não foi possivel discordar do documento, pois o ${disagreed}${signed}${expired}!`,
          { nzDuration: 7000 },
        );
      }
    }

    this.service.assinarAnexo.subscribe(async (data) => {
      this.assinarAnexos = data;
    });

    const title = `<i nz-icon nzType="warning" nzTheme="outline"> Confirma o desacordo do documento selecionado?</i>`;
    const okText = 'Sim';
    const cancelText = 'Não';
    const onOk = async (motivo) => {
      const idsDocsParaAssinar: number[] = confirmedDocs.map(
        (doc) => doc.idPrivado,
      );
      await this.documentService
        .discordarAnexo(idsDocsParaAssinar, motivo)
        .then(async () => {
          this.getDocumentsPerPeriod();
          this.updateDisplayDocs(idsDocsParaAssinar);
          modal.close();
          this.loading = false;
        })
        .catch(() =>
          this.documentsPendingService.notification.error(
            'Discordar documento',
            'Error ao discordar documento',
          ),
        );
    };

    const modal = this.modal.create({
      nzTitle: title,
      nzContent: ModalDialogComponent,
      nzFooter: [
        {
          label: cancelText,
          onClick: () => {
            modal.close();
            this.loading = false;
          },
        },
        {
          label: okText,
          disabled: (componentInstance) => !componentInstance.motivo,
          type: 'primary',
          onClick: (componentInstance) => onOk(componentInstance.motivo),
        },
      ],
      nzComponentParams: {
        valorSelecionado:
          this.documentService.getValorSelecionado(confirmedDocs),
        qtdSelecionados: confirmedDocs.length,
        existMotivo: true,
        attachToSign: this.listedAttachs,
        labelAnexo: 'Discordar',
      },
    });

    modal.afterClose.subscribe((resultClose: any) => {
      if (!resultClose) {
        modal.destroy();
        this.loading = false;
      }
    });
  };

  desfazer = async () => {
    this.listSignatariosToSign = [];
    const docCheckeds = this.anexos.filter((anx) => anx.checked).length;

    const confirmedDocs = this.anexos.filter((doc) => doc.checked);

    if (docCheckeds === 0) {
      return this.documentService.notification.info(
        'Desfazer desacordo',
        'Não foi possivel desfazer a discordância do documento, pois não foi selecionado nenhum documento!',
        { nzDuration: 7000 },
      );
    }
    this.loading = true;

    for (const ext of confirmedDocs) {
      const arrSigners =
        await this.documentService.getSignatarioByIdDocumentoAnexo(
          ext.idPrivado,
        );
      this.listSignatariosToSign.push(...arrSigners);
    }

    await confirmedDocs.map((itm) => {
      const pessoa = this.listSignatariosToSign.filter(
        (key) => key.idDocumentoAnexo === itm.idPrivado,
      );
      return {
        ...itm,
        anexoSignatarios: (itm.anexoSignatarios = pessoa),
      };
    });

    for (const docs of confirmedDocs) {
      if (
        !docs.anexoSignatarios.some(
          (item) =>
            item.cpf === this.documentService.authService.getCpfUser() ??
            item.cpfRepresentante ===
              this.documentService.authService.getCpfUser(),
        )
      ) {
        this.loading = false;

        return this.documentService.notification.info(
          'Desfazer desacordo',
          'Não foi possivel desfazer do documento, pois o usuário logado não é signatário desse documento!',
          { nzDuration: 7000 },
        );
      }

      if (docs.statusDocumento === StatusDocumento.Cancelado) {
        this.loading = false;

        return this.documentService.notification.info(
          'Desfazer desacordo',
          'Não foi possivel desfazer o documento, pois o documento selecionado está(ão) com status Cancelado!',
          { nzDuration: 7000 },
        );
      }

      if (docs.statusDocumento === StatusDocumento.Confirmado) {
        this.loading = false;

        return this.documentService.notification.info(
          'Desfazer desacordo',
          'Não foi possivel desfazer a discordância do documento, pois o documento selecionado está(ão) com status Concluído!',
          { nzDuration: 7000 },
        );
      }

      const signed = docs.anexoSignatarios
        .filter(
          (item) => item.cpf === this.documentService.authService.getCpfUser(),
        )
        .some((stats) => stats.status === StatusDocumento.Assinado)
        ? 'Assinado'
        : '';

      const pending = docs.anexoSignatarios
        .filter(
          (item) => item.cpf === this.documentService.authService.getCpfUser(),
        )
        .some((stats) => stats.status === StatusDocumento.Pendente)
        ? 'Pendente'
        : '';
      if (pending || signed) {
        this.loading = false;

        return this.documentService.notification.info(
          'Desfazer desacordo',
          `Não foi possivel desfazer a discordância do documento, pois o signatário está com status ${signed}${pending}!`,
          { nzDuration: 7000 },
        );
      }
    }

    this.service.assinarAnexo.subscribe(async (data) => {
      this.assinarAnexos = data;
    });

    const title = `<i nz-icon nzType="warning" nzTheme="outline"> Confirma o desfazer da discordância do documento selecionado?</i>`;
    const okText = 'Sim';
    const cancelText = 'Não';
    const onOk = async (motivo) => {
      const idsDocsParaAssinar: number[] = confirmedDocs.map(
        (doc) => doc.idPrivado,
      );

      await this.documentService
        .desfazerAnexo(idsDocsParaAssinar, motivo)
        .then(async () => {
          this.getDocumentsPerPeriod();
          this.updateDisplayDocs(idsDocsParaAssinar);
          modal.close();
          this.loading = false;
        })
        .catch(() =>
          this.documentService.notification.error(
            'Desfazer desacordo',
            'Error ao desfazer desacordo',
          ),
        );
    };

    const modal = this.modal.create({
      nzTitle: title,
      nzContent: ModalDialogComponent,
      nzFooter: [
        {
          label: cancelText,
          onClick: () => {
            modal.close();
            this.loading = false;
          },
        },
        {
          label: okText,
          disabled: (componentInstance) => !componentInstance.motivo,
          type: 'primary',
          onClick: (componentInstance) => onOk(componentInstance.motivo),
        },
      ],
      nzComponentParams: {
        valorSelecionado:
          this.documentService.getValorSelecionado(confirmedDocs),
        qtdSelecionados: confirmedDocs.length,
        existMotivo: true,
        attachToSign: this.listedAttachs,
        labelAnexo: 'Desfazer desacordo',
      },
    });

    modal.afterClose.subscribe((resultClose: any) => {
      if (!resultClose) {
        modal.destroy();
        this.loading = false;
      }
    });
  };

  resetarConsulta() {
    this.checkList = {
      tipoDoc: true,
      numeroDoc: true,
      status: true,
      chancela: true,
      nomeUnidadeOrc: true,
      nomeFav: true,
      tipoDocumento: true,
      valorDoc: true,
      numProcesso: true,
      dataDocumentoFormated: true,
      dataLimiteAssinatura: true,
      aplicacaoOrigem: true,
      numeroLicitacao: true,
      modalidadeLicitacao: true,
      numeroObra: true,
      numeroContrato: true,
      numeroEvento: true,
      numeroConvenio: true,
      numeroProgramaInstitucional: true,
      usuarioCriacao: true,
      acoes: true,
      // anexoSignatarios: true,
    };
  }

  async getCertificados() {
    // await this.assinantesService
    //   .getCertificadosAssinanteLogado()
    //   .then((result) => {
    //     this.certificadoAssinante = result;
    //
    //   })
    //   .catch(() => (this.hasCertificado = false));

    return await this.assinantesService
      .getCertificadosAssinanteLogado()
      .then((res: any) => {
        if (res.every((cert) => new Date(cert.dtFinalCert) < new Date())) {
          this.documentService.notification.error(
            'Certificado',
            `Renove a validade do seu certificado digital!`,
          );
          this.documentService.tabSessionService.addTab({
            name: 'Meus Certificados',
            url: '/session/meus-certificados',
          });

          return this.router.navigate(['/session/meus-certificados']);
          // return true
          // return (this.hasCertificado = !!res?.length);
        }

        if (res.every((cert) => cert.status === StatusCertificado.Deletado)) {
          this.documentService.notification.info(
            'Certificado',
            `Carregue um certificado digital válido!`,
          );
          this.documentService.tabSessionService.addTab({
            name: 'Meus Certificados',
            url: '/session/meus-certificados',
          });
          return this.router.navigate(['/session/meus-certificados']);
          // return true
          // return (this.hasCertificado = !!res?.length);
        }

        if (res.length === 0) {
          this.authService.createModalTermoAceite();
          return true;
          //   // this.certificadoInvalido = true;
          //   // this.certificadoLabel = 'Carregue um Certificado';
          //   this.notification.info(
          //     'Certificado',
          //     `Carregue um certificado digital válido!`,
          //   );
        }
        this.certificadoAssinante = res;
        return false;
      })
      .catch((err) => {
        return this.documentService.notification.error('Certificado', `${err}`);
      });
  }
}
