import { StatusMensagem } from './../shared/enum/mensagem.enum';
import { DocumentService } from './../shared/services/document.service';
import { ColorStatusMensagemEnvio } from './../shared/consts/mensagem';
import { ActivatedRoute } from '@angular/router';
import { IColumnsFieldsProps } from './../shared/interfaces/shared.interface';
import { IMensagemEnvio } from './../shared/interfaces/mensagem-interface';
import { ModalDialogComponent } from './../modal-dialog/modal-dialog.component';
import { IMenuButton } from './../../@lib/components/speed-dial-fab/speed-dial-fab.component';
import { MensagensEnvioService } from './../shared/services/mensagens-envio.service';
import { MensagensComponent } from './../mensagens/mensagens.component';
import { NzModalService } from 'ng-zorro-antd/modal';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Component, OnInit } from '@angular/core';
import moment from 'moment';

@Component({
  selector: 'app-mensagens-envio',
  templateUrl: './mensagens-envio.component.html',
  styleUrls: ['./mensagens-envio.component.scss'],
})
export class MensagensEnvioComponent implements OnInit {
  tabIndex: 0 | 1 = 0;
  filterType: Array<1 | 2 | 3> = [];
  loadingPage: boolean = false;
  loadingTable: boolean = false;

  innerWidth;

  // loading: boolean;
  formB: FormGroup = new FormGroup({});
  suggestions: Array<string> = [];
  assinantesSelected = [];
  assinanteSelected: any;
  assinanteSelectedMensagemEnvio: any;
  loadingDestinatarios: boolean = false;
  // fieldsSelected = [];
  fabButtons: IMenuButton[];
  signatariosSelected = [];
  showComboTags: boolean = false;
  displayData = [];
  pageTotal: Number;
  identifyer: boolean = false;

  fieldMensagensEnvioSelected;
  fieldsMensagensEnvioSelected = [];

  selectedMensagensEnvioValues;
  showComboTagsMensagensEnvio;

  checkAllIndeterminate: boolean = false;
  checkedAll;

  listSignatarios;
  listSignatariosMensagemEnvio;
  tableMensagemEnvioColumns;
  searchInput: any;
  arrColumnsMensagensEnvioSelect = [];
  tableColumns: IColumnsFieldsProps[] = [];
  sort = {
    nomPessoa: (b, a) => a.nomPessoa.localeCompare(b.nomPessoa),
    status: (a, b) => a.status.localeCompare(b.status),
    emailPricipal: (b, a) => a.emailPricipal.localeCompare(b.emailPricipal),
    telefone: (b, a) => a.telefone.localeCompare(b.telefone),
    dtEnvio: (a, b) => a.status.localeCompare(b.dtEnvio),
  };
  checkList = {
    idPublico: true,
    status: true,
    titulo: true,
    texto: true,
    dtCriacao: true,
    dtAlteracao: true,
    dtEnvio: true,
    dtProgramado: true,
  };

  tooltipVisible: object = {
    1: false,
    2: false,
    3: false,
    4: false,
  };

  constructor(
    private formBuilder: FormBuilder,
    private modalService: NzModalService,
    private readonly service: MensagensEnvioService,
    private readonly documentService: DocumentService,
    private activateRouter: ActivatedRoute,
  ) {
    // this.loadingPage = true;
    this.activateRouter.queryParamMap.subscribe(async (queryParams) => {
      // await this.setConfigMensagemEnvioFilter();
    });
  }

  phoneMask = (value) => {
    if (!value) return '';
    value = value.replace(/\D/g, '');
    value = value.replace(/(\d{2})(\d)/, '($1) $2');
    value = value.replace(/(\d)(\d{4})$/, '$1-$2');
    return value;
  };

  setFilterType(type: 1 | 2 | 3 | 4) {
    this.filterType = this.service.updateFilterType(this.filterType, type);
    if (this.innerWidth <= 748) {
      this.tooltipVisible[`${type}`] = true;
      setTimeout(() => {
        this.tooltipVisible[`${type}`] = false;
      }, 2000);
    }
  }

  async populateSignatarios() {
    this.loadingPage = true;
    const signatarios = await (
      await this.service.getSignatarios()
    )
      .filter((usuario) => usuario.cpf && usuario.telefone)
      .sort((a, b) => {
        if (a.nome < b.nome) {
          return -1;
        }
        if (a.nome > b.nome) {
          return 1;
        }
        return 0;
      });

    this.listSignatarios = signatarios;

    this.loadingPage = false;
  }

  async populateSignatariosMensagemEnvio() {
    this.loadingPage = true;

    this.listSignatariosMensagemEnvio = await (
      await this.service.getSignatarios()
    )
      .filter((usuario) => usuario.cpf && usuario.telefone)
      .sort((a, b) => {
        if (a.nome < b.nome) {
          return -1;
        }
        if (a.nome > b.nome) {
          return 1;
        }
        return 0;
      });
    this.loadingPage = false;
  }

  addFiltroPorAssinante(arrAssinantes: any = []) {
    this.listSignatarios = this.listSignatarios.filter(
      (signatario) => !(signatario.idPrivado === arrAssinantes.idPrivado),
    );
    this.signatariosSelected.push(arrAssinantes);
    if (arrAssinantes.length > 0) {
      for (const assinante of arrAssinantes) {
        this.signatariosSelected.push(assinante);

        this.signatariosSelected.push({
          props: 'assinante',
          field: 'Assinante',
          value: assinante.cpf,
          text: `${assinante.cpf} - ${assinante.nome}`,
        });
      }
      this.showComboTags = true;
    }
    setTimeout(() => (this.assinanteSelected = null), 0);
  }

  addFiltroPorAssinanteMensagensEnvio(arrAssinantes: any = []) {
    this.listSignatariosMensagemEnvio =
      this.listSignatariosMensagemEnvio.filter(
        (signatario) => !(signatario.idPrivado === arrAssinantes.idPrivado),
      );
    //to-do: passar idPessoaFisica,IdpessoaJuridica, idPrivada
    this.fieldsMensagensEnvioSelected.push({
      props: 'destinatario',
      field: 'Destinatario',
      value: arrAssinantes.idPrivado,
      text: `${arrAssinantes.cpf} - ${arrAssinantes.nome}`,
    });
    if (arrAssinantes.length > 0) {
      for (const assinante of arrAssinantes) {
        // this.fieldsMensagensEnvioSelected.push(assinante);

        this.fieldsMensagensEnvioSelected.push({
          props: 'destinatario',
          field: 'Destinatario',
          value: assinante.idPrivado,
          text: `${assinante.cpf} - ${assinante.nome}`,
        });
      }
    }
    setTimeout(() => (this.assinanteSelectedMensagemEnvio = null), 0);
    this.showComboTagsMensagensEnvio = true;
    // this.fieldsMensagensEnvioSelected;
  }

  removeFiltroPorField(signatarioValuesTag, index) {
    this.listSignatarios.push(signatarioValuesTag);
    this.signatariosSelected = this.signatariosSelected.filter(
      (signatario) => !(signatario.idPrivado === signatarioValuesTag.idPrivado),
    );
  }

  resetForm() {
    this.formB = this.formBuilder.group({
      idPrivado: [{ value: null, disabled: true }],
      idModelo: [{ value: null, disabled: true }],
      idPublico: [{ value: null, disabled: true }],
      titulo: [{ value: null, disabled: false }, [Validators.required]],
      texto: [{ value: null, disabled: false }, [Validators.required]],
    });
  }

  showModalTipoMensagem(event) {
    const tipoDocumentoModal = this.modalService.create({
      nzTitle: 'Selecione um tipo de documento',
      nzContent: MensagensComponent,
      nzComponentParams: {
        openModal: true,
        tabIndex: 1,
      },
      nzFooter: null,
      nzWidth: 1000,
    });
    tipoDocumentoModal.afterClose.subscribe((result) => {
      // this.setDataTipoDocAnexo(result);
      this.formB.patchValue(result);
    });
  }

  async changeTabIndex(value, search = true) {
    this.tabIndex = value;
    /*usado para carregar os destinatarios da parte de consulta
    apenas quando o usuário clicar em consulta*/

    // if (value === 1 && !this.listSignatariosMensagemEnvio) {
    //   await this.populateSignatariosMensagemEnvio();
    // }
    this.resetFabButtons();
    if (this.tabIndex === 1 && search === true) {
      return;
    }
  }

  async ngOnInit(): Promise<void> {
    this.tabIndex = 0;
    this.resetForm();
    this.resetFabButtons();

    this.suggestions = [
      'NOME_DO_DESTINATARIO',
      'CIDADE_DO_DESTINATARIO',
      'NÚMERO_DO_DESTINATARIO',
      'CARGO_DO_DESTINATARIO',
      'EMAIL_DO_DESTINATARIO',
    ];
    await this.setConfigMensagemEnvioTable();
    await this.populateSignatariosMensagemEnvio();
    await this.populateSignatarios();
  }

  buildFabButtons(fabButtons: IMenuButton[]) {
    this.fabButtons = fabButtons;
  }

  resetFabButtons() {
    const fabButtons: IMenuButton[] = [
      {
        icon: 'plus',
        tooltip: 'novo cadastro',
        condition: this.tabIndex === 1 || this.tabIndex === 0,
        onClick: this.novoCadastro,
      },
      {
        icon: 'send',
        tooltip: 'Enviar ou reenviar',
        color: 'green',
        condition: this.tabIndex === 1,
        onClick: this.enviar,
      },
      {
        icon: 'clock-circle',
        tooltip: 'programar',
        color: 'blue',
        condition: false,
        onClick: this.programar,
      },
      {
        icon: 'save',
        tooltip: 'Salvar',
        color: 'green',
        condition: this.tabIndex === 0,
        onClick: this.salvar,
      },
      {
        icon: 'edit',
        tooltip: 'Editar',
        color: 'yellow',
        condition: this.tabIndex === 1,
        onClick: this.editar,
      },
      {
        icon: 'delete',
        tooltip: 'Deletar',
        color: 'red',
        condition: this.tabIndex === 1,
        onClick: this.deletar,
      },
      {
        icon: 'search',
        tooltip: 'Consultar',
        condition: this.tabIndex === 0,
        onClick: this.consultar,
      },
      {
        icon: 'reload',
        tooltip: 'Atualizar',
        // color: 'blue',
        condition: true,
        onClick: this.attMensagensEnvio,
      },
    ];

    const fabButtonsFiltered = fabButtons.filter((button) => button.condition);

    this.buildFabButtons(fabButtonsFiltered);
  }

  novoCadastro = async () => {
    this.resetForm();
    await this.populateSignatarios();
    this.signatariosSelected = [];
    this.changeTabIndex(0);
  };

  enviar = async () => {
    const mensagens = this.mensagensChecadas();
    let mensagem = mensagens[0];
    if (mensagens.length !== 1) {
      return this.service.notification.warning(
        'Envio de Mensagens',
        'Não foi possível enviar a mensagem pois exitem muitas ou nenhuma selecionada',
      );
    }

    this.loadingPage = true;
    const destinatarios: any = await this.service.getDestinatariosByMensagem(
      mensagem,
    );
    this.loadingPage = false;

    const listdestinatarios = [];
    for (const destinatario of destinatarios) {
      listdestinatarios.push({
        ...this.listSignatarios.find((signatariosMensagemEnvio) => {
          return signatariosMensagemEnvio.cpf === destinatario.cpf;
        }),
        status: StatusMensagem[destinatario.status],
      });
    }
    const mensagemEnvio = { ...mensagem, destinatarios: listdestinatarios };

    const textTitle =
      mensagemEnvio.status === StatusMensagem[2]
        ? 'Confirma o reenvio da mensagem?'
        : 'Confirma o envio da mensagem?';


    const isAllEnviadas = destinatarios.every(
      (destinatario) => destinatario.status == StatusMensagem[2],
    );


    const qntdStatus =
      mensagemEnvio.qntdDestinatarios ===
      mensagemEnvio.qntdDestinatariosEnviado;
    const title = `<i nz-icon nzType="warning" nzTheme="outline">${textTitle}</i>`;
    const okText = `${isAllEnviadas ? 'Reenviar' : 'Enviar os não enviados'} `;
    const okText2 = `Enviar para todos`;
    const cancelText = 'Não';

    if (
      !qntdStatus &&
      (mensagemEnvio.status === StatusMensagem[1] ||
        mensagemEnvio.status === StatusMensagem[2] ||
        mensagemEnvio.status === StatusMensagem[4])
    ) {
      const senderText = {
        ['Enviada']: 'reenviar',
        ['Não enviada']: 'efetuar nova tentativa de enviar',
        ['Gravada']: 'enviar',
      };
      return this.modalService.confirm({
        nzTitle: `<i>Deseja ${senderText[mensagemEnvio.status]
          } a mensagem?</i>`,
        nzOnOk: () => {
          this.service.enviarMensagem(mensagemEnvio);
        },
      });
    }
    let mapDestinatarioStatus = destinatarios.map(
      (destinatario) => destinatario.status,
    );

    if (mapDestinatarioStatus.includes(StatusMensagem[4])) {
      const modal = this.modalService.create({
        nzTitle: title,
        nzWidth: 850,
        nzContent: ModalDialogComponent,
        nzFooter: [
          { label: cancelText, onClick: () => modal.close() },
          {
            label: okText2,
            onClick: (componentInstance) => enviarMensagem(componentInstance),
            disabled: (componentInstance) =>
              this.disableButton(componentInstance),
          },
          {
            label: okText,
            type: 'primary',
            onClick: (componentInstance) =>
              enviarMensagemNaoEnviadas(componentInstance),
          },
        ],

        nzComponentParams: {
          mensagemEnvio,
        },
      });
      const enviarMensagem = async (componentInstace) => {
        this.service.enviarMensagem(mensagemEnvio);
        return modal.close();
      };
      const enviarMensagemNaoEnviadas = async (componentInstace) => {
        this.service.enviarMensagemNaoEnviadas(mensagemEnvio);
        return modal.close();
      };
    }
  };

  programar = () => { };

  salvar = () => {
    const destinatarios = this.signatariosSelected;

    if (destinatarios && destinatarios.length < 1) {
      return this.service.notification.warning(
        'Envio de mensagens',
        'Selecione pelo menos um destinatário.',
      );
    }

    if (this.formB.invalid) {
      return this.service.notification.warning(
        'Envio de mensagens',
        'Por favor, preencha todos os campos corretamente.',
      );
    }
    const mensagem: IMensagemEnvio = {
      idPublico: this.formB.controls.idPublico.value,
      idPrivado: this.formB.controls.idPrivado.value,
      idModelo: this.formB.controls.idModelo.value,
      mensagem: { ...this.formB.value },
      destinatarios,
    };

    const textTitle = mensagem.idPublico
      ? 'Confirma as alterações na mensagem'
      : 'Confirma a mensagem a ser gravada';

    const title = `<i nz-icon nzType="warning" nzTheme="outline"> ${textTitle}?</i>`;
    const okText = 'Sim';
    const okText2 = `Sim e enviar`;
    const cancelText = 'Não';

    const onOk = async (componentInstance, enviar = false) => {
      if (mensagem.idPrivado) {
        this.service.editarMensagem(mensagem.idPrivado, mensagem, enviar);
        modal.close();
        return this.novoCadastro();
      }
      this.service.salvarMensagem(mensagem, enviar);
      modal.close();
      return this.novoCadastro();
    };

    const modal = this.modalService.create({
      nzTitle: title,
      nzWidth: 850,
      nzContent: ModalDialogComponent,
      nzFooter: [
        { label: cancelText, onClick: () => modal.close() },
        {
          label: okText2,
          onClick: (componentInstance) => onOk(componentInstance, true),
          disabled: (componentInstance) =>
            this.disableButton(componentInstance),
        },
        {
          label: okText,
          type: 'primary',
          onClick: (componentInstance) => onOk(componentInstance),
        },
      ],
      nzComponentParams: {
        mensagem,
      },
    });

    modal.afterClose.subscribe((resultClose: any) => {
      if (!resultClose) {
        modal.destroy();
      }
    });
  };

  async pageChange(valor) {
    if (!this.identifyer) {
      await this.getMensagensEnvioPerPeriod(
        this.fieldsMensagensEnvioSelected,
        Number(valor),
        10,
      );
    }
  }

  disableButton = (componentInstance) => {
    return false;
  };
  editar = async () => {
    const mensagens = this.mensagensChecadas();
    const mensagem = mensagens[0];
    if (mensagens.length !== 1) {
      return this.service.notification.error(
        'Envio de Mensagens',
        'Não foi possível editar a mensagem pois exitem muitas ou nenhuma selecionada',
      );
    }

    if (mensagem.status === StatusMensagem[StatusMensagem.Enviada]) {
      return this.service.notification.error(
        'Envio de Mensagens',
        'Não é permitido editar mensagem já enviada!',
      );
    }
    this.loadingPage = true;
    this.resetForm();
    await this.populateSignatarios();
    this.signatariosSelected = [];

    const destinatarios: any = await this.service.getDestinatariosByMensagem(
      mensagem,
    );

    const signatarios = this.listSignatarios;

    for (const destinatario of destinatarios) {
      this.listSignatarios = this.listSignatarios.filter(
        (signatariosMensagemEnvio) => {
          return signatariosMensagemEnvio.cpf !== destinatario.cpf;
        },
      );
      this.signatariosSelected.push(
        signatarios.find((signatario) => {
          return signatario.cpf === destinatario.cpf;
        }),
      );
    }

    const { destinatarios: destinatarioLista, ...mensagems } = mensagem;

    this.formB.patchValue({ ...mensagems });
    this.changeTabIndex(0);
    this.loadingPage = false;
  };

  async editarMensagemEnvio(MensagemEnvio) {
    const mensagem = MensagemEnvio;
    if (mensagem.status === StatusMensagem[StatusMensagem.Enviada]) {
      return this.service.notification.error(
        'Envio de Mensagens',
        'Não é permitido editar mensagem já enviada!',
      );
    }
    this.loadingPage = true;
    this.resetForm();
    await this.populateSignatarios();
    this.signatariosSelected = [];

    const destinatarios: any = await this.service.getDestinatariosByMensagem(
      mensagem,
    );

    const signatarios = this.listSignatarios;

    for (const destinatario of destinatarios) {
      this.listSignatarios = this.listSignatarios.filter(
        (signatariosMensagemEnvio) => {
          return signatariosMensagemEnvio.cpf !== destinatario.cpf;
        },
      );
      this.signatariosSelected.push(
        signatarios.find((signatario) => {
          return signatario.cpf === destinatario.cpf;
        }),
      );
    }

    const { destinatarios: destinatarioLista, ...mensagems } = mensagem;

    this.changeTabIndex(0);
    this.formB.patchValue({ ...mensagems });
    this.loadingPage = false;
  }

  deletar = () => {
    const mensagens = this.mensagensChecadas();
    const mensagem = mensagens[0];
    if (mensagens.length !== 1) {
      return this.service.notification.error(
        'Envio de Mensagens',
        'Não foi possível deletar a mensagem pois exitem muitas ou nenhuma selecionada',
      );
    }

    this.modalService.confirm({
      nzTitle: '<i>Deseja excluir a mensagem?</i>',
      nzOnOk: () => {
        this.service.deletarMensagem(mensagem);
        this.displayData = this.displayData.filter(
          (msg) => !(msg.idPublico === mensagem.idPublico),
        );
      },
    });
  };

  mensagensChecadas() {
    return this.displayData.filter((data) => data.checked);
  }

  consultar = () => {
    this.changeTabIndex(1);
  };

  attMensagensEnvio = () => {
    this.changeTabIndex(1);
    this.filterMensagensEnvio();
  };

  async getFilterMensagemEnvioOptionsSelect(column) {
    let arrTypes = [];
    this.selectedMensagensEnvioValues = [];

    for (const teste of arrTypes) {
      this.selectedMensagensEnvioValues.push({
        field: this.fieldMensagensEnvioSelected.text,
        value: column.objectChildren === 'tag' ? teste.tag : teste.tipo,
        text:
          column.objectChildren === 'tag'
            ? teste.tag
            : `${teste.idPublico} - ${teste.tipo}`,
      });
    }
  }

  filterMensagensEnvio() {
    return this.getMensagensEnvioPerPeriod(this.fieldsMensagensEnvioSelected);
  }

  async expandTableRow(item, index) {
    if (item.destinatarios) {
      return;
    }
    this.loadingDestinatarios = true;
    const destinatarios: any = await this.service.getDestinatariosByMensagem(
      item,
    );
    this.displayData = this.displayData.map((data) => {
      if (item.idPublico === data.idPublico) {
        return { ...data, destinatarios };
      }
      return { ...data };
    });
    this.loadingDestinatarios = false;
  }

  async getMensagensEnvioPerPeriod(
    fieldsMensagensEnvioSelected,
    skip?: number,
    take?: number,
  ) {
    this.loadingPage = true;
    fieldsMensagensEnvioSelected = {
      destinatarios: fieldsMensagensEnvioSelected
        .filter((field) => field.props === 'destinatario')
        .map((field) => field.value),
      filtrosCombinados: fieldsMensagensEnvioSelected.filter(
        (field) => !(field.props === 'destinatario'),
      ),
      status: this.filterType,
    };
    await this.service
      .getAllMensagensEnvioInInterval(fieldsMensagensEnvioSelected, skip, take)
      .then(async (result: any) => {
        if (result.data.length === 0) {
          this.service.notification.warning(
            'result',
            'Nenhum registro correspondente aos filtros.',
            { nzDuration: 7000 },
          );
        }
        this.pageTotal = result.data.count;

        this.displayData = result.data.mensagens.map((mensagen) => {
          return {
            ...mensagen,
            status: StatusMensagem[mensagen.status],
            expand: false,
          };
        });

        this.loadingPage = false;
      })
      .catch((err) => {
        this.service.notification.error('Mensagens', err);
        this.loadingPage = false;
      });
  }

  updateAllChecked() {
    if (this.displayData.length != 0) {
      this.checkAllIndeterminate = !this.checkAllIndeterminate;
      this.checkedAll = !this.checkedAll;
      this.displayData.forEach((e) => (e.checked = this.checkedAll));
    }
    let map = [];
    this.displayData.forEach((e) => map.push(e.checked));
  }

  removeFiltroMensagemPorField(fieldsValuesTag) {
    if (fieldsValuesTag.field === 'Destinatario') {
      this.listSignatariosMensagemEnvio.push(fieldsValuesTag);
      // Outra forma de remover o destinatario do array
      // this.signatariosSelected = this.signatariosSelected.filter(
      //   (signatario) => !(signatario.idPrivado === fieldsValuesTag.idPrivado),
      // );
      this.fieldsMensagensEnvioSelected.splice(
        this.fieldsMensagensEnvioSelected.indexOf(fieldsValuesTag),
        1,
      );
      if (this.fieldsMensagensEnvioSelected.length === 0)
        this.showComboTagsMensagensEnvio = false;

      return;
    }

    const tableExportColumn = this.tableMensagemEnvioColumns.find(
      (e) => e.value === fieldsValuesTag.props,
    );

    const item = {
      value: tableExportColumn.value,
      text: tableExportColumn.text,
      type: tableExportColumn.type,
    };

    this.arrColumnsMensagensEnvioSelect.splice(
      this.tableMensagemEnvioColumns.indexOf(tableExportColumn),
      0,
      item,
    );

    this.fieldsMensagensEnvioSelected.splice(
      this.fieldsMensagensEnvioSelected.indexOf(fieldsValuesTag),
      1,
    );

    if (this.fieldsMensagensEnvioSelected.length === 0) {
      this.showComboTagsMensagensEnvio = false;
    }
  }
  getWidthContent() {
    return window.innerWidth;
  }

  async setConfigMensagemEnvioTable() {
    this.loadingPage = true;
    await this.service
      .getMensagemEnvioTableProps('mensagem-envio')
      .then((result) => {
        const tableColumnsMensagensEnvio = result.tableColumnsAnexo.map(
          (column) => {
            return {
              ...column,
              compare:
                column.value === 'idPublico'
                  ? (a, b) => a[column.value] - b[column.value]
                  : (a, b) => {
                    const string = a[column.value] ?? ' ';
                    return string.localeCompare(b[column.value] ?? ' ');
                  },

              width:
                column.value === 'idPublico'
                  ? '5.7rem'
                  : column.value === 'titulo'
                    ? '13rem'
                    : column.value === 'status'
                      ? '8rem'
                      : column.value === 'texto'
                        ? '36rem'
                        : column.value === 'dtCriacao' ||
                          column.value === 'dtAlteracao' ||
                          column.value === 'dtEnvio' ||
                          column.value === 'dtProgramado'
                          ? '10rem'
                          : null,
              type: column.type,
            };
          },
        );
        this.tableMensagemEnvioColumns = tableColumnsMensagensEnvio;
        this.arrColumnsMensagensEnvioSelect = result.searchColumns;
        return;
      });
    this.loadingPage = false;
  }
  getCorStatusMensagem(status): string {
    return this.documentService.themeColors[
      ColorStatusMensagemEnvio[StatusMensagem[status]]
    ];
  }

  async setConfigMensagemEnvioFilter() {
    this.loadingPage = true;

    await this.service
      .getMensagemEnvioTableProps('mensagem-envio')
      .then((result) => {
        Object.assign(this, result);
        this.service.setCompareToTableColumns(
          this.displayData,
          this.tableColumns,
        );
      });
    this.loadingPage = false;
  }

  prepareColumnValue(item, column) {
    const value = column.objectChildren
      ? item[column.value][column.objectChildren]
      : item[column.value];
    return value;
  }

  selectItem(item) {
    return item;
  }
  dbClickItem(item) {
    return item;
  }

  addFiltroValuesPorCampo() {
    if (this.fieldMensagensEnvioSelected && this.searchInput) {
      if (this.fieldMensagensEnvioSelected.type === 'range-picker') {
        this.searchInput = `${moment(this.searchInput[0]).format(
          'DD/MM/yyyy',
        )} - ${moment(this.searchInput[1]).format('DD/MM/yyyy')}`;
      }

      if (this.fieldMensagensEnvioSelected.type === 'date-picker') {
        this.searchInput = moment(this.searchInput).format('DD/MM/yyyy');
      }
      this.fieldsMensagensEnvioSelected.push({
        props: this.fieldMensagensEnvioSelected.value,
        field: this.fieldMensagensEnvioSelected.text,
        value: this.searchInput.text ? this.searchInput.text : this.searchInput,
        text: `${this.fieldMensagensEnvioSelected.text} - ${this.searchInput.text ? this.searchInput.text : this.searchInput
          }`,
      });

      const index = this.arrColumnsMensagensEnvioSelect.findIndex(
        (a) => a.value === this.fieldMensagensEnvioSelected.value,
      );

      this.arrColumnsMensagensEnvioSelect.splice(index, 1);

      this.showComboTagsMensagensEnvio = true;
      this.fieldMensagensEnvioSelected = '';
      this.searchInput = '';
      this.selectedMensagensEnvioValues = [];
    }
  }
}
