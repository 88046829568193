import { NgModule } from '@angular/core';
import { SideNavDirective } from './side-nav.directive';
import { CommonModule } from '@angular/common';
import { AccessibleForm } from './acessible-form.directive';

@NgModule({
    declarations: [
        SideNavDirective,
        AccessibleForm
    ],
    imports: [CommonModule],
    exports: [
        SideNavDirective,
        AccessibleForm
    ],
})
export class PsDirectiveModule {}
