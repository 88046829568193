import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { NzNotificationService } from 'ng-zorro-antd/notification';
import { Subscription } from 'rxjs';
import { AuthService } from 'src/@lib';
import { ISetor } from '../interfaces/document-interfaces';
import { AbstractService } from './abstract.service';

@Injectable({
  providedIn: 'root',
})
export class SetorService extends AbstractService {
  private title: string = 'Setor';
  constructor(
    public http: HttpClient,
    public authService: AuthService,
    public notification: NzNotificationService,
  ) {
    super('setor', http, authService, notification);
  }

  public async listarSetor(params?: Partial<ISetor>): Promise<ISetor[]> {
    return await this.getListarSetor(params)
      .then((resp: { message: string; data: ISetor[] }) => {
        return resp.data as ISetor[];
      })
      .catch((err) => {
        this.notification.error(this.title, `${err.message}. err: ${err}`);
        return [];
      });
  }

  public async createSetor(body) {
    return await this.postCreateSetor(body)
      .then((resp: { message: string; data: any[] }) => {
        this.notification.success(this.title, resp.message);
        return resp.data as unknown[];
      })
      .catch((err) => {
        this.notification.error(this.title, `${err.message}. err: ${err}`);
      });
  }

  public async updateSetor(idPrivado: number, body): Promise<ISetor[] | void> {
    return await this.putUpdateSetor(idPrivado, body)
      .then((resp: { message: string; data: any[] }) => {
        this.notification.success(this.title, resp.message);
        return resp.data as ISetor[];
      })
      .catch((err) => {
        this.notification.error(this.title, `${err.message}. err: ${err}`);
      });
  }

  public async softDeleteSetor(idPrivado: number) {
    return await this.deleteSoftDeleteSetor(idPrivado)
      .then((resp: { message: string; data: any[] }) => {
        this.notification.success(this.title, resp.message);
      })
      .catch((err) => {
        this.notification.error(this.title, `${err.message}. err: ${err}`);
      });
  }

  public async getOne(
    setor: Partial<ISetor>,
  ): Promise<{ message: string; data: ISetor } | void> {
    return await this.getGetOne(setor)
      .then((resp: { message: string; data: ISetor }) => resp)
      .catch((err) => {
        this.notification.error(this.title, err.message);
      });
  }

  private async getGetOne(
    setor: Partial<ISetor>,
  ): Promise<{ message: string; data: ISetor }> {
    return new Promise<{ message: string; data: ISetor }>((resolve, reject) => {
      const subscription: Subscription = this.http
        .get(
          `${this.getServer('setor')}/get-one?${this.buildUrlQuery(
            setor as { [key: string]: string | number },
          )}`,
          this.headerAuthenticateOptions(),
        )
        .subscribe({
          next: (response: { message: string; data: ISetor }) => {
            resolve(response);
          },
          error: (error) => {
            reject(error);
          },
          complete() {
            subscription.unsubscribe();
          },
        });
    });
  }

  private deleteSoftDeleteSetor(idPrivado: number) {
    return new Promise<{ message: string; data: unknown[] }>(
      (resolve, reject) => {
        const putRequest: Subscription = this.http
          .delete(
            `${this.getServer('setor')}/delete/${idPrivado}`,
            this.headerAuthenticateOptions(),
          )
          .subscribe({
            next: (response: { message: string; data: unknown[] }) => {
              resolve(response);
            },
            error: (error) => {
              reject(error);
            },
            complete() {
              putRequest.unsubscribe();
            },
          });
      },
    );
  }

  private putUpdateSetor(idPrivado: number, body) {
    return new Promise<{ message: string; data: unknown[] }>(
      (resolve, reject) => {
        const putRequest: Subscription = this.http
          .put(
            `${this.getServer('setor')}/update/${idPrivado}`,
            body,
            this.headerAuthenticateOptions(),
          )
          .subscribe({
            next: (response: { message: string; data: unknown[] }) => {
              resolve(response);
            },
            error: (error) => {
              reject(error);
            },
            complete() {
              putRequest.unsubscribe();
            },
          });
      },
    );
  }

  private getListarSetor(
    params?: Partial<ISetor>,
  ): Promise<{ message: string; data: unknown[] }> {
    return new Promise<{ message: string; data: unknown[] }>(
      (resolve, reject) => {
        const getRequest: Subscription = this.http
          .get(
            `${this.getServer('setor')}/listar?${this.buildUrlQuery(
              params as {
                [key: string]: string | number | boolean;
              },
            )}`,
            this.headerAuthenticateOptions(),
          )
          .subscribe({
            next: (response: { message: string; data: unknown[] }) => {
              resolve(response);
            },
            error: (error) => {
              reject(error);
            },
            complete() {
              getRequest.unsubscribe();
            },
          });
      },
    );
  }
  private postCreateSetor(body) {
    return new Promise((resolve, reject) => {
      const postRequest: Subscription = this.http
        .post(
          `${this.getServer('setor')}/create`,
          body,
          this.headerAuthenticateOptions(),
        )
        .subscribe({
          next: (response: { message: string; data: unknown[] }) => {
            resolve(response);
          },
          error: (error) => {
            reject(error);
          },
          complete() {
            postRequest.unsubscribe();
          },
        });
    });
  }

  private buildUrlQuery(params: {
    [key: string]: string | number | boolean;
  }): string {
    const arrQuery: string[] = new Array();
    for (const key in params) {
      if (params[key]) {
        arrQuery.push(`${key}=${params[key]}`);
      }
    }

    return arrQuery.join('&');
  }
}
