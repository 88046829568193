import { DashboardService } from './../shared/services/dashboard.service';
import { Component, OnInit } from '@angular/core';
import { IMenuButton } from 'src/@lib';

@Component({
  selector: 'app-analitcs',
  templateUrl: './analitcs.component.html',
  styleUrls: ['./analitcs.component.scss'],
})
export class AnalitcsComponent implements OnInit {
  getDateNow: any = this.getDate();
  fabButtons: IMenuButton[];
  documentsTotalSubscriptions: any = 0;

  biDomain = `https://publicsoft.horusbi.com.br/`;

  //colocar aqui a path relativa desta aplicação dentro da URL do sistema
  appPath = '';

  //estamos usando como embedDomain o dominio atual da demo, isso é usado para o fallback
  //o fallback é usado caso haja algum erro na session do BI, então ele redireciona para este dominio, e nao para a tela de login normal do horus
  embedDomain = window.location;

  //pego a referencia dos elementos

  constructor(private abstractService: DashboardService) {
    this.bootstrap();
    this.getDate();
    // this.reset();
    this.buildFabButton([
      {
        icon: 'reload',
        tooltip: 'Atualizar',
        condition: true,
        onClick: this.attDashboard,
      },
    ]);
  }

  async ngOnInit(): Promise<void> {}

  async getLoginToken() {
    const json = await this.abstractService.getLoginToken();
    return json;
  }

  async populaMenu(menus) {
    //este exemplo utiliza javascript puro, seria bem melhor usar alguma abordagem reativa
    //o código abaixo é apenas um exemplo muito, muito simples
    const setMenu = document.getElementById('biembed-menu');
    const setSrc = document.querySelectorAll('iframe')[0];

    for (const el of menus.dashboards) {
      const node = document.createElement('li');

      const textnode = document.createTextNode(`Dashboard: ${el.nome}`);
      node.appendChild(textnode);

      node.addEventListener('click', (ev) => {
        setSrc.src = `${this.biDomain}dashboard/${el.id}`;
      });

      setMenu.appendChild(node);
    }

    for (const el of menus.datamarts) {
      const node = document.createElement('li');
      const textnode = document.createTextNode(`Análise: ${el.nome}`);
      node.appendChild(textnode);
      node.addEventListener('click', (ev) => {
        setSrc.src = `${this.biDomain}dashboard/${el.id}`;
      });
      // this.menu_ul.appendChild(node);
      setMenu.appendChild(node);
    }
  }

  checkBrowser() {
    // Get the user-agent string
    let userAgentString = navigator.userAgent;

    // Detect Chrome
    let chromeAgent = userAgentString.indexOf('Chrome') > -1;

    // Detect Internet Explorer
    let IExplorerAgent =
      userAgentString.indexOf('MSIE') > -1 ||
      userAgentString.indexOf('rv:') > -1;

    // Detect Firefox
    let firefoxAgent = userAgentString.indexOf('Firefox') > -1;

    // Detect Safari
    let safariAgent = userAgentString.indexOf('Safari') > -1;

    // Discard Safari since it also matches Chrome
    if (chromeAgent && safariAgent) {
      safariAgent = false;
    }

    // Detect Opera
    let operaAgent = userAgentString.indexOf('OP') > -1;

    return {
      IExplorerAgent,
      firefoxAgent,
      safariAgent,
      chromeAgent,
      operaAgent,
    };
  }

  async registrarStateIframe() {
    //mensagens que vem do iframe vem pela função onmessage, isso é usado para eventos ajax do BI, como crossfitlers
    let setSrc = document.querySelectorAll('iframe')[0];

    window.onmessage = function (e) {
      if (e.data) {
        history.pushState({ uri: e.data }, '', this.appPath + e.data);
      }
    };

    window.onpopstate = (event) => {
      if (event.state && event.state.uri) {
        setSrc.src = `${this.biDomain}${event.state.uri}`;
      }
    };
  }

  async bootstrap() {
    //inicia o bootstrap do embed
    const loginData = await this.getLoginToken();

    //embed total ou redirect devido ao safari
    const redirect = this.checkBrowser().safariAgent;

    let goTo = '';
    const path = window.location.pathname + window.location.search;
    //ATENÇÃO: ESSA LINHA ABAIXO CONSIDERA UM PATHNAME ABSOLUTO DO DOMINIO, SE O BI ESTIVER EM ALGUMA OUTRA PATH DENTRO DO SISTEMA
    //PRECISA SER LEVADO EM CONSIDERAÇÃO PARA SÓ SER LEVADO A PARTE CORRETA DA PASTA

    if (
      window.location.pathname &&
      path.substring(this.appPath.length) === this.appPath
    ) {
      goTo = `&goto=${encodeURIComponent(path.substring(this.appPath.length))}`;
    }

    const biLoginUrl = `${this.biDomain}login/api/?token=${
      loginData.token
    }&login=${loginData.email}&hidemenu=${redirect ? '0' : '1'}&fallback=${
      this.embedDomain
    }${goTo}`;

    //se for safari, ele redireciona, devido ao problema de cookies em iframe do safari
    if (redirect) {
      window.location.href = biLoginUrl;
      return;
    }

    await this.populaMenu(loginData.menus);

    this.registrarStateIframe();

    const setSrc = document.querySelectorAll('iframe')[0];

    setSrc.src = biLoginUrl;
    loginData.menus = [];
  }

  attDashboard = () => {
    this.bootstrap();
    this.getDate();
  };

  // async reset() {
  //   // await this.resetDashBoard();
  //   // await this.getDocumentsPerPeriod();
  //   await this.getTotalSubscriptions();
  // }

  buildFabButton(fabButtons: IMenuButton[]) {
    this.fabButtons = fabButtons;
  }

  getDate() {
    this.getDateNow = new Date().toLocaleString();
  }
}
