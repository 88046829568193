import { Component, ChangeDetectionStrategy } from '@angular/core';
import { FieldWrapper } from '@ngx-formly/core';

@Component({
  // tslint:disable-next-line: component-selector
  selector: 'ps-wrapper-password',
  template: `
    <nz-form-item>
      <ng-container *ngIf="to.label && to.hideLabel !== true">
        <nz-form-label
          [nzRequired]="to.required && to.hideRequiredMarker !== true"
          [nzFor]="id"
        >
          {{ to.label }}
        </nz-form-label>
        <a
          *ngIf="to.forget_password"
          class="float-right font-size-13 p-t-10 text-muted position-absolute"
          style="right: 0;"
          [routerLink]="[to.forget_password.link]"
          >{{ to.forget_password.text }}</a
        >
      </ng-container>
      <nz-form-control [nzValidateStatus]="errorState" [nzErrorTip]="errorTpl">
        <ng-container #fieldComponent></ng-container>
        <ng-template #errorTpl let-control>
          <formly-validation-message
            [field]="field"
          ></formly-validation-message>
        </ng-template>
      </nz-form-control>
    </nz-form-item>
  `,
  // changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PsFormWrapperPasswordComponent extends FieldWrapper {
  get errorState() {
    return this.showError ? 'error' : '';
  }
}
