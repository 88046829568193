import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { PsDirectiveModule } from '../../directives';
import {
  NzButtonModule,
  NzCardModule,
  NzCollapseModule,
  NzFormModule,
  NzGridModule,
  NzIconModule,
  NzTagModule,
} from '../../antd';
import { PsDynamicFormModule } from '../ps-dynamic-form';
import { PsFilterComponent } from './ps-filter.component';
import { PsGridModule } from '../ps-grid';

@NgModule({
  imports: [
    CommonModule,
    NzCardModule,
    NzFormModule,
    NzTagModule,
    NzIconModule,
    NzButtonModule,
    NzCollapseModule,
    NzGridModule,
    PsDynamicFormModule,
    FormsModule,
    ReactiveFormsModule,
    PsDirectiveModule,
    PsGridModule.forRoot({ gridType: 'ag-grid' }),
  ],
  declarations: [PsFilterComponent],
  exports: [PsFilterComponent],
  providers: [],
})
export class PsFilterModule {}
