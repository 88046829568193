import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Subscription } from 'rxjs';
import { AuthService, NzNotificationService } from 'src/@lib';
import { Helper } from '../helper';
import {
  AbstractError,
  IColumnsFieldsProps,
  IResponseFieldsProps,
  ISearchFieldsProps,
} from '../interfaces/shared.interface';
import { IPessoaFisica } from './../interfaces/pessoa-fisica';
import { AbstractService } from './abstract.service';
import { DocumentService } from './document.service';

@Injectable({
  providedIn: 'root',
})
export class PessoaFisicaService extends AbstractService {
  constructor(
    public abstractHttp: HttpClient,
    public abstractAuthService: AuthService,
    public abstractNotification: NzNotificationService,
    public documentService: DocumentService,
  ) {
    super(
      'pessoaFisica',
      abstractHttp,
      abstractAuthService,
      abstractNotification,
    );
  }

  async getDocumentTableProps(
    entidade: string = null,
  ): Promise<IResponseFieldsProps> {
    const camposPesquisa: ISearchFieldsProps[] = [];
    const colunasTabela: IColumnsFieldsProps[] = [];
    // return await this.http.get(`${this.getServer(entidade)}/props`, this.headerAuthenticateOptions()).toPromise()
    return new Promise((resolve) =>
      resolve({
        message: 'sucesso',
        data: {
          cpf: {
            isSearch: true,
            label: 'CPF',
            columnTable: true,
            mask: 'cpf',
          },
          nomPessoa: {
            isSearch: true,
            label: 'Nome',
            columnTable: true,
            objectChildren: ['pessoa'],
          },
          emailPrincipal: {
            isSearch: true,
            label: 'E-mail',
            columnTable: true,
            objectChildren: ['pessoa'],
          },
        },
      }),
    )
      .then((response: { message: string; data: any }) => {
        camposPesquisa.push({
          text: 'Todos',
          value: 'todos',
        });

        for (const p in response.data) {
          if (response.data[p].isSearch) {
            camposPesquisa.push({
              text: response.data[p].label,
              value: p,
            });
          }
        }
        for (const p in response.data) {
          if (response.data[p].columnTable) {
            colunasTabela.push({
              text: response.data[p].label,
              value: p,
              mask: response.data[p].mask ? response.data[p].mask : null,
              objectChildren: response.data[p].hasOwnProperty('objectChildren')
                ? response.data[p].objectChildren[0]
                : null,
            });
          }
        }
        return { searchColumns: camposPesquisa, tableColumns: colunasTabela };
      })
      .catch((err: AbstractError) => {
        this.notification.error('Propriedades', err.error.message);
        return { searchColumns: camposPesquisa, tableColumns: colunasTabela };
      });
  }

  public consultarPessoaFisicaPorParametro(param: {
    [key: string]: string | number | boolean;
  }): Promise<any> {
    return new Promise((resolve, reject) => {
      const ob$: Subscription = this.http
        .get(
          `${this.getServer(
            'pessoaFisica',
          )}/consultar-por-parametro?field=idPessoa&value=${param['value']}`,
          this.headerAuthenticateOptions(),
        )
        .subscribe({
          next: (resp) => resolve(resp),
          error: (e) => reject(e),
          complete: () => {
            ob$.unsubscribe();
          },
        });
    });
  }

  private buildQueryParams(params: {
    [key: string]: string | number | boolean;
  }): string {
    const arrQuery: string[] = new Array();
    for (const key in params) {
      if (params[key]) {
        arrQuery.push(`${key}=${params[key]}`);
      }
    }

    return arrQuery.join('&');
  }

  async getPessoasFisicas(parametrosPesquisa): Promise<any> {
    return await this.pesquisar(parametrosPesquisa, 'pessoaFisicaGlobal').then(
      async (result) => {
        return {
          pessoa: result.data.resultado,
          params: result.data.paramsPaginator,
        };
      },
    );
  }

  formatSubscriber(subscriber) {
    subscriber.cidades?.map((cidade) =>
      cidade.unidadesGestoras.map((ug) => {
        if (ug.cnpjPessoaJuridica === this.authService.getUserInfo().cnpjUg) {
          subscriber.cargo = ug.cargo;
          subscriber.matricula = ug.matricula;
        }
      }),
    );
  }

  async getSubscriberByCpf(cpf) {
    return await this.http
      .get(
        `${this.getServer('assinante')}?cpf=${cpf}`,
        this.headerAuthenticateOptions(),
      )
      .toPromise()
      .then((result: any) => result.data[0])
      .catch(() => null);
  }

  async getPessoaFisicaById(cpf) {
    return await this.http
      .get(
        `${this.getServer('assinante')}?cpf=${cpf}`,
        this.headerAuthenticateOptions(),
      )
      .toPromise()
      .then((result: any) => result.data[0])
      .catch(() => null);
  }

  async getPessoaFisicaById2(id) {
    return await this.http
      .get(
        `${this.getServer('pessoaFisicaById')}${id}`,
        this.headerAuthenticateOptions(),
      )
      .toPromise()
      .then((result: any) => result.data)
      .catch(() => null);
  }

  async getPessoasFisicaByCpf(cpf: string): Promise<IPessoaFisica> {
    if (!Helper.validaCPF(cpf)) {
      this.notification.warning('Pesquisa', 'CPF inválido');
      return;
    }

    return await this.http
      .get(
        `${this.getServer('pessoaFisicaByCpf')}${cpf}`,
        this.headerAuthenticateOptions(),
      )
      .toPromise()
      .then(async (result: { message: string; data: any }) => {
        if (!result) {
          return { message: 'cpf não encontrado!', data: null };
        }
        return result.data;
      })
      .catch((err) => {
        this.notification.warning('Pessoa física', err);
        return err as { message: string; result: any };
      });
  }

  async inserir(form: IPessoaFisica) {
    if (!Helper.validaCPF(form.cpf)) {
      return this.notification.warning('Pesquisa', 'CPF inválido');
    }

    await this.getPessoasFisicaByCpf(form.cpf)
      .then(async (result) => {
        if (result.cpf) {
          return this.notification.warning(
            'Cadastro',
            'Registro já cadastrado!',
          );
        }
        return this.http
          .post(
            `${this.getServer('pessoaFisicaGlobal')}`,
            await this.formatToRequest(form),
            this.headerAuthenticateOptions(),
          )
          .toPromise()
          .then((data) => {
            this.notification.success(
              'Pessoa física',
              'Cadastrado com sucesso!',
            );
            return data as { message: string; result: any };
          })
          .catch((err) => {
            this.notification.warning('Pessoa física', err);
            return err as { message: string; result: any };
          });
      })
      .catch(async (err) => {
        throw err;
      });
  }

  async alterar(form: IPessoaFisica) {
    if (!Helper.validaCPF(form.cpf)) {
      return this.notification.warning('Pesquisa', 'CPF inválido');
    }

    return this.http
      .put(
        `${this.getServer('pessoaFisicaGlobal')}`,
        await this.formatToRequest(form),
        this.headerAuthenticateOptions(),
      )
      .toPromise()
      .then((data) => {
        this.notification.success('Pessoa física', 'Alterado com sucesso!');
        return data as { message: string; result: any };
      })
      .catch((err) => {
        this.notification.warning('Pessoa física', err);
        return err as { message: string; result: any };
      });
  }

  async cadastrar(form) {
    this.insert(await this.formatToRequest(form));
  }

  async alterarSenha(form: any) {
    return await this.http
      .put(`${this.getServer('senha')}`, form, this.headerAuthenticateOptions())
      .toPromise()
      .then((response: any) => response.message);
  }

  async alterarAvatar(form: any, id: number) {
    return await this.http
      .patch(
        `${this.getServer('avatar')}/${id}`,
        form,
        this.headerAuthenticateOptions(),
      )
      .toPromise()
      .then((response: any) => response.message);
  }

  async uploadAvatar(avatar: any) {
    const formData = new FormData();
    formData.append('avatar', avatar);

    return await this.http
      .post(
        `${this.getServer('uploadAvatar')}`,
        formData,
        this.headerAuthenticateOptions(),
      )
      .toPromise()
      .then((result: any) => result.data);
  }

  async getEmpresasAssinanteLogado() {
    return await this.http
      .get(
        `${this.getServer(
          'pessoaFisica',
        )}/empresas/${this.authService.getCpfUser()}`,
        this.headerAuthenticateOptions(),
      )
      .toPromise()
      .then((result: any) => result.data?.empresas);
  }

  async formatToRequest({
    usuarioCriacao,
    aplicacaoOrigem,
    ...form
  }: IPessoaFisica) {
    let pessoaFisica;

    try {
      pessoaFisica = await this.http
        .get(
          `${this.getServer('pessoaFisicaByCpf')}${form.cpf}`,
          this.headerAuthenticateOptions(),
        )
        .toPromise()
        .then((result: any) => result.data)
        .catch(() => {
          throw null;
        });
    } catch (error) {
      pessoaFisica = error;
    }

    return {
      pessoa: {
        id: pessoaFisica?.pessoa.id,
        tipoPessoa: 0,
        nomPessoa: form.nome,
        usuarioCriacao,
        aplicacaoOrigem,
      },
      contatos: [
        {
          id: pessoaFisica?.contatos.find(
            (contato) => contato.email === pessoaFisica?.pessoa.emailPrincipal,
          ).id,
          email: form.email,
          telefone: form.telefone,
          principal: 1,
          usuarioCriacao,
          aplicacaoOrigem,
        },
      ],
      enderecos: [],
      usuarioCriacao,
      aplicacaoOrigem,
      ...form,
    };
  }
}
