import { IDocumento } from './../interfaces/document-interfaces';
import { Injectable } from '@angular/core';
import { StatusDocumento } from '../enum/const.enum';
import { DocumentsUploadService } from './documents-upload.service';
import { DocumentService } from './document.service';
import { HttpClient } from '@angular/common/http';
import { AbstractService } from './abstract.service';
import { AuthService, NzNotificationService } from 'src/@lib';

@Injectable({
  providedIn: 'root',
})
export class ModifyDocumentService extends AbstractService {
  constructor(
    public abstractHttp: HttpClient,
    public abstractAuthService: AuthService,
    public abstractNotification: NzNotificationService,
    public service: DocumentsUploadService,
  ) {
    super(
      'finishedDocument',
      abstractHttp,
      abstractAuthService,
      abstractNotification,
    );
  }

  verifySignDocument = (document: IDocumento, assianteCadastrado): boolean => {
    let pessoaFisica;
    const signatarios = this.getSignatarioByIdDocumento(document.idPrivado);
    return signatarios.some(async (ass) => {
      pessoaFisica =
        (await this.service.getPessoasFisicasById(ass.idPessoaFisica)) &&
        pessoaFisica?.cpf === assianteCadastrado.cpf &&
        ass.status === StatusDocumento.Assinado;
    });
  };

  getSignatarioByIdDocumento(idDocumento: number): any {
    return this.http
      .get(
        this.generateUrlByEnvironment(
          '5017',
          `signatario/${idDocumento}`,
          'assinatura-documentos',
        ),
        this.headerAuthenticateOptions(),
      )
      .subscribe(
        async (response: any) => {
          return response.data;
        },
        (err) => {
          console.log(err);
        },
      );
  }

  isNotDocActive = (doc: IDocumento) => {
    const dataLimiteAssinatura = doc.dataLimiteAssinatura?.slice(
      0,
      doc.dataLimiteAssinatura.lastIndexOf('.'),
    );

    return (
      [
        StatusDocumento.Desacordo,
        StatusDocumento.Assinado,
        StatusDocumento.Cancelado,
      ].includes(Number(doc.statusDocumento)) ||
      new Date(dataLimiteAssinatura) < new Date()
    );
  };

  docFullSigned = (doc: IDocumento, cpfUser: string) => {
    return doc.signatarios
      ?.filter((ass) => ass.cpf ?? ass.signatario === cpfUser)
      .every((ass) => ass.status === StatusDocumento.Assinado);
  };
}
