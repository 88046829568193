import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ModalTermoAceiteService } from '../shared/services/modal-termo-aceite.service';

@Component({
  selector: 'app-modal-termo-aceite-nouser',
  templateUrl: './modal-termo-aceite-nouser.component.html',
  styleUrls: ['./modal-termo-aceite-nouser.component.scss'],
})
export class ModalTermoAceiteNouserComponent implements OnInit {
  public isCheckedAceiteTermos = false;
  public certificados = [];

  isSpinning = false;
  loading = false;

  formB: FormGroup = new FormGroup({});

  constructor(
    public service: ModalTermoAceiteService,
    private formBuilder: FormBuilder,
  ) {
    this.formB = this.formBuilder.group({
      isCheckedAceiteTermos: [null, Validators.required],
    });
  }

  ngOnInit(): void {}
}
