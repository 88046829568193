import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormlyFieldConfig } from '@ngx-formly/core';
import { GridOptions, ColDef } from 'ag-grid-community';

@Component({
  // tslint:disable-next-line: component-selector
  selector: 'ps-complex-form',
  templateUrl: `./ps-complex-form.component.html`,
  styleUrls: ['./ps-complex-form.component.scss'],
})
export class PsComplexFormComponent implements OnInit {
  @Input() title: string;
  @Input() tabIndex: number;
  @Input() actions = {};

  @Input() filterFields: FormlyFieldConfig[];
  @Input() filterFieldsAvancado: FormlyFieldConfig[];

  @Input() gridOptions: GridOptions;
  @Input() rowData: any;
  @Input() defaultColDefs: ColDef;
  @Input() columnDefs: ColDef[];

  @Input() isAntd = false;

  @Output() changeTabIndex = new EventEmitter();

  constructor() {}

  ngOnInit(): void {}

  changeModel = (value) => this.changeTabIndex.emit(value);
}
