import { Component, ChangeDetectorRef, Inject, OnChanges } from '@angular/core';
import { GRID_CONFIG, GridConfig } from './models/grid.config';
import { PsLocalizationService } from '../../services/localization.service';
import { ColDef } from 'ag-grid-community';
import { delay } from 'rxjs/operators';
import { CustomLoadingOverlay } from './ag-grid/components/custom-loading-overlay.component';
import { PsAggridBaseComponent } from './ps-grid-base.component';
import { OnInit } from '@angular/core';

@Component({
  // tslint:disable-next-line: component-selector
  selector: 'ps-grid',
  templateUrl: './ps-grid.component.html',
  // changeDetection: ChangeDetectionStrategy.OnPush
})
export class PsGridComponent
  extends PsAggridBaseComponent
  implements OnChanges, OnInit {
  autoGroupColumnDef: ColDef = {
    headerName: 'Name',
    field: 'name',
    minWidth: 250,
    checkboxSelection: true,
    headerCheckboxSelection: true,
    cellRenderer: 'agGroupCellRenderer',
    cellRendererParams: { footerValueGetter: '"Total (" + x + ")"' },
  };

  constructor(
    private cdr: ChangeDetectorRef,
    @Inject(GRID_CONFIG) public gridConfig: GridConfig,
    protected localizationService: PsLocalizationService
  ) {
    super(gridConfig, localizationService);
    this.frameworksComponent = {
      customLoadingOverlay: CustomLoadingOverlay,
    };
    this.loadingOverlayComponent = 'customLoadingOverlay';
  }

  ngOnInit(): void {
    this.localizationService
      .getLocale()
      .pipe(delay(1000))
      .subscribe((locale) => {
        this.locale = locale;
        this.gridActivated = true;
        this.cdr.markForCheck();
      });
  }

  onGridReady(params): void {
    this.gridApi = params.api;
    this.gridApi.showLoadingOverlay();
    setTimeout(() => {
      this.gridApi.hideOverlay();
    }, 3000);
  }

  ngOnChanges(changes) {}
}
