import { NzNotificationService } from 'ng-zorro-antd/notification';
import { DocumentService } from './document.service';
import { AuthService } from './../../../@lib/services/api/auth.service';
import { HttpClient } from '@angular/common/http';
import { AbstractService } from './abstract.service';
import { Injectable } from '@angular/core';
import { JwtHelperService } from '@auth0/angular-jwt';

@Injectable({
  providedIn: 'root',
})
export class ModalTermoAceiteService extends AbstractService {
  constructor(
    public abstractHttp: HttpClient,
    public abstractAuthService: AuthService,
    public abstractNotification: NzNotificationService,
    public documentService: DocumentService,
  ) {
    super('assinante', abstractHttp, abstractAuthService, abstractNotification);
  }

  async cadastrar(object, entidade: string, querys?): Promise<any> {
    const formData = new FormData();
    const pswEncrpt = this.encryptUsingAES256(object.senhaCert);
    const querysEncrpt = this.encryptUsingAES256(querys.cpf);
    formData.append('senhaCert', pswEncrpt);
    formData.append(
      'termoAceite',
      JSON.stringify({
        cidade: object.cidade,
        estado: object.estado,
        text: object.text,
      }),
    );
    formData.append('cpf', querysEncrpt);
    if (object.certificadoDigitalB64) {
      formData.append('certificadoDigitalB64', object.certificadoDigitalB64);
    }

    return this.http
      .post(
        `${this.getServer(entidade)}`,
        formData,
        this.headerAuthenticateOptions(),
      )
      .toPromise()
      .then((data) => {
        this.notification.success(
          'Cadastrar.',
          'Certificado cadastrado com sucesso!',
        );
        return data as { message: string; result: any };
      })
      .catch((err) => {
        this.notification.warning('Cadastrar assinante', err);
        return err as { message: string; result: any };
      });
  }
}
