import { TipoDocumentoService } from './../shared/services/tipo-documento.service';
import { AuthService } from './../../@lib/services/api/auth.service';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { ITipoDoc } from './../shared/interfaces/tipo-documento';
import {
  Component,
  Input,
  OnInit,
  Optional,
  AfterContentInit,
} from '@angular/core';
import {
  IColumnsFieldsProps,
  ISearchFieldsProps,
} from '../shared/interfaces/shared.interface';
import { Helper } from '../shared/helper';
import { NzModalRef } from 'ng-zorro-antd/modal';
import { IMenuButton } from '../../@lib/components/speed-dial-fab/speed-dial-fab.component';
import { NzTrMeasureComponent } from 'ng-zorro-antd/table';

@Component({
  selector: 'app-tipo-documento',
  templateUrl: './tipo-documento.component.html',
  styleUrls: ['./tipo-documento.component.scss'],
})
export class TipoDocumentoComponent implements OnInit, AfterContentInit {
  @Input() openModal = false;
  @Input() entidadeTipoDoc: ITipoDoc = null;

  loading = false;

  searchInput: any;
  displayData = [];
  tipoDocumentList: ITipoDoc[] = [];

  searchColumns: ISearchFieldsProps[] = [];
  tableColumns: IColumnsFieldsProps[] = [];

  tabIndex = 0;

  formB: FormGroup = new FormGroup({});

  intervaloDigitando: any;

  checkList = {
    idPublico: true,
    tipo: true,
    descricao: true,
    tag: true,
    tituloManifesto: true,
    textoComplementar: true,
  };

  fabButtons: IMenuButton[];

  public innerWidth: any;
  public tooltipTiposDocumentos: boolean = false;

  constructor(
    @Optional()
    private modalRef: NzModalRef<TipoDocumentoComponent>,
    public service: TipoDocumentoService,
    private formBuilder: FormBuilder,
    private authService: AuthService,
  ) {
    this.reset();
  }

  async ngAfterContentInit() {
    await this.setConfigTable();
    this.reset();
  }

  reset() {
    this.formB = this.formBuilder.group({
      idPrivado: [null],
      idPublico: [{ value: null, disabled: true }],
      tipo: [null, Validators.required],
      tag: [null],
      tituloManifesto: [null],
      textoComplementar: [null],
      descricao: [null, Validators.required],
      idUnidadeGestora: [null],
      dtCriacao: [this.authService.getDateSelected()],
      usCriacao: [this.authService.getIdUser()],
    });
  }

  resetFabButton() {
    const buttons: IMenuButton[] = [
      {
        icon: 'plus',
        tooltip: 'Novo Cadastro',
        condition: !this.openModal || this.openModal,
        onClick: this.implantar,
      },
      {
        icon: 'save',
        tooltip: 'Salvar',
        color: 'green',
        condition: this.tabIndex === 0,
        onClick: this.gravar,
      },
      {
        icon: 'edit',
        tooltip: 'Editar',
        color: 'orange',
        condition: (!this.openModal || this.openModal) && this.tabIndex === 1,
        onClick: this.editar,
      },
      {
        icon: 'search',
        tooltip: 'Consultar',
        condition: true,
        onClick: this.consultar,
      },
      {
        icon: 'select',
        tooltip: 'Selecionar',
        condition: this.openModal && this.tabIndex === 1,
        onClick: this.selecionar,
      },
    ];
    const menuFiltered = buttons.filter((button) => button.condition);
    this.buildFabButtons(menuFiltered);
  }

  resetState() {
    this.formB.reset();
    this.reset();
  }

  ngOnInit() {
    if (this.openModal) {
      return this.changeTabIndex(1);
    }
    this.innerWidth = window.innerWidth;
    this.resetFabButton();
  }

  searchTiposDocumentos() {
    this.tooltipTiposDocumentos = true;
    setTimeout(() => {
      this.tooltipTiposDocumentos = false;
    }, 2000);
  }

  buildFabButtons(fabButtons: IMenuButton[]) {
    this.fabButtons = fabButtons;
  }

  async setTipoDocValido() {
    this.loading = true;
    const codigoValido = await this.service.getTipoDocs(null, true);
    const values = codigoValido.map((val) => val.idPublico);
    const max = Math.max.apply(null, values);
    const getCodigo = codigoValido.find((key) => key.idPublico === max);

    if (!getCodigo) {
      this.formB.controls.idPublico.setValue(1);
    }

    if (!this.formB.value.idPublico) {
      this.formB.controls.idPublico.setValue(getCodigo.idPublico + 1);
    }
    this.loading = false;
  }

  resetForm() {
    this.resetState();
    this.setTipoDocsData([]);
    this.changeTabIndex(0);
  }

  async setConfigTable() {
    this.loading = true;
    await this.service.getDocumentTableProps().then((result) => {
      Object.assign(this, result);
      this.setCompareToTableColumns();
    });
    this.loading = false;
  }

  setCompareToTableColumns() {
    this.tableColumns.map((column) => {
      column[`compare`] =
        this.displayData.length > 0 &&
        typeof this.displayData[0][column.value] === 'string'
          ? (a: ITipoDoc, b: ITipoDoc) =>
              a[column.value].localeCompare(b[column.value])
          : (a: ITipoDoc, b: ITipoDoc) => a[column.value] - b[column.value];
    });
  }

  async getTiposDoc(value = '') {
    clearTimeout(this.intervaloDigitando);
    this.intervaloDigitando = await setTimeout(async () => {
      this.loading = true;
      await this.service
        .getTipoDocs(value)
        .then((tiposDoc: ITipoDoc[]) => this.formatResultGetTiposDoc(tiposDoc))
        .catch(() => this.formatResultGetTiposDoc([]));
      this.loading = false;
    }, 1000);
  }

  formatResultGetTiposDoc(tiposDoc: ITipoDoc[]) {
    tiposDoc.map((tipo: ITipoDoc) => (tipo.checked = false));
    this.setTipoDocsData(tiposDoc);
  }

  setTipoDocsData(tipos: ITipoDoc[]) {
    this.tipoDocumentList = tipos;
    this.displayData = tipos;
  }

  getResult(): void {
    this.displayData = this.service.documentService.search(
      this.tipoDocumentList,
      this.searchInput,
    );
  }

  getColumnsStatus() {
    return this.tableColumns.find(
      (column) => column.value === 'statusDocumento',
    );
  }

  getTableColumns() {
    return this.tableColumns.filter(
      (column) => column.value !== 'statusDocumento',
    );
  }

  prepareColumnValue(item, column) {
    const value = column.objectChildren
      ? item[column.value][column.objectChildren]
      : item[column.value];
    if (value && column.mask) {
      return Helper.addMask(value, column.mask);
    }
    return value;
  }

  changeTabIndex(value) {
    this.tabIndex = value;
    this.resetFabButton();
    if (this.tabIndex === 1) {
      this.loading = true;
      this.service
        .getTipoDocs(null, true)
        .then((tiposDoc: ITipoDoc[]) => {
          this.loading = false;
          this.formatResultGetTiposDoc(tiposDoc);
        })
        .catch(() => {
          this.loading = false;
          this.formatResultGetTiposDoc([]);
        });
    }
    // if (this.tabIndex === 0 && this.openModal) {
    //   this.setTipoDocValido();
    // }
    // this.buildFabButtons([
    //   {
    //     icon: 'plus',
    //     tooltip: 'Novo Cadastro',
    //     condition: !this.openModal || this.openModal,
    //     onClick: this.implantar,
    //   },
    //   {
    //     icon: 'save',
    //     tooltip: 'Salvar',
    //     condition: this.tabIndex === 0,
    //     onClick: this.gravar,
    //   },
    //   {
    //     icon: 'edit',
    //     tooltip: 'Editar',
    //     condition: (!this.openModal || this.openModal) && this.tabIndex === 1,
    //     onClick: this.editar,
    //   },
    //   {
    //     icon: 'search',
    //     tooltip: 'Consultar',
    //     condition: true,
    //     onClick: this.consultar,
    //   },
    //   {
    //     icon: 'select',
    //     tooltip: 'Selecionar',
    //     condition: this.openModal && this.tabIndex === 1,
    //     onClick: this.selecionar,
    //   },
    // ]);
  }

  selectItem(entidadeTipoDoc) {
    this.checkItemPesquisa(entidadeTipoDoc);

    this.entidadeTipoDoc = this.tipoDocumentList.every((tipo) => !tipo.checked)
      ? null
      : entidadeTipoDoc;

    this.entidadeTipoDoc ? this.setDataFormTipoDoc() : this.resetState();
  }

  checkItemPesquisa(entidadeTipoDoc) {
    this.tipoDocumentList.map((tipo) => {
      tipo.checked =
        tipo.idPublico !== entidadeTipoDoc?.idPublico ? false : true;
    });
  }

  implantar = () => {
    this.resetForm();
    // this.setTipoDocValido();
  };

  selecionar = () => {
    if (
      !this.entidadeTipoDoc ||
      this.tipoDocumentList.every((tipo) => !tipo.checked)
    ) {
      return this.service.notification.warning(
        'Alterar',
        'Nenhum item selecionado!',
      );
    }

    this.modalRef.destroy(this.entidadeTipoDoc);
  };

  editar = () => {
    if (
      !this.entidadeTipoDoc ||
      this.tipoDocumentList.every((tipo) => !tipo.checked)
    ) {
      return this.service.notification.warning(
        'Alterar',
        'Nenhum item selecionado!',
      );
    }

    this.setDataFormTipoDoc();
    this.changeTabIndex(0);
  };

  setDataFormTipoDoc() {
    this.formB.patchValue(this.entidadeTipoDoc);
  }

  gravar = async () => {
    if (this.formB.invalid) {
      return this.service.notification.warning(
        'Formulário',
        'Por favor, preencha todos os campos corretamente',
      );
    }

    const ugSelected = await this.authService.getUgSelected();

    this.formB.controls.idUnidadeGestora.setValue(ugSelected.id);
    this.formB.controls.idPublico.enable();
    await this.setTipoDocValido();
    this.loading = true;
    this.formB.get('idPrivado').value
      ? await this.service
          .alterar(this.formB.value, this.formB.controls.idPrivado.value)
          .then(() => {
            this.resetForm();
          })
          .catch(() => (this.loading = false))
      : await this.service
          .inserir(this.formB.value)
          .then(() => {
            this.resetForm();
          })
          .catch(() => (this.loading = false));

    this.loading = false;
  };

  consultar = async () => {
    this.loading = true;
    this.changeTabIndex(1);
    this.searchInput = null;
    await this.service
      .getTipoDocs(null, true)
      .then((tiposDoc: ITipoDoc[]) => this.formatResultGetTiposDoc(tiposDoc))
      .catch(() => this.formatResultGetTiposDoc([]));
    this.loading = false;
  };

  dbClickItem(item: ITipoDoc) {
    if (!item.checked) {
      this.checkItemPesquisa(item);
      this.entidadeTipoDoc = item;
    }

    this.openModal
      ? this.modalRef.destroy(this.entidadeTipoDoc)
      : this.editar();
  }

  getWidthContent() {
    return window.innerWidth;
  }

  resetar() {
    this.checkList = {
      idPublico: true,
      tag: true,
      tipo: true,
      descricao: true,
      tituloManifesto: true,
      textoComplementar: true,
    };
  }
}
