import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AuthService, NzNotificationService } from 'src/@lib';
import { AbstractService } from './abstract.service';
import { DocumentService } from './document.service';

@Injectable({
  providedIn: 'root'
})
export class FinishedDocumentService extends AbstractService {

  constructor(
    public abstractHttp: HttpClient,
    public abstractAuthService: AuthService,
    public abstractNotification: NzNotificationService,
    public documentService: DocumentService,
  ) {
    super('finishedDocument', abstractHttp, abstractAuthService, abstractNotification);
  }
}
