import { Component } from '@angular/core';
import { AuthService, MenuService } from 'src/@lib';
import { MenuItem } from '../@lib/interfaces/menu-items.interface';
import { DocumentsUploadService } from './shared/services/documents-upload.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent {
  formLoading = false;
  labelTip: string;
  title = 'Assinatura Digital';
  formulario = [];
  menu: MenuItem[] = [
    {
      path: '/session/dashboard',
      title: 'Dashboard - Documentos',
      entidade: 'dashboard',
      iconType: 'nzIcon',
      iconTheme: 'outline',
      icon: 'area-chart',
      submenu: [],
    },
    {
      path: 'documentos',
      title: 'Documentos',
      entidade: '',
      iconType: 'nzIcon',
      iconTheme: 'outline',
      icon: 'file-protect',
      submenu: [
        {
          path: '/session/documentos',
          title: `Todos documentos - Assinar,\n Discordar...`,
          entidade: 'document',
          iconType: 'nzIcon',
          icon: 'field-time',
          iconTheme: 'outline',
          submenu: [],
        },
        {
          path: '/session/anexos',
          title: `Todos anexos - Assinar,\n Discordar...`,
          entidade: 'anexos',
          iconType: 'nzIcon',
          icon: 'paper-clip',
          iconTheme: 'outline',
          submenu: [],
        },
        {
          path: '/session/documentos-avulso',
          title: 'Documentos avulsos',
          entidade: 'document',
          iconType: 'nzIcon',
          icon: 'file-add',
          iconTheme: 'outline',
          submenu: [],
        },
      ],
    },
    {
      path: 'tabelas',
      title: 'Cadastrar',
      entidade: '',
      iconType: 'nzIcon',
      iconTheme: 'outline',
      icon: 'container',
      submenu: [
        {
          path: '/session/tipo-documento',
          title: 'Tipo de Documento',
          entidade: 'tipo-de-documento',
          iconType: 'nzIcon',
          icon: 'file-text',
          iconTheme: 'outline',
          submenu: [],
        },
        {
          path: '/session/setor',
          title: 'Setor',
          entidade: 'setor',
          iconType: 'nzIcon',
          icon: 'cluster',
          iconTheme: 'outline',
          submenu: [],
        },
        {
          path: '/session/pessoa-fisica',
          title: 'Pessoa Física',
          entidade: 'pessoa-fisica',
          iconType: 'nzIcon',
          icon: 'user',
          iconTheme: 'outline',
          submenu: [],
        },

        {
          path: '/session/pessoa-juridica',
          title: 'Pessoa Jurídica',
          entidade: 'pessoa-juridica',
          iconType: 'nzIcon',
          icon: 'team',
          iconTheme: 'outline',
          submenu: [],
        },
        {
          path: '/session/mensagens',
          title: `Modelos de Mensagens`,
          entidade: 'mensagens',
          iconType: 'nzIcon',
          icon: 'mail',
          iconTheme: 'outline',
          submenu: [],
        },
      ],
    },
    {
      path: 'ferramentas',
      title: 'Ferramentas',
      entidade: '',
      iconType: 'nzIcon',
      iconTheme: 'outline',
      icon: 'tool',
      submenu: [
        {
          path: '/session/exportacao-arquivos',
          title: `Exportação de arquivos`,
          entidade: 'exportacao-de-arquivos',
          iconType: 'nzIcon',
          icon: 'export',
          iconTheme: 'outline',
          submenu: [],
        },
        {
          path: '/session/envio-mensagens',
          title: `Envio de mensagens`,
          entidade: 'mensagem-envio',
          iconType: 'nzIcon',
          icon: 'send',
          iconTheme: 'outline',
          submenu: [],
        },
      ],
    },
    // {
    //   path: '/session/analitcs',
    //   title: 'Analytics',
    //   entidade: 'analitcs',
    //   iconType: 'nzIcon',
    //   iconTheme: 'outline',
    //   icon: 'bar-chart',
    //   submenu: [],
    // },
  ];

  constructor(
    private menuService: MenuService,
    private authService: AuthService,
    private service: DocumentsUploadService,
  ) {
    this.menuService.menuItems.next(this.menu);
    this.formulario = this.authService.getFormularios();

    this.service.formLoading.subscribe(async (data) => {
      this.formLoading = data;
    });

    this.service.labelTip.subscribe(async (msg) => {
      this.labelTip = msg;
    });
    // this.mountMenu();
  }

  mountMenu() {
    let data;

    this.formulario.forEach((form) => {
      data = this.menu.map((menu) => {
        menu.submenu.map((submenu) => {
          if (submenu.entidade === form.entidade) {
            return submenu;
          }
        });

        return menu;
      });
    });

    this.menuService.menuItems.next(data);
  }
}
