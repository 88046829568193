import {
  AfterContentInit,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
} from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { DomSanitizer } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { Optional } from 'ag-grid-community';
import * as moment from 'moment';
import { NzModalRef, NzModalService } from 'ng-zorro-antd/modal';
import { NzUploadFile } from 'ng-zorro-antd/upload';
import { PDFDocument } from 'pdf-lib';
import printJS from 'print-js';
import { Subscription } from 'rxjs';
import { AuthService } from 'src/@lib';
import { StatusCertificado } from 'src/app/shared/enum/const.enum';
import { IMenuButton } from '../../@lib/components/speed-dial-fab/speed-dial-fab.component';
import { PessoaFisicaComponent } from '../pessoa-fisica/pessoa-fisica.component';
import { PessoaJuridicaComponent } from '../pessoa-juridica/pessoa-juridica.component';
import { ColorStatusDoc } from '../shared/consts/document';
import { CodigoModulos, ModulosPublicSoft } from '../shared/consts/modulos';
import { StatusDocumento } from '../shared/enum/const.enum';
import { Helper } from '../shared/helper';
import {
  IColumnsFieldsProps,
  ISearchFieldsProps,
} from '../shared/interfaces/shared.interface';
import { AssinantesService } from '../shared/services/assinantes.service';
import { DocumentService } from '../shared/services/document.service';
import { PessoaJuridicaService } from '../shared/services/pessoa-juridica.service';
import { SetorService } from '../shared/services/setor.service';
import { PeriodVisualizeDoc } from '../shared/types/document.types';
import { TipoDocumentoComponent } from '../tipo-documento/tipo-documento.component';
import { DocumentsDetailComponent } from './../documents-detail/documents-detail.component';
import { ModalDialogComponent } from './../modal-dialog/modal-dialog.component';
import { SetorComponent } from './../setor/setor.component';
import {
  IntervalsTime,
  StatusDoc,
  StatusDocLabel,
  TypeActions,
  TypeAnexoView,
  TypeDocDownload,
  TypeDocView,
} from './../shared/consts/document';
import { TIPOS_MODALIDADE_LICITACO } from './../shared/enum/tipos-licitacao.enum';
import {
  IAssinante,
  IDocumento,
  IDocumentoAnexo,
  ISetor,
} from './../shared/interfaces/document-interfaces';
import { ITipoDoc } from './../shared/interfaces/tipo-documento';
import { DocumentsUploadService } from './../shared/services/documents-upload.service';
import { PessoaFisicaService } from './../shared/services/pessoa-fisica.service';
import { TipoDocumentoService } from './../shared/services/tipo-documento.service';

@Component({
  selector: 'app-documents-upload',
  templateUrl: './documents-upload.component.html',
  styleUrls: ['./documents-upload.component.scss'],
})
export class DocumentsUploadComponent implements OnInit, AfterContentInit {
  @Input() openModal = false;
  loadingSignatariosFormPesquisa = false;
  // documentSelected: IDocumento;
  assinanteLogado = [];
  // openModal: boolean;
  @Output() documentoSelecionado = new EventEmitter<IDocumento>();
  @Input() documentSelected;
  buscarSetorInput: number;

  dateFormat = 'dd/MM/yyyy HH:mm:ss';
  getMaskReturned: string;
  idDocSelected: number;
  assinanteSelected;
  fieldSelected;
  showComboTags = false;
  assinantesSelected = [];
  arrColumnsSelect = [];
  anexos: NzUploadFile[] = [];
  allAssinantes: any[];
  peopleArray = [];

  pageTotal: number;

  newDocument: IDocumento[] = [];
  filterType: number[];

  // previousValue = [1];

  loading = false;
  loadingSignatarios = false;
  loadingPanel = false;

  configuracao: any;
  nomeAnexo = [];

  expiracao: number;

  checkedAll = false;
  checkAllIndeterminate = true;

  searchInput: any;
  displayData = [];

  fieldsSelected = [];
  tiposModalidadesLicitacaoArr = Object.entries(TIPOS_MODALIDADE_LICITACO);
  tiposModalidadesLicitacaoArrSelect;
  tipoModalidadeLicitacaoValue;
  selectedValues = [];

  assinarAnexos: boolean;

  paramsWhere: any;

  intervalsTime = Object.entries(IntervalsTime);

  documentList = [];
  allDocuments: IDocumento[];

  statusDoc = StatusDoc;
  statusDocLabel = StatusDocLabel;

  loadingTipoDoc = false;
  loadingPessoaFisica = false;
  loadingPessoaJuridica = false;

  searchColumns: ISearchFieldsProps[] = [];
  tableColumns: IColumnsFieldsProps[] = [];

  tabIndex: 0 | 1 = 0;

  formB: FormGroup = new FormGroup({});
  model: any = {};

  rowData;

  file: string | ArrayBuffer | any;

  adm: boolean;

  dataLimiteAssinatura: Date;

  intervaloDigitando: any;
  intervaloDigitandoSetor: any;
  checkList = {
    assinantes: true,
    acoes: true,
    anexos: true,
    entidadeAnexos: true,
  };
  checkListConsulta = {
    numeroDoc: true,
    dataLimiteAssinatura: true,
    tipoDoc: true,
    idSetor: true,
    numProcesso: true,
    tipoDocumento: true,
    dataDocumentoFormated: true,
    numeroDocOriginal: true,
    status: true,
    chancela: true,
    ratificado: true,
    numeroLicitacao: true,
    modalidadeLicitacao: true,
    numeroObra: true,
    numeroContrato: true,
    numeroEvento: true,
    numeroConvenio: true,
    numeroProgramaInstitucional: true,
    usuarioCriacao: true,
    acoes: true,
  };

  documentoAvulsoModal;

  allSignatarios = [];

  panels = [{ active: true, name: 'Filtros' }];

  fabButtons: IMenuButton[];

  colorStatusDoc = ColorStatusDoc;

  hasCertificado;

  totalAssinaturas: string;
  listSignatarios = [];
  listSignatariosToSign = [];
  listaPessoas = [];
  listedAttachs: boolean;

  dataDocumentosUpload: Array<{ pag: number; documents: Array<IDocumento> }> =
    [];

  signatarios = [];

  certificadoAssinante: any[];
  public innerWidth: any;
  public tooltipVisible: any = {
    filterType1: false,
    filterType2: false,
    filterType3: false,
    filterType4: false,
    filterType5: false,
    filterType6: false,
    filterDocuments: false,
  };
  constructor(
    @Optional()
    private modalRef: NzModalRef<DocumentsUploadComponent>,
    private formBuilder: FormBuilder,
    private sanitizer: DomSanitizer,
    public service: DocumentsUploadService,
    private modalService: NzModalService,
    private tipoDocService: TipoDocumentoService,
    private pessoaFisicaService: PessoaFisicaService,
    private pessoaJuridicaService: PessoaJuridicaService,
    public documentService: DocumentService,
    private modal: NzModalService,
    private activateRouter: ActivatedRoute,
    protected authService: AuthService,
    private assinantesService: AssinantesService,
    private readonly setorService: SetorService,
    private router: Router,
  ) {
    this.admUser();
    this.getConfiguracaoExp();
    // this.intervalTimeSelected = '7';
    this.activateRouter.queryParamMap.subscribe(async (queryParams) => {
      this.filterPreSet(queryParams);
      const idDocument = queryParams.get('idDocument');
      if (idDocument) {
        this.loading = true;
        this.editDoc(
          await this.documentService.getDocumentById(Number(idDocument)),
        );
      }
    });

    this.filterType = [];
    this.tiposModalidadesLicitacaoArrObj();
    this.tipoModalidadeLicitacaoValue =
      this.tiposModalidadesLicitacaoArrSelect[0].value;
    this.formB = this.formBuilder.group({
      idPrivado: [null],
      psHash: [null],
      documentB64: [null, Validators.required],
      certByDocumentForSign: [null],
      entidadeTipoDoc: [null],
      entidadePessoaFisica: [null],
      entidadePessoaJuridica: [null],
      entidadePessoaFisicaAnexo: [null],
      entidadePessoaJuridicaAnexo: [null],
      dataLimiteAssinatura: [this.dataLimiteAssinatura],
      numeroDoc: [null, Validators.required],
      idTipoDocumento: [null, Validators.required],
      tipo: [{ value: null, disabled: true }],
      idSetor: [null],
      descricaoSetor: [{ value: null, disabled: true }],
      numeroLicitacao: [null],
      cpfPessoaFavorecido: [null],
      idPessoaFavorecido: [null],
      nomeFavorecido: [{ value: null, disabled: true }],
      modalidadeLicitacao: [
        { value: this.tiposModalidadesLicitacaoArrSelect[0].value },
      ],
      numeroObra: [null],
      numeroContrato: [null],
      numeroConvenio: [null],
      numeroEvento: [null],
      numeroProgramaInstitucional: [null],
      valorDoc: [null],
      informacoesAdicionais: [null],
      entidadeSetor: [null],
      historicoDoc: [null, Validators.required],
      idUnidadeGestora: [null, Validators.required],
      numProcesso: [null],
      chancela: [true],
      reaproveitar: [false],
      nome: [{ value: null, disabled: true }],
      nomeAnexo: [{ value: null, disabled: true }],
      razaoSocial: [{ value: null, disabled: true }],
      razaoSocialAnexo: [{ value: null, disabled: true }],
      cargo: [{ value: null, disabled: true }],
      cargoAnexo: [{ value: null, disabled: true }],
      matricula: [{ value: null, disabled: true }],
      matriculaAnexo: [{ value: null, disabled: true }],
      typePeopleSelected: [0],
      typePeopleAnexoSelected: [0],
      email: [{ value: null, disabled: true }],
      emailAnexo: [{ value: null, disabled: true }],
      cpf: [null],
      cpfAnexo: [null],
      vinculacao: [null],
      idTipoDocumentoAnexo: [null],
      tituloManifesto: [null],
      tipoDocumentoAnexo: [{ value: null, disabled: true }],
      descricao: [null],
      cnpj: [null],
      cnpjAnexo: [null],
      nomCidade: [{ value: null, disabled: true }],
      nomCidadeAnexo: [{ value: null, disabled: true }],
      signatarios: this.formBuilder.array([]),
      signatariosAnexo: this.formBuilder.array([]),
      signatario: [null],
      signatarioAnexo: [null],
      usCriacao: [null],
      dtCriacao: [null],
      anexos: this.formBuilder.array([]),
      entidadeAnexos: this.formBuilder.array([]),
      entidadeTipoDocAnexo: [null],
      idDocVinculado: [null],
      numeroDocOriginal: [{ value: null, disabled: true }],
    });

    this.authService.dateSelectedSubject.subscribe((date) => {
      this.formB.controls.dataLimiteAssinatura.setValue(
        this.documentService.formatDate23hrs(date),
      );
    });

    // this.documentService.updateDspAvulso.subscribe(async (msg) => {
    //   if (Boolean(msg) === true) {
    //     await this.getDocumentsPerPeriod();
    //   }
    // });

    this.resetFabButtons();
    if (this.openModal) {
      this.tabIndex = 1;
    }
  }

  public tiposModalidadesLicitacaoArrObj() {
    const tipoModalidadeLititacao: Array<{
      text: string | TIPOS_MODALIDADE_LICITACO;
      value: number;
    }> = [];
    for (const [value, text] of this.tiposModalidadesLicitacaoArr) {
      if (!isNaN(Number(value)))
        tipoModalidadeLititacao.push({ value: Number(value), text });
    }

    this.tiposModalidadesLicitacaoArrSelect = tipoModalidadeLititacao;
  }

  resetFabButtons() {
    const buttons: IMenuButton[] = [
      {
        icon: 'form',
        tooltip: 'Assinar',
        color: 'blue',
        condition: this.tabIndex === 1,
        onClick: this.assinar,
      },

      {
        icon: 'close-circle',
        tooltip: 'Discordar',
        color: 'red',
        condition: this.tabIndex === 1,
        onClick: this.discordar,
      },
      {
        icon: 'rollback',
        tooltip: 'Desfazer Desacordo',
        color: 'yellow',
        condition: this.tabIndex === 1,
        onClick: this.desfazer,
      },
      {
        icon: 'download',
        tooltip: 'Download',
        condition: this.tabIndex === 1,
        onClick: this.downloadDocuments,
      },
      {
        icon: 'plus',
        tooltip: 'Novo Cadastro',
        condition: true,
        onClick: this.implantar,
      },
      {
        icon: 'save',
        tooltip: this.formB.value.idPrivado ? 'Editar' : 'Salvar',
        color: 'green',
        condition:
          this.tabIndex === 0 &&
          (!this.formB.value.idPrivado || this.formB.value.idPrivado),
        onClick: this.enviar,
      },
      {
        icon: 'stop',
        tooltip: 'Cancelar',

        condition:
          (this.tabIndex === 0 && this.formB.value.idPrivado) ||
          this.tabIndex === 1,
        onClick: this.cancelar,
      },
      {
        icon: 'search',
        tooltip: 'Consultar',
        condition: this.tabIndex === 0,
        onClick: this.consultar,
      },
      {
        icon: 'printer',
        tooltip: 'Imprimir',
        condition: this.tabIndex === 1,
        onClick: this.imprimirArquivos,
      },
      {
        icon: 'select',
        tooltip: 'Selecionar',
        condition: this.openModal,
        onClick: this.selecionar,
      },
      {
        icon: 'reload',
        tooltip: 'Atualizar',
        condition: true,
        onClick: this.attDashboard,
      },
    ];

    const buttonsFilteres = buttons.filter((button) => button.condition);
    this.buildFabButtons(buttonsFilteres);
  }

  async ngAfterContentInit() {
    await this.setConfigTable();
    // this.reset();
  }

  doubleClick(item, indexArr) {
    this.openModal
      ? this.selecionar(item)
      : this.navigateToDocumentDetail(item, indexArr);
  }

  imprimirArquivos = async () => {
    if (this.dataDocumentsUploadCheckeds().length === 0) {
      return this.service.notification.info(
        'Imprimir arquivos',
        'Selecione algum arquivo para imprimir',
      );
    }

    const title = `<i nz-icon nzType="warning" nzTheme="outline">Confirma impressão de Documentos?</i>`;
    const okText = 'confirmar';
    const cancelText = 'Cancelar';
    const onOk = async (motivo) => {
      await this.imprimirDocs({
        docs: motivo.docsPrintChecked,
        anxs: motivo.anxsPrintChecked,
      });
      this.modal.closeAll();
    };

    const modal = this.modal.create({
      nzTitle: title,
      nzContent: ModalDialogComponent,
      nzFooter: [
        {
          label: cancelText,
          onClick: () => {
            modal.destroy();
            this.loading = false;
          },
        },
        {
          label: okText,
          disabled: (componentInstance) =>
            !(
              componentInstance.docsPrintChecked ||
              componentInstance.anxsPrintChecked
            ),
          type: 'primary',
          onClick: (componentInstance) => onOk(componentInstance),
        },
      ],
      nzComponentParams: { isPrinter: true },
    });

    modal.afterClose.subscribe((resultClose: any) => {
      if (!resultClose) {
        modal.destroy();
      }
    });
  };

  async imprimirDocs(print) {
    const urlDocumentos = [];
    const urlAnexos = [];
    const anexos = [];
    let files = this.dataDocumentsUploadCheckeds();
    let printFiles;

    if (this.checkedAll && this.documentList.length > 9) {
      await this.getDocumentsPerPeriod(this.checkedAll);
      let docsChecks = this.documentList.map((itm) => {
        return {
          ...itm,
          checked: true,
        };
      });
      files = this.documentList;
    }

    for (const file of files) {
      const typeView = TypeDocView[file.statusDocumento];

      const uuid = await this.documentService.getUuidFile(typeView, file);

      const typeAction = TypeActions[file.statusDocumento];

      const urlDocToView = await this.documentService.getUrlDocToUploadApi(
        uuid,
        typeAction,
      );

      urlDocumentos.push(urlDocToView.pdfURL);

      file.documentoAnexo.forEach(async (anexo) => {
        const typeViewAnexo = TypeAnexoView[anexo.status];

        const uuidAnexo = await this.documentService.getUuidFileAnexo(
          typeViewAnexo,
          anexo,
        );

        const urlAnexo = await this.documentService.getUrlDocToUploadApi(
          uuidAnexo,
          typeViewAnexo,
        );

        urlAnexos.push(urlAnexo.pdfURL), anexos.push(anexo);
      });
    }
    if (print.docs && !print.anxs) {
      printFiles = [{ tipo: 'documento', urlFiles: urlDocumentos }];
    }
    if (print.anxs && !print.docs) {
      printFiles = [{ tipo: 'anexo', urlFiles: urlAnexos }];
    }
    if (print.anxs && print.docs) {
      printFiles = [
        { tipo: 'anexo', urlFiles: urlAnexos },
        { tipo: 'documento', urlFiles: urlDocumentos },
      ];
    }

    let docsFilesLinks;
    let anxsFilesLinks;
    for (const printFile of printFiles) {
      printFile.tipo == 'documento'
        ? (docsFilesLinks = printFile.urlFiles)
        : (anxsFilesLinks = printFile.urlFiles);
    }
    const filesLinks =
      anxsFilesLinks && docsFilesLinks
        ? [docsFilesLinks, anxsFilesLinks]
        : anxsFilesLinks
        ? [anxsFilesLinks]
        : [docsFilesLinks];

    if (print.anxs) {
      for (const anexo of anexos) {
        this.getURLAnexo(anexo, true);
      }
    }

    if (print.docs) {
      await this.mergeAllPDFs(docsFilesLinks);
    }

    // const base64PDFMerged = await this.mergeAllPDFs(filesLinks);
    //https://printjs.crabbly.com/#documentation
  }

  async getURLAnexo(anexo: IDocumentoAnexo, download = false) {
    // this.pageLoading = true;
    return await this.documentService
      .getURLAnexo(anexo, download)
      .then((result) => {
        this.service.notification.success('Download success', 'Download');

        // this.eventTableDataList.push(result);
        // this.pageLoading = false;
      })
      .catch((e) => this.service.notification.error('Download Error', e));
  }

  async mergeAllPDFs(urls) {
    //https://stackoverflow.com/questions/21478738/how-can-we-do-pdf-merging-using-javascript
    const pdfDoc = await PDFDocument.create();
    const numDocs = urls.length;

    for (var i = 0; i < numDocs; i++) {
      const donorPdfBytes = await fetch(urls[i]).then((res) =>
        res.arrayBuffer(),
      );
      const donorPdfDoc = await PDFDocument.load(donorPdfBytes);
      const docLength = donorPdfDoc.getPageCount();
      for (var k = 0; k < docLength; k++) {
        const [donorPage] = await pdfDoc.copyPages(donorPdfDoc, [k]);
        pdfDoc.addPage(donorPage);
      }
    }

    if (numDocs === 0) {
      return this.service.notification.info(
        'Impressão de Documentos',
        'nenhum anexo encontrado',
      );
    }

    // if (docPagesTotal > 200) {
    //   return this.service.notification.info(
    //     'Impressão de Documentos',
    //     'Os Documentos selecionados possuem muitas páginas: ' + docPagesTotal,
    //     { nzDuration: 20000 },
    //   );
    // }

    const pdfDataUri = await pdfDoc.saveAsBase64({ dataUri: true });

    // strip off the first part to the first comma "data:image/png;base64,iVBORw0K..."
    const data_pdf = pdfDataUri.substring(pdfDataUri.indexOf(',') + 1);

    function printEmLotes(b64PDF) {
      //b64PDF => arquivos do lote
      //number => numero do lote
      return new Promise((resolve, reject) => {
        printJS({
          printable: b64PDF,
          type: 'pdf',
          showModal: false,
          onPrintDialogClose: async () => {
            resolve(true);
          },
          onError: (error) => {
            this.service.notification.error('Imprimir arquivos', error);
            reject(error);
          },
          base64: true,
        });
      });
    }

    // for (const number of [1, 2, 3, 4, 5]) {
    //teste de lotes
    await printEmLotes(data_pdf).then((bool) => {
      // printJS({
      //   printable: data_pdf,
      //   type: 'pdf',
      //   showModal: false,
      //   onPrintDialogClose: async () => {},
      //   onError: (error) => {
      //     this.service.notification.error('Imprimir arquivos', error);
      //   },
      //   base64: true,
      // });
    });
  }
  getDocumentSelected() {
    const documentos = this.dataDocumentsUploadCheckeds();
    if (documentos.length !== 1) {
      this.service.notification.warning(
        'Documentos avulsos',
        'Muitos ou nenhum documento selecionado!',
      );
      return;
    }
    const documento = documentos[0];
    return documento;
  }

  selecionar = (doc = this.getDocumentSelected()) => {
    this.modalRef.destroy(doc);
  };

  attDashboard = () => {
    this.resetAllCheckbox();
    this.reset();
    this.dataDocumentosUpload = [];
  };

  resetAllCheckbox() {
    this.dataDocumentosUpload = this.dataDocumentosUpload.map((pag) => {
      return {
        pag: pag.pag,
        documents: pag.documents.map((doc) => {
          return { ...doc, checked: false };
        }),
      };
    });
    this.documentList.map((itm) => {
      return {
        ...itm,
        checked: false,
      };
    });
    this.displayData = this.displayData.map((itm) => {
      return {
        ...itm,
        checked: false,
      };
    });
    // this.ratificarDoc = false;
    this.checkedAll = false;
    this.checkAllIndeterminate = true;
  }

  async tableProps() {
    await this.setConfigTable();
  }

  consultasBackground() {
    this.getAllAssinantes();
    this.setConfigTable();
  }

  ngOnInit(): void {
    // this.tabIndex = this.openModal ? 1 : 0;
    this.admUser();
    this.consultasBackground();
    if (this.openModal) {
      this.tabIndex = 1;
      this.setConfigTable();
      this.resetFabButtons();
    }

    this.formB.controls.dataLimiteAssinatura.setValue(
      this.documentService.formatDate23hrs(this.authService.getDateSelected()),
    );
    // setInterval(() => { //caso seja necessário atualizar a resolução em tempo de execução
    this.innerWidth = window.innerWidth;
    // }, 2000);
    this.displayData.map((dp) => {
      return {
        ...dp,
        tooltipVisibleTableRow: false,
        tooltipVisibleEye: false,
      };
    });
    this.filterPreSet();
    this.resetFabButtons();
  }

  filterPreSet(queryParams?) {
    const date = new Date();
    const fieldsSelectedHasDataDocumentoFormatedFilter: boolean =
      this.fieldsSelected.some(
        (field) => field.props === 'dataDocumentoFormated',
      );
    let primeiroDia: any = moment(
      new Date(date.getFullYear(), date.getMonth(), 1),
    ).format('DD/MM/YYYY');
    let diaAtual: any = moment().format('DD/MM/YYYY');

    //https://pt.stackoverflow.com/questions/226086/como-retornar-o-primeiro-e-%C3%BAltimo-dia-do-m%C3%AAs-corrente-em-javascript
    if (queryParams?.keys?.length > 0) {
      const { initialDate, finalDate, interval } = queryParams?.params;

      // const primeiroDiaIso = initialDate;
      primeiroDia = moment(initialDate).format('DD/MM/YYYY');
      diaAtual = moment(finalDate).format('DD/MM/YYYY');

      this.fieldsSelected = [];

      if (!fieldsSelectedHasDataDocumentoFormatedFilter) {
        if (interval && !(initialDate && finalDate)) {
          return this.fieldsSelected.push({
            field: 'Data do documento',
            props: 'dataDocumentoFormated',
            text: `Período - ${IntervalsTime[interval]}`,
            value: `${diaAtual}`,
          });
        }
      }
    }
    if (!fieldsSelectedHasDataDocumentoFormatedFilter)
      return this.fieldsSelected.push({
        field: 'Data do documento',
        props: 'dataDocumentoFormated',
        text: `Data do documento - ${primeiroDia} - ${diaAtual}`,
        value: `${primeiroDia} - ${diaAtual}`,
      });

    // this.showComboTags = this.fieldsSelected.length != 0;
  }

  showModalFavorecido() {
    const pessoaFisicaFavorecidoModal = this.modalService.create({
      nzTitle: 'Selecione uma pessoa física',
      nzContent: PessoaFisicaComponent,
      nzComponentParams: {
        openModal: true,
      },
      nzFooter: null,
      nzWidth: 1000,
    });

    pessoaFisicaFavorecidoModal.afterClose.subscribe((result) =>
      this.setDataPessoaFisicaFavorecido(result),
    );
  }

  setDataPessoaFisicaFavorecido(data) {
    this.formB.patchValue({
      cpfPessoaFavorecido: data.cpf,
      idPessoaFavorecido: data.idPessoa,
      nomeFavorecido: data.nome,
      entidadePessoaFavorecido: data,
    });
  }

  getEmail(id: number, index) {
    let email = 'Email não encontrado';

    for (const sig of this.displayData[index].signatarios) {
      if (sig.idPessoaFisica === id) {
        email = sig.email;
      }
    }
    return email;
  }

  // async ngAfterContentInit() {
  //   await this.getDocumentsPerPeriod();
  //   await this.setConfigTable();
  // }

  buildFabButtons(fabButtons: IMenuButton[]) {
    this.fabButtons = fabButtons;
  }

  async getAllAssinantes(avulso = true, signatarios?: IAssinante[]) {
    this.listSignatarios = [];
    this.loadingSignatariosFormPesquisa = true;
    const allSigners = await this.getAllSignatarios(avulso);
    // const allSignatarios = await this.getAllSignatarios(avulso);
    if (signatarios) {
      const idPessoa = signatarios.map((ass) => {
        return {
          idPessoaFisica: ass.idPessoaFisica,
          idPessoaJuridica: ass.idPessoaJuridica,
          idResponsavel: ass.idResponsavel,
        };
      });

      const seen = new Set();

      const filteredArr = idPessoa.filter((el) => {
        const duplicate = seen.has(
          el.idPessoaFisica || el.idPessoaJuridica || el.idResponsavel,
        );
        seen.add(el.idPessoaFisica || el.idPessoaJuridica || el.idResponsavel);
        return !duplicate;
      });
      for (const signers of filteredArr) {
        this.getAnyPeoples(signers);
      }
    }

    signatarios
      ? (this.listSignatariosToSign = this.peopleArray)
      : (this.listSignatarios = allSigners);
    this.loadingSignatariosFormPesquisa = false;
  }

  addFiltroPorAssinante() {
    if (this.assinanteSelected) {
      this.assinantesSelected.push(this.assinanteSelected);

      this.fieldsSelected.push({
        props: 'assinante',
        field: 'Assinante',
        value: this.assinanteSelected.cpf,
        text: `${this.assinanteSelected.cpf} - ${this.assinanteSelected.nome}`,
      });
      this.showComboTags = true;
    }

    setTimeout(() => (this.assinanteSelected = null), 0);
  }

  addFiltroPorCampo() {
    if (this.fieldSelected && this.searchInput) {
      if (this.fieldSelected.type === 'range-picker') {
        this.fieldsSelected = this.fieldsSelected.filter((el) => {
          return el.field != 'Data do documento';
        });
        this.searchInput = `${moment(this.searchInput[0]).format(
          'DD/MM/yyyy',
        )} - ${moment(this.searchInput[1]).format('DD/MM/yyyy')}`;
      }

      if (this.fieldSelected.type === 'date-picker') {
        this.searchInput = moment(this.searchInput).format('DD/MM/yyyy');
      }

      this.fieldsSelected.push({
        props: this.fieldSelected.value,
        field: this.fieldSelected.text,
        value: this.searchInput.text ? this.searchInput.text : this.searchInput,
        text: `${this.fieldSelected.text} - ${
          this.searchInput.text ? this.searchInput.text : this.searchInput
        }`,
      });

      const index = this.arrColumnsSelect.findIndex(
        (a) => a.value === this.fieldSelected.value,
      );

      this.arrColumnsSelect.splice(index, 1);

      this.showComboTags = true;
      this.fieldSelected = '';
      this.searchInput = '';
      this.selectedValues = [];
    }
  }

  // getMask(column) {
  //   this.getOptionsSelect(column);
  //   this.searchInput = '';
  //   clearTimeout(this.intervaloDigitando);
  //   this.intervaloDigitando = setTimeout(() => {
  //     if (column.mask) {
  //       this.getMaskReturned = Helper.returnMask(column.mask);
  //     }
  //   }, 1000);
  // }

  async getOptionsSelect(column) {
    let arrTypes = [];
    this.selectedValues = [];

    if (column?.objectChildren === 'tag') {
      arrTypes = await this.tipoDocService.getTipoDocByTag();
    }

    if (column?.value === 'tipoDoc') {
      arrTypes = await this.tipoDocService.getTipoDocs(null, true);
    }

    if (column?.value === 'tipoDoc' || column?.objectChildren === 'tag') {
      for (const teste of arrTypes) {
        this.selectedValues.push({
          field: this.fieldSelected.text,
          value: column.objectChildren === 'tag' ? teste.tag : teste.tipo,
          text:
            column.objectChildren === 'tag'
              ? teste.tag
              : `${teste.idPublico} - ${teste.tipo}`,
        });
      }
    }
    if (column?.value === 'idSetor') {
      const setores: ISetor[] = await this.setorService.listarSetor();
      this.selectedValues = setores
        .map((setor: ISetor) => ({
          field: this.fieldSelected.text,
          value: setor.idPublico,
          text: `${setor.idPublico} - ${setor.descricao}`,
        }))
        .reverse();
    }
  }

  columnsSelect(tableColumns) {
    this.arrColumnsSelect = this.documentService.getColumnsSelect(tableColumns);
  }

  removeFiltroPorField(index) {
    const indx = this.assinantesSelected.findIndex(
      (a) => a.cpf === this.fieldsSelected[index].value,
    );

    if (indx > -1) {
      this.assinantesSelected.splice(indx, 1);
    }

    this.fieldsSelected.splice(index, 1);

    this.columnsSelect(this.tableColumns);

    if (this.fieldsSelected.length === 0) {
      this.showComboTags = false;
    }
  }

  removeFiltroPorAssinante(cpf) {
    this.documentService.removeSubscriberToFilter(
      this.assinantesSelected,
      this.listSignatarios,
      cpf,
    );
  }

  async reset() {
    await this.getDocumentsPerPeriod();
    await this.setConfigTable();
  }

  setFilterType(type: number) {
    this.filterType = this.documentService.updateFilterType(
      this.filterType,
      type,
    );
    // setFilterType[type]
    if (this.innerWidth <= 748) {
      this.tooltipVisible[`filterType${type}`] = true;
      setTimeout(() => {
        this.tooltipVisible[`filterType${type}`] = false;
      }, 2000);
    }
  }

  toggleTooltipVisible(item) {
    item.tooltipVisibleTableRow = !item.tooltipVisibleTableRow;

    setTimeout(() => {
      item.tooltipVisibleTableRow = false;
    }, 2000);
  }

  toggleTooltipVisibleEye(item) {
    item.tooltipVisibleEye = !item.tooltipVisibleEye;

    setTimeout(() => {
      item.tooltipVisibleEye = false;
    }, 2000);
  }

  async getSignatarios(idDoc: number): Promise<IAssinante[]> {
    return await this.documentService.getSignatarioByIdDocumento(idDoc);
  }

  async getAllSignatarios(avulso) {
    return await this.documentService.getAllSignatarios(avulso);
  }

  async getInfoSignatarios(documentos) {
    this.listaPessoas = [];
    let teste = [];
    for (const doc of documentos) {
      teste = await this.getSignatarios(doc.idPrivado);
      // teste.map(async (ass) => {
      //   const pessoaFisica = await this.service.getPessoasById(ass.idPessoa);
      //   const pessoaJuridica =
      //     pessoaFisica.pessoa?.pessoaJuridica?.responsaveis?.idPessoaFisica;
      //   const pessoaJuridicaResponsavel = pessoaJuridica
      //     ? await this.service.getPessoasFisicasById(pessoaJuridica)
      //     : null;
      //   return {
      //     nome: pessoaFisica.pessoa.nome,
      //     nomeRepresentante: pessoaJuridicaResponsavel?.pessoa?.nome,
      //     status: ass.status,
      //     dataAssinatura: ass.dataAssinatura,
      //     razaoSocial: pessoaFisica.pessoa?.pessoaJuridica?.nomFantasia,
      //     idPessoa: ass.idPessoa,
      //     idDocumento: ass.idDocumento,
      //   };
      // });
    }
  }

  dataDocumentsUploadCheckeds(): Array<IDocumento> {
    const documentsCheckeds = [];
    for (const docPag of this.dataDocumentosUpload) {
      docPag.documents.forEach((document) => {
        if (document.checked) {
          documentsCheckeds.push(document);
        }
      });
    }
    return documentsCheckeds;
  }

  getDocsToDataDocumentsUpload(): Array<IDocumento> {
    const documents = [];
    for (const docPag of this.dataDocumentosUpload) {
      docPag.documents.forEach((document) => {
        documents.push(document);
      });
    }
    return documents;
  }

  async getDocumentsPerPeriod(
    checkAll = false,
    typePeriod?: PeriodVisualizeDoc,
    skip?: number,
    take?: number,
  ) {
    this.loading = true;
    if (
      this.dataDocumentosUpload.find((data) => {
        return data.pag === skip;
      })
    ) {
      const { documents } = this.dataDocumentosUpload.find((data) => {
        return data.pag === skip;
      });

      const docs = this.checkedAll
        ? documents.map((doc) => {
            return { ...doc, checked: this.checkedAll };
          })
        : documents;

      return await this.formatResultGetDocuments(docs);
    }

    await this.documentService
      .getAllDocumentsInInterval(
        null,
        this.assinantesSelected,
        null,
        true,
        null,
        this.filterType,
        this.fieldsSelected,
        checkAll,
        skip,
        take,
      )
      .then(async (documents: any) => {
        const pag = skip || 1;

        const documentsData = this.checkedAll
          ? documents.data.resultado.map((doc) => {
              return { ...doc, checked: this.checkedAll };
            })
          : documents.data.resultado;

        if (
          this.dataDocumentosUpload.length === 0 ||
          !this.dataDocumentosUpload.find((data) => {
            return data.pag === pag;
          })
        ) {
          this.dataDocumentosUpload.push({
            pag,
            documents: documentsData,
          });
        }
        this.pageTotal = documents.data.total;
        this.paramsWhere = documents.where;
        await this.formatResultGetDocuments(documentsData);
        this.loading = false;
      })
      .catch(() => (this.loading = false));
  }

  async pageChange(valor) {
    // this.previousValue.unshift(Number(valor));

    // if (Number(valor) !== this.previousValue[1]) {
    // }
    await this.getDocumentsPerPeriod(false, null, Number(valor), 10);
  }

  async getAnyPeoples(assinante) {
    const pessoaFisica =
      assinante.idPessoaFisica !== null &&
      assinante.idPessoaFisica !== undefined
        ? await this.documentService.getPessoasById(assinante.idPessoaFisica)
        : null;

    const pessoaJuridica =
      assinante.idPessoaJuridica !== null &&
      assinante.idPessoaJuridica !== undefined
        ? await this.documentService.getPessoaJuridicaById(
            assinante.idPessoaJuridica,
          )
        : null;

    const pessoaJuridicaResponsavel =
      assinante.idResponsavel !== null && assinante.idResponsavel !== undefined
        ? await this.service.getPessoaFisicaByPessoaId(assinante.idResponsavel)
        : null;

    this.peopleArray.push({
      nome: pessoaFisica?.pessoa.nome,
      idPessoaFisica: pessoaFisica?.id,
      idPessoaJuridica: pessoaJuridica?.id,
      cpf: pessoaFisica?.cpf,
      cnpj: pessoaJuridica?.pessoa.pessoaJuridica?.cnpj,
      nomeRepresentante: pessoaJuridicaResponsavel?.pessoa?.nome,
      cpfRepresentante: pessoaJuridicaResponsavel?.cpf,
      razaoSocial: pessoaJuridica?.razaoSocial,
      email:
        pessoaFisica?.pessoa?.emailPrincipal ||
        pessoaJuridicaResponsavel?.pessoa?.emailPrincipal,
      text: pessoaFisica
        ? `${pessoaFisica?.cpf} - ${pessoaFisica?.pessoa.nome}`
        : `${pessoaJuridica?.cnpj} - ${pessoaJuridica?.razaoSocial}`,
    });
  }

  filterDocuments() {
    this.formSearchValidate();
    this.attDashboard();
    this.dataDocumentosUpload = [];
    this.getDocumentsPerPeriod();
    this.resetAllCheckbox();
  }

  formSearchValidate() {
    if (this.filterType.length === 0) {
      throw this.documentService.notification.info(
        'Selecionar Status',
        'Selecione algum status para poder aplicar os filtros!',
        { nzDuration: 7000 },
      );
    }

    if (this.fieldsSelected.length === 0) {
      throw this.documentService.notification.info(
        'Buscar',
        'Selecione ao menos algum filtro combinado.',
        { nzDuration: 7000 },
      );
    }

    if (
      !this.fieldsSelected.find((key) => key.props === 'dataDocumentoFormated')
    ) {
      throw this.documentService.notification.info(
        'Buscar',
        'A seleção do filtro Data do Documento é obrigatória!',
        { nzDuration: 7000 },
      );
    }
  }

  async modifyDocument(document: IDocumento, expand: boolean, index = 0) {
    this.loadingSignatarios = true;
    try {
      if (document.signatarios.some((a) => a.nome)) {
        this.newDocument.push(this.displayData[index]);
        this.loadingSignatarios = false;
        return;
      }
      if (expand === true) {
        const pessoaReturned =
          await this.documentService.getSignatarioByIdDocumento(
            document.idPrivado,
          );

        const documentFormatted = Object.assign(document, {
          signatarios: pessoaReturned.sort((a, b) => {
            if (a.nome < b.nome) {
              return -1;
            }
            if (a.nome > b.nome) {
              return 1;
            }
            return 0;
          }),
        });
        this.loadingSignatarios = false;
        this.newDocument.push(documentFormatted);
      }
    } catch (error) {
      this.loadingSignatarios = false;
    }
  }

  async formatResultGetDocuments(documents: IDocumento[]) {
    this.displayData = documents.map((itm: IDocumento) => {
      return {
        ...itm,
        dataDocumentoFormated: moment(itm.dataDocumento)
          .utcOffset(0, true)
          .format(),
        aplicacaoOrigemDetalhe:
          ModulosPublicSoft['Assinatura Digital'] === itm.aplicacaoOrigem
            ? 'Avulso'
            : CodigoModulos[itm.aplicacaoOrigem],
        tipoDoc: `${itm.tipoDocumento.idPublico} - ${itm.tipoDocumento.tipo}`,
        numeroDocOriginal: itm.documentoVinculado?.numeroDoc ?? null,
        idSetor: itm.setor
          ? `${itm.setor.idPublico} - ${itm.setor.descricao}`
          : '',

        // documentoAnexo: {
        //   ...itm.documentoAnexo,
        //   tipoDocumentoAnexo: `${itm.tipoDocumento.idPublico} - ${itm.tipoDocumento.tipo}`
        // },
        expand: false,
      };
    });
    // documents = documents.reverse();
    // this.documentList.unshift(...displayData);
    // this.documentList.sort((a, b) => {
    //   return b.idPrivado - a.idPrivado;
    // });
    // }

    documents.map(async (doc: IDocumento) => {
      await this.documentService.formatColumnsDocForDysplay(
        doc,
        this.listSignatarios,
        doc.checked,
      );
    });

    // if (this.documentList.length === 0) {
    // }
    this.documentList = this.displayData;

    await this.setConfigTable();
  }

  async setConfigTable() {
    this.service.getDocumentTableProps().then((result) => {
      Object.assign(this, result);
      this.documentService.setCompareToTableColumns(
        this.displayData,
        this.tableColumns,
      );
    });
    this.columnsSelect(this.tableColumns);
  }

  getStatusCheckDocument(item: IDocumento) {
    return (
      (item.dataLimiteAssinatura < new Date().toISOString() &&
        item.statusDocumento === StatusDocumento.Pendente) ||
      (item.statusDocumento !== StatusDocumento.Confirmado &&
        item.statusDocumento !== StatusDocumento.Pendente &&
        item.statusDocumento !== StatusDocumento.Desacordo) ||
      this.listSignatarios.some(
        (ass) =>
          (ass.cpf ?? ass.signatario) ===
            this.documentService.authService.getCpfUser() &&
          ass.status !== StatusDocumento.Assinado &&
          ass.status !== StatusDocumento.Pendente &&
          ass.status !== StatusDocumento.Desacordo,
      ) ||
      this.listSignatarios.every(
        (ass) =>
          (ass.cpf ?? ass.cpfRepresentante) !==
          this.documentService.authService.getCpfUser(),
      )
    );
  }

  check(doc: IDocumento, check) {
    // this.resetFabButtons();
    this.displayData
      .filter((key) => key.idPrivado === doc.idPrivado)
      .map((itm) => ({
        ...itm,
        checked: !check,
      }));
    this.dataDocumentosUpload = this.dataDocumentosUpload.map(
      (dataDocuments) => {
        let dataDoc = dataDocuments.documents.map((document) => {
          if (document.idPrivado === doc.idPrivado) {
            return {
              ...document,
              checked: !check,
            };
          }
          return {
            ...document,
          };
        });

        return {
          pag: dataDocuments.pag,
          documents: dataDoc,
        };
      },
    );

    this.checkAllIndeterminate = !(
      this.dataDocumentsUploadCheckeds().length === this.pageTotal
    );

    this.checkedAll =
      this.dataDocumentsUploadCheckeds().length === this.pageTotal;

    // if (
    //   !this.getDocsToDataDocumentsUpload().find(
    //     (registro) =>
    //       registro.checked === false &&
    //       registro.statusDocumento !== StatusDocumento.Assinado,
    //   ) ||
    //   (this.getDocsToDataDocumentsUpload().filter(
    //     (registro) =>
    //       registro.checked === false &&
    //       registro.statusDocumento !== StatusDocumento.Assinado,
    //   ).length === 1 &&
    //     this.checkedAll)
    // ) {
    //   this.checkAllIndeterminate = !this.checkAllIndeterminate;
    //   this.checkedAll = !this.checkedAll;
    // }
  }

  async updateAllChecked() {
    this.dataDocumentosUpload = this.dataDocumentosUpload.map(
      (dataDocuments) => {
        let dataDoc = dataDocuments.documents.map((document) => {
          return {
            ...document,
            checked: !this.checkedAll && this.checkAllIndeterminate,
          };
        });

        return {
          pag: dataDocuments.pag,
          documents: dataDoc,
        };
      },
    );

    this.displayData = this.displayData.map((doc) => {
      return {
        ...doc,
        checked: !this.checkedAll && this.checkAllIndeterminate,
      };
    });

    await this.documentService
      .updateAllChecked(
        this.documentList,
        this.checkedAll,
        this.checkAllIndeterminate,
        this.listSignatarios,
      )
      .then((result) => Object.assign(this, result));
  }

  navigateToDocumentDetail(item: IDocumento, index) {
    this.idDocSelected = item.idPrivado;
    this.modifyDocument(item, true, index);
    this.showModalDetailDocument(item);
  }

  showModalDetailDocument(document: IDocumento) {
    // const userInfo = this.authService.getUserInfo();
    document.modalidadeLicitacao =
      TIPOS_MODALIDADE_LICITACO[Number(document.modalidadeLicitacao)];

    const filterType = this.filterType;
    this.modal.create({
      nzTitle: `<h4>Detalhes do documento</h4>`,
      nzWidth: window.innerWidth * 0.7,
      nzContent: DocumentsDetailComponent,
      nzComponentParams: { document, filterType },
      nzFooter: [],
    });
    this.modal.afterAllClose.subscribe(() => {
      // this.updateDisplayDocs(this.idDocSelected ? [this.idDocSelected] : []);
      this.idDocSelected = null;
    });
  }

  updateDisplayDocs(idsDocument: number[]) {
    if (this.documentList.length === 0 || idsDocument?.length === 0) {
      return;
    }

    this.loading = true;
    const listDocs = this.documentList;
    this.documentList = [];
    this.displayData = [];

    const indexBeforeDocs: number[] = [];
    const newDocs = [];
    for (const id of idsDocument) {
      const indexDoc = listDocs.findIndex((doc) => doc.idPrivado === id);

      indexBeforeDocs.push(indexDoc);
    }

    newDocs.forEach((doc, index) => {
      this.documentService.formatColumnsDocForDysplay(
        doc,
        this.listSignatarios,
      );
      listDocs.splice(indexBeforeDocs[index], 1, doc);
    });

    setTimeout(() => {
      this.documentService
        .setDocumentsData(
          listDocs,
          this.filterType,
          this.documentList,
          this.displayData,
          this.listSignatarios,
          this.assinanteLogado,
        )
        .then((result) => Object.assign(this, result));
      this.loading = false;
    }, 0);
  }

  async viewDoc(doc: IDocumento) {
    this.loading = true;
    const typeView = TypeDocView[doc.statusDocumento];
    const typeAction = TypeActions[doc.statusDocumento];
    const uuid = await this.documentService.getUuidFile(typeView, doc);
    const urlDocToView = await this.documentService.getUrlDocToUploadApi(
      uuid,
      typeAction,
    );
    this.loading = false;

    uuid ? window.open(urlDocToView.pdfURL, '_blank') : null;

    if (urlDocToView.events) {
      doc.eventos.push(urlDocToView.events);
    }
  }

  async downloadDoc(doc: IDocumento, type: string) {
    this.loading = true;
    const event = await this.documentService.downloadDocWithUrlToUploadApi(
      await this.documentService.getUuidFile(type, doc),
      type,
      doc.tipoDocumento.tipo,
      doc.numeroDoc,
    );
    doc.eventos.push(event);
    setTimeout(() => (this.loading = false), 3000);
  }

  downloadDocuments = async () => {
    this.loading = true;
    let newDocs: IDocumento[];
    if (this.checkedAll && this.documentList.length > 9) {
      await this.getDocumentsPerPeriod(this.checkedAll);
      newDocs = this.documentList.map((itm) => {
        return {
          ...itm,
          checked: true,
        };
      });
    }
    const docCheckeds =
      newDocs.length ?? this.dataDocumentsUploadCheckeds().length;

    if (!docCheckeds && !this.documentList) {
      this.loading = false;
      return this.documentService.notification.info(
        'Download',
        'Selecione algum arquivo para realizar o download!',
      );
    }

    const doc = newDocs ?? this.dataDocumentsUploadCheckeds();
    for (const documents of doc) {
      const typeDownload = TypeDocDownload[documents.statusDocumento];

      const event = await this.documentService.downloadDocWithUrlToUploadApi(
        await this.documentService.getUuidFile(typeDownload, documents),
        typeDownload,
        documents.tipoDocumento.tipo,
        documents.numeroDoc,
      );

      documents.eventos.push(event);
      this.loading = false;
    }
  };

  async notifySigner(idPessoaFisica: number, idDocumento: number) {
    const pessoaFisica = await this.service.getPessoasFisicasById(
      idPessoaFisica,
    ); // TODO
    this.loading = true;
    await this.documentService.notifySubscriber(pessoaFisica, [idDocumento]);
    this.loading = false;
  }

  assinar = async () => {
    this.listSignatariosToSign = [];
    let newDocs: IDocumento[];
    let docsChecks: IDocumento[];
    let body = [];

    if (this.checkedAll && this.documentList.length > 9) {
      await this.getDocumentsPerPeriod(this.checkedAll);
      docsChecks = this.documentList.map((itm) => {
        return {
          ...itm,
          checked: true,
        };
      });
    }
    const documentList = docsChecks
      ? docsChecks
      : this.getDocsToDataDocumentsUpload();

    const docCheckeds = this.dataDocumentsUploadCheckeds().length;

    const confirmedDocs = documentList.filter((doc) => doc.checked);
    if (docCheckeds === 0) {
      return this.documentService.notification.info(
        'Assinar documento',
        'Não foi possivel assinar o documento, pois não foi selecionado nenhum documento!',
        { nzDuration: 7000 },
      );
    }

    if (confirmedDocs.some((key) => key.ratificado === true)) {
      // this.resetAllCheckbox();

      return this.documentService.notification.info(
        'Assinar documento',
        'Não foi possivel assinar o documento, pois o documento está em processo de ratificação, contudo apenas sendo possível ratificá-lo!',
        { nzDuration: 7000 },
      );
    }

    this.loading = true;

    const docsIdsPrivados: number[] = [];

    for (const ext of confirmedDocs) {
      this.listedAttachs = ext.documentoAnexo.length > 0 ? true : false;

      if (ext.statusDocumento === StatusDocumento.Confirmado) {
        this.loading = false;
        return this.documentService.notification.info(
          'Assinar documento',
          'Não foi possivel assinar o documento, pois o(s) documento(s) selecionado(s) está(ão) com status Concluído!',
          { nzDuration: 7000 },
        );
      }
      if (ext.statusDocumento === StatusDocumento.Cancelado) {
        this.loading = false;
        return this.documentService.notification.info(
          'Assinar documento',
          'Não foi possivel assinar o documento, pois o(s) documento(s) selecionado(s) está(ão) com status Cancelado!',
          { nzDuration: 7000 },
        );
      }
      docsIdsPrivados.push(ext.idPrivado);

      // this.listSignatariosToSign.push(...arrSigners);
    }

    const arrSigners =
      await this.documentService.getSignatarioByIdDocumentoInLote(
        docsIdsPrivados,
      );
    // const arrSigners = await this.documentService.getSignatarioByIdDocumento(
    //   ext.idPrivado,
    // );

    this.listSignatariosToSign.push(...arrSigners);

    newDocs = await confirmedDocs.map((itm) => {
      const pessoa = this.listSignatariosToSign.filter(
        (key) => key.idDocumento === itm.idPrivado,
      );
      return {
        ...itm,
        signatarios: (itm.signatarios = pessoa),
      };
    });

    if (newDocs.length < 1) {
      for (const docs of newDocs) {
        if (
          !docs.signatarios.some(
            (item) =>
              item.cpf === this.documentService.authService.getCpfUser() ??
              item.cpfRepresentante ===
                this.documentService.authService.getCpfUser(),
          )
        ) {
          this.loading = false;
          return this.documentService.notification.info(
            'Assinar documento',
            'Não foi possivel assinar o documento, pois o usuário logado não é signatário desse documento!',
            { nzDuration: 7000 },
          );
        }

        if (
          docs.signatarios
            .filter(
              async (item) =>
                item.cpf === this.documentService.authService.getCpfUser() ??
                item.cpfRepresentante ===
                  this.documentService.authService.getCpfUser(),
            )
            .find(
              (stats) =>
                (stats.status === StatusDocumento.Desacordo ||
                  stats.status === StatusDocumento.Assinado) &&
                stats.cpf === this.documentService.authService.getCpfUser(),
            ) ||
          docs.statusDocumento === StatusDocumento.Desacordo ||
          docs.statusDocumento === StatusDocumento.Confirmado ||
          this.documentService.docIsExpirado(docs.dataLimiteAssinatura)
        ) {
          const confirmed = docs.signatarios
            .filter(
              (item) =>
                item.cpf === this.documentService.authService.getCpfUser() ??
                item.cpfRepresentante ===
                  this.documentService.authService.getCpfUser(),
            )
            .find((stats) => stats.status === StatusDocumento.Confirmado)
            ? 'signatário está com status Confirmado'
            : '';
          const signed = docs.signatarios
            .filter(
              (item) =>
                item.cpf === this.documentService.authService.getCpfUser() ??
                item.cpfRepresentante ===
                  this.documentService.authService.getCpfUser(),
            )
            .some((stats) => stats.status === StatusDocumento.Assinado)
            ? 'signatário está com status Assinado'
            : '';
          const disagreed = docs.signatarios
            .filter(
              (item) =>
                item.cpf === this.documentService.authService.getCpfUser() ??
                item.cpfRepresentante ===
                  this.documentService.authService.getCpfUser(),
            )
            .find((stats) => stats.status === StatusDocumento.Desacordo)
            ? 'signatário está com status Desacordo'
            : '';
          const expired = this.documentService.docIsExpirado(
            docs.dataLimiteAssinatura,
          )
            ? 'documento está Expirado'
            : '';
          this.loading = false;
          return this.documentService.notification.info(
            'Assinar documento',
            `Não foi possivel assinar o documento, pois o ${disagreed}${confirmed}${signed}${expired}!`,
            { nzDuration: 7000 },
          );
        }
      }
    }

    const searchCert = await this.getCertificados();

    if (searchCert) {
      return;
    }

    const title = `<i nz-icon nzType="warning" nzTheme="outline"> Confirma a assinatura dos documentos selecionados?</i>`;
    const okText = 'Sim';
    const cancelText = 'Não';

    this.service.assinarAnexo.subscribe(async (data) => {
      this.assinarAnexos = data;
    });

    const onOk = async (componentInstance) => {
      this.loading = true;

      const newComponent = componentInstance.certByDocumentForSign.map((a) => {
        return {
          ...a,
          passwordCert: this.documentService.encryptUsingAES256(a.passwordCert),
        };
      });

      modal.close();
      // this.service.loadingEvent(true);
      // this.service.sendLabelTip(
      //   'Aguarde a assinatura em lote ser executada, isso pode levar alguns minutos...',
      // );

      if (!componentInstance.password) {
        return this.service.notification.warning(
          'Formulário',
          'Por favor, preencha o campo com a senha do certificado digital!',
        );
      }
      const dateUser = new Date().toLocaleString();

      const idsDocsParaAssinar: number[] = newComponent.map((row) => row.idDoc);

      for (const a of newComponent) {
        body.push({ idPrivado: a.idDoc });
      }

      await this.documentService
        .assinar(
          newComponent,
          dateUser,
          this.listedAttachs ? this.assinarAnexos : false,
          this.paramsWhere,
          this.checkedAll,
          this.checkedAll ? this.pageTotal : confirmedDocs.length,
          false,
          false,
          false,
          true,
        )
        .then(async () => {
          // this.service.loadingEvent(false);
          this.getDocumentsPerPeriod();
          this.updateDisplayDocs(idsDocsParaAssinar);

          modal.close();
          this.loading = false;
        })
        .catch((err) => {
          this.service.notification.error('Assinar documento', err);
          this.resetAllCheckbox();
          modal.close();
          this.loading = false;
        });
    };
    const modal = this.modal.create({
      nzTitle: title,
      nzWidth: 850,
      nzContent: ModalDialogComponent,
      nzFooter: [
        {
          label: cancelText,
          onClick: () => {
            modal.close();
            this.loading = false;
          },
        },
        {
          label: okText,
          type: 'primary',
          onClick: (componentInstance) => onOk(componentInstance),
        },
      ],
      nzComponentParams: {
        valorSelecionado: this.documentService.getValorSelecionado(newDocs),
        qtdSelecionados: newDocs.length,
        docsSelecionados: newDocs,
        assinanteLogado: this.certificadoAssinante,
        signatarios: this.listSignatarios,
        attachToSign: this.listedAttachs,
        docsChecked: this.pageTotal,
        labelAnexo: 'Assinar',
      },
    });

    modal.afterClose.subscribe((resultClose: any) => {
      if (!resultClose) {
        modal.destroy();
        this.loading = false;
      }
    });
    this.attDashboard();
  };

  discordar = async () => {
    let newDocs: IDocumento[];
    if (this.checkedAll && this.documentList.length > 9) {
      await this.getDocumentsPerPeriod(this.checkedAll);
      newDocs = this.documentList.map((itm) => {
        return {
          ...itm,
          checked: true,
        };
      });
    }
    this.listSignatariosToSign = [];
    const docCheckeds =
      newDocs?.length ?? this.dataDocumentsUploadCheckeds().length;
    const confirmedDocs =
      newDocs ??
      this.getDocsToDataDocumentsUpload().filter((doc) => doc.checked);

    if (docCheckeds === 0) {
      return this.documentService.notification.info(
        'Discordar documento',
        'Não foi possivel discordar do documento, pois não foi selecionado nenhum documento!',
        { nzDuration: 7000 },
      );
    }

    this.loading = true;

    for (const ext of confirmedDocs) {
      this.listedAttachs = ext.documentoAnexo.length > 0 ? true : false;

      if (ext.statusDocumento === StatusDocumento.Confirmado) {
        this.loading = false;

        return this.documentService.notification.info(
          'Discordar documento',
          'Não foi possivel discordar o documento, pois o(s) documento(s) selecionado(s) está(ão) com status Concluído!',
          { nzDuration: 7000 },
        );
      }

      if (ext.statusDocumento === StatusDocumento.Cancelado) {
        this.loading = false;

        return this.documentService.notification.info(
          'Discordar documento',
          'Não foi possivel discordar o documento, pois o(s) documento(s) selecionado(s) está(ão) com status Cancelado!',
          { nzDuration: 7000 },
        );
      }
      const arraySigners =
        await this.documentService.getSignatarioByIdDocumento(ext.idPrivado);

      this.listSignatariosToSign.push(...arraySigners);
    }

    await confirmedDocs.map((itm) => {
      const pessoa = this.listSignatariosToSign.filter(
        (key) => key.idDocumento === itm.idPrivado,
      );
      return {
        ...itm,
        signatarios: (itm.signatarios = pessoa),
      };
    });

    for (const docs of confirmedDocs) {
      if (
        !docs.signatarios.some(
          (item) =>
            item.cpf === this.documentService.authService.getCpfUser() ??
            item.cpfRepresentante ===
              this.documentService.authService.getCpfUser(),
        )
      ) {
        this.loading = false;

        return this.documentService.notification.info(
          'Discordar documento',
          'Não foi possivel discordar do documento, pois o usuário logado não é signatário desse documento!',
          { nzDuration: 7000 },
        );
      }

      if (
        docs.signatarios
          .filter(
            (item) =>
              item.cpf === this.documentService.authService.getCpfUser() ||
              item.cpfRepresentante ===
                this.documentService.authService.getCpfUser(),
          )
          .some(
            (stats) =>
              stats.status === StatusDocumento.Desacordo ||
              stats.status === StatusDocumento.Assinado,
          ) ||
        docs.statusDocumento === StatusDocumento.Desacordo ||
        docs.statusDocumento === StatusDocumento.Confirmado ||
        this.documentService.docIsExpirado(docs.dataLimiteAssinatura)
      ) {
        const disagreed = docs.signatarios.some(
          (stats) => stats.status === StatusDocumento.Desacordo,
        )
          ? 'signatário está com status Desacordo'
          : '';
        const signed = docs.signatarios.some(
          (stats) =>
            stats.status === StatusDocumento.Assinado ||
            docs.statusDocumento === StatusDocumento.Confirmado,
        )
          ? 'signatário está com status Assinado'
          : '';
        const expired = this.documentService.docIsExpirado(
          docs.dataLimiteAssinatura,
        )
          ? 'documento está Expirado'
          : '';
        this.loading = false;

        return this.documentService.notification.info(
          'Discordar documento',
          `Não foi possivel discordar do documento, pois o ${disagreed}${signed}${expired}!`,
          { nzDuration: 7000 },
        );
      }
    }

    this.service.assinarAnexo.subscribe(async (data) => {
      this.assinarAnexos = data;
    });

    const title = `<i nz-icon nzType="warning" nzTheme="outline"> Confirma o desacordo do documento selecionado?</i>`;
    const okText = 'Sim';
    const cancelText = 'Não';
    const onOk = async (motivo) => {
      const idsDocsParaAssinar: number[] = this.documentService
        .getDocsSelecionadosParaAssinar(
          confirmedDocs ?? this.dataDocumentsUploadCheckeds(),
        )
        .map((doc) => doc.idPrivado);

      await this.documentService
        .discordar(idsDocsParaAssinar, motivo, this.assinarAnexos)
        .then(async () => {
          this.getDocumentsPerPeriod();
          this.updateDisplayDocs(idsDocsParaAssinar);
          modal.close();
          this.loading = false;
        })
        .catch(() =>
          this.service.notification.error(
            'Discordar documento',
            'Error ao discordar documento',
          ),
        );
    };

    const modal = this.modal.create({
      nzTitle: title,
      nzContent: ModalDialogComponent,
      nzFooter: [
        {
          label: cancelText,
          onClick: () => {
            modal.close();
            this.loading = false;
          },
        },
        {
          label: okText,
          disabled: (componentInstance) => !componentInstance.motivo,
          type: 'primary',
          onClick: (componentInstance) => onOk(componentInstance.motivo),
        },
      ],
      nzComponentParams: {
        valorSelecionado:
          this.documentService.getValorSelecionado(confirmedDocs),
        qtdSelecionados: docCheckeds,
        existMotivo: true,
        attachToSign: this.listedAttachs,
        labelAnexo: 'Discordar',
      },
    });

    modal.afterClose.subscribe((resultClose: any) => {
      if (!resultClose) {
        modal.destroy();
        this.attDashboard();
        this.loading = false;
      }
    });
  };

  desfazer = async () => {
    let newDocs: IDocumento[];
    if (this.checkedAll && this.documentList.length > 9) {
      await this.getDocumentsPerPeriod(this.checkedAll);
      newDocs = this.documentList.map((itm) => {
        return {
          ...itm,
          checked: true,
        };
      });
    }

    this.listSignatariosToSign = [];

    const docCheckeds: number =
      newDocs?.length ?? this.dataDocumentsUploadCheckeds().length;

    const confirmedDocs =
      newDocs ??
      (await this.getDocsToDataDocumentsUpload().filter((doc) => doc.checked));
    if (docCheckeds === 0) {
      return this.documentService.notification.info(
        'Desfazer desacordo',
        'Não foi possivel desfazer a discordância do documento, pois não foi selecionado nenhum documento!',
        { nzDuration: 7000 },
      );
    }
    this.loading = true;

    for (const ext of confirmedDocs) {
      this.listedAttachs = ext.documentoAnexo.length > 0 ? true : false;

      const arraySigners =
        await this.documentService.getSignatarioByIdDocumento(ext.idPrivado);

      this.listSignatariosToSign.push(...arraySigners);
    }

    await confirmedDocs.map((itm) => {
      const pessoa = this.listSignatariosToSign.filter(
        (key) => key.idDocumento === itm.idPrivado,
      );
      return {
        ...itm,
        signatarios: (itm.signatarios = pessoa),
      };
    });

    for (const docs of confirmedDocs) {
      if (
        !docs.signatarios.some(
          (item) =>
            item.cpf === this.documentService.authService.getCpfUser() ??
            item.cpfRepresentante ===
              this.documentService.authService.getCpfUser(),
        )
      ) {
        this.loading = false;

        return this.documentService.notification.info(
          'Desfazer desacordo',
          'Não foi possivel desfazer do documento, pois o usuário logado não é signatário desse documento!',
          { nzDuration: 7000 },
        );
      }

      if (docs.statusDocumento === StatusDocumento.Cancelado) {
        this.loading = false;

        return this.documentService.notification.info(
          'Desfazer desacordo',
          'Não foi possivel desfazer o documento, pois o(s) documento(s) selecionado(s) está(ão) com status Cancelado!',
          { nzDuration: 7000 },
        );
      }

      if (docs.statusDocumento === StatusDocumento.Confirmado) {
        this.loading = false;

        return this.documentService.notification.info(
          'Desfazer desacordo',
          'Não foi possivel desfazer a discordância do documento, pois o documento selecionado está(ão) com status Concluído!',
          { nzDuration: 7000 },
        );
      }

      const signed = docs.signatarios
        .filter(
          (item) => item.cpf === this.documentService.authService.getCpfUser(),
        )
        .some((stats) => stats.status === StatusDocumento.Assinado)
        ? 'Assinado'
        : '';

      const pending = docs.signatarios
        .filter(
          (item) => item.cpf === this.documentService.authService.getCpfUser(),
        )
        .some((stats) => stats.status === StatusDocumento.Pendente)
        ? 'Pendente'
        : '';

      if (signed || pending) {
        this.loading = false;

        return this.documentService.notification.info(
          'Desfazer desacordo',
          `Não foi possivel desfazer a discordância do documento, pois o signatário está com status ${signed}${pending}!`,
          { nzDuration: 7000 },
        );
      }
    }

    this.service.assinarAnexo.subscribe(async (data) => {
      this.assinarAnexos = data;
    });

    const title = `<i nz-icon nzType="warning" nzTheme="outline"> Confirma o desfazer da discordância do documento selecionado?</i>`;
    const okText = 'Sim';
    const cancelText = 'Não';
    const onOk = async (motivo) => {
      const idsDocsParaAssinar: number[] = this.documentService
        .getDocsSelecionadosParaAssinar(confirmedDocs)
        .map((doc) => doc.idPrivado);

      await this.documentService
        .desfazer(idsDocsParaAssinar, motivo, this.assinarAnexos)
        .then(async () => {
          this.getDocumentsPerPeriod();
          this.updateDisplayDocs(idsDocsParaAssinar);
          modal.close();
          this.loading = false;
        })
        .catch(() =>
          this.service.notification.error(
            'Desfazer desacordo',
            'Error ao desfazer desacordo',
          ),
        );
    };

    const modal = this.modal.create({
      nzTitle: title,
      nzContent: ModalDialogComponent,
      nzFooter: [
        {
          label: cancelText,
          onClick: () => {
            modal.close();
            this.loading = false;
          },
        },
        {
          label: okText,
          disabled: (componentInstance) => !componentInstance.motivo,
          type: 'primary',
          onClick: (componentInstance) => onOk(componentInstance.motivo),
        },
      ],
      nzComponentParams: {
        valorSelecionado:
          this.documentService.getValorSelecionado(confirmedDocs),
        qtdSelecionados: docCheckeds,
        existMotivo: true,
        attachToSign: this.listedAttachs,
        labelAnexo: 'Desfazer desacordo',
      },
    });

    modal.afterClose.subscribe((resultClose: any) => {
      if (!resultClose) {
        modal.destroy();
        this.attDashboard();
        this.loading = false;
      }
    });
  };

  getConfiguracaoExp() {
    const getConfiguracao: any = JSON.parse(
      localStorage.getItem('unidadeGestoraSelected'),
    );
    this.expiracao = getConfiguracao.expiracao;
    this.configuracao = getConfiguracao.configuracao;
  }

  resetForm = () => {
    if (this.formB.value.idPrivado) {
      this.formB.controls.cpf.enable();
      this.formB.controls.cnpj.enable();

      this.formB.controls.cpfAnexo.enable();
      this.formB.controls.cnpjAnexo.enable();

      this.formB.controls.cpf.updateValueAndValidity();
      this.formB.controls.cnpj.updateValueAndValidity();
      this.formB.controls.cpfAnexo.updateValueAndValidity();
      this.formB.controls.cnpjAnexo.updateValueAndValidity();
    }
    this.anexos = [];
    this.removeAllAttachments();
    this.resetDocument();
    this.removeAllAssinantes();
    this.allSignatarios = [];
    this.formB.reset();
    this.formB.get('typePeopleSelected').setValue(0);
    this.formB.get('typePeopleAnexoSelected').setValue(0);
    this.formB
      .get('dataLimiteAssinatura')
      .setValue(
        this.documentService.formatDate23hrs(
          this.authService.getDateSelected(),
        ),
      );
    this.formB.get('chancela').setValue(true);
  };

  verifyIfSigned(doc?: Partial<IDocumento>) {
    const isSigned = doc.signatarios?.some(
      (assinante) => assinante.status === StatusDocumento.Assinado,
    );
    if (isSigned === true) {
      this.formB.controls.cpf.disable();
      this.formB.controls.cnpj.disable();
      this.formB.controls.cpf.updateValueAndValidity();
      this.formB.controls.cnpj.updateValueAndValidity();
    }
  }

  async docB64(doc: Partial<IDocumento>) {
    const uuid = await this.documentService.getUuidFile('view', doc);
    const docB64 = await this.documentService.getB64DocToUploadApi(uuid);
    return docB64;
  }

  async docAnexadoB64(uuidAnexo: string, idDoc: number) {
    const docAnexado = await this.documentService.getUuidDocAnexo(
      uuidAnexo,
      idDoc,
    );
    return docAnexado;
  }

  async prepareToUpdateDoc(doc: Partial<IDocumento>) {
    const docAnexo = await this.documentService.getAllAnexosByDocument(
      doc.idPrivado,
    );
    Object.assign(doc, { documentoAnexo: docAnexo });

    const userInfo = this.authService.getUserInfo();
    let signatarioCpf;
    let mergeById;
    // await this.listSignatarios.find((itm) => {
    //   mergeById = {
    //     signatarios: [itm],
    //     doc,
    //   };
    // });

    const docBase64 = await this.docB64(doc);
    const documentUploadOriginal = this.setDoc(docBase64);
    const assinanteForm: FormArray = this.formB.get('signatarios') as FormArray;
    const newSigs = doc.signatarios.map(async (assinante) => {
      const pessoa = await this.pessoaFisicaService.getPessoaFisicaById2(
        assinante.idPessoaFisica,
      );
      const pessoaResponsavel =
        assinante.idResponsavel !== null
          ? await this.pessoaFisicaService.getPessoaFisicaById2(
              assinante.idResponsavel,
            )
          : null;

      const pessoaJuridica =
        assinante.idPessoaJuridica !== null
          ? await this.documentService.getPessoaJuridicaById(
              assinante.idPessoaJuridica,
            )
          : null;
      pessoaResponsavel ? (signatarioCpf = pessoaResponsavel.cpf) : null;
      this.pessoaFisicaService
        .consultarPessoaFisicaPorParametro({
          value: Number(doc.idPessoaFavorecido),
        })
        .then((resultado) => {
          resultado;
          this.setDataPessoaFisicaFavorecido({
            ...resultado.data,
            nome: resultado.data.pessoa.nome,
            idPessoa: resultado.data.idPessoa,
          });
        })
        .catch((e) => {
          this.service.notification.error(
            'Favorecido',
            'Erro ao consultar favorecido',
          );
        });
      assinanteForm.push(
        this.formBuilder.group({
          idPessoaFisica: [assinante.idPessoaFisica],
          idPessoaJuridica: [assinante.idPessoaJuridica],
          signatario: [signatarioCpf],
          razaoSocial: [pessoaJuridica?.razaoSocial],
          idResponsavel: [assinante.idResponsavel],
          usCriacao: [userInfo.idUsuario],
          nome: [pessoa?.pessoa?.nome],
          email: [pessoa?.pessoa?.emailPrincipal],
          cpf: [pessoa?.cpf],
          cnpj: [pessoaJuridica?.cnpj],
          idPrivado: [assinante.idPrivado],
          idDocumento: [assinante.idDocumento],
          status: [assinante.status],
          nomCidade: [pessoaJuridica?.enderecos[0]?.nomCidade],
        }),
      );
    });

    const anexos = doc.documentoAnexo?.map(async (attach) => {
      const anexosForm: FormArray = this.formB.get('anexos') as FormArray;
      anexosForm.push(
        this.formBuilder.group({
          idPrivado: [attach?.idPrivado],
          descricao: [attach?.descricao],
          vinculacao: [attach?.vinculacao],
          idDocumento: [attach?.idDocumento],
          nome: [attach?.nome],
          tipoDocumentoAnexo: [attach?.tipoDocumento?.tipo],
        }),
      );
    });

    const tipoDoc = {
      idPrivado: doc.tipoDocumento?.idPrivado,
      idPublico: doc.tipoDocumento?.idPublico,
      tipo: doc.tipoDocumento?.tipo,
      descricao: doc.tipoDocumento?.descricao,
      idUnidadeGestora: doc.tipoDocumento?.idUnidadeGestora,
    };

    this.formB.patchValue({
      idTipoDocumento: doc.tipoDocumento.idPublico,
      tipo: doc.tipoDocumento.tipo,
      numeroDoc: doc.numeroDoc,
      dataLimiteAssinatura: doc.dataLimiteAssinatura,
      historicoDoc: doc.historicoDoc,
      psHash: doc.psHash,
      idPublico: doc.idPublico,
      document: documentUploadOriginal,
      idDocVinculado: doc.idDocVinculado,
      numeroDocOriginal: doc.numeroDocOriginal,
      idPrivado: doc.idPrivado,
      chancela: doc.chancela,
      entidadeTipoDoc: tipoDoc,
      signatarios: newSigs,
      anexos: anexos,
      idSetor: doc.setor ? doc.setor?.idPublico : null,
      descricaoSetor: doc.setor ? doc.setor?.descricao : '',
      modalidadeLicitacao: Number(doc.modalidadeLicitacao),
      numeroObra: doc.numeroObra,
      numeroLicitacao: doc.numeroLicitacao,
      numeroContrato: doc.numeroContrato,
      numeroConvenio: doc.numeroConvenio,
      numeroEvento: doc.numeroEvento,
      numeroProgramaInstitucional: doc.numeroProgramaInstitucional,
      valorDoc: doc.valorDoc,
      informacoesAdicionais: doc.informacoesAdicionais,
    });
    await this.changeTabIndex(0);
  }

  mascararCpfCnpj(cpfCnpj) {
    return Helper.mascaraCpfCnpj(cpfCnpj);
  }

  setDoc(base64) {
    this.file = this.sanitizer.bypassSecurityTrustResourceUrl(
      this.setInitialStringBase64(base64),
    );
  }

  async setDocAnexo(file) {
    await this.upload(file);
  }

  setInitialStringBase64(base64) {
    return base64.lastIndexOf('base64,') === -1
      ? `data:application/pdf;base64,${base64}`
      : base64;
  }

  editAssinante(cpf) {
    const assinante = this.formB.value.signatarios.find(
      (signer) => signer.cpf === cpf,
    );
    this.formB.patchValue(assinante);
  }

  addAssinanteAnexo() {
    if (
      this.formB.value.signatariosAnexo.some(
        (ass) =>
          ass.cpfAnexo === this.formB.value.entidadePessoaFisicaAnexo?.cpf ||
          ass.cnpjAnexo === this.formB.value.entidadePessoaJuridicaAnexo?.cnpj,
      )
    ) {
      this.limparAssinanteAnexo();
      return this.service.notification.warning(
        'Assinante',
        'Assinante já cadastrado no documento!',
      );
    }

    const assinanteAnexoForm: FormArray = this.formB.get(
      'signatariosAnexo',
    ) as FormArray;
    const userInfo = this.authService.getUserInfo();
    assinanteAnexoForm.push(
      this.formBuilder.group({
        idPessoaFisica: [this.formB.value.entidadePessoaFisicaAnexo?.id],
        idPessoaJuridica: [this.formB.value.entidadePessoaJuridicaAnexo?.id],
        nomeAnexo: [
          this.formB.value.entidadePessoaFisicaAnexo?.pessoa?.nomPessoa,
        ],
        emailAnexo: [
          this.formB.value.entidadePessoaFisicaAnexo?.pessoa?.emailPrincipal,
        ],
        cpfAnexo: [this.formB.value.cpfAnexo],
        cnpjAnexo: [this.formB.value.cnpjAnexo],
        signatarioAnexo: [this.formB.value.signatarioAnexo],
        cargoAnexo: [this.formB.value.entidadePessoaFisicaAnexo?.cargo],
        razaoSocialAnexo: [
          this.formB.value.entidadePessoaJuridicaAnexo?.razaoSocial,
        ],
        matriculaAnexo: [this.formB.value.entidadePessoaFisicaAnexo?.matricula],
        nomCidadeAnexo: [
          this.formB.value.entidadePessoaJuridicaAnexo?.nomCidade,
        ],
        idResponsavel: [null],
        usCriacao: [userInfo.idUsuario],
      }),
    );
    this.allSignatarios = [];
    this.limparAssinanteAnexo();
  }

  addAssinante() {
    if (
      this.formB.value.signatarios.some(
        (ass) =>
          ass.cpf === this.formB.value.entidadePessoaFisica?.cpf ||
          ass.cnpj === this.formB.value.entidadePessoaJuridica?.cnpj,
      )
    ) {
      this.limparAssinante();
      return this.service.notification.warning(
        'Assinante',
        'Assinante já cadastrado no documento!',
      );
    }

    const assinanteForm: FormArray = this.formB.get('signatarios') as FormArray;
    const userInfo = this.authService.getUserInfo();
    assinanteForm.push(
      this.formBuilder.group({
        idPessoaFisica: [this.formB.value.entidadePessoaFisica?.id],
        idPessoaJuridica: [this.formB.value.entidadePessoaJuridica?.id],
        nome: [this.formB.value.entidadePessoaFisica?.pessoa?.nomPessoa],
        email: [this.formB.value.entidadePessoaFisica?.pessoa?.emailPrincipal],
        cpf: [this.formB.value.cpf],
        cnpj: [this.formB.value.cnpj],
        signatario: [this.formB.value.signatario],
        cargo: [this.formB.value.entidadePessoaFisica?.cargo],
        razaoSocial: [this.formB.value.entidadePessoaJuridica?.razaoSocial],
        matricula: [this.formB.value.entidadePessoaFisica?.matricula],
        nomCidade: [this.formB.value.entidadePessoaJuridica?.nomCidade],
        idResponsavel: [null],
        usCriacao: [userInfo.idUsuario],
      }),
    );
    this.allSignatarios = [];
    this.limparAssinante();
  }

  addAnexo() {
    for (const detalheAnexo of this.anexos) {
      this.nomeAnexo.push({
        name: detalheAnexo.name,
      });
    }
    const anexosForm: FormArray = this.formB.get('anexos') as FormArray;
    const userInfo = this.authService.getUserInfo();
    anexosForm.push(
      this.formBuilder.group({
        files: [this.anexos],
        vinculacao: [this.formB.value.vinculacao],
        descricao: [this.formB.value.descricao],
        usCriacao: [userInfo.idUsuario],
        nome: [this.anexos[0].name],
        tipoDocumentoAnexo: [this.formB.value.entidadeTipoDocAnexo.tipo],
        idTipoDocumentoAnexo: [this.formB.value.entidadeTipoDocAnexo.idPrivado],
        tituloManifesto: [
          this.formB.value.entidadeTipoDocAnexo.tituloManifesto,
        ],
      }),
    );
    this.nomeAnexo = [];
    this.limparAnexo();
  }

  async setSignatario(data) {
    await data.pessoaResponsaveis.forEach((element) => {
      this.pessoaJuridicaService
        .getPessoasFisicaById(element.idPessoaFisica)
        .then((resp) => {
          this.allSignatarios.push(`${resp.cpf} - ${resp.pessoa.nomPessoa}`);
        });
    });
  }

  resetDocument() {
    this.formB.get('documentB64').setValue(null);
    this.file = null;
  }

  resetAssinante() {
    const assinanteForm: FormArray = this.formB.get('signatarios') as FormArray;
    assinanteForm.controls.splice(0, assinanteForm.controls.length);
  }

  showColorRatificado(signatarios: IAssinante[]): boolean {
    if (
      signatarios.some(
        (key) => key.status === StatusDocumento.PendenteRatificacao,
      )
    ) {
      return true;
    }
    return false;
  }

  limparAssinante() {
    this.formB.controls.entidadePessoaFisica.reset();
    this.formB.controls.entidadePessoaJuridica.reset();
    this.formB.controls.cpf.reset();
    this.formB.controls.cnpj.reset();
    this.formB.controls.nome.reset();
    this.formB.controls.razaoSocial.reset();
    this.formB.controls.email.reset();
    this.formB.controls.nomCidade.reset();
    this.formB.controls.cargo.reset();
    this.formB.controls.matricula.reset();
    this.formB.controls.signatario.reset();
    this.allSignatarios = [];
  }

  limparAssinanteAnexo() {
    this.formB.controls.entidadePessoaFisicaAnexo.reset();
    this.formB.controls.entidadePessoaJuridicaAnexo.reset();
    this.formB.controls.cpfAnexo.reset();
    this.formB.controls.cnpjAnexo.reset();
    this.formB.controls.nomeAnexo.reset();
    this.formB.controls.razaoSocialAnexo.reset();
    this.formB.controls.emailAnexo.reset();
    this.formB.controls.nomCidadeAnexo.reset();
    this.formB.controls.cargoAnexo.reset();
    this.formB.controls.matricula.reset();
    this.formB.controls.signatario.reset();
    this.allSignatarios = [];
  }

  limparAnexo() {
    this.formB.controls.entidadeAnexos.reset();
    this.formB.controls.descricao.reset();
    this.formB.controls.tipoDocumentoAnexo.reset();
    this.formB.controls.idTipoDocumentoAnexo.reset();
    this.formB.controls.vinculacao.reset();
    this.anexos = [];
  }

  removeAllAssinantes() {
    const assinanteFormb: FormArray = this.formB.get(
      'signatarios',
    ) as FormArray;
    assinanteFormb.clear();

    const assinanteAnexoFormb: FormArray = this.formB.get(
      'signatariosAnexo',
    ) as FormArray;
    assinanteAnexoFormb.clear();
  }

  removeAllAttachments() {
    const anexosFormb: FormArray = this.formB.get('anexos') as FormArray;
    anexosFormb.clear();
  }

  removeAssinanteAnexo(cpfAnexo) {
    const assinanteAnexoFormb: FormArray = this.formB.get(
      'signatariosAnexo',
    ) as FormArray;
    const index = this.formB.value.signatariosAnexo.indexOf(
      this.formB.value.signatariosAnexo.find(
        (assinante) => assinante.cpfAnexo === cpfAnexo,
      ),
    );
    const assinanteAnexoToRemove = this.formB.value.signatariosAnexo[index];
    assinanteAnexoToRemove.idPrivado
      ? this.delete(
          assinanteAnexoToRemove.idPrivado,
          assinanteAnexoFormb,
          index,
          'deleteSignatario',
          'signatário',
        )
      : assinanteAnexoFormb.removeAt(index);
  }

  removeAssinante(cpf) {
    const assinanteFormb: FormArray = this.formB.get(
      'signatarios',
    ) as FormArray;
    const index = this.formB.value.signatarios.indexOf(
      this.formB.value.signatarios.find((assinante) => assinante.cpf === cpf),
    );
    const assinanteToRemove = this.formB.value.signatarios[index];
    assinanteToRemove.idPrivado
      ? this.delete(
          assinanteToRemove.idPrivado,
          assinanteFormb,
          index,
          'deleteSignatario',
          'signatário',
        )
      : assinanteFormb.removeAt(index);
  }

  async removeAnexo(documento) {
    const anexosFormb: FormArray = this.formB.get('anexos') as FormArray;
    const index = this.formB.value.anexos.indexOf(
      this.formB.value.anexos.find((key) => key.documento === documento),
    );
    const attachToRemove = this.formB.value.anexos[index];
    attachToRemove.idPrivado
      ? this.delete(
          attachToRemove.idPrivado,
          anexosFormb,
          index,
          'deleteAnexo',
          'anexo',
        )
      : anexosFormb.removeAt(index);
  }

  delete(idPrivado, file, index, endPoint: string, service: string) {
    const title = `<span> Deseja remover este ${service}?</span>`;
    const okText = 'Sim';
    const cancelText = 'Não';
    const onOk = async () => {
      await this.service.deleteById(idPrivado, endPoint).then((result) => {
        file.removeAt(index);
        modal.close();
        return this.service.notification.success('Remover', result);
      });
    };

    const modal = this.modalService.create({
      nzContent: title,
      nzFooter: [
        { label: cancelText, onClick: () => modal.close() },
        {
          label: okText,
          onClick: () => onOk(),
        },
      ],
    });

    modal.afterClose.subscribe((resultClose: any) => {
      if (!resultClose) {
        modal.destroy();
      }
    });
  }

  verificaAssinanteVazio() {
    return !this.formB.value.cpf && !this.formB.value.cnpj;
  }

  beforeUpload = async (file): Promise<boolean> => {
    const MAX_FILE_SIZE = 10 * 1092917; // 10 MB

    if (file.name.lastIndexOf('.pdf') === -1) {
      this.service.notification.error('Arquivo', 'Tipo de arquivo inválido!');
      return false;
    }

    if (file.size > MAX_FILE_SIZE) {
      this.service.notification.error(
        'Arquivo',
        'Selecione um documento menor que 10mb!',
      );
      return false;
    }
    // if (file.name.lastIndexOf('.pfx') === -1) {
    //   setTimeout(() => {
    //     this.service.notification.error('Arquivo', 'Tipo de arquivo inválido!');
    //   }, 1000);
    //   return false;
    // }
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onloadend = (e) => {
      const base64 = e.target.result.toString();
      this.formB.controls.documentB64.setValue(file);
      this.file = this.sanitizer.bypassSecurityTrustResourceUrl(base64);
    };
    return false;
  };

  enviar = async () => {
    let getIdResponsavel;

    this.listedAttachs = this.formB.value.anexos.length > 0 ? true : false;

    const items = this.formB.controls.signatarios.value;
    if (this.formB.value.signatarios) {
      for (let i = 0; i < items.length; i++) {
        if (items[i].cnpj) {
          const cpfSignatario = items[i].signatario;

          const cpf = cpfSignatario.toString().replace(/[^0-9]+/g, '');
          getIdResponsavel = await this.service.getPessoasFisicaByCpf(cpf);
          (
            (this.formB.get('signatarios') as FormArray).at(i) as FormGroup
          ).patchValue({
            idResponsavel: getIdResponsavel.id,
          });
        }
      }
    }

    const searchCert = await this.getCertificados();

    if (searchCert) {
      return;
    }

    if (
      this.certificadoAssinante.every(
        (cert) => new Date(cert.dtFinalCert) < new Date(),
      )
    ) {
      return this.service.notification.warning(
        'Certificado',
        'Certificado digital expirado!',
      );
    }

    if (
      this.certificadoAssinante.every(
        (cert) => cert.status === StatusCertificado.Deletado,
      ) ||
      this.certificadoAssinante.length === 0
    ) {
      return this.service.notification.warning(
        'Certificado',
        'Por favor, cadastre um certificado digital na plataforma!',
      );
    }

    // if(this.certificadoAssinante.find(key => key.))
    if (this.formB.value.idPrivado) {
      const form = this.formB.controls;
      form.documentB64.clearValidators();
      form.documentB64.updateValueAndValidity();
    }
    const ugSelect = await this.authService.getUgSelected();
    const userInfo = this.authService.getUserInfo();
    this.formB.controls.usCriacao.setValue(userInfo.idUsuario);
    this.formB.controls.idUnidadeGestora.setValue(ugSelect.id);

    if (
      this.formB.controls.signatarios.value.length === 0 ||
      !this.formB.controls.entidadeTipoDoc ||
      !this.formB.controls.documentB64 ||
      this.formB.invalid
    ) {
      return this.service.notification.warning(
        'Formulário',
        'Por favor, preencha todos os campos corretamente',
      );
    }

    if (
      (this.formB.value.anexos.length === 0 &&
        this.formB.controls.signatariosAnexo.value.length > 0) ||
      (this.formB.value.anexos.length > 0 &&
        this.formB.controls.signatariosAnexo.value.length === 0)
    ) {
      return this.service.notification.warning(
        'Formulário',
        'Por favor, preencha todos os campos corretamente',
      );
    }

    let res: IDocumento;
    await this.documentService
      .findDocument(
        this.formB.controls.numeroDoc.value,
        this.formB.controls.idTipoDocumento.value,
      )
      .then((result) => {
        res = result;
      });

    if (res && !this.formB.value.idPrivado) {
      this.service.notification.warning(
        'Formulário',
        'Número do documento já em uso.',
      );
      return;
    }

    if (
      this.checkDataExpiracao(this.formB.controls.dataLimiteAssinatura.value)
    ) {
      return;
    }
    const textTitle = this.formB.value.idPrivado
      ? 'Confirma as mudanças do documento'
      : 'Confirma o envio do documento para coleta de assinatura';
    const title = `<i nz-icon nzType="warning" nzTheme="outline"> ${textTitle}?</i>`;
    const okText = 'Sim';
    const okText2 = 'Sim e assinar';
    const cancelText = 'Não';
    let pessoaFisica;

    const isAssinante = this.formB.value.signatarios.some(
      async (assinante: IAssinante) => {
        pessoaFisica =
          (await this.service.getPessoasFisicasById(
            Number(assinante.idPessoaFisica),
          )) && pessoaFisica === this.service.authService.getCpfUser();
      },
    );

    this.service.assinarAnexo.subscribe(async (data) => {
      this.assinarAnexos = data;
    });

    const onOk = async (componentInstance, assinar = false) => {
      if (assinar === true && !componentInstance.password) {
        return this.service.notification.warning(
          'Formulário',
          'Por favor, preencha o campo com a senha do certificado digital!',
        );
      }
      const newComponent = componentInstance.certByDocumentForSign.map((a) => {
        return {
          ...a,
          passwordCert: this.documentService.encryptUsingAES256(a.passwordCert),
        };
      });
      this.loading = true;

      this.formB.get('certByDocumentForSign').setValue(newComponent);
      this.formB.value.idPrivado
        ? await this.service
            .updateDocument(
              this.formB.value,
              assinar,
              this.assinanteLogado,
              this.assinarAnexos,
            )
            .then(() => {
              this.loading = false;
              modal.close();
              this.resetForm();
              this.formB.get('chancela').setValue(true);
            })
            .catch((err) => {
              this.loading = false;
              modal.close();
            })
        : await this.service
            .insertDocumento(
              this.formB.value,
              assinar,
              this.assinanteLogado,
              this.assinarAnexos,
            )
            .then(() => {
              this.loading = false;
              modal.close();
              this.resetForm();
              this.formB.get('chancela').setValue(true);
            })
            .catch((e) => {
              this.service.notification.error('Cadastrar', e.message);
              this.loading = false;
              modal.close();
            });
      modal.close();
    };

    const modal = this.modal.create({
      nzTitle: title,
      nzWidth: 850,
      nzContent: ModalDialogComponent,
      nzFooter: [
        { label: cancelText, onClick: () => modal.close() },
        {
          label: okText2,
          onClick: (componentInstance) => onOk(componentInstance, true),
          disabled: (componentInstance) =>
            this.disableButton(componentInstance),
        },
        {
          label: okText,
          type: 'primary',
          onClick: (componentInstance) => onOk(componentInstance),
        },
      ],
      nzComponentParams: {
        docsSelecionados: [
          this.service.formatDocumentToInsertOrUpdate(
            this.formB.value,
            this.assinanteLogado,
            true,
          ),
        ],
        assinanteLogado: this.certificadoAssinante
          ? this.certificadoAssinante
          : null,
        tipoDocumento: this.formB.value.entidadeTipoDoc.tipo,
        qtdAssinantes: this.formB.value.signatarios.length,
        signatarios: this.listSignatarios,
        attachToSign: this.listedAttachs,
        labelAnexo: 'Assinar',
      },
    });

    modal.afterClose.subscribe((resultClose: any) => {
      if (!resultClose) {
        modal.destroy();
      }
    });
  };

  disableButton(component) {
    for (const teste of component.certByDocumentForSign) {
      if (
        teste.certificados.cpfCnpjCert ===
          this.documentService.authService.getCpfUser() &&
        teste.signatarios.some(
          (key) => key.cpf === this.documentService.authService.getCpfUser(),
        ) &&
        teste.certificados.status === StatusCertificado.Valido
      ) {
        return false;
      }
      return true;
    }
  }

  implantar = () => {
    this.resetForm();
    this.changeTabIndex(0);
  };

  consultar = () => {
    this.changeTabIndex(1);
  };

  async admUser() {
    // this.documentService.isAdmUser().then((data) => (this.adm = data));
    this.adm = this.documentService.adm;
  }

  cancelar = async () => {
    const userInfo = this.authService.getUserInfo();
    const nomUser = this.authService.getNomUser();

    const qtdSelecionados = this.documentService.getQtdSelecionados(
      this.getDocsToDataDocumentsUpload(),
    );

    if (qtdSelecionados === 0 || qtdSelecionados > 1) {
      return this.service.notification.info(
        'Cancelar documento',
        `Não foi possível cancelar o documento pois exitem muitos documentos selecionados ou nenhum. `,
      );
    }

    const confirmedDocs = await this.documentList.filter((doc) => doc.checked);
    const userDocument = confirmedDocs.find((ass) => ass.idPrivado);

    if (
      userDocument.usCriacao !== userInfo.idUsuario &&
      !(await this.documentService.isAdmUser())
    ) {
      return this.documentService.notification.info(
        'Cancelar documento',
        'Não foi possivel cancelar o documento, pois você não é criador deste documento!',
        { nzDuration: 7000 },
      );
    }

    for (const docs of confirmedDocs) {
      if (docs.statusDocumento === StatusDocumento.Cancelado) {
        return this.documentService.notification.info(
          'Cancelar documento',
          'Não foi possivel cancelar o documento, pois o documento selecionado está com status Cancelado!',
          { nzDuration: 7000 },
        );
      }
    }

    const documents = this.documentList.filter(
      (registro) => registro.checked === true,
    );

    const id = documents.map((item) => {
      return item.idPrivado;
    });

    const usuarioCriador = {
      nome: nomUser,
      cpf: userInfo.cpf,
      id: userInfo.idUsuario,
      email: userInfo.login,
      admin: this.adm,
      sistema: true,
    };

    const title = `<i nz-icon nzType="warning" nzTheme="outline"> Confirma o cancelamento do documento selecionado?</i>`;
    const okText = 'Sim';
    const cancelText = 'Não';
    const onOk = async (motivo) => {
      const idsDocsParaAssinar: number[] = this.documentService
        .getDocsSelecionadosParaAssinar(this.documentList)
        .map((doc) => doc.idPrivado);

      await this.documentService
        .cancel(Number(id), motivo, usuarioCriador)
        .then(async () => {
          this.getDocumentsPerPeriod();
          this.updateDisplayDocs(idsDocsParaAssinar);
          modal.close();
        })
        .catch((e) => {
          this.service.notification.error(
            'Cancelar documento',
            `Error ao cancelar documento \n ${e}`,
          );
        });
    };

    const modal = this.modal.create({
      nzTitle: title,
      nzContent: ModalDialogComponent,
      nzFooter: [
        { label: cancelText, onClick: () => modal.close() },
        {
          label: okText,
          disabled: (componentInstance) => !componentInstance.motivo,
          type: 'primary',
          onClick: (componentInstance) => onOk(componentInstance.motivo),
        },
      ],
      nzComponentParams: {
        qtdSelecionados: this.documentService.getQtdSelecionados(
          this.documentList,
        ),
        tipoDocumento: `${documents.map((i) => i.tipoDocumento.tipo)}`,
        existMotivo: true,
      },
    });

    modal.afterClose.subscribe((resultClose: any) => {
      if (!resultClose) {
        modal.destroy();
        this.loading = false;
      }
      this.resetAllCheckbox();
    });
  };

  async changeTabIndex(value) {
    this.loading = true;
    this.tabIndex = value;
    this.resetFabButtons();

    if (this.tabIndex === 1) {
      // await this.setConfigTable();
      if (this.listSignatarios.length === 0) {
        this.getAllAssinantes();
      }
    }

    this.loading = false;
  }

  showModalPFAnexo() {
    const pessoaFisicaAnexoModal = this.modalService.create({
      nzTitle: 'Selecione uma pessoa física',
      nzContent: PessoaFisicaComponent,
      nzComponentParams: {
        openModal: true,
      },
      nzFooter: null,
      nzWidth: 1000,
    });

    pessoaFisicaAnexoModal.afterClose.subscribe((result) =>
      this.setDataPessoaFisicaAnexo(result),
    );
  }

  showModalPF() {
    const pessoaFisicaModal = this.modalService.create({
      nzTitle: 'Selecione uma pessoa física',
      nzContent: PessoaFisicaComponent,
      nzComponentParams: {
        openModal: true,
      },
      nzFooter: null,
      nzWidth: 1000,
    });

    pessoaFisicaModal.afterClose.subscribe((result) =>
      this.setDataPessoaFisica(result),
    );
  }

  showModalPJAnexo() {
    const pessoaModal = this.modalService.create({
      nzTitle: 'Selecione uma pessoa jurídica',
      nzContent: PessoaJuridicaComponent,
      nzComponentParams: {
        openModal: true,
      },
      nzFooter: null,
      nzWidth: 1000,
    });

    pessoaModal.afterClose.subscribe((result) => {
      this.setDataPessoaJuridicaAnexo(result);
      this.setSignatario(result);
    });
  }

  showModalPJ() {
    const pessoaModal = this.modalService.create({
      nzTitle: 'Selecione uma pessoa jurídica',
      nzContent: PessoaJuridicaComponent,
      nzComponentParams: {
        openModal: true,
      },
      nzFooter: null,
      nzWidth: 1000,
    });

    pessoaModal.afterClose.subscribe((result) => {
      this.setDataPessoaJuridica(result);
      this.setSignatario(result);
    });
  }

  reuseSignerDoc(event) {
    if (event === true) {
      if (this.formB.value.signatarios.length === 0) {
        this.formB.controls.reaproveitar.setValue(false);
        return this.service.notification.warning(
          'Assinante',
          'Não há signatários a serem reaproveitados!',
        );
      }

      if (
        this.formB.value.signatariosAnexo.some(
          (ass) =>
            ass.cpfAnexo ===
              this.formB.value.entidadePessoaFisicaAnexo?.cpfAnexo ||
            ass.cnpjAnexo ===
              this.formB.value.entidadePessoaJuridicaAnexo?.cnpjAnexo,
        )
      ) {
        return this.service.notification.warning(
          'Assinante',
          'Assinante já cadastrado no documento!',
        );
      }

      const assinanteAnexoForm: FormArray = this.formB.get(
        'signatariosAnexo',
      ) as FormArray;

      const userInfo = this.authService.getUserInfo();

      for (const signerAnx of this.formB.value.signatarios) {
        assinanteAnexoForm.push(
          this.formBuilder.group({
            idPessoaFisica: [signerAnx?.idPessoaFisica],
            idPessoaJuridica: [signerAnx?.idPessoaJuridica],
            nomeAnexo: [signerAnx?.nome],
            emailAnexo: [signerAnx?.email],
            cpfAnexo: [signerAnx?.cpf],
            cnpjAnexo: [signerAnx?.cnpj],
            signatarioAnexo: [signerAnx?.signatario],
            cargoAnexo: [signerAnx?.cargo],
            razaoSocialAnexo: [signerAnx?.razaoSocial],
            matriculaAnexo: [signerAnx?.matricula],
            nomCidadeAnexo: [signerAnx?.nomCidade],
            idResponsavel: [null],
            usCriacao: [userInfo.idUsuario],
          }),
        );
      }
      this.allSignatarios = [];
      this.limparAssinanteAnexo();
      return;
    }

    const assinanteAnexoFormb: FormArray = this.formB.get(
      'signatariosAnexo',
    ) as FormArray;
    while (assinanteAnexoFormb.length) {
      assinanteAnexoFormb.removeAt(0);
    }
  }

  setDataPessoaFisica(data) {
    this.formB.patchValue({
      entidadePessoaFisica: data,
      nome: data?.pessoa?.nomPessoa,
      email: data?.pessoa?.emailPrincipal,
      cnpj: null,
      razaoSocial: null,
      nomCidade: null,
      entidadePessoaJuridica: null,
      ...data,
      idPrivado: this.formB.value.idPrivado,
    });
  }

  setDataPessoaFisicaAnexo(data) {
    this.formB.patchValue({
      entidadePessoaFisicaAnexo: data,
      nomeAnexo: data?.pessoa?.nomPessoa,
      emailAnexo: data?.pessoa?.emailPrincipal,
      cnpjAnexo: null,
      razaoSocialAnexo: null,
      nomCidadeAnexo: null,
      entidadePessoaJuridicaAnexo: null,
      cpfAnexo: data.cpf,
      idPrivado: this.formB.value.idPrivado,
      search: false,
    });
  }

  setDataAnexo(data) {
    this.formB.patchValue({
      entidadeAnexos: data,
    });
  }

  setDataPessoaJuridicaAnexo(data) {
    this.formB.patchValue({
      entidadePessoaJuridicaAnexo: data,
      nomCidadeAnexo: data.enderecos[0].nomCidade,
      entidadePessoaFisicaAnexo: null,
      nomeAnexo: null,
      emailAnexo: null,
      cpfAnexo: null,
      idPrivado: this.formB.value.idPrivado,
      ...data,
    });
  }

  setDataPessoaJuridica(data) {
    this.formB.patchValue({
      entidadePessoaJuridica: data,
      nomCidade: data.enderecos[0].nomCidade,
      entidadePessoaFisica: null,
      nome: null,
      email: null,
      cpf: null,
      idPrivado: this.formB.value.idPrivado,
      ...data,
    });
  }

  showModalTipoDoc() {
    const tipoDocumentoModal = this.modalService.create({
      nzTitle: 'Selecione um tipo de documento',
      nzContent: TipoDocumentoComponent,
      nzComponentParams: {
        openModal: true,
      },
      nzFooter: null,
      nzWidth: 1000,
    });

    tipoDocumentoModal.afterClose.subscribe((result: ITipoDoc) => {
      this.setDataTipoDoc(result);
    });
  }

  showModalSetor() {
    const setorModal = this.modalService.create({
      nzTitle: 'Selecione um setor',
      nzContent: SetorComponent,
      nzComponentParams: {
        openModal: true,
      },
      nzFooter: null,
      nzWidth: 1000,
    });
    const modalSubscription: Subscription = setorModal.afterClose.subscribe({
      next: (result: any) => {
        this.setDataSetor(result);
      },
      error: (err) => {},
      complete: () => {
        modalSubscription.unsubscribe();
      },
    });
  }

  limparDocAvulso() {
    this.formB.get('idDocVinculado').setValue('');
    this.formB.get('numeroDocOriginal').setValue('');
  }

  async showModalDocAvulso() {
    const documentoAvulsoModal = this.modalService.create({
      nzTitle: 'Selecione um tipo de documento',
      nzContent: DocumentsUploadComponent,
      nzComponentParams: {
        openModal: true,
      },
      nzFooter: null,
      nzWidth: 1000,
    });
    await documentoAvulsoModal.afterClose.subscribe((result: IDocumento) => {
      this.formB.patchValue({
        idDocVinculado: result.idPrivado,
        numeroDocOriginal: result.numeroDoc,
      });
      this.formB.get('idDocVinculado').setValue(result.idPrivado);
      this.formB.get('numeroDocOriginal').setValue(result.numeroDoc);
    });
  }

  showModalTipoDocAnexo() {
    const tipoDocumentoModal = this.modalService.create({
      nzTitle: 'Selecione um tipo de documento',
      nzContent: TipoDocumentoComponent,
      nzComponentParams: {
        openModal: true,
      },
      nzFooter: null,
      nzWidth: 1000,
    });
    tipoDocumentoModal.afterClose.subscribe((result: ITipoDoc) => {
      this.setDataTipoDocAnexo(result);
    });
  }

  setDataTipoDoc(data: ITipoDoc) {
    this.formB.patchValue({
      entidadeTipoDoc: data,
      idTipoDocumento: data?.idPublico,
      tipo: data?.tipo,
    });
  }

  setDataTipoDocAnexo(data: ITipoDoc) {
    this.formB.patchValue({
      entidadeTipoDocAnexo: data,
      idTipoDocumentoAnexo: data?.idPublico,
      tipoDocumentoAnexo: data?.tipo,
    });
  }

  setDataSetor(data: any) {
    this.formB.patchValue({
      entidadeSetor: data,
      idSetor: data.idPublico,
      descricaoSetor: data.descricao,
    });
  }

  limparEntidadeTipoDoc() {
    this.formB.get('idTipoDocumento').reset();
    this.formB.get('tipo').reset();
  }

  limparEntidadeTipoDocAnexo() {
    this.formB.get('idTipoDocumentoAnexo').reset();
    this.formB.get('tipoDocumentoAnexo').reset();
  }

  limparEntidadeSetor() {
    this.formB.get('idSetor').reset();
    this.formB.get('descricaoSetor').reset();
    this.formB.get('entidadeSetor').reset();
  }

  formatProgress = () => ``;

  async editDoc(doc: Partial<IDocumento>) {
    this.loading = true;
    this.verifyIfSigned(doc);
    this.resetForm();

    await this.prepareToUpdateDoc(doc);
    this.loading = false;
  }

  async searchTipoDoc(value) {
    if (
      !value ||
      this.formB.value.entidadeTipoDoc ||
      this.formB.value.idPrivado
    ) {
      return;
    }
    clearTimeout(this.intervaloDigitando);
    this.intervaloDigitando = await setTimeout(() => {
      if (this.formB.value.idPrivado) {
        return;
      }
      this.loadingTipoDoc = true;
      this.tipoDocService.getTipoDocsByIdPublico(value).then((result) => {
        this.setDataTipoDoc(result);
      });
      this.loadingTipoDoc = false;
    }, 2000);
  }

  async searchTipoDocAnexo(value) {
    if (
      !value ||
      this.formB.value.entidadeTipoDoc ||
      this.formB.value.idPrivado
    ) {
      return;
    }
    clearTimeout(this.intervaloDigitando);
    this.intervaloDigitando = await setTimeout(() => {
      if (this.formB.value.idPrivado || value) {
        return;
      }
      this.loadingTipoDoc = true;
      this.tipoDocService.getTipoDocsByIdPublico(value).then((result) => {
        this.setDataTipoDocAnexo(result);
      });
      this.loadingTipoDoc = false;
    }, 2000);
  }

  seachSetor(value) {
    if (
      !value ||
      this.formB.value.entidadeSetor
      //  ||
      // this.formB.value.idPrivado
    ) {
      return;
    }

    clearTimeout(this.intervaloDigitandoSetor);
    this.intervaloDigitandoSetor = setTimeout(async () => {
      // if (this.formB.value.idPrivado) {
      //   return;
      // }
      const setor: unknown = await this.consultarSetor({ idPublico: value });

      this.setDataSetor(setor);
    }, 1000);
  }

  private async consultarSetor(params?: Partial<ISetor>): Promise<ISetor> {
    this.loadingTipoDoc = true;

    return await this.setorService
      .getOne(params)
      .then((result: { message: string; data: ISetor }) => {
        this.loadingTipoDoc = false;
        return result.data;
      });
  }

  async searchPessoaFisicaAnexo(value) {
    if (
      (this.formB.get('entidadePessoaFisicaAnexo').value &&
        this.formB.get('entidadePessoaFisicaAnexo').value.cpf === value) ||
      value?.length < 11 ||
      !value
    ) {
      return;
    }

    clearTimeout(this.intervaloDigitando);
    this.intervaloDigitando = await setTimeout(async () => {
      this.loadingPessoaFisica = true;
      await this.pessoaFisicaService
        .getPessoasFisicaByCpf(value)
        .then((result) => {
          this.setDataPessoaFisicaAnexo(result);
          this.loadingPessoaFisica = false;
        })
        .catch(() => (this.loadingPessoaFisica = false));
    }, 1000);
  }

  async searchPessoaFisica(value) {
    if (
      (this.formB.get('entidadePessoaFisica').value &&
        this.formB.get('entidadePessoaFisica').value.cpf === value) ||
      value?.length < 11 ||
      !value
    ) {
      return;
    }

    clearTimeout(this.intervaloDigitando);
    this.intervaloDigitando = await setTimeout(async () => {
      this.loadingPessoaFisica = true;
      await this.pessoaFisicaService
        .getPessoasFisicaByCpf(value)
        .then((result) => {
          this.setDataPessoaFisica(result);
          this.loadingPessoaFisica = false;
        })
        .catch(() => (this.loadingPessoaFisica = false));
    }, 1000);
  }

  async searchPessoaJuridica(value) {
    if (
      (this.formB.get('entidadePessoaJuridica').value &&
        this.formB.get('entidadePessoaJuridica').value.cnpj === value) ||
      value?.length < 14 ||
      !value
    ) {
      return;
    }

    clearTimeout(this.intervaloDigitando);
    this.intervaloDigitando = await setTimeout(async () => {
      this.loadingPessoaJuridica = true;
      await this.pessoaJuridicaService
        .getPessoasJuridicasByCnpj(value)
        .then((result) => {
          this.setSignatario(result);
          this.setDataPessoaJuridica(result);
          this.loadingPessoaJuridica = false;
        })
        .catch(() => (this.loadingPessoaJuridica = false));
    }, 1000);
  }

  async searchPessoaJuridicaAnexo(value) {
    if (
      (this.formB.get('entidadePessoaJuridicaAnexo').value &&
        this.formB.get('entidadePessoaJuridicaAnexo').value.cnpjAnexo ===
          value) ||
      value?.length < 14 ||
      !value
    ) {
      return;
    }

    clearTimeout(this.intervaloDigitando);
    this.intervaloDigitando = await setTimeout(async () => {
      this.loadingPessoaJuridica = true;
      await this.pessoaJuridicaService
        .getPessoasJuridicasByCnpj(value)
        .then((result) => {
          this.setSignatario(result);
          this.setDataPessoaJuridica(result);
          this.loadingPessoaJuridica = false;
        })
        .catch(() => (this.loadingPessoaJuridica = false));
    }, 1000);
  }

  getQtdSelecionados(): number {
    return this.documentList.filter((doc) => doc.checked).length;
  }

  getDocsParaAssinar() {
    return this.documentList.filter(
      (product) =>
        product.statusDocumento === StatusDocumento.Pendente ||
        product.statusDocumento === StatusDocumento.Desacordo,
    );
  }
  getDocsSelecionadosParaAssinar() {
    return this.getDocsParaAssinar().filter((a) => a.checked);
  }

  isUserCreateDoc(doc: IDocumento) {
    return doc.usCriacao === this.service.authService.getIdUser();
  }

  isSigned(doc: IDocumento) {
    return doc.signatarios.some(
      (key) => key.status === StatusDocumento.Assinado,
    );
  }

  resetar() {
    this.checkList = {
      assinantes: true,
      acoes: true,
      anexos: true,
      entidadeAnexos: true,
    };
  }

  resetarConsulta() {
    this.checkListConsulta = {
      numeroDoc: true,
      dataLimiteAssinatura: true,
      tipoDoc: true,
      idSetor: true,
      numProcesso: true,
      dataDocumentoFormated: true,
      tipoDocumento: true,
      numeroDocOriginal: true,
      status: true,
      chancela: true,
      ratificado: true,
      numeroLicitacao: true,
      modalidadeLicitacao: true,
      numeroObra: true,
      numeroContrato: true,
      numeroEvento: true,
      numeroConvenio: true,
      numeroProgramaInstitucional: true,
      usuarioCriacao: true,
      acoes: true,
    };
  }

  checkDataExpiracao(data) {
    if (data) {
      const today = new Date(this.authService.getDateSelected()).getTime();
      const selectedDate = new Date(data).getTime();

      if (selectedDate < today) {
        return this.service.notification.warning(
          'Formulário',
          'Data de expiração não pode ser anterior a data de hoje',
        );
      }

      return;
    }
  }

  validarDataSelecionada(data) {
    const today = new Date(
      this.authService.getDateSelectedWithoutTime(),
    ).getTime();
    const selectedDate = new Date(data).getTime();

    return selectedDate < today;
  }

  async previewDocVinculado(item) {
    if (!(item.numeroDocOriginal || item.hashDocOriginal)) {
      return;
    }
    this.loading = true;
    const document = await this.documentService.getDocumentByDocOriginal({
      numeroDocOriginal: item.numeroDocOriginal,
      hashDocOriginal: item.hashDocOriginal,
    });

    if (!document) {
      this.loading = false;
      return this.service.notification.warning(
        'Documentos avulsos',
        'Documento cancelado, em desacordo ou inexistente',
      );
    }

    const pessoaReturned =
      await this.documentService.getSignatarioByIdDocumento(document.idPrivado);

    const documentFormatted = Object.assign(document, {
      signatarios: pessoaReturned.sort((a, b) => {
        if (a.nome < b.nome) {
          return -1;
        }
        if (a.nome > b.nome) {
          return 1;
        }
        return 0;
      }),
    });
    if (!document) {
      this.loading = false;
      return this.documentService.notification.warning(
        'Documentos avulsos',
        'Documento em desacordo ou cancelado',
      );
    }

    const filterType = this.filterType;
    this.modal.create({
      nzTitle: `<h4>Detalhes do documento</h4>`,
      nzWidth: window.innerWidth * 0.7,
      nzContent: DocumentsDetailComponent,
      nzComponentParams: {
        document: {
          ...documentFormatted,
          tipoDoc: document?.tipoDocumento?.descricao,
        },
        filterType,
      },
      nzFooter: [],
    });

    this.loading = false;
  }

  getResult() {
    let data;
    this.loading = true;
    const hasDash = /\-/;
    const hasBar = /\//;

    if (hasBar.test(this.searchInput) && this.searchInput?.length === 10) {
      data = moment(this.searchInput, 'DD/MM/yyyy').format('yyyy-MM-DD');
    }

    if (hasBar.test(this.searchInput) && this.searchInput?.length === 5) {
      const getYear = new Date().getFullYear();
      this.searchInput = moment(this.searchInput, 'DD/MM').format('MM-DD');
      data = `${getYear}-${this.searchInput}`;
    }

    Object.assign(
      this,
      this.documentService.getResult(
        this.displayData,
        this.documentList,
        hasDash.test(data) ? data : this.searchInput,
      ),
    );
    this.loading = false;
  }

  getNomeStatusDocumento(documento: IDocumento) {
    return StatusDocLabel[this.documentService.getStatusDocumento(documento)];
  }

  getNomeBooleanChancela(documento: IDocumento) {
    return StatusDocLabel[this.documentService.getStatusDocumento(documento)];
  }

  getQuantidadeDeAssinantesProgressBar(documento: IDocumento) {
    const status = this.documentService.getStatusDocumento(documento);

    const totalDeAssinaturas = documento.signatarios?.filter(
      (doc) => doc.status === StatusDocumento.Assinado,
    ).length;
    if (
      [
        StatusDocumento.Cancelado,
        StatusDocumento.Expirado,
        StatusDocumento.Desacordo,
      ].includes(status)
    ) {
      return '';
    }

    return `${totalDeAssinaturas}/${documento.totalSignatarios}`;
  }

  getCorDocumento(documento: IDocumento) {
    const status = this.documentService.getStatusDocumento(documento);
    return this.documentService.themeColors[ColorStatusDoc[status]];
  }

  async mostarProgressBarAndClock(documento: IDocumento) {
    const status = this.documentService.getStatusDocumento(documento);

    if (
      [
        StatusDocumento.Cancelado,
        StatusDocumento.Expirado,
        StatusDocumento.Desacordo,
        StatusDocumento.Confirmado,
        StatusDocumento.Assinado,
      ].includes(status)
    ) {
      return false;
    }
    return true;
  }

  async getCertificados() {
    return await this.assinantesService
      .getCertificadosAssinanteLogado()
      .then((res: any) => {
        if (res.every((cert) => new Date(cert.dtFinalCert) < new Date())) {
          this.documentService.notification.error(
            'Certificado',
            `Renove a validade do seu certificado digital!`,
          );

          this.documentService.tabSessionService.addTab({
            name: 'Meus Certificados',
            url: '/session/meus-certificados',
          });

          this.loading = false;
          return this.router.navigate(['/session/meus-certificados']);
        }

        if (res.every((cert) => cert.status === StatusCertificado.Deletado)) {
          this.documentService.notification.info(
            'Certificado',
            `Carregue um certificado digital válido!`,
          );

          this.documentService.tabSessionService.addTab({
            name: 'Meus Certificados',
            url: '/session/meus-certificados',
          });

          this.loading = false;
          return this.router.navigate(['/session/meus-certificados']);
        }

        if (res.length === 0) {
          this.authService.createModalTermoAceite();

          this.loading = false;
          return true;
        }
        this.certificadoAssinante = res;
        this.loading = false;
        return false;
      })
      .catch((err) => {
        this.loading = false;
        return this.documentService.notification.error('Certificado', `${err}`);
      });
  }

  upload = (file: NzUploadFile): boolean => {
    if (this.anexos.length > 0) {
      this.service.notification.error(
        'Arquivo',
        'Apenas um documento pode ser anexado!',
      );
      return false;
    }
    this.anexos = this.anexos.concat(file);
    const entidadeAnexosForm: FormArray = this.formB.get(
      'entidadeAnexos',
    ) as FormArray;
    entidadeAnexosForm.push(
      this.formBuilder.group({
        files: [file],
      }),
    );

    return false;
  };

  removeFile = (file: NzUploadFile): boolean => {
    for (let i = 0; i < this.anexos.length; i++) {
      this.anexos.splice(i);
    }
    return true;
  };

  getCpfMasked(cpf) {
    return Helper.cpfCnpjMascarado(cpf);
  }
}
