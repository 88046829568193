import { Injectable } from '@angular/core';
import {
  CanDeactivate,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  UrlTree,
} from '@angular/router';
import { Observable } from 'rxjs';
import { NzModalService } from '../antd';

@Injectable({
  providedIn: 'root',
})
export class TabSessionCanDeactivateGuard implements CanDeactivate<any> {
  constructor(private modal: NzModalService) {}
  canDeactivate(
    component: any,
    currentRoute: ActivatedRouteSnapshot,
    currentState: RouterStateSnapshot,
    nextState?: RouterStateSnapshot
  ):
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree>
    | boolean
    | UrlTree {
    return this.confirm();
    // return component?.canDeactivate ? component.canDeactivate : false;
  }

  private confirm(): Observable<boolean> {
    return new Observable((observer) => {
      this.modal.confirm({
        nzTitle: 'Are you sure you want to leave this tab?',
        nzOnOk: () => {
          observer.next(true);
          observer.complete();
        },
        nzOnCancel: () => {
          observer.next(false);
          observer.complete();
        },
      });
    });
  }
}
