import { Component, Input, OnInit } from '@angular/core';

@Component({
  // tslint:disable-next-line: component-selector
  selector: 'ps-unique-form',
  templateUrl: `./ps-unique-form.component.html`,
})
export class PsUniqueFormComponent implements OnInit {
  @Input() title: string;
  @Input() actions: {};

  constructor() {}

  ngOnInit(): void {}
}
