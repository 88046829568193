import { NgModule, ModuleWithProviders, Inject } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import {
  NzFormModule,
  NzInputModule,
  NzLayoutModule,
  NzButtonModule,
  NzIconModule,
  NzDatePickerModule,
  NzInputNumberModule,
  NzModalModule,
  NzTabsModule,
} from '../../antd';
import { NgxMaskModule } from 'ngx-mask';
import {
  FormlyModule,
  FormlyConfig,
  FORMLY_CONFIG,
  ConfigOption,
} from '@ngx-formly/core';
import { FormlyNgZorroAntdModule } from '@ngx-formly/ng-zorro-antd';
import { PsFormGroupComponent } from './custom/ps-form-group.component';
import { PsFormArrayComponent } from './custom/ps-form-array.component';
import { PsFormInputDateComponent } from './custom/ps-form-input-date.component';
import { PsDynamicFormComponent } from './ps-dynamic-form.component';
import { PsFormTemplateComponent, PsFormInputComponent } from './custom';
import { PsInputCurrencyComponent } from './custom/ps-input-currency.component';
import { PsFormWrapperPasswordComponent } from './custom/ps-form-wrapper-password';
import { RouterModule } from '@angular/router';
import {
  minValidatorMessage,
  minlengthValidationMessages,
} from './custom/messages/custom-messages';
import {
  fieldMatchValidator,
  emailValidator,
} from './custom/validators/custom-validators';
import { PsFormTabsComponent } from './custom/ps-form-tabs.component';
import { PsFormSelectComponent } from './custom/ps-form-select.component';
import { GridTypeComponent } from './../ps-grid/types/grid.type';
import { AgGridModule } from 'ag-grid-angular';
import 'ag-grid-enterprise';
import { PsInputLupaComponent } from './custom/ps-input-lupa.component';

@NgModule({
  declarations: [
    PsFormGroupComponent,
    PsFormArrayComponent,
    PsFormInputDateComponent,
    PsInputCurrencyComponent,
    PsFormInputComponent,
    PsFormTabsComponent,
    PsDynamicFormComponent,
    PsFormTemplateComponent,
    PsFormWrapperPasswordComponent,
    GridTypeComponent,
  ],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    RouterModule,
    FormsModule,
    NzModalModule,
    NzInputModule,
    NzInputNumberModule,
    NzLayoutModule,
    NzFormModule,
    NzButtonModule,
    NzIconModule,
    NzDatePickerModule,
    NzTabsModule,
    AgGridModule.withComponents([]),
    NgxMaskModule.forRoot(),
    FormlyNgZorroAntdModule,
    FormlyModule.forRoot({
      types: [
        { name: 'formly-group', component: PsFormGroupComponent },
        { name: 'array', component: PsFormArrayComponent },
        { name: 'date', component: PsFormInputDateComponent, extends: 'input' },
        { name: 'input', component: PsFormInputComponent },
        {
          name: 'currency',
          component: PsInputCurrencyComponent,
          extends: 'input',
        },
        { name: 'tabs', component: PsFormTabsComponent },
        { name: 'grid', component: GridTypeComponent },
        // { name: 'password', component: PsInputPasswordComponent, extends: 'input' },
        { name: 'ps-template', component: PsFormTemplateComponent },
        {
          name: 'ps-select',
          component: PsFormSelectComponent,
          extends: 'select',
        },
      ],
      validationMessages: [
        { name: 'required', message: 'Campo obrigatório' },
        { name: 'email', message: 'Email inválido' },
        { name: 'min', message: minValidatorMessage },
        { name: 'minlength', message: minlengthValidationMessages },
      ],
      validators: [
        { name: 'email', validation: emailValidator },
        { name: 'fieldMatch', validation: fieldMatchValidator },
      ],
      wrappers: [
        {
          name: 'wrapper-password',
          component: PsFormWrapperPasswordComponent,
        },
      ],
    }),
  ],
  exports: [PsDynamicFormComponent, PsInputCurrencyComponent, FormlyModule],
})
export class PsDynamicFormModule {
  static forRoot(
    config: ConfigOption = {}
  ): ModuleWithProviders<PsDynamicFormModule> {
    return {
      ngModule: PsDynamicFormModule,
      providers: [
        // { provide: FORMLY_CONFIG, multi: true, useFactory: defaultFormlyConfig, deps: [FormlyConfig] },
        {
          provide: FORMLY_CONFIG,
          useValue: config,
          multi: true,
          deps: [FormlyConfig],
        },
      ],
    };
  }

  constructor(
    configService: FormlyConfig,
    @Inject(FORMLY_CONFIG) configs: ConfigOption[] = []
  ) {
    if (!configs) {
      return;
    }

    configs.forEach((config) => configService.addConfig(config));
  }
}
