import { ITipoDoc } from './../interfaces/tipo-documento';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AuthService, NzNotificationService } from 'src/@lib';
import {
  AbstractError,
  IColumnsFieldsProps,
  IResponseFieldsProps,
  ISearchFieldsProps,
} from '../interfaces/shared.interface';
import { AbstractService } from './abstract.service';
import { DocumentService } from './document.service';

@Injectable({
  providedIn: 'root',
})
export class TipoDocumentoService extends AbstractService {
  constructor(
    public abstractHttp: HttpClient,
    public abstractAuthService: AuthService,
    public abstractNotification: NzNotificationService,
    public documentService: DocumentService,
  ) {
    super('tipoDoc', abstractHttp, abstractAuthService, abstractNotification);
  }

  async getDocumentTableProps(): Promise<IResponseFieldsProps> {
    const camposPesquisa: ISearchFieldsProps[] = [];
    const colunasTabela: IColumnsFieldsProps[] = [];
    return new Promise((resolve) =>
      resolve({
        message: 'sucesso',
        data: {
          idPublico: {
            isSearch: true,
            label: 'Código',
            columnTable: true,
          },
          tipo: {
            isSearch: true,
            label: 'Tipo do documento',
            columnTable: true,
          },
          tag: {
            isSearch: true,
            label: 'Tag',
            columnTable: true,
          },
          tituloManifesto: {
            isSearch: true,
            label: 'Título do manifesto',
            columnTable: true,
          },
          textoComplementar: {
            isSearch: true,
            label: 'Texto complementar',
            columnTable: true,
          },
          descricao: {
            isSearch: true,
            label: 'Descrição do documento',
            columnTable: true,
          },
        },
      }),
    )
      .then((response: { message: string; data: any }) => {
        camposPesquisa.push({
          text: 'Todos',
          value: 'todos',
        });

        for (const p in response.data) {
          if (response.data[p].isSearch) {
            camposPesquisa.push({
              text: response.data[p].label,
              value: p,
            });
          }
        }
        for (const p in response.data) {
          if (response.data[p].columnTable) {
            colunasTabela.push({
              text: response.data[p].label,
              value: p,
              mask: response.data[p].mask ? response.data[p].mask : null,
              objectChildren: response.data[p].hasOwnProperty('objectChildren')
                ? response.data[p].objectChildren[0]
                : null,
            });
          }
        }
        return { searchColumns: camposPesquisa, tableColumns: colunasTabela };
      })
      .catch((err: AbstractError) => {
        this.notification.error('Propriedades', err.error.message);
        return { searchColumns: camposPesquisa, tableColumns: colunasTabela };
      });
  }

  async getTipoDocumentoValido() {
    return await this.http
      .get(
        `${this.getServer('tipoDoc')}/id-publico/valid`,
        this.headerAuthenticateOptions(),
      )
      .toPromise()
      .then((result: any) => result.data)
      .catch(() => null);
  }

  async getTipoDocs(value: string, all = false): Promise<ITipoDoc[]> {
    return await this.globalSearch(value, all).then((result) => result.data);
  }

  getTipoDocById(idTipoDoc: number): Promise<ITipoDoc> {
    return this.http
      .get(
        this.generateUrlByEnvironment(
          '5017',
          `tipo-documento/${idTipoDoc}`,
          'assinatura-documentos',
        ),
        this.headerAuthenticateOptions(),
      )
      .toPromise()
      .then((result: { message: string; data: ITipoDoc }) => result.data);
  }

  getTipoDocByTag(): Promise<any[]> {
    return this.http
      .get(
        this.generateUrlByEnvironment(
          '5017',
          `tipo-documento/by-tag`,
          'assinatura-documentos',
        ),
        this.headerAuthenticateOptions(),
      )
      .toPromise()
      .then((result: any) => result.data);
  }

  async getTipoDocsByIdPublico(idPublico: string): Promise<ITipoDoc> {
    return await this.getByIdPublico(idPublico).then((result) => result.data);
  }

  async inserir(form: ITipoDoc) {
    return await this.insert(form);
  }

  async alterar(form: ITipoDoc, id: string) {
    return await this.update(form, id);
  }
}
