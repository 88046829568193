import { NzStatisticModule } from 'ng-zorro-antd/statistic';
import { NzProgressModule } from 'ng-zorro-antd/progress';
import { NzEmptyModule } from 'ng-zorro-antd/empty';
import { NzTableModule } from 'ng-zorro-antd/table';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { NgxMaskModule } from 'ngx-mask';

import { PerfectScrollbarModule } from 'ngx-perfect-scrollbar';

import { SearchRecursivePipe } from '../pipes';
import { PsDirectiveModule } from '../directives';
import { PsDividerComponent } from './ps-divider/ps-divider.component';
import { PsHeaderComponent } from './ps-header/ps-header.component';
import { PsSidebarComponent } from './ps-sidebar/ps-sidebar.component';
import { PsFooterComponent } from './ps-footer/ps-footer.component';
import { PsContentComponent } from './ps-content/ps-content.component';
import { BrowserModule } from '@angular/platform-browser';
import { PsTabSessionComponent } from './ps-tab-session/ps-tab-session.component';
import { PsTabSessionService } from './ps-tab-session';
// import { PsGridComponent } from './ps-grid';
import { PsActionsComponent } from './ps-actions';
import { SpeedDialFabComponent } from './speed-dial-fab';
import { PsDynamicFormModule } from './ps-dynamic-form';
import { PsModalLupaComponent } from './ps-modal';
import { PsGridModule } from './ps-grid/ps-grid.module';
import { PsFilterModule } from './ps-filter/ps-filter.module';
import {
  NzAvatarModule,
  NzButtonModule,
  NzCardModule,
  NzDatePickerModule,
  NzDividerModule,
  NzDrawerModule,
  NzDropDownModule,
  NzFormModule,
  NzGridModule,
  NzIconModule,
  NzLayoutModule,
  NzMessageService,
  NzOutletModule,
  NzSelectModule,
  NzSpinModule,
  NzTabsModule,
  NzTagModule,
  NzToolTipModule,
  NzInputModule,
  NzMenuModule,
  NzRadioModule,
  NzCalendarModule,
  NzListModule,
  NzSwitchModule,
  NzModalModule,
  NzCollapseModule,
  NzBadgeModule,
} from '../antd';
import { PsConsultaComponent } from './ps-consulta/ps-consulta.component';
import { PsManutencaoComponent } from './ps-manutencao/ps-manutencao.component';
import { PsSimpleFormComponent } from './ps-simple-form/ps-simple-form.component';
import { PsComplexFormComponent } from './ps-complex-form/ps-complex-form.component';
import { PsUniqueFormComponent } from './ps-unique-form/ps-unique-form.component';
import { PsTableComponent } from './ps-table/ps-table.component';

@NgModule({
  declarations: [
    PsHeaderComponent,
    PsSidebarComponent,
    PsFooterComponent,
    PsContentComponent,
    SearchRecursivePipe,
    PsDividerComponent,
    PsTabSessionComponent,
    PsActionsComponent,
    SpeedDialFabComponent,
    PsModalLupaComponent,
    PsConsultaComponent,
    PsManutencaoComponent,
    PsSimpleFormComponent,
    PsComplexFormComponent,
    PsUniqueFormComponent,
    PsTableComponent,
  ],
  imports: [
    CommonModule,
    BrowserModule,
    BrowserAnimationsModule,
    FormsModule,
    ReactiveFormsModule,
    RouterModule,
    PsDynamicFormModule,
    PerfectScrollbarModule,
    PsDirectiveModule,
    PsGridModule.forRoot({
      gridType: 'antd',
    }),
    NgxMaskModule.forRoot(),
    NzAvatarModule,
    NzBadgeModule,
    NzEmptyModule,
    NzDatePickerModule,
    NzDrawerModule,
    NzSelectModule,
    NzDropDownModule,
    NzLayoutModule,
    NzIconModule,
    NzOutletModule,
    NzProgressModule,
    NzStatisticModule,
    NzToolTipModule,
    NzFormModule,
    NzTagModule,
    NzCardModule,
    NzTabsModule,
    NzSpinModule,
    NzGridModule,
    NzButtonModule,
    NzDividerModule,
    NzInputModule,
    NzMenuModule,
    NzSwitchModule,
    NzRadioModule,
    NzCalendarModule,
    NzTableModule,
    NzListModule,
    NzModalModule,
    NzCollapseModule,
    PsFilterModule,
  ],
  exports: [
    PsHeaderComponent,
    PsSidebarComponent,
    PsFooterComponent,
    PsContentComponent,
    PsDividerComponent,
    PsTabSessionComponent,
    PsActionsComponent,
    SpeedDialFabComponent,
    PsModalLupaComponent,
    PsFilterModule,
    PsConsultaComponent,
    PsManutencaoComponent,
    PsSimpleFormComponent,
    PsComplexFormComponent,
    PsUniqueFormComponent,
    PsTableComponent,
    PerfectScrollbarModule,
  ],
  providers: [PsTabSessionService, NzMessageService],
})
export class PsComponentsModule {}
