export const ptBR = {
  locale: 'pt-br',
  Pagination: {
    items_per_page: '/ página',
    jump_to: 'Vá até',
    jump_to_confirm: 'confirme',
    page: '',
    prev_page: 'Página anterior',
    next_page: 'Próxima página',
    prev_5: '5 páginas anteriores',
    next_5: '5 próximas páginas',
    prev_3: '3 páginas anteriores',
    next_3: '3 próximas páginas',
  },
  DatePicker: {
    lang: {
      placeholder: 'Selecionar data',
      rangePlaceholder: ['Data inicial', 'Data final'],
      rangeYearPlaceholder: ['Ano inicial', 'Ano final'],
      rangeMonthPlaceholder: ['Mês inicial', 'Mês final'],
      rangeWeekPlaceholder: ['Semana inicial', 'Semana final'],
      yearPlaceholder: 'Selecionar ano',
      quarterPlaceholder: 'Selecionar trimestre',
      monthPlaceholder: 'Selecionar mês',
      weekPlaceholder: 'Selecionar semana',
      locale: 'pt_BR',
      today: 'Hoje',
      now: 'Agora',
      backToToday: 'Voltar para hoje',
      ok: 'Ok',
      clear: 'Limpar',
      month: 'Mês',
      year: 'Ano',
      timeSelect: 'Selecionar hora',
      dateSelect: 'Selecionar data',
      monthSelect: 'Escolher mês',
      yearSelect: 'Escolher ano',
      decadeSelect: 'Escolher década',
      yearFormat: 'YYYY',
      dateFormat: 'D/M/YYYY',
      dayFormat: 'D',
      dateTimeFormat: 'D/M/YYYY HH:mm:ss',
      monthBeforeYear: false,
      previousMonth: 'Mês anterior (PageUp)',
      nextMonth: 'Próximo mês (PageDown)',
      previousYear: 'Ano anterior (Control + esquerda)',
      nextYear: 'Próximo ano (Control + direita)',
      previousDecade: 'Década anterior',
      nextDecade: 'Próxima década',
      previousCentury: 'Século anterior',
      nextCentury: 'Próximo século',
    },
    timePickerLocale: {
      placeholder: 'Hora',
    },
  },
  TimePicker: {
    placeholder: 'Hora',
  },
  Calendar: {
    lang: {
      placeholder: 'Selecionar data',
      rangePlaceholder: ['Data inicial', 'Data final'],
      locale: 'pt_BR',
      today: 'Hoje',
      now: 'Agora',
      backToToday: 'Voltar para hoje',
      ok: 'Ok',
      clear: 'Limpar',
      month: 'Mês',
      year: 'Ano',
      timeSelect: 'Selecionar hora',
      dateSelect: 'Selecionar data',
      monthSelect: 'Escolher mês',
      yearSelect: 'Escolher ano',
      decadeSelect: 'Escolher década',
      yearFormat: 'YYYY',
      dateFormat: 'D/M/YYYY',
      dayFormat: 'D',
      dateTimeFormat: 'D/M/YYYY HH:mm:ss',
      monthBeforeYear: false,
      previousMonth: 'Mês anterior (PageUp)',
      nextMonth: 'Próximo mês (PageDown)',
      previousYear: 'Ano anterior (Control + esquerda)',
      nextYear: 'Próximo ano (Control + direita)',
      previousDecade: 'Década anterior',
      nextDecade: 'Próxima década',
      previousCentury: 'Século anterior',
      nextCentury: 'Próximo século',
    },
    timePickerLocale: {
      placeholder: 'Hora',
    },
  },
  Table: {
    filterTitle: 'Filtro',
    filterConfirm: 'OK',
    filterReset: 'Resetar',
    selectAll: 'Selecionar página atual',
    selectInvert: 'Inverter seleção',
  },
  Modal: {
    okText: 'OK',
    cancelText: 'Cancelar',
    justOkText: 'OK',
  },
  Popconfirm: {
    okText: 'OK',
    cancelText: 'Cancelar',
  },
  Transfer: {
    searchPlaceholder: 'Procurar',
    itemUnit: 'item',
    itemsUnit: 'items',
  },
  Upload: {
    uploading: 'Enviando...',
    removeFile: 'Remover arquivo',
    uploadError: 'Erro no envio',
    previewFile: 'Visualizar arquivo',
    downloadFile: 'Baixar arquivo',
  },
  Empty: {
    description: 'Não há dados',
  },
  Text: {
    edit: 'editar',
    copy: 'copiar',
    copied: 'copiado',
    expand: 'expandir',
  },
};
