import { DocumentService } from './../shared/services/document.service';
import { environment } from '../../environments/environment';
import { IMenuButton } from '../../@lib/components/speed-dial-fab/speed-dial-fab.component';
import { ModalDialogComponent } from '../modal-dialog/modal-dialog.component';
import {
  StatusDocLabel,
  StatusProcessDocLabel,
  IconsStatusDoc,
  StatusDoc,
  ColorStatusDoc,
  DateLabel,
  TypeDocDownload,
  TypeDocView,
} from '../shared/consts/document';
import { DomSanitizer } from '@angular/platform-browser';
import {
  IAssinante,
  IDocumentoAnexo,
  IEventTableDetailDocument,
  IUnidadeGestora,
} from '../shared/interfaces/document-interfaces';
import { TypeActionsDoc } from '../shared/types/events.types';
import { NzModalService } from 'ng-zorro-antd/modal';
import { NzNotificationService } from 'ng-zorro-antd/notification';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { ActivatedRoute } from '@angular/router';
import { Component, OnInit } from '@angular/core';
import { FooterConfig, AuthService } from 'src/@lib';
import { IDocumento } from '../shared/interfaces/document-interfaces';
import { StatusDocumento } from '../shared/enum/const.enum';
import * as moment from 'moment';
import { EventsActionsDoc } from '../shared/enum/events.enum';
import { ModalTermoAceiteNouserComponent } from '../modal-termo-aceite-nouser/modal-termo-aceite-nouser.component';
import { DocumentsUploadService } from '../shared/services/documents-upload.service';
import { ITipoDoc } from '../shared/interfaces/tipo-documento';
import { TipoDocumentoService } from '../shared/services/tipo-documento.service';
import { UrlUploadApi } from '../shared/consts/urls-consts';
import { IColumnsFieldsProps } from '../shared/interfaces/shared.interface';

@Component({
  selector: 'app-document-sign',
  templateUrl: './document-sign.component.html',
  styleUrls: ['./document-sign.component.scss'],
})
export class DocumentSignComponent implements OnInit {
  isSpinning = false;
  loadingAnexos = false;
  loadingEvents = false;

  footerConfig: FooterConfig;

  document: IDocumento;
  subscriber: IAssinante[];
  uuidForSign: string;

  allChecked = false;
  indeterminate = true;
  listedAttachs: boolean;
  assinarAnexos: boolean;

  file: string | ArrayBuffer | any;
  newDoc = [];
  anexos = [];
  eventos = [];

  fabButtons: IMenuButton[];

  eventTableDataList: IEventTableDetailDocument[] = [];
  tableColumns: IColumnsFieldsProps[] = [];

  statusDoc = StatusDoc;
  statusDocLabel = StatusDocLabel;

  urlDocumentoOriginal = '';
  urlDocumentoAssinado = '';
  listSignatarios: IAssinante[];
  dadosUnidadeGestora: IUnidadeGestora;
  dadosTipoDocumento: ITipoDoc;
  loadingAnexosSig = false;
  newDocument: IDocumentoAnexo[] = [];

  checkListConsulta = {
    nome: true,
    nomeOriginal: true,
    descricao: true,
    tipoDocumentoAnexo: true,
    status: true,
    uuid: true,
    // anexoSignatarios: true,
  };

  constructor(
    private activateRouter: ActivatedRoute,
    public http: HttpClient,
    public notification: NzNotificationService,
    private modal: NzModalService,
    private sanitizer: DomSanitizer,
    private documentService: DocumentService,
    public tipoDocService: TipoDocumentoService,
    public service: DocumentsUploadService,
    private authService: AuthService,
  ) {}

  getValueDoc() {
    const valor = Number(this.document?.valorDoc);
    return valor?.toLocaleString('pt-BR', {
      style: 'currency',
      currency: 'BRL',
    });
  }

  public headerOptions() {
    return {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'request-date': moment().format(),
      }),
    };
  }

  generateUrlByEnvironment(port: string, path?: string, server = ''): string {
    // if (server === 'assinatura-documentos') {
    //   return `http://localhost:3000/${path}`;
    // }
    return `${environment.apiUrl(server)}/${path}`;
  }

  ngOnInit(): void {
    this.isSpinning = true;

    this.footerConfig = {
      hasIcons: true,
      company: {
        name: 'Publicsoft',
        url: 'https://www.publicsoft.com.br/',
      },
      icons: [
        {
          type: 'instagram',
          url: 'https://www.instagram.com/publicsoft/?hl=pt-br',
        },
        {
          type: 'facebook',
          url: 'https://www.facebook.com/PublicSoftOficial/',
        },
        {
          type: 'twitter',
          url: 'https://twitter.com/publicsoft',
        },
      ],
    };

    this.activateRouter.queryParamMap.subscribe((queryParams) => {
      if (!queryParams.get('key')) {
        return;
      }
      this.uuidForSign = queryParams.get('key');
      this.getDocByUuidForSign();
    });
  }

  resetFabButtons() {
    this.buildFabButtons([
      {
        icon: 'form',
        tooltip: 'Assinar',
        condition: true,
        onClick: this.assinar,
      },
      {
        icon: 'close-circle',
        tooltip: 'Discordar',
        condition: true,
        onClick: this.discordar,
      },
      {
        icon: 'rollback',
        tooltip: 'Desfazer Desacordo',
        condition: true,
        onClick: this.desfazer,
      },
    ]);
  }

  // getNameSubscriber(cpfSignatario: string) {
  //   let pessoaJuridicaResponsavel;
  //   this.document?.signatarios.find(async (item) => {
  //     const pessoaFisica = await this.service.getPessoasById(item.idPessoa);
  //     const pessoaJuridica =
  //       pessoaFisica.pessoa.pessoaJuridica.responsaveis.idPessoaFisica;
  //     pessoaJuridicaResponsavel = await this.service.getPessoasFisicasById(
  //       pessoaJuridica,
  //     );
  //     pessoaJuridicaResponsavel.cpf === cpfSignatario;
  //   });
  //   const nomPessoaResponsavel = pessoaJuridicaResponsavel.pessoa.nome;
  //   return nomPessoaResponsavel;
  // }

  getPanelHeader() {
    this.loadingAnexos = true;
    const panelNames = this.anexos.map((ass) => {
      return { vinculacao: ass.vinculacao };
    });
    const a = panelNames.filter(
      (v, i, a) => a.findIndex((t) => t.vinculacao === v.vinculacao) === i,
    );
    this.newDoc = a;
    this.loadingAnexos = false;
  }

  getResult(value) {
    let data;
    this.isSpinning = true;
    const hasDash = /\-/;
    const hasBar = /\//;

    if (hasBar.test(value) && value?.length === 10) {
      data = moment(value, 'DD/MM/yyyy').format('yyyy-MM-DD');
    }

    if (hasBar.test(value) && value?.length === 5) {
      const getYear = new Date().getFullYear();
      value = moment(value, 'DD/MM').format('MM-DD');
      data = `${getYear}-${value}`;
    }

    Object.assign(
      this,
      this.documentService.getResult(
        this.anexos,
        this.anexos,
        hasDash.test(data) ? data : value,
      ),
    );
    this.isSpinning = false;
  }

  async getURLAnexo(anexo: IDocumentoAnexo, download = false) {
    this.isSpinning = true;
    return await this.documentService
      .getURLAnexo(anexo, download)
      .then((result) => {
        this.eventTableDataList.push(result);
        this.isSpinning = false;
      })
      .catch(() => (this.isSpinning = false));
  }

  setCompareToTableColumns() {
    this.tableColumns.map((column) => {
      column[`compare`] =
        this.anexos.length > 0 &&
        typeof this.anexos[0][column.value] === 'string'
          ? (a: IDocumentoAnexo, b: IDocumentoAnexo) =>
              a[column.value].localeCompare(b[column.value])
          : (a: IDocumentoAnexo, b: IDocumentoAnexo) =>
              a[column.value] - b[column.value];
    });
  }

  getNomeStatusAnexo(anexo: IDocumentoAnexo): string {
    return StatusDocLabel[anexo.status];
  }

  async notifySigner(idPessoa: number, idDocumento: number) {
    const pessoaFisica = await this.service.getPessoasFisicasById(idPessoa);
    this.isSpinning = true;
    await this.documentService.notifySubscriber(pessoaFisica, [idDocumento]);
    this.isSpinning = false;
  }

  getEmail(id: number, index) {
    let email = 'Email não encontrado';

    for (const sig of this.document.signatarios) {
      if (sig.idPessoaFisica === id) {
        email = sig.email;
      }
    }
    return email;
  }

  async setConfigTable() {
    this.isSpinning = true;
    await this.service
      .getDocumentTableProps('documentoAnexo')
      .then((result) => {
        Object.assign(this, result);
        this.setCompareToTableColumns();
      });
    this.isSpinning = false;
  }

  verifyStatusCheckAll(): boolean {
    const statusDocumento = this.documentService.getStatusDocumento(
      this.document,
    );
    if ([StatusDocumento.Pendente].includes(statusDocumento)) {
      return false;
    }
  }

  buildFabButtons(fabButtons: IMenuButton[]) {
    this.fabButtons = fabButtons;
  }

  async modifyDocument(document: IDocumentoAnexo, expand: boolean, index = 0) {
    this.loadingAnexosSig = true;
    try {
      if (document.anexoSignatarios.some((a) => a.nome)) {
        this.loadingAnexosSig = false;

        this.newDocument.push(this.anexos[index]);
        return;
      }

      if (expand === true) {
        const pessoaReturned =
          await this.documentService.getSignatarioByIdDocumentoAnexo(
            document.idPrivado,
          );

        const documentFormatted = Object.assign(document, {
          anexoSignatarios: pessoaReturned.sort((a, b) => {
            if (a.nome < b.nome) {
              return -1;
            }
            if (a.nome > b.nome) {
              return 1;
            }
            return 0;
          }),
        });
        this.loadingAnexosSig = false;
        this.newDocument.push(documentFormatted);
      }
    } catch (error) {
      this.loadingAnexosSig = false;
    }
  }

  async getDocByUuidForSign() {
    await this.http
      .get(
        `${this.generateUrlByEnvironment(
          '5017',
          'document',
          'assinatura-documentos',
        )}/subscriber/${this.uuidForSign}`,
        this.headerOptions(),
      )
      .toPromise()
      .then(async (result: { data: { document: IDocumento } }) => {
        // http://localhost:5504/assinar-documento?key=765d22d1-b893-403f-9bd7-fef7ddd7c484
        this.document = result.data.document;
        this.anexos = result.data.document.documentoAnexo.map((a) => ({
          ...a,
          expand: false,
        }));
        this.resetFabButtons();
        this.setConfigTable();

        this.getUrlDocumentoOriginal(
          this.document.infoUpload.uuidArquivoOriginal,
        )
          .then((resultUrl) => {
            this.isSpinning = false;
            this.urlDocumentoOriginal = resultUrl.url;
            this.setDoc();
          })
          .catch(() => {
            this.isSpinning = false;
          });

        this.subscriber = result.data.document.signatarios;
        const signed = this.subscriber.find(
          (signer) => signer.uuidForSign === this.uuidForSign,
        );

        if (
          // !this.subscriber ||
          // !this.subscriber.certificadoDigitalProps ||
          // (this.subscriber.usuarioTemporario === 1 &&
          this.document.statusDocumento !== StatusDocumento.Confirmado ||
          signed.status !== StatusDocumento.Assinado
        ) {
          await this.createModalTermoAceite();
        }

        this.getPanelHeader();

        this.isSpinning = false;
      })
      .catch(() => {
        this.isSpinning = false;
      });
  }

  getCorDocumento(anexo: any) {
    return this.documentService.themeColors[ColorStatusDoc[anexo.status]];
  }

  async updateAllChecked() {
    await this.documentService
      .updateAllAnexosChecked(this.anexos, this.allChecked, this.indeterminate)
      .then((result) => Object.assign(this, result));
  }

  async getSignatarios(idDoc: number) {
    return await this.documentService.getSignatarioByIdDocumento(idDoc);
  }

  createModalTermoAceite() {
    let loadingButton = false;
    const modal = this.modal.create({
      nzTitle: 'Termo de adesão',
      nzWidth: 1050,
      nzClosable: false,
      nzMaskClosable: false,
      nzContent: ModalTermoAceiteNouserComponent,
      nzOnOk: () => new Promise((resolve) => setTimeout(resolve, 1000)),
      nzFooter: [
        {
          label: 'recusar',
          onClick: () =>
            (window.location.href = `${this.documentService.getUrlAppAssinatura()}/not-found`),
        },
        {
          loading: loadingButton,
          label: 'Aceitar',
          disabled: (componentInstance) =>
            this.disableButton(componentInstance),
          type: 'primary',
          onClick: () => {
            modal.close();
            loadingButton = false;
          },
        },
      ],
    });
  }

  disableButton(component) {
    if (component.formB.invalid) {
      return true;
    }
    if (component.formB.value.isCheckedAceiteTermos === false) {
      return true;
    }
    return false;
  }

  async setEventsTableDoc(events: IEventTableDetailDocument[]) {
    events?.map((event) => (event.data = event.data?.toLocaleString('pt-BR')));
    this.eventTableDataList = events;
  }

  async setDoc() {
    const typeDownload = TypeDocView[this.document.statusDocumento];
    this.isSpinning = true;

    const urlDoc = await this.documentService.getPublicUrlDocToUploadApi(
      await this.documentService.getUuidFile(typeDownload, this.document),
      typeDownload,
    );

    setTimeout(() => (this.isSpinning = false), 3000);
    return (this.file = this.sanitizer.bypassSecurityTrustResourceUrl(urlDoc));
  }

  getStatusDocument() {
    return StatusProcessDocLabel[this.document?.statusDocumento];
  }

  getDataDocFormated() {
    return moment(this.document?.dataDocumento)
      .utcOffset(0, true)
      .format('DD/MM/YYYY HH:mm:ss');
  }

  getIcoStatusSigner(status: StatusDocumento) {
    return IconsStatusDoc[status];
  }

  getcolorStatusSigner(status: StatusDocumento) {
    return StatusDoc[status];
  }

  getLabelByStatus(assinante: IAssinante) {
    return DateLabel[assinante.status];
  }

  getDateSigned(assinante: IAssinante) {
    if (DateLabel[assinante.status] === 'Pendente de assinatura') {
      return '__/__/____';
    }
    return moment(
      assinante?.dataAssinatura ||
        assinante?.dataCancelamento ||
        assinante?.dataDesacordo,
    ).format('DD/MM/YYYY HH:mm:ss');
  }

  getColorStatusSigner(status: StatusDocumento) {
    return ColorStatusDoc[status];
  }

  getEventColor(acao: string) {
    const colors = {
      [EventsActionsDoc.Assinar]: 'blue',
      [EventsActionsDoc.Discordar]: 'red',
      [EventsActionsDoc.DownloadCancelado]: 'black',
      [EventsActionsDoc.DownloadAssinado]: 'green',
      [EventsActionsDoc.DownloadDesacordo]: 'red',
      [EventsActionsDoc.DownloadOriginal]: 'grayLight',
      [EventsActionsDoc.Visualizar]: 'grayLight',
      [EventsActionsDoc.UsuarioCriador]: 'grayLight',
    };

    return colors[acao];
  }

  getEventIco(acao) {
    const icons = {
      [EventsActionsDoc.Assinar]: 'form',
      [EventsActionsDoc.UsuarioCriador]: 'user',
      [EventsActionsDoc.Discordar]: 'close-circle',
      [EventsActionsDoc.DownloadAssinado]: 'download',
      [EventsActionsDoc.DownloadCancelado]: 'download',
      [EventsActionsDoc.DownloadDesacordo]: 'download',
      [EventsActionsDoc.DownloadOriginal]: 'download',
      [EventsActionsDoc.DownloadAnexo]: 'download',
      [EventsActionsDoc.Visualizar]: 'file-search',
      [EventsActionsDoc.Desfazer]: `rollback`,
      [EventsActionsDoc.Cancelar]: `stop`,
      [EventsActionsDoc.VisualizarCancelado]: `file-search`,
      [EventsActionsDoc.VisualizarAssinado]: `file-search`,
      [EventsActionsDoc.VisualizarAnexo]: `folder-open`,
      [EventsActionsDoc.VisualizarAnexoAssinado]: `folder-open`,
      [EventsActionsDoc.VisualizarDocPai]: `folder-open`,
      [EventsActionsDoc.Edicao]: `file`,
      [EventsActionsDoc.AssinarAnexo]: `form`,
      [EventsActionsDoc.DiscordarAnexo]: `close-circle`,
      [EventsActionsDoc.DesfeitoAnexo]: `rollback`,
      [EventsActionsDoc.CancelarAnexo]: `stop`,
      [EventsActionsDoc.RatificarDocumento]: `diff`,
    };

    return icons[acao];
  }

  getDescEventDoc(user: string, acao: TypeActionsDoc, empresa?: string) {
    const text = empresa ? `representante da: ` : '';

    const textUserCreator =
      this.document.avulso === false ? 'via integração' : '';
    const textEmpresa = empresa ? `representante da: ${empresa}` : '';
    const descs = {
      [EventsActionsDoc.UsuarioCriador]: `O Usuário ${user} criou o documento ${textUserCreator}`,
      [EventsActionsDoc.Assinar]: `O signatário ${user} ${text} ${textEmpresa} realizou uma assinatura`,
      [EventsActionsDoc.Visualizar]: `O signatário ${user} ${text} ${textEmpresa} visualizou o documento`,
      [EventsActionsDoc.Discordar]: `O signatário ${user} ${text} ${textEmpresa} não concordou com o documento`,
      [EventsActionsDoc.Desfazer]: `O signatário ${user} ${text} ${textEmpresa} desfez a discordância do documento`,
      [EventsActionsDoc.DownloadAssinado]: `O signatário ${user} ${text} ${textEmpresa} fez o download do documento assinado`,
      [EventsActionsDoc.DownloadCancelado]: `O signatário ${user} ${text} ${textEmpresa} fez o download do documento cancelado`,
      [EventsActionsDoc.DownloadDesacordo]: `O signatário ${user} ${text} ${textEmpresa} fez o download do documento em desacordo`,
      [EventsActionsDoc.DownloadOriginal]: `O signatário ${user} ${text} ${textEmpresa} fez o download do documento original`,
      [EventsActionsDoc.Cancelar]: `O signatário ${user} ${text} ${textEmpresa} cancelou o documento`,
      [EventsActionsDoc.VisualizarDesacordado]: `O signatário ${user} ${text} ${textEmpresa} visualizou o documento em desacordo`,
      [EventsActionsDoc.VisualizarCancelado]: `O signatário ${user} ${text} ${textEmpresa} visualizou o documento cancelado`,
      [EventsActionsDoc.VisualizarAssinado]: `O signatário ${user} ${text} ${textEmpresa} visualizou o documento assinado`,
      [EventsActionsDoc.VisualizarAnexo]: `O signatário ${user} ${text} ${textEmpresa} visualizou um documento anexado`,
      [EventsActionsDoc.VisualizarAnexoAssinado]: `O signatário ${user} ${text} ${textEmpresa} visualizou um anexo assinado`,
      [EventsActionsDoc.DownloadAnexo]: `O signatário ${user} ${text} ${textEmpresa} fez o download de um documento anexado`,
      [EventsActionsDoc.VisualizarDocPai]: `O signatário ${user} ${text} ${textEmpresa} visualizou o documento pai`,
      [EventsActionsDoc.Edicao]: `O signatário ${user} ${text} ${textEmpresa} editou este documento`,
      [EventsActionsDoc.AssinarAnexo]: `O signatário ${user} ${text} ${textEmpresa} assinou um documento anexado`,
      [EventsActionsDoc.DiscordarAnexo]: `O signatário ${user} ${text} ${textEmpresa} discordou um documento anexado`,
      [EventsActionsDoc.DesfeitoAnexo]: `O signatário ${user} ${text} ${textEmpresa} desfez a discordância do documento anexado`,
      [EventsActionsDoc.CancelarAnexo]: `O signatário ${user} ${text} ${textEmpresa} cancelou um documento anexado`,
      [EventsActionsDoc.RatificarDocumento]: `O signatário ${user} ${text} ${textEmpresa} ratificou um documento`,
    };

    return descs[acao];
  }

  assinar = async () => {
    const signed = this.subscriber.some(
      (stats) => stats.status === StatusDocumento.Assinado,
    )
      ? 'signatário está com status Assinado'
      : '';
    const disagreed = this.subscriber.some(
      (stats) => stats.status === StatusDocumento.Desacordo,
    )
      ? 'signatário está com status Desacordo'
      : '';
    const expired = this.documentService.docIsExpirado(
      this.document.dataLimiteAssinatura,
    )
      ? 'documento está Expirado'
      : '';

    if (signed || disagreed || expired) {
      return this.documentService.notification.info(
        'Assinar documento',
        `Não foi possivel assinar o documento, pois o ${disagreed}${signed}${expired}!`,
        { nzDuration: 7000 },
      );
    }

    const subscriberNouser = this.document.signatarios.find(
      (item) => item.uuidForSign === this.uuidForSign,
    );

    const cpfSubscriber =
      subscriberNouser.cpf ?? subscriberNouser.cpfRepresentante;
    const title = `<i nz-icon nzType="warning" nzTheme="outline"> Confirma a assinatura do documento?</i>`;
    const okText = 'Sim';
    const cancelText = 'Não';
    const signedDate = this.authService.getDateSelected();

    this.listedAttachs = this.document.documentoAnexo.length > 0 ? true : false;

    const onOk = async (componentInstance) => {
      if (componentInstance.formB.invalid) {
        return this.notification.warning(
          'Formulário',
          'Por favor, preencha todos os campos corretamente',
        );
      }
      const formData = new FormData();

      formData.append(
        'senhaCert',
        this.documentService.encryptUsingAES256(
          componentInstance.formB.value.senhaCert,
        ),
      );
      formData.append(
        'certificadoDigitalB64',
        componentInstance.formB.value.certificadoDigitalB64,
      );
      formData.append('dateUser', signedDate);
      formData.append('cpf', cpfSubscriber);
      formData.append(
        'idPessoa',
        String(
          subscriberNouser.idPessoaFisica || subscriberNouser.idResponsavel,
        ),
      );
      formData.append(
        'nameUser',
        subscriberNouser.nome || subscriberNouser.nomeRepresentante,
      );
      formData.append(
        'emailPrincipal',
        subscriberNouser.email || subscriberNouser.emailRepresentante,
      );
      formData.append('razaoSocial', subscriberNouser.razaoSocial || '');

      await this.http
        .post(
          `${this.generateUrlByEnvironment(
            '5017',
            'document',
            'assinatura-documentos',
          )}/sign-nouser/${this.uuidForSign}/assinarAnexos=${
            componentInstance.formB.value.assinarAnexos
          }`,
          formData,
        )
        .toPromise()
        .then(async () => {
          this.isSpinning = true;
          modal.close();
          await this.notification.success(
            'Assinar documento',
            'Documento assinado com sucesso!',
          );
          setTimeout(() => {
            this.isSpinning = false;
            window.location.reload();
          }, 7000);
        })
        .catch((err) =>
          this.notification.error(
            'Assinar documento',
            `Error ao assinar documento \n${err}`,
          ),
        );
    };

    const modal = this.modal.create({
      nzTitle: title,
      nzWidth: 850,
      nzContent: ModalDialogComponent,
      nzFooter: [
        { label: cancelText, onClick: () => modal.close() },
        {
          label: okText,
          type: 'primary',
          onClick: (componentInstance) => onOk(componentInstance),
        },
      ],
      nzComponentParams: {
        selecionarCertificado: true,
        attachToSign: this.listedAttachs,
        labelAnexo: 'Assinar',
      },
    });

    modal.afterClose.subscribe((resultClose: any) => {
      if (!resultClose) {
        modal.destroy();
      }
    });
  };

  discordar = () => {
    const signed = this.subscriber.some(
      (stats) => stats.status === StatusDocumento.Assinado,
    )
      ? 'Assinado'
      : '';
    const disagreed = this.subscriber.some(
      (stats) => stats.status === StatusDocumento.Desacordo,
    )
      ? 'Desacordo'
      : '';
    const expired = this.documentService.docIsExpirado(
      this.document.dataLimiteAssinatura,
    )
      ? 'Expirado'
      : '';
    if (signed || disagreed || expired) {
      return this.documentService.notification.info(
        'Discordar documento',
        `Não foi possivel assinar o documento, pois o signatário está com status ${disagreed}${signed}${expired}!`,
        { nzDuration: 7000 },
      );
    }

    if (this.document.statusDocumento === StatusDocumento.Desacordo) {
      return this.documentService.notification.info(
        'Desacordo',
        `Não foi possivel discordar do documento, pois o documento selecionado está com status em Desacordo!`,
        { nzDuration: 7000 },
      );
    }

    const subscriberNouser = this.document.signatarios.find(
      (item) => item.uuidForSign === this.uuidForSign,
    );
    const cpfSubscriber =
      subscriberNouser.cpf ?? subscriberNouser.cpfRepresentante;
    const title = `<i nz-icon nzType="warning" nzTheme="outline"> Confirma o desacordo do documento selecionado?</i>`;
    const okText = 'Sim';
    const cancelText = 'Não';
    const dateUser = this.authService.getDateSelected();

    this.service.assinarAnexo.subscribe(async (data) => {
      this.assinarAnexos = data;
    });

    this.listedAttachs = this.document.documentoAnexo.length > 0 ? true : false;

    const onOk = async (motivo) => {
      await this.http
        .post(
          `${this.generateUrlByEnvironment(
            '5017',
            'document',
            'assinatura-documentos',
          )}/disagree-nouser/${this.uuidForSign}/discordarAnexos=${
            this.assinarAnexos
          }`,
          {
            motivo,
            dateUser,
            cpf: cpfSubscriber,
            idPessoa:
              subscriberNouser.idPessoaFisica || subscriberNouser.idResponsavel,
            nameUser:
              subscriberNouser.nome || subscriberNouser.nomeRepresentante,
            emailPrincipal:
              subscriberNouser.email || subscriberNouser.emailRepresentante,
            razaoSocial: subscriberNouser.razaoSocial,
          },
          this.headerOptions(),
        )
        .toPromise()
        .then(async (result: { message: string; data: any }) => {
          this.isSpinning = true;
          modal.close();
          await this.notification.success(
            'Discordar documento',
            result.message,
          );
          setTimeout(() => {
            this.isSpinning = false;
            window.location.reload();
          }, 10000);
        })
        .catch((err) =>
          this.notification.error(
            'Discordar documento',
            'Error ao discordar documento.' + err,
          ),
        );
    };

    const modal = this.modal.create({
      nzTitle: title,
      nzContent: ModalDialogComponent,
      nzFooter: [
        { label: cancelText, onClick: () => modal.close() },
        {
          label: okText,
          disabled: (componentInstance) => !componentInstance.motivo,
          type: 'primary',
          onClick: (componentInstance) => onOk(componentInstance.motivo),
        },
      ],
      nzComponentParams: {
        tipoDocumento: `${this.document.tipoDocumento.tipo}`,
        existMotivo: true,
        attachToSign: this.listedAttachs,
        labelAnexo: 'Discordar',
      },
    });

    modal.afterClose.subscribe((resultClose: any) => {
      if (!resultClose) {
        modal.destroy();
      }
    });
  };

  desfazer = async () => {
    const signed = this.subscriber.some(
      (stats) => stats.status === StatusDocumento.Assinado,
    )
      ? 'Assinado'
      : '';
    const pending = this.subscriber.some(
      (stats) => stats.status === StatusDocumento.Pendente,
    )
      ? 'Pendente'
      : '';
    const expired = this.documentService.docIsExpirado(
      this.document.dataLimiteAssinatura,
    )
      ? 'Expirado'
      : '';
    if (signed || pending || expired) {
      return this.documentService.notification.info(
        'Desfazer desacordo',
        `Não foi possivel assinar o documento, pois o signatário está com status ${signed}${pending}${expired}!`,
        { nzDuration: 7000 },
      );
    }

    const subscriberNouser = this.document.signatarios.find(
      (item) => item.uuidForSign === this.uuidForSign,
    );
    const cpfSubscriber =
      subscriberNouser.cpf ?? subscriberNouser.cpfRepresentante;

    const title = `<i nz-icon nzType="warning" nzTheme="outline"> Confirma o desfazer da discordância do documento selecionado?</i>`;
    const okText = 'Sim';
    const cancelText = 'Não';
    const dateUser = this.authService.getDateSelected();
    const onOk = async (motivo) => {
      await this.http
        .post(
          `${this.generateUrlByEnvironment(
            '5017',
            'document',
            'assinatura-documentos',
          )}/desfazer-nouser/${this.uuidForSign}`,
          {
            motivo,
            dateUser,
            cpf: cpfSubscriber,
            idPessoa:
              subscriberNouser.idPessoaFisica || subscriberNouser.idResponsavel,
            nameUser:
              subscriberNouser.nome || subscriberNouser.nomeRepresentante,
            emailPrincipal:
              subscriberNouser.email || subscriberNouser.emailRepresentante,
            razaoSocial: subscriberNouser.razaoSocial,
          },
          this.headerOptions(),
        )
        .toPromise()
        .then(async (result: { message: string; data: any }) => {
          this.isSpinning = true;
          modal.close();
          await this.notification.success('Desfazer desacordo', result.message);
          setTimeout(() => {
            this.isSpinning = false;
            window.location.reload();
          }, 5000);
        })
        .catch((err) =>
          this.notification.error(
            'Desfazer desacordo',
            'Error ao Desfazer desacordo.' + err,
          ),
        );
    };
    const modal = this.modal.create({
      nzTitle: title,
      nzContent: ModalDialogComponent,
      nzFooter: [
        { label: cancelText, onClick: () => modal.close() },
        {
          label: okText,
          disabled: (componentInstance) => !componentInstance.motivo,
          type: 'primary',
          onClick: (componentInstance) => onOk(componentInstance.motivo),
        },
      ],
      nzComponentParams: {
        tipoDocumento: `${this.document.tipoDocumento.tipo}`,
        existMotivo: true,
      },
    });

    modal.afterClose.subscribe((resultClose: any) => {
      if (!resultClose) {
        modal.destroy();
      }
    });
  };

  isActiveFabButton() {
    const assinante = this.listSignatarios?.find(async (ass) => {
      // const pessoaFisica = await this.service.getPessoasById(ass.idPessoa);
      // const pessoaJuridica =
      //   pessoaFisica.pessoa.pessoaJuridica.responsaveis.idPessoaFisica;
      // const pessoaJuridicaResponsavel =
      //   await this.service.getPessoasFisicasById(pessoaJuridica);
    });
    return (
      this.document.statusDocumento !== StatusDocumento.Confirmado &&
      this.document.statusDocumento !== StatusDocumento.Cancelado &&
      assinante?.status !== StatusDocumento.Confirmado
    );
  }

  async getUrlDocumentoOriginal(uuid: string): Promise<any> {
    UrlUploadApi;
    return await this.http
      .get(
        this.authService.generateUrlByEnvironment(
          '80',
          `api/arquivo/url/${uuid}`,
          'upload',
        ),
      )
      .toPromise();
  }

  resetarConsulta() {
    this.checkListConsulta = {
      nome: true,
      nomeOriginal: true,
      descricao: true,
      tipoDocumentoAnexo: true,
      status: true,
      uuid: true,
    };
  }
}
