import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { NzModalRef } from 'ng-zorro-antd/modal';
import { NgxMaskModule } from 'ngx-mask';
import { ModalTermoAceiteNouserComponent } from './modal-termo-aceite-nouser/modal-termo-aceite-nouser.component';
import { NotFoundComponent } from './not-found/not-found.component';
import { AbstractService } from './shared/services/abstract.service';
import { DashboardService } from './shared/services/dashboard.service';
import { DetailDocumentService } from './shared/services/detail-document.service';
import { DocumentsPendingService } from './shared/services/documents-pending.service';
import { DocumentsUploadService } from './shared/services/documents-upload.service';
import { FinishedDocumentService } from './shared/services/finished-document.service';
import { MensagensService } from './shared/services/mensagens.service';
import { ModalTermoAceiteService } from './shared/services/modal-termo-aceite.service';
import { ModifyDocumentService } from './shared/services/modify-document.service';
import { PessoaFisicaService } from './shared/services/pessoa-fisica.service';
import { SetorService } from './shared/services/setor.service';
import { TipoDocumentoService } from './shared/services/tipo-documento.service';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';

import { HttpClientModule } from '@angular/common/http';
import {
  PsComponentsModule,
  PsDirectiveModule,
  PsDynamicFormModule,
  PsGridModule,
  PsUiModule,
} from 'src/@lib';

import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgChartjsModule } from 'ng-chartjs';
import { AnalitcsComponent } from './analitcs/analitcs.component';
import { AnexosComponent } from './anexos/anexos.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { DocumentSignComponent } from './document-sign/document-sign.component';
import { DocumentosFinalizadosComponent } from './documentos-finalizados/documentos-finalizados.component';
import { DocumentsDetailComponent } from './documents-detail/documents-detail.component';
import { DocumentsInvalidsComponent } from './documents-invalids/documents-invalids.component';
import { DocumentsUploadComponent } from './documents-upload/documents-upload.component';
import { DocumentsComponent } from './documents/documents.component';
import { ExportacaoArquivosComponent } from './exportacao-arquivos/exportacao-arquivos.component';
import { IframeFileExportComponent } from './iframe-file-export/iframe-file-export.component';
import { MensagensEnvioComponent } from './mensagens-envio/mensagens-envio.component';
import { MensagensComponent } from './mensagens/mensagens.component';
import { MeusDocumentosComponent } from './meus-documentos/meus-documentos.component';
import { ModalDialogComponent } from './modal-dialog/modal-dialog.component';
import { ModalTermoAceiteComponent } from './modal-termo-aceite/modal-termo-aceite.component';
import { PerfilComponent } from './perfil/perfil.component';
import { PessoaFisicaComponent } from './pessoa-fisica/pessoa-fisica.component';
import { PessoaJuridicaComponent } from './pessoa-juridica/pessoa-juridica.component';
import { SetorComponent } from './setor/setor.component';
import { AssinantesService } from './shared/services/assinantes.service';
import { DocumentService } from './shared/services/document.service';
import { PessoaJuridicaService } from './shared/services/pessoa-juridica.service';
import { TipoDocumentoComponent } from './tipo-documento/tipo-documento.component';
import { VerifyDocumentComponent } from './verify-document/verify-document.component';

@NgModule({
  declarations: [
    MensagensComponent,
    AppComponent,
    AnalitcsComponent,
    NotFoundComponent,
    DashboardComponent,
    DocumentsComponent,
    DocumentosFinalizadosComponent,
    ModalTermoAceiteComponent,
    DocumentsDetailComponent,
    DocumentsUploadComponent,
    PessoaFisicaComponent,
    PessoaJuridicaComponent,
    TipoDocumentoComponent,
    VerifyDocumentComponent,
    DocumentSignComponent,
    ModalDialogComponent,
    PerfilComponent,
    DocumentsInvalidsComponent,
    MeusDocumentosComponent,
    ModalTermoAceiteNouserComponent,
    AnalitcsComponent,
    ExportacaoArquivosComponent,
    AnexosComponent,
    IframeFileExportComponent,
    MensagensComponent,
    MensagensEnvioComponent,
    SetorComponent,
  ],
  imports: [
    CommonModule,
    BrowserModule,
    ReactiveFormsModule,
    FormsModule,
    HttpClientModule,
    NgChartjsModule,
    NgxMaskModule,
    AppRoutingModule,
    PsDynamicFormModule.forRoot({
      validationMessages: [{ name: 'pattern', message: 'Formato invalido' }],
    }),
    PsGridModule.forRoot({ gridType: 'antd' }),
    PsUiModule.forRoot({ appName: 'Assinatura Digital', appVersion: '1.0.0' }),
    PsComponentsModule,
    PsDirectiveModule,
  ],
  providers: [
    { provide: NzModalRef, useValue: {} },
    MensagensService,
    AbstractService,
    ModifyDocumentService,
    DocumentService,
    DashboardService,
    DocumentsPendingService,
    PessoaFisicaService,
    PessoaJuridicaService,
    FinishedDocumentService,
    DetailDocumentService,
    ModalTermoAceiteService,
    DocumentsUploadService,
    TipoDocumentoService,
    AssinantesService,
    SetorService,
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
